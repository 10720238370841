import React from 'react';
import {
  GetClientKvKRes,
  GetClientRes,
  KvkDetails,
  SearchedClinetRes,
  SimpleKvkResponse,
} from 'app-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { TableResponse } from 'app-tables';
import { Spinner } from '../../../components/Common';
import debounce from '../../../utils/debounce';
import { __ } from '../../../services/translation';
import Suggestion from './Suggestion';
import './ClientAutosuggestion.scss';

import ApiService from '../../../services/api-service';
import RequiredIndicator from '../RequiredIndicator';
import NewClientSuggestion from './NewClientSuggestion';

interface Props {
  kvkOnly?: boolean;
  allowNewClient?: boolean;
  onNewClientSelect?: (name: string) => void;
  forForm?: 'invoiceForm' | 'offerForm';
  input: {
    onChange: (data: any) => void;
    name: string;
    id: string;
    value: string;
  };
  placeholder: string;
  onOptionSelect: (data: GetClientKvKRes) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  meta: { error: any; touched: any };
}

interface State {
  active: boolean;
  resultsKvk: SimpleKvkResponse[];
  resultsClients: GetClientRes[];
  loading: boolean;
}

class ClientAutosuggestion extends React.Component<Props, State> {
  debouncedSearch: any;

  constructor(props: Props) {
    super(props);
    this.debouncedSearch = debounce(this.fetchList, 200);

    this.state = {
      active: false,
      resultsKvk: [],
      resultsClients: [],
      loading: false,
    };
  }

  private fetchList = async (query: string) => {
    const { kvkOnly } = this.props;
    const result: { resultsKvk: SimpleKvkResponse[]; resultsClients: GetClientRes[] } = {
      resultsKvk: [],
      resultsClients: [],
    };
    const listKvk: SearchedClinetRes = await ApiService.callFetch(
      'GET',
      `client/search-data/${query}`,
    );
    result.resultsKvk = listKvk.items;
    if (!kvkOnly) {
      const listClients: TableResponse<GetClientRes> = await ApiService.callFetch(
        'GET',
        `client/list/?alternative=true&searchBy=name,firstName,lastName,email&searchQuery=${query},${query},${query},${query}`,
      );
      result.resultsClients = listClients.items;
    }
    this.setState({
      ...result,
      loading: false,
    });
  };

  private handleChange = (val: string) => {
    const { input } = this.props;

    input.onChange(val);
    this.setState({ loading: true, active: true });
    this.debouncedSearch(val);
  };

  private handleOwnClientSelect = (option: GetClientKvKRes) => {
    const { onOptionSelect } = this.props;
    onOptionSelect(option);
  };

  private handleOptionSelect = (option: SimpleKvkResponse) => {
    const { onOptionSelect } = this.props;
    ApiService.callFetch('GET', `kvk/details/${option.kvk}`, (data: GetClientKvKRes) => {
      onOptionSelect(data);
    });
  };

  render() {
    const {
      input,
      label,
      placeholder,
      required,
      meta,
      allowNewClient,
      onNewClientSelect,
      disabled,
      forForm,
    } = this.props;
    const { resultsClients, resultsKvk, loading, active } = this.state;
    const inputLabel = label && (
      <label htmlFor={input.name}>
        {__(label)} {required && <RequiredIndicator />}{' '}
      </label>
    );
    return (
      <div className="input-container">
        {inputLabel}

        <div className="input-sugestion-container">
          <div className="input-group">
            <input
              readOnly={disabled}
              autoComplete="off"
              className="client-autosuggestion-input"
              type="text"
              id="client-autosuggestion-input"
              value={input.value}
              onChange={(e: any) => this.handleChange(e.target.value)}
              onFocus={(e: any) => {
                if (!disabled) {
                  this.handleChange(e.target.value);
                  this.setState({ active: true });
                }
              }}
              onBlur={(e: any) => window.setTimeout(() => this.setState({ active: false }), 100)}
              placeholder={__(placeholder)}
            />

            {!disabled && (
              <FontAwesomeIcon icon={faSearch} className="client-autosuggestion-icon" />
            )}
          </div>

          {active &&
            (resultsKvk.length ||
              resultsClients.length ||
              loading ||
              (allowNewClient && input.value)) && (
              <ul className="client-suggestions">
                {loading && <Spinner overlay />}
                {resultsClients.length > 0 && (
                  <>
                    <li className="client-suggestions-separator">
                      {__('application.your_clients')}
                    </li>
                    {resultsClients.map((r: GetClientRes) => (
                      <Suggestion
                        forForm={forForm}
                        item={r}
                        onSelect={(client: GetClientRes | SimpleKvkResponse) =>
                          this.handleOwnClientSelect(client as GetClientRes)
                        }
                        key={r.kvk}
                      />
                    ))}
                  </>
                )}
                {resultsKvk.length > 0 && (
                  <>
                    <li className="client-suggestions-separator">{__('application.sugestions')}</li>
                    {resultsKvk.map((r: SimpleKvkResponse) => (
                      <Suggestion
                        forForm={forForm}
                        item={r}
                        onSelect={this.handleOptionSelect}
                        key={r.kvk}
                      />
                    ))}
                  </>
                )}
                {allowNewClient && typeof onNewClientSelect === 'function' && input.value && (
                  <>
                    <li className="client-suggestions-separator">{__('application.new_client')}</li>
                    <NewClientSuggestion name={input.value} onNewClientSelect={onNewClientSelect} />
                  </>
                )}
              </ul>
            )}
          {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
        </div>
      </div>
    );
  }
}

export default ClientAutosuggestion;
