import { UserEntity } from '../user';

export interface PaymentUser {
  companyName?: string;
  addressCity?: string;
  addressPostCode?: string;
  addressStreet?: string;
  kvk?: string;
  nip?: string;
  email: string;
  accountNumber?: string;
  bic?: string;
}

export interface PaymentRes {
  id: string;
  date: Date;
  amount: number;
  isPaid: boolean;
  invoiceNumber: string;
  status: PaymentStatus;
  companyName: string;
}

export interface FullPaymentRes extends PaymentRes {
  isManuallyApproved: boolean;
  email: string;
}

export enum PaymentType {
  'monthly', //legacy
  'yearly', //legacy
  'certificate',
  'uittreksel',
}

export enum AccountType {
  'basic',
  'professional',
}

export interface PreparePaymentRequest {
  redirectPath: string;
  paymentType: PaymentType;
  accountType: AccountType;
}

export enum AccountPrice {
  BasicMonthly = 9.99,
  BasicYearly = 99.9,
  ProfessionalMonthly = 13.99,
  ProfessionalYearly = 139.9,
}

export interface PaymentUrl {
  href: string;
}

export enum PaymentStatus {
  OPEN,
  CANCELED,
  PENDING,
  AUTHORIZED,
  EXPIRED,
  FAILED,
  PAID,
}

export interface PaymentAdditionalData {
  subscriptionTo?: Date,
  subscriptionFrom?: Date
}