import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserRole } from 'app-types';
import { Redirect, useParams } from 'react-router';
import { WelcomeHeader, Logo, PromoImage } from '../../components/Common';
import { Page, Content } from '../../components/Layout';
import WelcomeFooter from './WelcomeFooter';
import { auth, modal } from '../../actions';
import { ApplicationState } from '../../reducers';
import ApiService from '../../services/api-service';
import { AccountActivated } from '../../modals';
import { history } from '../../App';
import useHeader from '../../hooks/useHeader';

// const { LocalNotifications, PushNotifications } = Plugins;

const Welcome = () => {
  useHeader('');
  const dispatch = useDispatch();
  const { userId, updateToken } = useParams<{ userId: string; updateToken: string }>();
  const user = useSelector((state: ApplicationState) => state.user.details);

  const activate = async () => {
    dispatch(auth.logout());
    await ApiService.callFetch(
      'PATCH',
      `user/activate/${userId}/${updateToken}`,
      () => {
        dispatch(modal.showModal(<AccountActivated />));
      },
      () => {
        history.push('/');
      },
    );
  };

  if (userId && updateToken) {
    activate();
  } else {
    if (user && user.role === UserRole.Admin) return <Redirect to="/admin" />;
    if (user) return <Redirect to="/home" />;
  }

  return (
    <>
      <Page center stretch primary padded>
        <Logo big animate />
        <Content center>
          <WelcomeHeader text="application.welcome" />
          <PromoImage />
        </Content>
        <span />
        <WelcomeFooter />
      </Page>
    </>
  );
};

export default Welcome;
