export const parseTimeInput = (val: any) => {
  if (typeof val === 'undefined' || !val) return val;

  const value = val.toString();

  const parts = value.split(':');
  const hour = parseInt(parts[0]);
  const minutes = parseInt(parts[1]);

  let parsedHour = '00';
  let parsedMinutes = '00';

  if (!hour || hour < 0) parsedHour = '00';
  else if (hour > 23) parsedHour = '24';
  else if (hour < 10) parsedHour = `0${hour}`;
  else parsedHour = hour.toString();

  if (!minutes || minutes < 0) parsedMinutes = '00';
  else if (minutes > 59) parsedMinutes = '59';
  else if (minutes < 10) parsedMinutes = `0${minutes}`;
  else parsedMinutes = minutes.toString();

  return `${parsedHour}:${parsedMinutes}`;
};
