import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { UserRole, ClientUserEntity } from 'app-types';
import { bindActionCreators } from 'redux';
import { Swipeable } from 'react-swipeable';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEuroSign,
  faKey,
  faBalanceScaleLeft,
  faEdit,
  faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';
import MenuShadow from './MenuShadow';
import UserInfo from './UserInfo';
import MenuItems from './MenuItems';
import { menu, auth, user } from '../../../actions';
import { ApplicationState } from '../../../reducers';
import { MenuItemType } from '../../../types/menu';
import MenuHeader from './MenuHeader';
import { isMobileBuild } from '../../../utils/is-mobile';
import ManagedUser from './ManagedUser';
import './Menu.scss';
import { __ } from '../../../services/translation';

interface Props {
  toggleMenu: () => void;
}

const Menu = ({ toggleMenu }: Props) => {
  const userDetails = useSelector((state: ApplicationState) => state.user.details);

  const { hideTrialIndicator, toggled } = useSelector((state: ApplicationState) => state.menu);
  const { managedUser } = useSelector((state: ApplicationState) => state.accounting);
  const { count: invitationsCount } = useSelector(
    (state: ApplicationState) => state.pendingInvitations,
  );

  if (!userDetails) return null;

  const menuItems: MenuItemType[] = [
    {
      to: '/dashboard/invitations/',
      name: __('application.invitations', { invitationsCount }),
      icon: 'contacts',
      inactive: userDetails.role !== UserRole.Accountant,
      translate: false,
    },
    {
      to: '/dashboard/clients-and-contacts/',
      name: 'application.clients_and_contacts',
      icon: 'contacts',
      inactive: userDetails.role === UserRole.Accountant && !managedUser,
      translate: true,
    },
    {
      to: '/dashboard/time-sheets',
      name: 'application.time_sheets',
      icon: 'time_sheets',
      inactive: userDetails.role === UserRole.Accountant && !managedUser,
      translate: true,
    },
    {
      to: '/dashboard/mileages',
      name: 'application.mileages',
      icon: 'mileages',
      inactive: userDetails.role === UserRole.Accountant && !managedUser,
      translate: true,
    },
    {
      to: '/dashboard/costs',
      name: 'application.costs',
      icon: 'costs',
      inactive: userDetails.role === UserRole.Accountant && !managedUser,
      translate: true,
    },
    {
      to: '/dashboard/other-income',
      name: 'application.other_income',
      icon: 'other-income',
      inactive: userDetails.role === UserRole.Accountant && !managedUser,
      translate: true,
    },
    {
      to: '/dashboard/invoices',
      name: 'application.invoices',
      icon: 'invoices',
      inactive: userDetails.role === UserRole.Accountant && !managedUser,
      translate: true,
    },
    {
      to: '/dashboard/statistics',
      name: 'application.statistics',
      icon: 'statistics',
      inactive: userDetails.role === UserRole.Accountant && !managedUser,
      translate: true,
    },
    {
      to: '/dashboard/send-to-accountant',
      name: 'application.send_to_accountant',
      icon: 'send_documents',
      inactive: userDetails.role === UserRole.Accountant && !managedUser,
      translate: true,
    },
    {
      to: '/dashboard/generator',
      name: 'application.generator',
      icon: 'generator',
      inactive: userDetails.role === UserRole.Accountant && !managedUser,
      translate: true,
    },
    {
      to: '/dashboard/addons',
      name: 'application.addons',
      icon: 'cog',
      restrict: [UserRole.Client],
      translate: true,
    },
    {
      name: 'application.accountants',
      to: '/dashboard/accountants',
      icon: 'bookkepeers',
      restrict: [UserRole.Client],
      indicator: true,
      translate: true,
    },
  ];

  const menuItemsAdmin: MenuItemType[] = [
    {
      name: 'application.client_list',
      to: '/admin/client-list',
      icon: 'clients',
      translate: true,
    },
    {
      name: 'application.accountant_list_for_admin',
      to: '/admin/accountant-list',
      icon: 'clients',
      translate: true,
    },
    {
      to: '/admin/notifications',
      name: 'application.notifications',
      icon: 'bell',
      translate: true,
    },
    {
      to: '/admin/stats',
      name: 'admin.stats',
      icon: 'statistics',
      translate: true,
    },
    {
      to: '/admin/certificate-requests',
      name: 'application.certificate_requests',
      icon: 'list',
      translate: true,
    },
    {
      name: 'application.payments',
      to: '/admin/payments',
      icon: <FontAwesomeIcon icon={faEuroSign} className="dashboard-navigation-icon" />,
      translate: true,
    },
    {
      name: 'application.change_password',
      to: '/admin/change-password',
      icon: <FontAwesomeIcon icon={faKey} className="dashboard-navigation-icon" />,
      translate: true,
    },
    {
      name: 'application.cheaters',
      to: '/admin/cheaters',
      icon: <FontAwesomeIcon icon={faBalanceScaleLeft} className="dashboard-navigation-icon" />,
      translate: true,
    },
    {
      name: 'application.update_requests',
      to: '/admin/edition-request',
      icon: <FontAwesomeIcon icon={faEdit} className="dashboard-navigation-icon" />,
      translate: true,
    },
    {
      name: 'application.invoice_number',
      to: '/admin/invoice-number',
      icon: <FontAwesomeIcon icon={faFileInvoice} className="dashboard-navigation-icon" />,
      translate: true,
    },
    {
      name: 'application.settings',
      to: '/admin/settings',
      icon: 'settings',
      translate: true,
    },
  ];

  const isTrial = () => {
    if (isMobileBuild()) return false; // hide info on mobiles
    if (hideTrialIndicator) return false;
    if (userDetails && userDetails.role === UserRole.Client) {
      const { trial } = userDetails as ClientUserEntity;
      return trial;
    }
    return false;
  };

  const showMenu =
    userDetails && (userDetails as ClientUserEntity).isActiveSub !== null ? (
      <MenuItems items={userDetails.role === UserRole.Admin ? menuItemsAdmin : menuItems} />
    ) : null;
  if (!userDetails) return null;

  return (
    <Swipeable onSwipedLeft={toggleMenu} className="swipeable-menu">
      <MenuShadow />
      <div
        className={clsx('menu-container', {
          toggled,
          'not-logged': !user,
          trial: isTrial(),
        })}
      >
        <ManagedUser />
        <MenuHeader />
        <UserInfo />
        {showMenu}
      </div>
    </Swipeable>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ toggleMenu: menu.toggleMenu, logout: auth.logout }, dispatch);

export default connect(null, mapDispatchToProps)(Menu);
