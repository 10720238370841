import React, { useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { CategoryRes, CostRes, GetCostSchemeRes } from 'app-types';
import ApiService from '../../../services/api-service';
import {
  Spinner,
  Button,
  RequiredIndicator,
  CostSchemeAutosuggestion,
} from '../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../components/Layout';
import { modal } from '../../../actions';
import { flatpickr } from '../../../App';
import { formOnTranslateString } from '../../../utils/trans-form';

import CostFieldsMapper from '../../../views/DashboardClient/Costs/CostFieldsMapper';
import AddCostScheme from '../../../modals/AddCostScheme';
import AddCategory from '../../../modals/AddCategory';

interface Props {
  formUrl: string;
  formSendUrl: string;
  method: string;
  callback?: (data: CostRes) => void;
}

const CostForm = ({ formUrl, formSendUrl, method, callback }: Props) => {
  const dispatch = useDispatch();
  const [selectedCategory, selectCategory] = useState<CategoryRes | null>();

  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  const registerFormCallbacks = () => {
    const currentCategoryId = itfForm.api.getValue('categoryId');
    const currentCategoryName = itfForm.api.getValue('category');
    if (currentCategoryId) {
      selectCategory({
        title: currentCategoryName,
        id: currentCategoryId,
        description: '',
      });
    }
  };

  const onOptionSelect = (option: GetCostSchemeRes) => {
    let newValues = {
      name: option.name,
      taxRate: option.taxRate,
    } as GetCostSchemeRes;
    if (option.valueType === 0) {
      newValues.value = option.value;
    }
    itfForm.api.setValues(newValues);
  };

  const onNewNameSelect = (name: string) => {
    itfForm.api.setValues({
      name,
    });
  };

  const onCreate = (name: string) => {
    dispatch(modal.showModal(<AddCostScheme name={name} callback={onOptionSelect} />));
  };

  const onCategorySelect = (category: CategoryRes | null) => {
    if ((!category && selectedCategory) || category) {
      if (itfForm) {
        itfForm.api.setValue('category', category ? category.title : null);
        selectCategory(category); // important that this is after setting form value
      }
    }
  };

  const onNewCategorySelect = (name: string) => {
    dispatch(modal.showModal(<AddCategory name={name} callback={onCategorySelect} />));
  };

  return (
    <>
      {/* Form data handled outside form */}
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="add-cost"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm(formUrl)}
          sendFetcher={(formId: string, values: any) => {
            const fd = new FormData();
            for (const key in values) {
              if (key === 'cost' && values.cost) {
                for (const file of values.cost) {
                  fd.append('cost', file);
                }
              } else if (key === 'category') {
                if (selectedCategory) fd.append('category', selectedCategory.id);
              } else fd.append(key, values[key]);
            }

            return ApiService.sendForm(formSendUrl, fd, method);
          }}
          onSavedSuccessfully={(res: CostRes) => {
            if (typeof callback === 'function') callback(res);
          }}
          loadingInfo={<Spinner transparent />}
          loadingInfoAboveContent
          submitButton={
            <ButtonsContainer>
              <Button type="submit" text="application.save" primary />
            </ButtonsContainer>
          }
          onRenderFullField={(
            field: any,
            errors: any,
            fieldHtmlId: any,
            currentValue: any,
            onChange: any,
            onFocus: any,
            onBlur: any,
          ) => {
            if (field.id === 'name') {
              return (
                <CostSchemeAutosuggestion
                  required={field.required}
                  label={field.title}
                  input={{
                    value: currentValue,
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    onChange,
                  }}
                  onOptionSelect={onOptionSelect}
                  placeholder={field.placeholder}
                  onNewNameSelect={onNewNameSelect}
                  allowNewName
                  onCreate={onCreate}
                  allowCreate
                />
              );
            } else {
              return (
                <CostFieldsMapper
                  data={{
                    field,
                    errors,
                    fieldHtmlId,
                    currentValue,
                    onChange,
                    onFocus,
                    onBlur,
                    onCategorySelect,
                    onNewCategorySelect,
                  }}
                />
              );
            }
          }}
          onTranslateString={formOnTranslateString}
          thirdPartyComponents={{ flatpickr }}
          registerAsGlobalForm
          onFormGloballyRegistered={registerFormCallbacks}
        />
      </FormContainer>
    </>
  );
};

export default CostForm;
