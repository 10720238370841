import ApiService from '../services/api-service';
import { modal, notifications } from '../actions';

import { history } from '../App';

export const deleteCost = (id: string) => async (dispatch: any) => {
  await ApiService.delete(
    dispatch,
    'DELETE_COST_SUCCESS',
    'DELETE_COST_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(notifications.successNotification('application.cost_was_deleted.'));
      dispatch({ type: 'REFRESH_API_TABLE' });
      dispatch({ type: 'REFRESH_API_LIST' });
      history.push('/dashboard/costs/list');
    },
    null,
    `cost/one/${id}`,
    'LOADING_DELETE_COST',
  );
};

export const getCostDetails = (id: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_SINGLE_COST_DETAILS_SUCCESS',
    'GET_SINGLE_COST_DETAILS_FAILED',
    null,
    null,
    `cost/detail/${id}`,
    'LOADING_SINGLE_COST',
  );
};
