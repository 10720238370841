import React, { useRef, useState, useEffect } from 'react';
import { ReactCreatableSelect } from '../../../App';
import { __ } from '../../../services/translation';

interface Props {
  options: { label: string; value: string }[];
  onChange: any;
  field: any;
  placeholder: any;
}

const SelectWithSuggestion = ({ options, field, onChange, placeholder }: Props) => {
  const selector = useRef<any>(null);

  const handleBlur = () => {
    const { inputValue, value } = selector.current.state;

    if (inputValue) selector.current.onChange(inputValue ? { label: inputValue, value: inputValue } : value);
  };

  const handleClick = (e: any) => {
    if (e.keyCode === 13) {
      const { inputValue, value } = selector.current.state;
      selector.current.onChange(inputValue ? { label: inputValue, value: inputValue } : value);
    }
  };

  return (
    <ReactCreatableSelect
      onChange={onChange}
      options={options}
      onBlurResetsInput={false}
      onBlur={handleBlur}
      onKeyDown={handleClick}
      formatCreateLabel={() => false}
      isValidNewOption={() => false}
      noOptionsMessage={() => null}
      isClearable
      placeholder={__('application.choose')}
      ref={selector}
    />
  );
};

export default SelectWithSuggestion;
