import React from 'react';
import { OrderEvent, OrderEventType } from 'app-types';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Subheader,
  ListElementWrapper,
  Button,
  Alert,
  Icon,
  ListElementsWrapper,
} from '../../../../../components/Common';
import { ButtonsContainer } from '../../../../../components/Layout';
import { modal } from '../../../../../actions';
import TimeSheetsForGenerator from '../../../../../modals/TimeSheetsForGenerator';
import MileageForGenerator from '../../../../../modals/MileageForGenerator';
import TimeSheetItem from '../../Items/TimeSheetItem';
import MileageItem from '../../Items/MileageItem';
import { __ } from '../../../../../services/translation';

interface Props {
  fields: any;
  meta: any;
  data: OrderEvent[];
}

const RenderEvents = ({ fields, data }: Props) => {
  const dispatch = useDispatch();
  const openTimeSheetModal = () => {
    dispatch(modal.showModal(<TimeSheetsForGenerator callback={addNewEvent} />));
  };

  const openMileageModal = () => {
    dispatch(modal.showModal(<MileageForGenerator callback={addNewEvent} />));
  };

  const addNewEvent = (event: OrderEvent) => {
    fields.push(event);
  };

  console.log('EVENTS', fields, data);

  return (
    <>
      <Button
        small
        leftIcon
        icon="plus"
        constant
        click={openTimeSheetModal}
        text="application.add_time_sheet"
        className="generator-add-button"
        type="button"
      />
      <Button
        small
        leftIcon
        icon="plus"
        constant
        click={openMileageModal}
        text="application.add_mileage"
        className="generator-add-button"
        type="button"
      />

      {fields.length === 0 && <Alert type="error" simple text="application.no_data" />}
      <ListElementsWrapper empty={fields.length === 0}>
        <Subheader text="application.events" />
        {fields.map(
          (item: any, index: any) =>
            data[index] && (
              <li key={item.templateId}>
                <ListElementWrapper
                  header={`${__('application.event')} #${index + 1}`}
                  icon="cross"
                  click={() => fields.remove(index)}
                >
                  {data[index].type === OrderEventType.timeSheet ? (
                    <TimeSheetItem id={data[index].templateId} />
                  ) : (
                    <MileageItem id={data[index].templateId} />
                  )}
                </ListElementWrapper>
              </li>
            ),
        )}
      </ListElementsWrapper>
    </>
  );
};

export default RenderEvents;
