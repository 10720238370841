import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import ReactSelect from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';
import { MileageToCreate } from 'app-types';

import {
  Button,
  Spinner,
  RequiredIndicator,
  NarrowParagraph,
  MileageFieldsMapper,
} from '../../../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../../../components/Layout';
import ApiService from '../../../../../services/api-service';
import { notifications, header } from '../../../../../actions';
import { flatpickr, history, store } from '../../../../../App';
import { formOnTranslateString } from '../../../../../utils/trans-form';
import { __ } from '../../../../../services/translation';

interface State {
  route: { startPoint: string; finishPoint: string; distance: number; time: number };
  returnRoute: { startPoint: string; finishPoint: string; distance: number; time: number };
  itfForm: any;
}

interface Props {
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class AddMileage extends React.Component<Props, State> {
  state = {
    route: { startPoint: '', finishPoint: '', distance: 0, time: 0 },
    returnRoute: { startPoint: '', finishPoint: '', distance: 0, time: 0 },
    itfForm: null,
  };

  componentDidMount() {
    const { setHeader } = this.props;

    setHeader('application.add_mileage', '/dashboard/mileages/add', {
      title: 'application.add_mileage',
      text: 'mileage.disclaimer',
    });
  }

  registerFormCallbacks = () => {
    window.globalForms['mileage-without-scheme'].valueChangedClb = (newValues: MileageToCreate) => {
      this.setState({
        route: {
          startPoint: newValues.startPoint,
          finishPoint: newValues.finishPoint,
          distance: newValues.distance,
          time: newValues.time,
        },
        returnRoute: {
          startPoint: newValues.startPointReturn ?? '',
          finishPoint: newValues.finishPointReturn ?? '',
          distance: newValues.distanceReturn ?? 0,
          time: newValues.timeReturn ?? 0,
        },
      });
    };
  };

  setRef = (form: any) => {
    this.setState({
      itfForm: form,
    });
  };

  render() {
    const { itfForm, route, returnRoute } = this.state;

    return (
      <>
        <NarrowParagraph text="mileage.create_description" />
        <FormContainer>
          <ItfApiForm
            ref={this.setRef}
            formId="mileage-without-scheme"
            schemaMayBeDynamic
            schemaCacheEnabled
            requiredIndicator={<RequiredIndicator />}
            schemaFetcher={() => ApiService.loadForm('mileage/form/add-item')}
            sendFetcher={(formId: string, values: any) =>
              ApiService.sendForm('mileage/new', { ...values }, 'POST')
            }
            onSavedSuccessfully={() => {
              store.dispatch(notifications.successNotification('application.mileage_added'));
              history.push('/dashboard/mileages');
            }}
            loadingInfo={<Spinner transparent />}
            submitButton={
              <ButtonsContainer marginTop>
                <Button type="submit" text="application.add" primary />
              </ButtonsContainer>
            }
            thirdPartyComponents={{ ReactSelect, ReactCreatableSelect, flatpickr }}
            onTranslateString={formOnTranslateString}
            registerAsGlobalForm
            enableLiveValuesOfGlobalForm
            onFormGloballyRegistered={this.registerFormCallbacks}
            onRenderFullField={(
              field: any,
              errors: any,
              fieldHtmlId: any,
              currentValue: any,
              onChange: any,
              onFocus: any,
              onBlur: any,
            ) => (
              <MileageFieldsMapper
                data={{
                  itfForm,
                  field,
                  errors,
                  fieldHtmlId,
                  currentValue,
                  onChange,
                  onFocus,
                  onBlur,
                  route,
                  returnRoute,
                  allowOwnAddress: true,
                }}
              />
            )}
          />
        </FormContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ setHeader: header.setHeader }, dispatch);

export default connect(null, mapDispatchToProps)(AddMileage);
