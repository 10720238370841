import React from 'react';
import { TimeSheetEntity, OrderEvent, OrderEventType, TimeSheetTemplateEntity } from 'app-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { timeSheetSchemes } from '../../../actions';
import { FormContainer } from '../../../components/Layout';
import { TimeSheetSchemeForm } from '../../../components/Common';

interface Props {
  callback: (event: OrderEvent) => void;
}

type FormProps = InjectedFormProps<TimeSheetEntity, any> & Props;

// eslint-disable-next-line react/prefer-stateless-function
const AddTimeSheetScheme = ({ callback }: FormProps) => {
  const addCallback = (scheme: TimeSheetTemplateEntity) => {
    callback({ templateId: scheme.id, type: OrderEventType.timeSheet });
  };

  return (
    <>
      <FormContainer>
        <TimeSheetSchemeForm
          formSendUrl="time-sheet/template/new"
          formUrl="time-sheet/template/form/add-item"
          method="POST"
          buttonText="application.use"
          callback={addCallback}
        />
      </FormContainer>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      addTimeSheetScheme: timeSheetSchemes.addTimeSheetScheme,
    },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(
  reduxForm<any, any>({
    form: 'addTimeSheetSchemeToGenerator',
    enableReinitialize: true,
  })(AddTimeSheetScheme),
);
