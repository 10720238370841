import React from 'react';
import { useDispatch } from 'react-redux';
import useHeader from '../../../../../hooks/useHeader';
import CostForm from '../../../../../components/Common/CostForm';
import { notifications } from '../../../../../actions';
import { history } from '../../../../../App';

const AddCost = () => {
  useHeader('application.add_cost', '/dashboard/costs/list');
  const dispatch = useDispatch();
  return (
    <CostForm
      method="POST"
      formSendUrl="cost/file"
      formUrl="cost/form/add-item/"
      callback={() => {
        dispatch(notifications.successNotification('application.cost_added.'));
        history.push('/dashboard/costs');
      }}
    />
  );
};

export default AddCost;
