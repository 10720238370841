import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../../../../components/Common';
import { __ } from '../../../../services/translation';
import './Addon.scss';

interface Props {
  addon: any;
}

const Addon = ({ addon }: Props) => {
  const content = (
    <div className="addon-content">
      <header className="addon-header-container">
        <Icon icon={addon.external ? 'external' : 'internal'} />
        <h2 className="addon-header">{__(addon.title)}</h2>
      </header>

      <div className="addon-description">
        <p>{__(addon.description)}</p>
      </div>
    </div>
  );
  if (addon.external) {
    return (
      <div className="addon-tile-container">
        <a className="addon-tile" target="_blank" href={addon.link}>
          {content}
        </a>
      </div>
    );
  }
  return (
    <div className="addon-tile-container">
      <Link to={addon.link} className="addon-tile">
        {content}
      </Link>
    </div>
  );
};

export default Addon;
