import React from 'react';
import { faChessBoard, faClock, faHistory } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { differenceInMinutes } from 'date-fns';
import { EditTimeSheet, Confirmation } from '../../../../../modals';
import { ListItem } from '../../../../../components/Common';
import CopyTimeSheet from '../../../../../modals/CopyTimeSheet';
import { modal, timeSheet } from '../../../../../actions';
import './TimeSheetItem.scss';
import { formatDate } from '../../../../../utils/format-date';
import { formatTime } from '../../../../../utils/format-time';
import { formatDuration } from '../../../../../utils/format-duration';

interface Props {
  item: any;
  showModal: (content: React.ReactNode) => void;
  deleteTimeSheet: (id: string) => void;
}

const TimeSheetItem = ({ item, showModal, deleteTimeSheet }: Props) => (
  <>
    <ListItem
      header={item.name}
      icon="time_sheets"
      paragraphs={[
        {
          icon: 'time_sheets',
          text: (
            <span>
              <strong>
                {formatDuration(
                  differenceInMinutes(new Date(item.finish), new Date(item.start)),
                  true,
                )}
              </strong>
            </span>
          ),
        },
        {
          icon: faHistory,
          text: (
            <span>
              <strong>{formatDate(item.start)}</strong> {formatTime(item.start)}
            </span>
          ),
        },
        {
          icon: faChessBoard,
          text: (
            <span>
              <strong>{formatDate(item.finish)}</strong> {formatTime(item.finish)}
            </span>
          ),
        },
      ]}
      buttons={[
        {
          text: 'application.details',
          type: 'primary',
          to: `/dashboard/time-sheets/${item.id}`,
        },
        {
          text: 'application.edit',
          type: 'secondary',
          click: () => showModal(<EditTimeSheet id={item.id} />),
        },
        {
          text: 'application.copy',
          type: 'secondary',
          click: () => showModal(<CopyTimeSheet id={item.id} />),
        },
        {
          text: 'application.delete',
          type: 'danger',
          click: () =>
            showModal(
              <Confirmation
                text="application.delete?"
                confirmCallback={() => deleteTimeSheet(item.id)}
              />,
            ),
        },
      ]}
    />
  </>
);

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { showModal: modal.showModal, deleteTimeSheet: timeSheet.deleteTimeSheet },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(TimeSheetItem);
