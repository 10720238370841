import React from 'react';
import Logo from '../../Logo';
import './MenuHeader.scss';
import { HamburgerButton } from '../..';

const MenuHeader = () => (
  <div className="menu-header">
    <HamburgerButton />
    <Logo dark />
  </div>
);

export default MenuHeader;
