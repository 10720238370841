import React from 'react';
import './Container.scss';

interface Props {
  children: React.ReactNode;
  noMobilePadding?: boolean;
  paddingTop?: boolean;
  fluid?: boolean;
}

const Container = ({ children, paddingTop, noMobilePadding, fluid }: Props) => (
  <div
    className={`container ${noMobilePadding ? 'no-mobile-padding' : ''} ${paddingTop ? 'padding-top' : ''} ${
      fluid ? 'fluid' : ''
    }`}
  >
    {children}
  </div>
);

export default Container;
