import ApiService from '../services/api-service';

export const getUserInfo = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_USER_INFO_SUCCESS',
    'GET_USER_INFO_FAILED',
    null,
    null,
    'user/info',
    'LOADING_USER_INFO',
  );
};
