import { apiList, modal, notifications } from '.';
import ApiService from '../services/api-service';
import { history } from '../App';

export const deleteMileageCar = (mileageCarId: string, redirect?: string) => async (
  dispatch: any,
) => {
  await ApiService.delete(
    dispatch,
    'DELETE_MILEAGE_CAR_SUCCESS',
    'DELETE_MILEAGE_CAR_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(apiList.refreshApiList());
      dispatch(notifications.successNotification('application.car_deleted'));
      if (redirect) {
        history.push(redirect);
      }
    },
    null,
    `mileage-car/${mileageCarId}`,
  );
};
