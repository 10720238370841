import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Button, Spinner } from '../../../../../../components/Common';
import { ButtonsContainer } from '../../../../../../components/Layout';
import ApiService from '../../../../../../services/api-service';
import './InvoicePreview.scss';

interface Props {
  data: any;
}

const InvoicePreview = ({ data }: Props) => {
  const [numPages, setNumPages] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const getInvoicePreview = async () => {
    const res = await ApiService.sendForFile('POST', 'invoice/preview', data);
    const converted = await res.data.arrayBuffer();
    setFile(converted);
    setLoading(false);
  };

  useEffect(() => {
    getInvoicePreview();
  }, []);

  if (loading) return <Spinner />;

  return (
    <div>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess} className="preview-container">
        <Page pageNumber={pageNumber} />
      </Document>
      {numPages && numPages > 1 && (
        <ButtonsContainer>
          <Button
            faIcon={faChevronLeft}
            leftIcon
            disabled={pageNumber === 1}
            click={() => setPageNumber((num) => num - 1)}
          />
          <Button
            faIcon={faChevronRight}
            leftIcon
            disabled={pageNumber === numPages}
            click={() => setPageNumber((num) => num + 1)}
          />
        </ButtonsContainer>
      )}
    </div>
  );
};

export default InvoicePreview;
