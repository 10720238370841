import uuidv4 from 'uuid/v4';
import { ApiErrorCode } from 'app-types';
import SingleNotification from '../types/notifications/notifications';
import { } from '../services/translation';

interface HideNotification {
  type: 'HIDE_NOTIFICATION';
  payload: string;
}

interface SuccessNotification {
  type: 'SUCCESS_NOTIFICATION';
  payload: string;
}

interface ErrorNotification {
  type: 'ERROR_NOTIFICATION';
  payload: ApiErrorCode;
}
interface ErrorNotificationText {
  type: 'ERROR_NOTIFICATION_TEXT';
  payload: string;
}
type Action = HideNotification | SuccessNotification | ErrorNotification | ErrorNotificationText;

export type NotificationsState = SingleNotification[];

const initialState: NotificationsState = [];

const getError = (error: ApiErrorCode) => {
  switch (error) {
    case ApiErrorCode.InvalidPass:
      return 'error.invalid_pwd';
    case ApiErrorCode.InvalidLoginData:
      return 'error.invalid_login_data';
    case ApiErrorCode.InsufficientPrivileges:
      return 'error.insufficient_privileges._please_check_if_you_are_using_the_right_account';
    case ApiErrorCode.UserLocked:
      return 'error.your_account_is_banned._please_conntact_administration';
    case ApiErrorCode.OtherError:
      return 'error.an_error_occured';
    case ApiErrorCode.NoSuchUser:
      return 'error.no_such_user';
    case ApiErrorCode.InvalidMail:
      return 'error.invalid_email';
    case ApiErrorCode.UserAlreadyExists:
      return 'error.such_email_is_already_in_use';
    case ApiErrorCode.InvalidOldPass:
      return 'error.invalid_old_password';
    case ApiErrorCode.InvalidNip:
      return 'error.invalid_nip.';
    case ApiErrorCode.CompanyAddressCannotBeEmpty:
      return 'error.address_cannot_be_empty.';
    case ApiErrorCode.CompanyNameCannotBeEmpty:
      return 'error.company_name_cannot_be_empty';
    case ApiErrorCode.KvkNumberIsRequired:
      return 'error.kvk_number_is_required';
    case ApiErrorCode.PhoneNoCannotBeEmpty:
      return 'error.phone_number_is_required.';
    case ApiErrorCode.PassCannotBeEmptyOrIsTooShort:
      return 'error.password_should_be_at least 8 characters long.';
    case ApiErrorCode.NameCannotBeEmpty:
      return 'error.name_is_required.';
    case ApiErrorCode.AddressNotComplete:
      return 'error.address_not_complete.';
    case ApiErrorCode.ContactPersonNameIsRequired:
      return 'error.contact_person_name_is_required.';
    case ApiErrorCode.ContactAlreadyExists:
      return 'error.contact_already_exists';
    case ApiErrorCode.ThisPointOfSaleIsAlreadyRegistered:
      return 'error.this_point_of_sale_is_already_registered';
    case ApiErrorCode.EmailAlreadySent:
      return 'error.email_already_sent';
    case ApiErrorCode.RequiredDocumentsNotUploaded:
      return 'error.required_documents_not_uploaded';
    case ApiErrorCode.InvalidPrice:
      return 'error.invalid_price';
    case ApiErrorCode.InvalidProductCode:
      return 'error.invalid_product_code';
    case ApiErrorCode.ThisProductCodeIsAlreadyInUse:
      return 'error.this_product_code_is_already_in_use';
    case ApiErrorCode.NoSuchObjectWithThisId:
      return 'error.no_such_object_with_this_id';
    case ApiErrorCode.FormValidationError:
      return 'error.form_validation_error';
    case ApiErrorCode.EmptyKvkResponse:
      return 'error.no_results';
    case ApiErrorCode.CannotReadGpsCoords:
      return 'error.gps_not_working';
    case ApiErrorCode.NotUniuqueParama:
      return 'error.data_exists';
    case ApiErrorCode.SearchLimit:
      return 'error.kvk_limit_reached';
    case ApiErrorCode.UpgradeAccountToAddMoreCosts:
      return 'error.upgrade_account_to_add_more_costs';
    case ApiErrorCode.UpgradeAccountToAddMoreOtherIncome:
      return 'error.upgrade_account_to_add_more_other_income';
    case ApiErrorCode.TimeSheetUnvalidated:
      return 'error.time_sheet_unvalidated';
    case ApiErrorCode.ForebiddenInvoiceNumber:
      return 'error.incorrect_invoice_number.';
    case ApiErrorCode.InvalidLang:
      return 'error.invalid_lang.';
    case ApiErrorCode.NoMileageForThisYear:
      return 'error.no_mileage_for_this_year';
    case ApiErrorCode.NoMileagesForThisYear:
      return 'error.no_mileages_for_this_year';
    case ApiErrorCode.NoTimesheetsForThisYear:
      return 'error.no_timesheets_for_this_year';
    case ApiErrorCode.NoMileageStarted:
      return 'error.no_mileage_started';
    case ApiErrorCode.NoMileageFinished:
      return 'error.no_mileage_finished';
    case ApiErrorCode.InvalidCoordinate:
      return 'error.gps_error';
    case ApiErrorCode.NoTrackingStarted:
      return 'error.no_tracking_started';
    case ApiErrorCode.NoPaymentFound:
      return 'error.payment_not_found';
    case ApiErrorCode.ExistTimeSheetInThisTime:
      return 'error.time_sheet_already_exists_in_this_period_of_time';
    case ApiErrorCode.NoWorkFinished:
      return 'error.work_is_not_finished';
    case ApiErrorCode.NoWorkStarted:
      return 'error.work_is_not_started';
    case ApiErrorCode.InvalidId:
      return 'error.invalid_id';
    case ApiErrorCode.InvalidAccountTypeName:
      return 'error.invalid_account_type';
    case ApiErrorCode.TooMuchUploadedFiles:
      return 'error.too_much_uploaded_files';
    case ApiErrorCode.InvalideService:
      return 'error.invalide_service';
    case ApiErrorCode.invoiceWithoutService:
      return 'error.please_add_service_or_product';
    case ApiErrorCode.ForebiddenForTrialAccount:
      return 'error.forbidden_for_trial_account';
    case ApiErrorCode.ForebiddenFileFormat:
      return 'error.forbidden_file_format';
    case ApiErrorCode.UserNotConfirmedByAdmin:
      return 'error.your_account_is_not_confirmed_by_admin';
    case ApiErrorCode.NoDataForThisKVK:
      return 'error.no_data_for_this_kvk';
    case ApiErrorCode.NoLogo:
      return 'error.no_logo';
    case ApiErrorCode.FileIsTooLarge:
      return 'error.file_is_too_large';
    case ApiErrorCode.NoInvoiceForThisPayment:
      return 'error.no_invoice_for_this_payment';
    case ApiErrorCode.InvalidEvent:
      return 'error.invalid_event';
    case ApiErrorCode.MustChooseOneCategory:
      return 'error.must_choose_one_category';
    case ApiErrorCode.InvalidKvk:
      return 'error.invalid_kvk';
    case ApiErrorCode.MileagestUnvalidated:
      return 'error.mileage_unvalidated';
    case ApiErrorCode.MissingFile:
      return 'error.missing_file';
    case ApiErrorCode.NonConfirmedEmail:
      return 'error.non_confirmed_email';
    case ApiErrorCode.InvalidActiveData:
      return 'error.invalid_activate_data';
    case ApiErrorCode.InvalidCost:
      return 'error.invalid_cost';
    case ApiErrorCode.MustBeAtLeastOneDayDifference:
      return 'error.must_be_at_least_one_day_difference';
    case ApiErrorCode.InvalidToken:
      return 'error.invalid_token';
    case ApiErrorCode.UserAlreadyHasVof:
      return 'error.user_already_has_vof';
    case ApiErrorCode.YouAlreadyInvitedThisUser:
      return 'error.user_already_invited';
    case ApiErrorCode.YouCantInviteMoreUsers:
      return 'error.you_cant_invite_more';
    case ApiErrorCode.AskVofCreatorToInvite:
      return 'error.ask_creator_to_invite';
    case ApiErrorCode.YouCantInviteYourself:
      return 'error.cant_inivite_yourself';
    case ApiErrorCode.YouCantRemoveBecauseOfOrder:
      return 'error.cant_remove_because_of_order';
    case ApiErrorCode.ClientExists:
      return 'error.client_exists';
    case ApiErrorCode.NoPermission:
      return 'error.no_permission';
    case ApiErrorCode.CantStartOnWeekend:
      return 'error.cant_start_on_weekend';
    case ApiErrorCode.IncompleteUserData:
      return 'error.incomplete_user_data';
    case ApiErrorCode.YouHaveNoInvoices:
      return 'error.you_have_no_invoices';
    case ApiErrorCode.ErrorWhilePreparingPayment:
      return 'error.error_while_preparing_payment';
    case ApiErrorCode.YouAlreadyHaveActiveSubscription:
      return 'error.you_already_have_active_subscription';
    case ApiErrorCode.AccountantDoesNotExist:
      return 'error.accountant_does_not_exist';
    case ApiErrorCode.AccountantAlreadyHasAccess:
      return 'error.accountant_already_has_access';
    case ApiErrorCode.CantMixFileTypes:
      return 'error.cannot_mix_file_types';
    case ApiErrorCode.OnlyOneFileInThisType:
      return 'error.only_one_file_in_this_type';
    case ApiErrorCode.Max10FilesAllowed:
      return 'error.max_10_files_allowed';
    case ApiErrorCode.AtLeastOnePeriodRequired:
      return 'error.at_least_one_period_required';
    case ApiErrorCode.OfferAlreadyAccepted:
      return 'error.offer_already_accepted';
    case ApiErrorCode.OfferIsInvalid:
      return 'error.offer_is_invalid';
    default:
      return 'error.unknown_error';
  }
};

const getErrorNotification = (text: string) => ({ type: 'error', text, id: uuidv4() });
const getSuccessNotification = (text: string) => ({ type: 'success', text, id: uuidv4() });

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SUCCESS_NOTIFICATION': {
      const notification = getSuccessNotification(action.payload);
      return [...state, notification];
    }
    case 'ERROR_NOTIFICATION_TEXT': {
      const notification = getErrorNotification(action.payload);
      return [...state, notification];
    }
    case 'ERROR_NOTIFICATION': {
      const notification = getErrorNotification(getError(action.payload));
      return [...state, notification];
    }
    case 'HIDE_NOTIFICATION':
      return state.filter((notification: SingleNotification) => notification.id !== action.payload);
    default:
      return state;
  }
};
