import React, { useState } from 'react';
import { CircularInput, CircularTrack, CircularProgress, CircularThumb } from 'react-circular-input';
import './TimePicker.scss';

interface Props {
  change: (event: any) => void;
  value: any;
}

const TimePicker = (props: Props) => {
  const hourRadius = window.innerWidth > 768 ? 150 : window.innerWidth / 3.6;
  const minRadius = window.innerWidth > 768 ? 100 : window.innerWidth / 5;
  const parseHourToPercent = (hour = 1) => (hour / 24) * 99 * 0.01;
  const parseMinToPercent = (hour = 1) => (hour / 60) * 99 * 0.01;
  const parseHour = (hour: number) => {
    if (typeof hour === 'undefined') {
      return '00';
    } else if (hour > 0.9691 || hour < 0.02) {
      return '00';
    }
    return ((hour * 24) / 99)
      .toFixed(2)
      .toString()
      .slice(2, 4);
  };
  const parseMin = (min: number) => {
    const result = ((min * 60) / 99)
      .toFixed(2)
      .toString()
      .slice(2, 4);
    if (typeof min === 'undefined' || result === '60' || result === '61') {
      return '00';
    }
    return result;
  };
  const { value, change } = props;

  const [hour, setHour] = useState(parseHourToPercent(value ? value.split(':')[0] : 0));
  const [min, setMin] = useState(parseMinToPercent(value ? value.split(':')[1] : 0));

  const handleHourChange = (hour: any) => {
    setHour(hour);
    change(`${parseHour(hour)}:${parseMin(min)}`);
  };
  const handleMinuteChange = (min: any) => {
    setMin(min);
    change(`${parseHour(hour)}:${parseMin(min)}`);
  };
  return (
    <div className="time-picker-container">
      <div className="slider-cont">
        <div className="hour-cont">
          <CircularInput value={hour} onChange={handleHourChange} radius={hourRadius} color="faba1a">
            <CircularTrack stroke="#fff5d9" strokeWidth={12} />
            <CircularProgress stroke="#ffc942" />
            <CircularThumb color="#cf9300" fill="#eda900" />
          </CircularInput>
        </div>
        <div className="time-indicator">
          {parseHour(hour)}:{parseMin(min)}
        </div>
        <div className="min-cont">
          <CircularInput value={min} onChange={handleMinuteChange} radius={minRadius}>
            <CircularTrack strokeWidth={9} stroke="#fff5d9" />
            <CircularProgress />
            <CircularThumb />
          </CircularInput>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
