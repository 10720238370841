import React, { useMemo } from 'react';

import { faFileInvoice, faEdit, faHourglassEnd, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ListItem } from '../../../../../components/Common';
import { __ } from '../../../../../services/translation';
import { formatDate } from '../../../../../utils/format-date';
import { formatPrice } from '../../../../../utils/format-price';
import './OfferListItem.scss';
import { DownloadService } from '../../../../../services/download-service';
import { OfferListRes, OfferStatus } from '../../../../../../../heyzzp-back/types';

interface Props {
  item: OfferListRes;
  id?: string;
  date: string;
}

const OfferListItem = ({ item }: Props) => {
  const paragraphs = useMemo(
    () => [
      { icon: 'time_sheets', text: formatDate(item.createdAt) },
      { icon: faHourglassEnd, text: formatDate(item.validTo) },
      { icon: 'pencil', text: item.mark },
      { icon: 'costs', text: formatPrice(item.totalVal + item.totalTaxVal) },
      {
        icon: faCheck,
        text: __('application.offer_accepted_at', { date: formatDate(item.statusChangeDt) }),
        hide: item.status !== OfferStatus.ACCEPTED,
        success: true,
      },
    ],
    [],
  );

  const downloadPDF = async () => {
    await DownloadService.download(`offer/pdf/${item.id}`);
  };

  return (
    <div className="offer-list-item-uberwrapper">
      <ListItem
        header={item.client}
        icon={faFileInvoice}
        paragraphs={paragraphs}
        buttons={[
          {
            text: 'application.offer_to_invoice',
            to: `/dashboard/invoices/offer/${item.id}`,
          },
          {
            text: 'application.details',
            to: `/dashboard/offers/${item.id}`,
          },
          {
            text: 'application.download_pdf',
            click: downloadPDF,
            type: 'secondary',
          },
          {
            text: 'application.edit',
            to: `/dashboard/offers/edit/${item.id}`,
            type: 'secondary',
          },
          {
            text: 'application.send_to_client',
            to: `/dashboard/offers/send/${item.id}`,
            type: 'success',
          },
        ]}
        noShadow
      />
    </div>
  );
};

export default OfferListItem;
