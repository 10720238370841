import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import CertificateNonArrears from './CertificateNonArrears';
import Uittreksel from './Uittreksel';
import List from './List';
// import PaymentStatus from './PaymentStatus';

const Addons = () => (
  <Page light withNavigation>
    <Dashboard>
      <Switch>
        <Route exact path="/dashboard/addons" render={() => <Redirect to="/dashboard/addons/list" />} />
        <Route exact path="/dashboard/addons/list" render={() => <List />} />
        <Route exact path="/dashboard/addons/certificate" component={CertificateNonArrears} />
        <Route exact path="/dashboard/addons/uittreksel" component={Uittreksel} />
      </Switch>
    </Dashboard>
  </Page>
);

export default Addons;
