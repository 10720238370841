import { UserEntity } from "../user";

export interface VofEntity {
  id: string;
  createdAt: Date;
  status: VofStatus;
  parent: UserEntity;
  child: UserEntity;
}

export enum VofStatus {
  PENDING,
  ACTIVE
}
