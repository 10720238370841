import React from 'react';
import './ButtonsContainer.scss';

interface Props {
  children: React.ReactNode;
  marginLittle?: boolean;
  marginTop?: boolean;
  block?: boolean;
  grid?: boolean;
  max?: boolean;
  inline?: boolean;
  center?: boolean;
}

const ButtonsContainer = ({ children, max, marginLittle, marginTop, block, grid, inline, center }: Props) => (
  <div
    className={
      grid
        ? 'col-xs-1 col-sm-2 col-md-4'
        : `buttons-container  
            ${marginLittle ? 'margin-little' : ''}
            ${marginTop ? 'margin-top' : ''}
            ${block ? 'block' : ''}
            ${max ? 'max' : ''}
            ${center ? 'center' : ''}
            ${inline ? 'inline' : ''}
             `
    }
  >
    {children}
  </div>
);

export default ButtonsContainer;
