import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  faPlus,
  faProjectDiagram,
  faFileInvoice,
  faStickyNote,
  faRoad,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Page } from '../../../components/Layout';
import AddMileageWithoutScheme from './AddMileage/WithoutSchema';
import AddMileageWithScheme from './AddMileage/WithSchema';
import ChooseMileageScheme from './AddMileage';
import MileageList from './List';
import Dashboard from '../../DashboardCommon';
import SingleMileage from './SingleMileage';
import YearSummary from './YearSummary';
import SendMileage from './Send';
import AddWithLocation from './AddMileage/UseGeolocation';
import GeolocationResults from './AddMileage/UseGeolocation/Results';
import { RouteClient, RouteClientAccountant } from '../../../components/Routes';

const mileageTabs = [
  {
    title: 'application.mileage_list',
    link: '/dashboard/mileages/list',
    icon: 'mileages',
  },
  {
    title: 'application.add_mileage',
    link: '/dashboard/mileages/add',
    icon: 'plus',
  },
  {
    title: 'application.mileage_schemes',
    link: '/dashboard/mileage-schemes',
    icon: 'list',
  },
  {
    title: 'application.cars',
    link: '/dashboard/mileage-cars/list',
    icon: 'car',
  },
  {
    title: 'application.annual_summary',
    link: '/dashboard/mileages/summary',
    icon: 'summary',
  },
  {
    title: 'application.generator',
    link: '/dashboard/generator',
    icon: 'generator',
  },
];

const Mileage = () => (
  <>
    <Page light withNavigation>
      <Dashboard navigation={mileageTabs}>
        <Switch>
          <Route
            exact
            path="/dashboard/mileages"
            render={() => <Redirect to="/dashboard/mileages/add" />}
          />
          <Route exact path="/dashboard/mileages/add" component={ChooseMileageScheme} />
          <Route
            exact
            path="/dashboard/mileages/add/noscheme"
            component={AddMileageWithoutScheme}
          />
          <RouteClientAccountant
            needsFullData
            exact
            path="/dashboard/mileages/summary"
            component={YearSummary}
          />
          <Route
            exact
            path="/dashboard/mileages/add/usescheme/:id"
            component={AddMileageWithScheme}
          />
          <Route exact path="/dashboard/mileages/list/:type?" component={MileageList} />
          <Route exact path="/dashboard/mileages/add/geolocation" component={AddWithLocation} />
          <Route
            exact
            path="/dashboard/mileages/add/geolocationresults"
            component={GeolocationResults}
          />
          <Route exact path="/dashboard/mileages/send/:year" component={SendMileage} />
          <Route exact path="/dashboard/mileages/:id" component={SingleMileage} />
        </Switch>
      </Dashboard>
    </Page>
  </>
);

export default Mileage;
