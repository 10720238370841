import { TaxRate } from 'app-types';

export const percentFromTaxRate = (taxRate: TaxRate) => {
  switch (taxRate) {
    case TaxRate['0%']:
    case TaxRate['BTW Verlegd']:
    case TaxRate['BTW vrijgesteld']:
      return 0;
    case TaxRate['9%']:
      return 9;
    case TaxRate['21%']:
      return 21;
    default:
      return 0;
  }
};

export const getTaxRate = (taxRate: any) => {
  switch (taxRate) {
    case '2':
    case '3':
      return 0;
    case '1':
      return 9;
    case '0':
      return 21;
    default:
      return 0;
  }
};
