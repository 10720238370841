import { PaymentStatus } from '../payment';
import { UserEntity } from '../user';

export type CertificateLanguage = 'nl' | 'fr' | 'en' | 'de';

export enum BussinesType {
  ONEPERSON,
  VOF,
}

export interface CertificateListDetails {
  id: string;
  date: Date;
  businessType: BussinesType;
  companyName: string;
  lastName: string;
  language: CertificateLanguage;
  paymentStatus: PaymentStatus;
  rsin: string;
  bsn: string;
}

export interface CertificateEntity {
  id: string;
  date: Date;
  companyName: string;
  lastName: string;
  rsin: string;
  bsn: string;
  language: CertificateLanguage;
  businessType: BussinesType;
  every3Months: boolean;
  owner: UserEntity;
  paymentStatus: PaymentStatus;
  paymentId: string;
  invoiceNumber: string;
}

export interface NewCertificateReq {
  companyName: string;
  lastName: string;
  rsin: string;
  bsn: string;
  language: CertificateLanguage;
  businessType: BussinesType;
  every3Months: boolean;
}

