import React from 'react';
import { ButtonRound } from '../../../../components/Common';
import { isMobile } from '../../../../utils/is-mobile';
import useHeader from '../../../../hooks/useHeader';
import './AddMileage.scss';

const ChooseMileageScheme = () => {
  useHeader('application.add_mileage', '/dashboard/mileages/list');
  return (
    <div className="add-mileage-wrapper">
      <ButtonRound to="/dashboard/mileage-schemes/list" label="application.use_scheme" icon="route" />
      <ButtonRound to="/dashboard/mileages/add/noscheme" label="application.blank_mileage" icon="street" />
      {isMobile() && (
        <ButtonRound to="/dashboard/mileages/add/geolocation" label="application.use_geolocation" icon="pin" />
      )}
    </div>
  );
};

export default ChooseMileageScheme;
