import React from 'react';
import { faCar, faMapPin, faUndo, faClock } from '@fortawesome/free-solid-svg-icons';
import { MileageSchemaEntity } from 'app-types';
import { useDispatch } from 'react-redux';
import { ListItem } from '../../../../../../components/Common';
import { __ } from '../../../../../../services/translation';
import { formatDistance } from '../../../../../../utils/format-distance';
import { formatDuration } from '../../../../../../utils/format-duration';
import MileageSubheader from '../../../../../../components/Common/MileageSubheader';
import { modal, mileageSchemes } from '../../../../../../actions';
import { EditMileageScheme, Confirmation } from '../../../../../../modals';
import { SingleListParagraph } from '../../../../../../types/list-paragraph';

interface Props {
  item: MileageSchemaEntity;
}

const MileageSchemeItem = ({ item }: Props) => {
  const dispatch = useDispatch();
  let paragraphs: SingleListParagraph[] = [
    { icon: 'pin_start', text: `${item.startPoint}`, success: true },
    { icon: 'pin_stop', text: `${item.finishPoint}`, danger: true },
    { icon: 'time_sheets', text: formatDistance(item.distance) },
    {
      hide: !item.time,
      icon: 'mileages',
      text: typeof item.time !== 'undefined' ? formatDuration(item.time) : item.time,
    },
  ];

  if (item.startPointReturn) {
    paragraphs = [
      ...paragraphs,
      { icon: faUndo, text: __('mileage.includes_return') },
      { icon: 'pin_start', text: `${item.startPointReturn}`, success: true },
      { icon: 'pin_stop', text: `${item.finishPointReturn}`, danger: true },
      { icon: 'time_sheets', text: formatDistance(item.distanceReturn) },
      {
        hide: !item.timeReturn,
        icon: 'mileages',
        text: typeof item.time !== 'undefined' ? formatDuration(item.timeReturn) : item.timeReturn,
      },
    ];
  }

  const deleteScheme = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.are_you_sure_you_want_to_delete_this_mileage_scheme?"
          confirmCallback={() => dispatch(mileageSchemes.deleteSingleMileageScheme(item.id))}
          cancelCallback={() => dispatch(modal.hideModal())}
        />,
      ),
    );
  };

  return (
    <ListItem
      header={item.name}
      paragraphs={paragraphs}
      buttons={[
        { text: 'application.details', to: `/dashboard/mileage-schemes/${item.id}` },
        {
          text: 'application.edit',
          type: 'secondary',
          click: () => dispatch(modal.showModal(<EditMileageScheme mileageId={item.id} />)),
        },
        {
          type: 'success',
          text: 'application.use',
          to: `/dashboard/mileages/add/usescheme/${item.id}`,
        },
        { type: 'danger', text: 'application.delete', click: deleteScheme },
      ]}
    />
  );
};

export default MileageSchemeItem;
