import React, { useState, useEffect } from 'react';
import { ClientUserEntity } from 'app-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import ApiService from '../../../services/api-service';
import { ApplicationState } from '../../../reducers';
import './CompanyLogo.scss';

interface Props {
  desktop?: boolean;
  alwaysVisible?: boolean;
  full?: boolean;
  small?: boolean;
}

const CompanyLogo = ({ desktop, alwaysVisible, full, small }: Props) => {
  const [available, setAvailable] = useState(true);
  const user = useSelector((state: ApplicationState) => state.user.details);

  useEffect(() => {
    getLogo();
  }, []);

  const noImage = () => {
    setAvailable(false);
  };
  if (!user) {
    return null;
  }
  if (!available) {
    return (
      <div
        className={clsx('company-logo', { desktop, full, small, 'always-visible': alwaysVisible })}
      >
        {(user as ClientUserEntity).companyName
          ? (user! as ClientUserEntity).companyName![0]
          : user.email[0]}
      </div>
    );
  }

  const getLogo = async () => {
    console.log(await ApiService.getImageSrc('user/logo'));
  };

  return (
    <img
      className={clsx('company-logo', { desktop, full, small, 'always-visible': alwaysVisible })}
      onError={noImage}
      alt={
        ((user as ClientUserEntity).companyName
          ? (user as ClientUserEntity).companyName
          : user.email) || ''
      }
      src={`${ApiService.url}user/logo`}
    />
  );
};

export default CompanyLogo;
