import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PaymentType, AccountType, SomeUserEntity, ClientUserEntity } from 'app-types';
import { modal, payment } from '../../../../actions';
import { Button } from '../../../../components/Common';
import { ButtonsContainer } from '../../../../components/Layout';
import { ApplicationState } from '../../../../reducers';
import { checkIfMissingData } from '../../../../utils/user';
import EditUserData from '../../../../modals/EditUserData';
import './PricingContainer.scss';

interface Props {
  user: SomeUserEntity;
  paymentType: { paymentType: PaymentType; accountType: AccountType };
  newPayment: (paymentType: PaymentType, accountType: AccountType) => void;
  showModal: (content: React.ReactNode) => void;
}

const PricingContainer = ({
  user,
  newPayment,
  paymentType: { paymentType, accountType },
  showModal,
}: Props) => {
  const handleNewPayment = () => {
    if (checkIfMissingData(user as ClientUserEntity)) {
      showModal(<EditUserData />);
    } else {
      newPayment(paymentType, accountType);
    }
  };
  return (
    <div className="buy-sub-buy-container">
      <ButtonsContainer marginTop>
        <Button
          text="application.compare_payment_plans"
          secondary
          bottomMargin
          to="/payment-plans"
          rightMargin
        />
        {(!user || !(user as ClientUserEntity).isActiveSub || (user as ClientUserEntity).trial) && (
          <Button text="application.buy_subscription" click={handleNewPayment} />
        )}
      </ButtonsContainer>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ newPayment: payment.newPayment, showModal: modal.showModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PricingContainer);
