import React from 'react';
import './AppOffline.scss';

const AppOffline = () => (
  <div className="app-status">
    <div className="offline-logo-container">
      <svg enableBackground="new 0 0 381 131" version="1.1" viewBox="0 0 381 131" xmlns="http://www.w3.org/2000/svg">
        <ellipse
          className="st0"
          fill="#FABA1A"
          transform="matrix(.3968 -.9179 .9179 .3968 -4.0516 104.13)"
          cx="77.2"
          cy="55.1"
          rx="55.1"
          ry="55.1"
        />

        <path
          className="st1"
          fill="#1A1A18"
          d="m76.3 39.1c-0.9-3.5-4.5-5.7-8-4.8s-5.7 4.5-4.8 8c9.4 37.8 8.5 63.1-2.7 71.2-12.8 9.3-38.6-3-47.5-8.5-3.1-1.9-7.1-1-9.1 2.1-1.9 3.1-1 7.1 2.1 9.1 1.2 0.7 22.4 13.8 42.5 13.8 7 0 13.8-1.6 19.7-5.8 16.6-12 19.1-39.9 7.8-85.1z"
        />
        <path
          className="st1"
          fill="#1A1A18"
          d="m17.3 49 28.5 13.6c0.9 0.4 1.9 0.6 2.8 0.6 2.5 0 4.8-1.4 5.9-3.7 1.6-3.3 0.2-7.2-3.1-8.8l-28.4-13.6c-3.3-1.6-7.2-0.2-8.8 3.1s-0.2 7.2 3.1 8.8z"
        />
        <path
          className="st1"
          fill="#1A1A18"
          d="m3.7 65.8 32.8 15.6c0.9 0.4 1.9 0.6 2.8 0.6 2.5 0 4.8-1.4 5.9-3.7 1.6-3.3 0.2-7.2-3.1-8.8l-32.7-15.6c-3.3-1.6-7.2-0.2-8.8 3.1-1.5 3.3-0.1 7.2 3.1 8.8z"
        />
        <path
          className="st1"
          fill="#1A1A18"
          d="m26.6 100c0.9 0.4 1.9 0.6 2.8 0.6 2.5 0 4.8-1.4 5.9-3.7 1.6-3.3 0.2-7.2-3.1-8.8l-22-10.5c-3.3-1.6-7.2-0.2-8.8 3.1-1.4 3.3 0 7.3 3.3 8.8l21.9 10.5z"
        />

        <path
          className="st2"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E8A300"
          d="m336.4 69.9h10.4v5.2h0.7c1.2-1.9 2.9-3.4 5.1-4.7s4.8-1.9 7.7-1.9c3.9 0 7.4 1 10.6 2.9s5.7 4.6 7.4 8.1c1.8 3.5 2.7 7.4 2.7 11.8s-0.9 8.4-2.7 11.8c-1.8 3.5-4.3 6.1-7.4 8.1-3.2 1.9-6.7 2.9-10.6 2.9-2.9 0-5.5-0.6-7.8-1.9-2.3-1.2-4-2.8-5.1-4.6h-0.7l0.7 6.1v17.3h-11v-61.1zm22 34.1c2.1 0 4-0.5 5.8-1.6s3.2-2.6 4.3-4.5 1.6-4.1 1.6-6.6-0.5-4.7-1.6-6.6-2.5-3.4-4.3-4.4-3.7-1.6-5.8-1.6c-2 0-3.9 0.5-5.7 1.6-1.8 1-3.2 2.5-4.3 4.4s-1.6 4.1-1.6 6.6 0.5 4.7 1.6 6.6 2.5 3.4 4.3 4.5c1.7 1 3.6 1.6 5.7 1.6z"
        />
        <polygon
          className="st2"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E8A300"
          points="293 103.8 315.4 79.2 293.7 79.2 293.7 69.9 329 69.9 329 78.9 306.6 103.5 329.7 103.5 329.7 112.8 293 112.8"
        />
        <polygon
          className="st2"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E8A300"
          points="251.8 103.8 274.2 79.2 252.5 79.2 252.5 69.9 287.7 69.9 287.7 78.9 265.4 103.5 288.4 103.5 288.4 112.8 251.8 112.8"
        />

        <polygon
          className="st3"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#1A1A18"
          points="219.9 110.8 201.8 69.9 214.3 69.9 225.5 96.6 226.1 96.6 236.8 69.9 249.1 69.9 222.6 131 210.7 131"
        />
        <path
          className="st3"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#1A1A18"
          d="m181.6 114.2c-4.3 0-8.1-1-11.5-2.9-3.4-2-6.1-4.7-8-8.1-1.9-3.5-2.9-7.3-2.9-11.7 0-4.1 1-7.9 2.9-11.4s4.5-6.3 7.9-8.4 7.1-3.1 11.3-3.1c4.4 0 8.2 1 11.4 2.9s5.6 4.5 7.3 7.8 2.5 7 2.5 11c0 1.3-0.1 2.5-0.3 3.7h-32.2c0.6 3.4 1.9 6 4.1 7.8s4.8 2.7 7.8 2.7c2.5 0 4.7-0.5 6.5-1.6s3.3-2.6 4.3-4.4l8.9 4.4c-4.4 7.4-11.1 11.3-20 11.3zm10.2-28.1c-0.1-1.3-0.6-2.7-1.5-4s-2.1-2.4-3.6-3.3-3.4-1.3-5.4-1.3c-2.6 0-4.9 0.8-6.8 2.3s-3.2 3.6-4.1 6.3h21.4z"
        />
        <path
          className="st3"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#1A1A18"
          d="m150.1 73.2c-2.8-3.1-6.8-4.7-12-4.7-2.8 0-5.3 0.6-7.7 1.9-2.4 1.2-4.2 2.9-5.4 4.9v-22.8h-11v60.3h11v-21.8c0-3.4 0.9-6.3 2.8-8.7s4.3-3.5 7.2-3.5c2.5 0 4.4 0.8 5.9 2.3s2.2 3.6 2.2 6.4v25.3h11v-26.7c0.2-5.5-1.2-9.8-4-12.9z"
        />
      </svg>
    </div>
    <p>OFFLINE</p>

    <svg
      className="nowifi-icon"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m256 0c-67.468 0-131.5 26.88-180.31 75.688s-75.688 112.84-75.688 180.31c0 139.48 116.13 256 256 256 139.48 0 256-116.13 256-256 0-139.48-116.13-256-256-256zm-159.1 415.1c-85.646-85.645-89.056-217.87-10.262-307.25l26.815 26.815c-10.11 6.838-19.785 14.425-28.931 22.75-6.126 5.576-6.573 15.063-0.998 21.19 5.575 6.126 15.062 6.573 21.19 0.998 9.529-8.672 19.707-16.438 30.401-23.275l21.723 21.723c-12.761 7.556-24.738 16.608-35.68 27.084-5.984 5.729-6.19 15.225-0.461 21.208 5.729 5.984 15.225 6.19 21.208 0.46 11.172-10.696 23.616-19.643 36.97-26.716l22.538 22.538c-16.302 7.228-31.205 17.655-43.631 30.838-5.683 6.028-5.402 15.521 0.626 21.204s15.522 5.402 21.204-0.626c12.613-13.381 28.006-22.984 44.938-28.279l25.482 25.482c-22.939 1.796-43.455 14.16-56.006 32.543-4.671 6.842-2.912 16.175 3.93 20.846 6.847 4.674 16.178 2.907 20.846-3.93 7.022-10.286 18.045-17.272 30.243-19.169 24.864-3.87 41.106 13.323 46.499 15.221l108.6 108.6c-89.375 78.793-221.6 75.383-307.25-10.262zm328.46-10.95-162.95-162.95c26.722 1.591 51.372 13.106 69.977 32.844 5.687 6.034 15.181 6.304 21.204 0.626 6.028-5.682 6.309-15.175 0.626-21.204-25.437-26.986-61.236-42.464-98.218-42.464-7.39 0-14.732 0.628-21.954 1.833l-25.048-25.048c15.087-4.468 30.887-6.785 47.002-6.785 42.726 0 83.245 16.267 114.09 45.804 5.984 5.73 15.479 5.522 21.208-0.46 5.729-5.984 5.523-15.479-0.461-21.208-36.459-34.91-84.347-54.136-134.84-54.136-24.488 0-48.358 4.535-70.613 13.174l-22.815-22.815c29.069-13.313 60.857-20.359 93.428-20.359 56.032 0 109.76 20.813 151.28 58.604 6.127 5.576 15.613 5.129 21.19-0.998 5.576-6.127 5.129-15.614-0.998-21.19-47.061-42.829-107.96-66.416-171.48-66.416-40.686 0-80.291 9.693-115.88 27.907l-32.268-32.268c42.825-37.755 95.482-56.649 148.15-56.649 57.246 0 114.49 22.304 159.1 66.911 85.646 85.646 89.055 217.87 10.262 307.25z" />
      <path d="m256 331c-24.813 0-45 20.187-45 45s20.187 45 45 45 45-20.187 45-45-20.187-45-45-45zm0 60c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.729 15-15 15z" />
    </svg>
  </div>
);

export default AppOffline;
