import React from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from 'app-types';
import NavItems from './NavItems';
import { Container } from '../../components/Layout';
import { ApplicationState } from '../../reducers';
import { NavigationItem } from '../../types/navigation';
import './Dashboard.scss';

interface Props {
  children: React.ReactNode;
  navigation?: NavigationItem[];
  hide?: boolean;
}

const Dashboard = ({ navigation, children, hide }: Props) => {
  const user = useSelector((state: ApplicationState) => state.user.details);
  return (
    <>
      {navigation && (
        <nav className={`dashboard-navigation ${hide ? 'hide' : ''}`}>
          <Container fluid noMobilePadding>
            <NavItems items={navigation} />
          </Container>
        </nav>
      )}
      <Container paddingTop fluid={user && user.role === UserRole.Admin}>
        {children}
      </Container>
    </>
  );
};

export default Dashboard;
