import { NotificationBase, UserNotificationDetails, UserRole } from 'app-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modal } from '../../actions';
import { Spinner, Button } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import { ButtonsContainer } from '../../components/Layout';
import { ApplicationState } from '../../reducers';
import ApiService from '../../services/api-service';
import './NotificationPreview.scss';

interface Props {
  id: string;
  forAdmin?: boolean;
}

const NotificationPreview = ({ id, forAdmin }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.user.details);
  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<UserNotificationDetails | NotificationBase | null>(null);

  useEffect(() => {
    getNotification();
  }, []);

  const getNotification = async () => {
    setLoading(true);
    await ApiService.callFetch(
      'GET',
      `notification/details/${id}`,
      (data: UserNotificationDetails | NotificationBase) => {
        if ([UserRole.Client, UserRole.Accountant].includes(user.role)) {
          setItem(data as UserNotificationDetails);
        }
        if (user.role === UserRole.Admin) {
          setItem(data as NotificationBase);
        }
      },
    );
    setLoading(false);
  };

  if (!item || loading) return <Spinner />;

  let titleToShow;
  let textToShow;
  let imageToShow;
  let buttonTextToShow;
  let buttonHrefToShow;
  let itemId;

  if ([UserRole.Client, UserRole.Accountant].includes(user.role)) {
    const { id, title, text, notification } = item as UserNotificationDetails;
    titleToShow = notification ? notification.title : title;
    textToShow = notification ? notification.text : text;
    buttonHrefToShow = notification ? notification.buttonHref : '';
    buttonTextToShow = notification ? notification.buttonText : '';
    imageToShow = notification ? notification.image : '';
    itemId = notification ? notification.id : '';
  } else {
    const { id, title, text, image, buttonHref, buttonText } = item as NotificationBase;
    titleToShow = title;
    textToShow = text;
    buttonHrefToShow = buttonHref;
    buttonTextToShow = buttonText;
    imageToShow = image;
    itemId = id;
  }

  console.log('NOTIFICATIONPREVIEW');

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle rawTitle={titleToShow} />
      </ModalHeader>
      <div className="notification-preview">
        {imageToShow && (
          <img src={`${ApiService.url}notification/image/${itemId}`} alt={titleToShow} />
        )}
        {textToShow}

        {buttonHrefToShow && buttonTextToShow && (
          <ButtonsContainer center marginTop>
            <Button to={buttonHrefToShow} blank text={buttonTextToShow} />
          </ButtonsContainer>
        )}
      </div>
      <ButtonsContainer marginTop>
        <Button
          className="button-modal"
          click={() => dispatch(modal.hideModal())}
          text="application.ok"
          primary
          noShadow
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default NotificationPreview;
