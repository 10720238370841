import React from 'react';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { MileageCarForm } from '../../components/Common';
import { FormContainer } from '../../components/Layout';

interface Props {
  id: string;
}
const EditMileageCar = ({ id }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_car" />
    </ModalHeader>
    <FormContainer>
      <MileageCarForm
        formUrl={`mileage-car/form/update-item/${id}`}
        formSendUrl={`mileage-car/${id}`}
        method="PATCH"
        edit
        withNotification
      />
    </FormContainer>
  </ModalContent>
);

export default EditMileageCar;
