export enum ClientType {
  COMPANY,
  PRIVATE,
}

export interface ClientEntity {
  id: string;
  type: ClientType;
  address: string;
  city: string;
  name: string;
  kvk: string;
  btw: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  ident: number;
  phone: string;
  note: string;
  iban: string;
  bic: string;
  additionalEmailAddressForSendingInvoice?: string
}
