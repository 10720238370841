import React from 'react';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import ApiService from '../../../../services/api-service';
import { Spinner, Button, FieldsMapper, RequiredIndicator } from '../../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../../components/Layout';
import { notifications } from '../../../../actions';
import { history, flatpickr } from '../../../../App';
import { formOnTranslateString } from '../../../../utils/trans-form';
import useHeader from '../../../../hooks/useHeader';

const AddOtherIncome = () => {
  const dispatch = useDispatch();
  useHeader('application.add_other_income', '/dashboard/other-income/list');
  return (
    <>
      <FormContainer>
        <ItfApiForm
          formId="add-other-income"
          schemaMayBeDynamic={false}
          schemaCacheEnabled
          useFormDataInsteadOfPlainObject
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm('other-income/form/add-item')}
          sendFetcher={(formId: string, values: any) => ApiService.sendForm('other-income', values, 'POST')}
          onSavedSuccessfully={() => {
            dispatch(notifications.successNotification('application.other_income_added'));
            history.push('/dashboard/other-income');
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button type="submit" text="application.save" primary />
            </ButtonsContainer>
          }
          onRenderFullField={FieldsMapper}
          onTranslateString={formOnTranslateString}
          thirdPartyComponents={{ flatpickr }}
        />
      </FormContainer>
    </>
  );
};

export default AddOtherIncome;
