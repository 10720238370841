import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientUserEntity, MileageCarEntity, MileageCarRes, UserEntity } from 'app-types';
import { __ } from '../../../services/translation';
import {
  CarAutosuggestion,
  FieldsMapper,
  GoogleMapsRouteButton,
  Input,
  Paragraph,
  ToggleInput,
} from '..';
import { ButtonsContainer } from '../../Layout';
import { ApplicationState } from '../../../reducers';
import { modal } from '../../../actions';
import { ChangeCar } from '../../../modals';
import RouteInput from '../RouteInput';

interface Props {
  data: {
    field: any;
    errors: any;
    fieldHtmlId: any;
    currentValue: any;
    onChange: any;
    onFocus: any;
    onBlur: any;
    formState?: any;
    route: {
      startPoint: string;
      finishPoint: string;
      distance: number;
      time: number;
    };
    returnRoute?: {
      startPoint: string;
      finishPoint: string;
      distance: number;
      time: number;
    };
    allowOwnAddress?: boolean;
    noDurationHelp?: boolean;
    itfForm?: any;
    closedRelatedModalCallback?: (formVals: any) => void;
  };
}

const MileageFieldsMapper = ({
  data: {
    field,
    errors,
    fieldHtmlId,
    currentValue,
    onChange,
    onFocus,
    onBlur,
    formState,
    route,
    returnRoute,
    allowOwnAddress,
    noDurationHelp,
    itfForm,
    closedRelatedModalCallback,
  },
}: Props) => {
  const dispatch = useDispatch();
  const [thisSameReturnRoute, setThisSameReturnRoute] = useState(false);

  const getCarCurrentVals = () => {
    if (itfForm) {
      return {
        carModel: itfForm.api.getValue('carModel'),
        regNumber: itfForm.api.getValue('regNumber'),
        carType: itfForm.api.getValue('carType'),
      };
    } else if (formState) {
      return {
        carModel: formState.carModel,
        regNumber: formState.regNumber,
        carType: formState.carType,
      };
    }

    return {
      carModel: '',
      regNumber: '',
      carType: '',
    };
  };

  const onNewCarSelected = (val: string) => {
    dispatch(
      modal.showModal(
        <ChangeCar
          regNumber={val}
          currentVals={getCarCurrentVals()}
          callback={(vals) => {
            if (closedRelatedModalCallback) {
              return closedRelatedModalCallback(
                formState ? { ...formState, ...vals } : { ...vals },
              );
            }

            onCarSelected(vals);

            dispatch(modal.hideModal());
          }}
        />,
      ),
    );
  };

  const onCarSelected = (val: MileageCarEntity) => {
    const { regNumber, carModel, carType } = val;
    itfForm.api.setValues({
      regNumber,
      carModel,
      carType,
    });
  };

  if (field.id === 'startPoint') {
    return (
      <>
        {FieldsMapper(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur)}
        <div className="route-input-container">
          <div>
            <RouteInput
              startPoint={route.startPoint}
              finishPoint={route.finishPoint}
              distance={route.distance}
              time={route.time}
              onChange={(vals: any) => {
                if (closedRelatedModalCallback) {
                  return closedRelatedModalCallback(
                    formState ? { ...formState, ...vals } : { ...vals },
                  );
                }
                itfForm.api.setValues({
                  startPoint: vals.startPoint,
                  finishPoint: vals.finishPoint,
                  distance: vals.distance,
                  time: vals.time,
                });
              }}
              customCloseModal={!!closedRelatedModalCallback}
              allowOwnAddress={allowOwnAddress}
            />
          </div>
          {returnRoute && (
            <div>
              <RouteInput
                startPoint={returnRoute.startPoint}
                finishPoint={returnRoute.finishPoint}
                distance={returnRoute.distance}
                time={returnRoute.time}
                onChange={(vals: any) => {
                  const newVals = {
                    startPointReturn: vals.startPoint,
                    finishPointReturn: vals.finishPoint,
                    distanceReturn: vals.distance,
                    timeReturn: vals.time,
                  };
                  if (closedRelatedModalCallback) {
                    return closedRelatedModalCallback(
                      formState ? { ...formState, ...newVals } : { ...newVals },
                    );
                  }
                  setThisSameReturnRoute(false);
                  itfForm.api.setValues({ ...newVals });
                }}
                customCloseModal={!!closedRelatedModalCallback}
                allowOwnAddress={allowOwnAddress}
                returnRoute
              />

              <div className="field">
                <Input
                  type="checkbox"
                  label="application.this_same_return_route"
                  input={{
                    name: 'useMyAddress',
                    id: 'useMyAddress',
                    placeholder: field.placeholder,
                    value: thisSameReturnRoute,
                    onChange: (e: any) => {
                      const isSet = Boolean(e.target.checked);
                      setThisSameReturnRoute(isSet);
                      if (isSet) {
                        itfForm.api.setValues({
                          startPointReturn: route.finishPoint,
                          finishPointReturn: route.startPoint,
                          distanceReturn: route.distance,
                          timeReturn: route.time,
                        });
                      } else {
                        itfForm.api.setValues({
                          startPointReturn: '',
                          finishPointReturn: '',
                          distanceReturn: '',
                          timeReturn: '',
                        });
                      }
                    },
                    onFocus,
                    onBlur,
                  }}
                  meta={{}}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  } else if (field.id === 'isPrivate') {
    return (
      <ToggleInput
        input={{
          value: currentValue,
          onChange: onChange,
          id: fieldHtmlId,
        }}
        firstButtonLabel="application.mileage_company"
        secondButtonLabel="application.mileage_private"
        translateLabels
      />
    );
  } else if (field.id === 'regNumber') {
    return (
      <CarAutosuggestion
        required={field.required}
        label={field.title}
        input={{
          value: currentValue,
          name: fieldHtmlId,
          id: fieldHtmlId,
          onChange,
        }}
        onOptionSelect={onCarSelected}
        placeholder={field.placeholder}
        meta={{
          touched: errors.length > 0,
          error: errors.length ? __(errors[0].messageWithPlaceholders, errors[0].params) : null,
        }}
        allowNewCar
        onNewCarSelect={onNewCarSelected}
      />
    );
  } else if (field.id === 'isExtras') {
    return (
      <>
        <Paragraph translation="application.exceptions" strong />{' '}
        {FieldsMapper(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur)}
      </>
    );
  }
  return FieldsMapper(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur);
};

export default MileageFieldsMapper;
