import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CheaterUser } from 'app-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { user, modal } from '../../../../actions';
import { Confirmation } from '../../../../modals';
import { Section, ButtonsContainer } from '../../../../components/Layout';
import { Button } from '../../../../components/Common';
import { __ } from '../../../../services/translation';
import './Cheater.scss';
import CommonButtons from '../../CommonButtons';

interface Props {
  item: CheaterUser;
  deleteUser: (user: string) => void;
  markAsCheater: (userId: string, isCheater: boolean) => void;
  showModal: (content: React.ReactNode) => void;
  hideModal: () => void;
}

class Cheater extends Component<Props> {
  private confirmClientMarkAsNotCheater = (client: CheaterUser) => {
    const { showModal, markAsCheater } = this.props;
    showModal(
      <Confirmation
        text="application.mark_as_not_cheater_confirmation"
        confirmCallback={() => markAsCheater(client.id, false)}
      />,
    );
  };

  render() {
    const { item } = this.props;
    return (
      <Section>
        <div className="cheater-container">
          <div className="cheater-name-container">
            {item.changeCount > 0 && (
              <p className="cheater-reason">
                <FontAwesomeIcon icon={faExclamationCircle} className="reason-icon" />
                <strong>{__('application.account_type_count_change')}: </strong>
                {item.changeCount}
              </p>
            )}
            {item.isCheater && (
              <p className="cheater-reason">
                <FontAwesomeIcon icon={faExclamationCircle} className="reason-icon" />
                <strong>{__('application.account_marked_by_admin')}</strong>
              </p>
            )}

            <div className="name">{item.companyName}</div>
            <span className="email">{item.email}</span>
          </div>

          <ButtonsContainer>
            <CommonButtons client={item} />
            <Button
              small
              primary
              text="application.mark_as_not_cheater"
              click={() => this.confirmClientMarkAsNotCheater(item)}
            />
          </ButtonsContainer>
        </div>
      </Section>
    );
  }
}
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      deleteUser: user.deleteUser,
      markAsCheater: user.markAsCheater,
      showModal: modal.showModal,
      hideModal: modal.hideModal,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Cheater);
