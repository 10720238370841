import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CategoryRes } from 'app-types';
import { useDispatch } from 'react-redux';
import { TableSchema, costsForUser } from 'app-tables';
import { Alert, ApiList, Spinner } from '../../../components/Common';
import CostItem from '../Costs/Item';
import { InteractiveTableSchema } from '../../../utils/table-shcema-utils';
import useHeader from '../../../hooks/useHeader';
import ApiService from '../../../services/api-service';

import { header } from '../../../actions';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import { __ } from '../../../services/translation';

const costTabs = [
  {
    title: 'application.cost_list',
    link: '/dashboard/costs/list',
    icon: 'back',
  },
];

const CostListByCategory = () => {
  useHeader('application.cost_list_by_category', '/');
  const dispatch = useDispatch();
  const { categoryId } = useParams<{ categoryId: string }>();
  const scheme: TableSchema = new InteractiveTableSchema(costsForUser);

  const [category, setCategory] = useState<CategoryRes>();
  const [loading, setLoading] = useState<boolean>(false);

  const getCategory = async () => {
    setLoading(true);
    await ApiService.callFetch('GET', `category/${categoryId}`, (data: CategoryRes) => {
      setCategory(data);
      dispatch(
        header.setHeader('application.cost_list_by_category', '/', undefined, ` ${data.title}`),
      );
    });
    setLoading(false);
  };

  useEffect(() => {
    if (categoryId) getCategory();
    else {
      dispatch(
        header.setHeader(
          'application.cost_list_by_category',
          '/',
          undefined,
          ` ${__('application.no_category')}`,
        ),
      );
    }
  }, []);

  return (
    <Page light withNavigation>
      <Dashboard navigation={costTabs}>
        {loading && <Spinner />}
        {!loading && categoryId && !category && <Alert type="notice" text="application.no_data" />}
        {(!categoryId || (categoryId && category)) && (
          <ApiList
            apiEndpointSubUrl={`cost/list/by-category/${categoryId || ''}`}
            scheme={scheme}
            component={CostItem}
            padded
          />
        )}
      </Dashboard>
    </Page>
  );
};

export default CostListByCategory;
