export interface ContactUsRequest {
  title: string;
  content: string;
  type: ContactUsType;
}

export enum ContactUsType {
  Idea,
  Help,
  Question,
  Payment,
  EditionRequest,
}

export interface SendCollectionReq {
  email: string;
  message: string;
  startAt: Date;
  endAt: Date;
  withInvoices: boolean;
  withMileages: boolean;
  withTimesheets: boolean;
  withCosts: boolean;
  withOtherIncome: boolean;
  sendCopyToMe?: boolean;
}
