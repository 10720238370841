import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { __ } from '../../../services/translation';
import { modal } from '../../../actions';
import './Disclaimer.scss';

interface Props {
  text: string;
  list?: string[];
  bottomPadding?: boolean;
  smallBottomPadding?: boolean;
  desktopOnly?: boolean;
}

const Disclaimer = ({ text, list, bottomPadding, smallBottomPadding, desktopOnly }: Props) => (
  <>
    <p
      className={`disclaimer ${bottomPadding ? 'bottom-padding' : ''}  ${desktopOnly ? 'desktop-only' : ''} ${
        smallBottomPadding ? 'small-bottom-padding' : ''
      }`}
    >
      {__(text)}
    </p>
    {list ? (
      <ul>
        {list.map((item: any) => (
          <li>{__(item)}</li>
        ))}
      </ul>
    ) : null}
  </>
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ showModal: modal.showModal }, dispatch);

export default connect(null, mapDispatchToProps)(Disclaimer);
