import { ApiErrorCode, ApiResult } from 'app-types';
import { Capacitor, Plugins } from '@capacitor/core';
import { notifications, auth } from '../actions';
import SessionService from './session-service';
import { store, history } from '../App';

const { SecureStoragePlugin } = Plugins;

export default class ApiService {
  static url =
    typeof process.env.REACT_APP_API_URL === 'string'
      ? `${process.env.REACT_APP_API_URL.trim()}/`
      : 'http://localhost:3001/';

  static call = async (
    dispatch: any,
    method: string,
    successAction: string,
    failedAction: string,
    successCb: any,
    failedCb: any,
    url: string,
    loadingAction = 'LOADING',
    requestData?: any,
    sendAsMultipart?: boolean,
    disableFailureNotification?: boolean,
  ) => {
    dispatch({ type: loadingAction, payload: true });

    const { managedUser } = store.getState().accounting;

    const headers: any = sendAsMultipart
      ? {
        'Content-Type': 'multipart/form-data',
      }
      : {
        'Content-Type': 'application/json',
      };

    const formDataHeaders: any = {};

    if (Capacitor.isNative) {
      const { token } = store.getState().user;
      headers['Authorization'] = `Bearer ${token}`;
      formDataHeaders['Authorization'] = `Bearer ${token}`;
    }

    if (managedUser) {
      headers['Accounting-User'] = managedUser.id;
      formDataHeaders['Accounting-User'] = managedUser.id;
    }

    try {
      const response = await fetch(`${ApiService.url}${url}`, {
        method,
        headers: requestData && requestData instanceof FormData ? formDataHeaders : headers,
        credentials: 'include',
        body:
          requestData && requestData instanceof FormData
            ? requestData
            : JSON.stringify(requestData),
      });

      const data = await response.json();
      if (ApiService.is403(data)) {
        if (data.message) dispatch(notifications.errorNotification(data.message));
        else dispatch(notifications.errorNotification(ApiErrorCode.InsufficientPrivileges));
      } else {
        switch (data.result) {
          case ApiResult.ok:
            dispatch({ type: successAction, payload: data.payload });
            if (successCb) successCb(data.payload);
            dispatch({ type: loadingAction, payload: false });
            break;
          case ApiResult.error:
            if (data.error_code === ApiErrorCode.NoSuchObjectWithThisId) {
              history.push('/error');
            } else {
              dispatch({ type: failedAction, payload: data.error_code });
              if (failedCb) failedCb(data.error_code);
              if (disableFailureNotification !== true) {
                dispatch(notifications.errorNotification(data.error_code));
              }
              dispatch({ type: loadingAction, payload: false });
            }
            break;
          default:
            if (ApiService.is404(data)) {
              history.push('/error');
            } else if (ApiService.is401(data)) {
              if (SessionService.isUserSet()) dispatch(auth.clearUserSession());
              history.push('/');
              dispatch({ type: loadingAction, payload: false });
            } else if (ApiService.is502(data)) {
              history.push('/error');
            } else {
              dispatch({ type: failedAction, payload: ApiErrorCode.OtherError });
              dispatch(notifications.errorNotification(ApiErrorCode.OtherError));
              dispatch({ type: loadingAction, payload: false });
            }
        }
      }
    } catch (error) {
      console.log('error', error);
      dispatch({ type: failedAction, payload: ApiErrorCode.OtherError });
      // dispatch(notifications.errorNotification(ApiErrorCode.OtherError));
      // user should not be cleared there! Session should be keep aliv
      dispatch({ type: loadingAction, payload: false });
    }
  };

  static simpleFetch = async (method: string, url: string) => {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    const { managedUser } = store.getState().accounting;

    if (Capacitor.isNative) {
      const { token } = store.getState().user;
      headers['Authorization'] = `Bearer ${token}`;
    }

    if (managedUser) {
      headers['Accounting-User'] = managedUser.id;
    }

    const response = await fetch(url, {
      method,
      headers,
      credentials: 'include',
    });

    return response;
  };

  static callFetch = async (
    method: string,
    url: string,
    successCb?: any,
    failedCb?: any,
    requestData?: any,
  ) => {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    const formDataHeaders: any = {};

    const { managedUser } = store.getState().accounting;

    if (Capacitor.isNative) {
      const { token } = store.getState().user;
      headers['Authorization'] = `Bearer ${token}`;
      formDataHeaders['Authorization'] = `Bearer ${token}`;
    }

    if (managedUser) {
      headers['Accounting-User'] = managedUser.id;
      formDataHeaders['Accounting-User'] = managedUser.id;
    }

    try {
      const response = await fetch(`${ApiService.url}${url}`, {
        method,
        headers: requestData && requestData instanceof FormData ? formDataHeaders : headers,
        credentials: 'include',
        body:
          requestData && requestData instanceof FormData
            ? requestData
            : JSON.stringify(requestData),
      });

      const data = await response.json();
      if (ApiService.is403(data)) {
        if (data.message) store.dispatch(notifications.errorNotification(data.message));
        else store.dispatch(notifications.errorNotification(ApiErrorCode.InsufficientPrivileges));
      } else {
        switch (data.result) {
          case ApiResult.ok:
            if (successCb) successCb(data.payload);
            return data.payload;
          case ApiResult.error:
            if (failedCb) failedCb(data);

            store.dispatch(notifications.errorNotification(data.error_code));
            break;
          default:
            if (ApiService.is401(data)) {
              history.push('/');
              if (SessionService.isUserSet()) store.dispatch(auth.clearUserSession());
            } else {
              store.dispatch(notifications.errorNotification(data.error_code));
            }
        }
      }
    } catch (error) {
      store.dispatch(notifications.errorNotification(ApiErrorCode.OtherError));
    }
  };

  static loadForm = async (url: string) => {
    try {
      return JSON.parse(((await ApiService.callFetch('GET', url)) as any).form);
    } catch (e) {
      console.error(e);
    }
  };

  static loadFormWithData = async (url: string, body: any) => {
    try {
      return JSON.parse(
        ((await ApiService.callFetch('POST', url, undefined, undefined, body)) as any).form,
      );
    } catch (e) {
      console.error(e);
    }
  };

  static sendForm = async (
    url: string,
    values: any,
    method = 'POST',
    successCb?: any,
    failedCb?: any,
  ): Promise<any | void> =>
    new Promise(async (resolve, reject) => {
      await ApiService.callFetch(
        method,
        url,
        (payload: any) => {
          if (successCb) successCb(payload);
          resolve(payload);
        },
        (data: any) => {
          if (data.form_errors) {
            resolve(data);
          } else {
            if (failedCb) failedCb(data);
            reject(data.result);
          }
        },
        values,
      );
    });

  static is401 = (response: any) => response.statusCode === 401;

  static is403 = (response: any) => response.statusCode === 403;

  static is404 = (response: any) => response.statusCode === 404;

  static is502 = (response: any) => response.statusCode === 502;

  static post = async (
    dispatch: any,
    successAction: string,
    failedAction: string,
    successCb: any,
    failedCb: any,
    url: string,
    loadingAction = 'LOADING',
    requestData: any,
    sendAsMultipart?: boolean,
    disableFailureNotification?: boolean,
  ) => {
    if (sendAsMultipart) {
      return await ApiService.call(
        dispatch,
        'POST',
        successAction,
        failedAction,
        successCb,
        failedCb,
        url,
        loadingAction,
        requestData,
        sendAsMultipart,
      );
    } else if (disableFailureNotification) {
      return await ApiService.call(
        dispatch,
        'POST',
        successAction,
        failedAction,
        successCb,
        failedCb,
        url,
        loadingAction,
        requestData,
        (sendAsMultipart = false),
        disableFailureNotification,
      );
    } else if (disableFailureNotification && sendAsMultipart) {
      return await ApiService.call(
        dispatch,
        'POST',
        successAction,
        failedAction,
        successCb,
        failedCb,
        url,
        loadingAction,
        requestData,
        sendAsMultipart,
        disableFailureNotification,
      );
    } else {
      return await ApiService.call(
        dispatch,
        'POST',
        successAction,
        failedAction,
        successCb,
        failedCb,
        url,
        loadingAction,
        requestData,
      );
    }
  };

  static patch = async (
    dispatch: any,
    successAction: string,
    failedAction: string,
    successCb: any,
    failedCb: any,
    url: string,
    loadingAction = 'LOADING',
    requestData?: any,
  ) => {
    await ApiService.call(
      dispatch,
      'PATCH',
      successAction,
      failedAction,
      successCb,
      failedCb,
      url,
      loadingAction,
      requestData,
    );
  };

  static get = async (
    dispatch: any,
    successAction: string,
    failedAction: string,
    successCb: any,
    failedCb: any,
    url: string,
    loadingAction = 'LOADING',
  ) => {
    await ApiService.call(
      dispatch,
      'GET',
      successAction,
      failedAction,
      successCb,
      failedCb,
      url,
      loadingAction,
    );
  };

  static delete = async (
    dispatch: any,
    successAction: string,
    failedAction: string,
    successCb: any,
    failedCb: any,
    url: string,
    loadingAction = 'LOADING',
  ) => {
    await ApiService.call(
      dispatch,
      'DELETE',
      successAction,
      failedAction,
      successCb,
      failedCb,
      url,
      loadingAction,
    );
  };

  static sendForFile = async (
    method: string,
    url: string,
    data?: any,
    excludeApiURL = false,
  ): Promise<any | void> => {
    let filename = '';
    const headers: any = {
      'Content-Type': 'application/json',
    };
    const { managedUser } = store.getState().accounting;

    if (managedUser) {
      headers['Accounting-User'] = managedUser.id;
    }

    return fetch(excludeApiURL ? url : `${ApiService.url}${url}`, {
      method,
      body: data ? JSON.stringify(data) : undefined,
      headers,
      credentials: 'include',
    })
      .then((response: any) => {
        filename = response.headers.get('filename');
        return response.blob();
      })

      .then((data: any) => {
        return { filename, data };
      });
  };

  static getImageSrc = async (url: string): Promise<any | void> => {
    let filename = '';
    const headers: any = {
      'Content-Type': 'application/json',
    };
    const { managedUser } = store.getState().accounting;

    if (managedUser) {
      headers['Accounting-User'] = managedUser.id;
    }

    return fetch(`${ApiService.url}${url}`, {
      method: 'GET',
      headers,
      credentials: 'include',
    })
      .then((response: any) => {
        filename = response.headers.get('filename');
        return response.blob();
      })

      .then((data: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
        };
      });
  };
}
