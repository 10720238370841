import React from 'react';
import { __ } from '../../../../services/translation';
import './Suggestion.scss';
import { MileageCarRes } from 'app-types';

interface Props {
  item: MileageCarRes;
  onSelect: (data: MileageCarRes) => void;
}

const Suggestion = ({ item, onSelect }: Props) => {
  return (
    <li
      role="button"
      onClick={() => {
        onSelect(item);
      }}
      className="company-suggestion-wrapper"
    >
      <div>
        <span className="company-suggestion-name">{item.regNumber}</span>
        <div className="company-suggestion-address">
          {item.carModel} {item.carType}
        </div>
      </div>
    </li>
  );
};

export default Suggestion;
