import {
    ClientEntity,
    NewCost,
    OfferRes,
} from 'app-types';
import ApiService from '../services/api-service';
import { history } from '../App';
import { notifications, modal } from '.';

export const getSingleOffer = (id: string) => async (dispatch: any) => {
    await ApiService.get(
        dispatch,
        'GET_SINGLE_OFFER_SUCCESS',
        'GET_SINGLE_OFFER_FAILED',
        null,
        null,
        `offer/detail/${id}`,
        'LOADING_SINGLE_OFFER',
    );
};


export const deleteSingleOffer = (id: string) => async (dispatch: any) => {
    await ApiService.delete(
        dispatch,
        'DELETE_OFFER_SUCCESS',
        'DELETE_OFFER_FAILED',
        () => {
            dispatch(notifications.successNotification('application.offer_deleted.'));
            dispatch(modal.hideModal());
            history.push('/dashboard/offers/list');
        },
        null,
        `offer/${id}`,
        'LOADING_OFFER',
    );
};

export const deleteFileFromOffer = (fileId: string, offerId: string) => async (
    dispatch: any,
) => {
    await ApiService.delete(
        dispatch,
        'DELETE_FILE_FROM_OFFER_SUCCESS',
        'DELETE_FILE_FROM_OFFER_FAILED',
        () => {
            dispatch(modal.hideModal());
            window.location.reload();
        },
        null,
        `offer/file/${fileId}/form/${offerId}`,
        'LOADING_DELETE_FILE_FROM_OFFER',
    );
};


export const receiveOfferData = (values: any) => ({
    type: 'RECEIVE_OFFER_DATA',
    payload: values,
});


export const saveOffer = (formValues: any, id?: string, redirectToSend?: boolean) => async (
    dispatch: any,
) => {
    const values = { ...formValues };
    const formData = new FormData();

    if (values.files) {
        values.files.forEach((file: any) => {
            if (file.file) formData.append('files', file.file, file.file.name);
            // NECESSARY! Do not remove. Without this assigning coosts without files won't work.
            else formData.append('files', new File([], 'offer_no_file'), 'offer_no_file');
        });
    }

    if (values.costs) {
        values.costs.forEach((cost: NewCost) => {
            if (cost.file) formData.append('costs', cost.file, cost.file.name);
            // NECESSARY! Do not remove. Without this assigning coosts without files won't work.
            else formData.append('costs', new File([], 'offer_no_file'), 'offer_no_file');
        });
    }

    const jsonData = JSON.stringify(values);

    const func = id ? ApiService.patch : ApiService.post;

    formData.append('data', jsonData);
    await func(
        dispatch,
        'ADD_OFFER_SUCCESS',
        'ADD_OFFER_FAILED',
        (offer: OfferRes) => {
            if (id) {
                dispatch(
                    notifications.successNotification(
                        'application.offer_updated',
                    ),
                );
            } else {
                dispatch(
                    notifications.successNotification(
                        'application.offer_created'
                    ),
                );
            }
            if (redirectToSend) history.push(`/dashboard/offers/send/${offer.id}`);
            else history.push('/dashboard/offers/list');
        },
        null,
        id ? `offer/${id}` : 'offer/new',
        'LOADING_OFFER_SEND',
        formData,
        true,
    );
};


export const setOfferClient = (client: ClientEntity | null) => ({
    type: 'SET_OFFER_CLIENT',
    payload: client,
});
