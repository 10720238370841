import React, { Component } from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ContactUsType, ContactUsRequest } from 'app-types';
import { InjectedFormProps, reduxForm } from 'redux-form';
import ApiService from '../../../services/api-service';
import { notifications, header } from '../../../actions';
import { Page, ButtonsContainer, FormContainer, Container } from '../../../components/Layout';
import { Button, Spinner, FieldsMapper, RequiredIndicator } from '../../../components/Common';
import { ReactSelect, ReactCreatableSelect } from '../../../App';
import { formOnTranslateString } from '../../../utils/trans-form';
import { __ } from '../../../services/translation';

interface Props {
  contactAdministration: (values: ContactUsRequest) => void;
  successNotification: (notification: string) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class ContactUs extends Component<InjectedFormProps<ContactUsRequest> & Props> {
  private options = [
    { value: ContactUsType.Idea, name: __('application.got_idea') },
    { value: ContactUsType.Help, name: __('application.need_help') },
    { value: ContactUsType.Question, name: __('application.got_question') },
    { value: ContactUsType.Payment, name: __('application.payment_issue') },
    { value: ContactUsType.EditionRequest, name: __('application.update_request') },
  ];

  componentDidMount() {
    const { setHeader } = this.props;
    setHeader('application.contact_us', '', {
      title: 'application.contact_us',
      text: 'annotations.contact_us',
    });
  }

  render() {
    const { handleSubmit, contactAdministration, successNotification } = this.props;
    return (
      <>
        <Page light>
          <Container>
            <FormContainer>
              <ItfApiForm
                formId="contact-us"
                schemaMayBeDynamic
                schemaCacheEnabled={false}
                requiredIndicator={<RequiredIndicator />}
                schemaFetcher={() => ApiService.loadForm('mail/form/contac-us')}
                sendFetcher={(formId: string, values: any) =>
                  ApiService.sendForm('mail/contact-us', values, 'POST')
                }
                onSavedSuccessfully={() => {
                  successNotification('application.message_success');
                }}
                loadingInfo={<Spinner transparent />}
                submitButton={
                  <ButtonsContainer>
                    <Button type="submit" className="form-submit-button" text="Submit" primary />
                  </ButtonsContainer>
                }
                onTranslateString={formOnTranslateString}
                thirdPartyComponents={{ ReactSelect, ReactCreatableSelect }}
                onRenderFullField={FieldsMapper}
              />
            </FormContainer>
          </Container>
        </Page>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { successNotification: notifications.successNotification, setHeader: header.setHeader },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(
  reduxForm<any, any>({
    form: 'contactUsForm',
    enableReinitialize: true,
  })(ContactUs),
);
