import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { RouteComponentProps } from 'react-router';
import ReactSelect from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';
import { MileageToCreate } from 'app-types';
import { ButtonsContainer, FormContainer } from '../../../../../components/Layout';
import {
  Button,
  Spinner,
  RequiredIndicator,
  NarrowParagraph,
  MileageFieldsMapper,
} from '../../../../../components/Common';
import ApiService from '../../../../../services/api-service';
import { notifications, header } from '../../../../../actions';
import { flatpickr, history, store } from '../../../../../App';

import { formOnTranslateString } from '../../../../../utils/trans-form';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

interface State {
  route: { startPoint: string; finishPoint: string; distance: number; time: number };
  returnRoute: { startPoint: string; finishPoint: string; distance: number; time: number };
  itfForm: any;
}

class AddMileageWithScheme extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      route: { startPoint: '', finishPoint: '', distance: 0, time: 0 },
      returnRoute: { startPoint: '', finishPoint: '', distance: 0, time: 0 },
      itfForm: null,
    };
  }

  setRef = (form: any) => {
    this.setState({
      itfForm: form,
    });
  };

  componentDidMount() {
    const { setHeader } = this.props;

    setHeader('application.add_mileage', '/dashboard/mileages/add', {
      title: 'application.add_mileage',
      text: 'mileage.disclaimer',
    });
  }

  registerFormCallbacks = () => {
    // first render - initial values
    this.setState({
      route: {
        startPoint: window.globalForms['mileage-with-scheme'].values.startPoint,
        finishPoint: window.globalForms['mileage-with-scheme'].values.finishPoint,
        distance: window.globalForms['mileage-with-scheme'].values.distance,
        time: window.globalForms['mileage-with-scheme'].values.time,
      },
      returnRoute: {
        startPoint: window.globalForms['mileage-with-scheme'].values.startPointReturn ?? '',
        finishPoint: window.globalForms['mileage-with-scheme'].values.finishPointReturn ?? '',
        distance: window.globalForms['mileage-with-scheme'].values.distanceReturn ?? 0,
        time: window.globalForms['mileage-with-scheme'].values.timeReturn ?? 0,
      },
    });
    window.globalForms['mileage-with-scheme'].valueChangedClb = (newValues: MileageToCreate) => {
      this.setState({
        route: {
          startPoint: newValues.startPoint,
          finishPoint: newValues.finishPoint,
          distance: newValues.distance,
          time: newValues.time,
        },
        returnRoute: {
          startPoint: newValues.startPointReturn ?? '',
          finishPoint: newValues.finishPointReturn ?? '',
          distance: newValues.distanceReturn ?? 0,
          time: newValues.timeReturn ?? 0,
        },
      });
    };
  };

  render() {
    const { itfForm, returnRoute, route } = this.state;
    const { match } = this.props;
    return (
      <>
        <NarrowParagraph text="mileage.create_description" />
        <FormContainer>
          <ItfApiForm
            ref={this.setRef}
            formId="mileage-with-scheme"
            schemaMayBeDynamic
            schemaCacheEnabled={false}
            requiredIndicator={<RequiredIndicator />}
            schemaFetcher={() =>
              ApiService.loadForm(`mileage/form/add-form-from-schema/${match.params.id}`)
            }
            sendFetcher={(formId: string, values: any) =>
              ApiService.sendForm('mileage/new', values, 'POST')
            }
            onSavedSuccessfully={() => {
              store.dispatch(notifications.successNotification('application.mileage_added'));
              history.push('/dashboard/mileages');
            }}
            loadingInfo={<Spinner transparent />}
            submitButton={
              <ButtonsContainer>
                <Button type="submit" primary text="application.add" />
              </ButtonsContainer>
            }
            thirdPartyComponents={{ ReactSelect, ReactCreatableSelect, flatpickr }}
            onTranslateString={formOnTranslateString}
            registerAsGlobalForm
            enableLiveValuesOfGlobalForm
            onFormGloballyRegistered={this.registerFormCallbacks}
            onRenderFullField={(
              field: any,
              errors: any,
              fieldHtmlId: any,
              currentValue: any,
              onChange: any,
              onFocus: any,
              onBlur: any,
            ) => (
              <MileageFieldsMapper
                data={{
                  itfForm,
                  field,
                  errors,
                  fieldHtmlId,
                  currentValue,
                  onChange,
                  onFocus,
                  onBlur,
                  route,
                  returnRoute,
                }}
              />
            )}
          />
        </FormContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ setHeader: header.setHeader }, dispatch);

export default connect(null, mapDispatchToProps)(AddMileageWithScheme);
