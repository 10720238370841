export const isMobile = () =>
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i);

export const isMobileBuild = () =>
  typeof process.env.REACT_APP_IS_MOBILE_APP === 'string' && process.env.REACT_APP_IS_MOBILE_APP.trim() === 'true';
