import React, { Component } from 'react';
import { TableSchema, clientForAdminTableSchema, FieldType } from 'app-tables';
import { UserLabel, UserListForAdmin } from 'app-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InteractiveTableSchema } from '../../../utils/table-shcema-utils';
import { SectionHeader, ApiTable, Button } from '../../../components/Common';
import { __ } from '../../../services/translation';
import {
  Confirmation,
  EditUserForAdmin,
  EditUserNotificationsOptions,
  ManageSubscription,
  SelectLabel,
  UserDetails,
} from '../../../modals';
import { user, modal, header } from '../../../actions';
import { history } from '../../../App';
import CommonButtons from '../CommonButtons';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';

interface Props {
  deleteUser: (client: string) => void;
  showModal: (content: React.ReactNode) => void;
  extendSubscription: (userId: string) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
  markAsCheater: (userId: string, isCheater: boolean) => void;
}

class ClientListForAdmin extends Component<Props> {
  private scheme: TableSchema;

  constructor(props: Props) {
    super(props);
    this.scheme = new InteractiveTableSchema(clientForAdminTableSchema)
      .modifyFieldStatic('language', { customRender: (language: string) => __(language) })
      .modifyFieldStatic('currentAccountType', { customRender: (type: string) => __(type) })

      .addFieldAtTheEnd({
        name: 'application.actions',
        field: 'actions',
        mobile: true,
        type: FieldType.Custom,
        customRender: (value, field, values: UserListForAdmin) => this.getButtons(values),
      });
  }

  componentDidMount() {
    const { setHeader } = this.props;
    setHeader('application.client_list');
  }

  private getButtons = (client: UserListForAdmin) => (
    <>
      <Button small secondary text="application.details" click={() => this.goToDetails(client)} />
      <Button small secondary text="application.payments" click={() => this.goToPayments(client)} />
      <Button small secondary text="application.user_label" click={() => this.goToLabel(client)} />

      <Button
        small
        secondary
        text="application.subscription"
        click={() => this.manageSubscription(client)}
      />
      <Button small secondary text="application.edit" click={() => this.editUser(client)} />
      <Button
        small
        secondary
        text="application.edit_user_notifications_settings"
        click={() => this.goToNotificationOptions(client)}
      />
      <CommonButtons client={client} />
      {!client.isCheater && (
        <Button
          small
          primary
          text="application.mark_as_cheater"
          click={() => this.confirmClientMarkAsCheater(client)}
        />
      )}
      <Button
        small
        danger
        text="application.delete"
        click={() => this.confirmClientDelete(client)}
      />
    </>
  );

  private goToNotificationOptions = (client: UserListForAdmin) => {
    const { showModal } = this.props;
    showModal(<EditUserNotificationsOptions userId={client.id} />);
  };

  private goToDetails = (client: UserListForAdmin) => {
    const { showModal } = this.props;
    showModal(<UserDetails id={client.id} />);
  };

  private goToLabel = (client: UserListForAdmin) => {
    const { showModal } = this.props;
    showModal(<SelectLabel userId={client.id} />);
  };

  private manageSubscription = (client: UserListForAdmin) => {
    const { showModal } = this.props;
    showModal(<ManageSubscription id={client.id} />);
  };

  private goToPayments = (client: UserListForAdmin) => {
    history.push(`/admin/payments/${client.id}`);
  };

  private confirmClientDelete = (client: UserListForAdmin) => {
    const { showModal, deleteUser } = this.props;
    showModal(
      <Confirmation
        text="application.are_you_sure_you_want_to_delete_this_client?"
        confirmCallback={() => deleteUser(client.id)}
      />,
    );
  };

  private confirmClientMarkAsCheater = (client: UserListForAdmin) => {
    const { showModal, markAsCheater } = this.props;
    showModal(
      <Confirmation
        text="application.mark_as_cheater_confirmation"
        confirmCallback={() => markAsCheater(client.id, true)}
      />,
    );
  };

  private editUser = (client: UserListForAdmin) => {
    const { showModal } = this.props;
    showModal(<EditUserForAdmin id={client.id} />);
  };

  render() {
    return (
      <Page light>
        <Dashboard>
          <ApiTable apiEndpointSubUrl="user/client/all" scheme={this.scheme} />
        </Dashboard>
      </Page>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      deleteUser: user.deleteUser,
      markAsCheater: user.markAsCheater,
      hideModal: modal.hideModal,
      showModal: modal.showModal,
      extendSubscription: user.extendSubscription,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(ClientListForAdmin);
