import React from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DataChangedResponse, GetCompanyKvKRes } from 'app-types';
import { ButtonsContainer, FormContainer } from '../../../components/Layout';
import {
  Spinner,
  Button,
  FieldsMapper,
  RequiredIndicator,
  CompanyAutosuggestion,
} from '../../../components/Common';
import ApiService from '../../../services/api-service';
import { user, notifications, modal } from '../../../actions';
import { formOnTranslateString } from '../../../utils/trans-form';
import { ApplicationState } from '../../../reducers';
import { __ } from '../../../services/translation';

interface Props {
  getUserDetails: () => void;
  hideModal: () => void;
  successNotification: (text: string) => void;
  accountantAccess?: boolean;
}

const UserDataForm = ({
  getUserDetails,
  successNotification,
  hideModal,
  accountantAccess,
}: Props) => {
  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  const selectOption = (option: GetCompanyKvKRes) => {
    itfForm.api.setValues({
      companyName: option.name,
      addressStreet: option.address,
      addressCity: option.city,
      addressPostCode: option.postalCode,
      kvk: option.kvk,
    });
  };

  return (
    <>
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="edit-client-data"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() =>
            ApiService.loadForm(
              accountantAccess
                ? 'user/form/update-my-data-by-accountant'
                : 'user/form/update-my-data/',
            )
          }
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm(
              accountantAccess ? 'user/my-client-data-by-accountant' : 'user/my-client-data/',
              values,
              'PATCH',
            )
          }
          onSavedSuccessfully={(res: DataChangedResponse) => {
            if (res.isWaitingForConfirmation) {
              successNotification('application.update_request_has_been_sent');
            } else successNotification('application.your_data_has_been_updated.');
            getUserDetails();
            hideModal();
          }}
          loadingInfoAboveContent
          loadingInfo={<Spinner halfTransparent overlay />}
          submitButton={
            <ButtonsContainer>
              <Button type="submit" primary text="application.save" />
            </ButtonsContainer>
          }
          onRenderFullField={(
            field: any,
            errors: any,
            fieldHtmlId: any,
            currentValue: any,
            onChange: any,
            onFocus: any,
            onBlur: any,
          ) => {
            if (field.id === 'companyName') {
              return (
                <CompanyAutosuggestion
                  required={field.required}
                  label={field.title}
                  input={{
                    value: currentValue,
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    onChange,
                  }}
                  onOptionSelect={selectOption}
                  placeholder={field.placeholder}
                  meta={{
                    touched: errors.length > 0,
                    error: errors.length
                      ? __(errors[0].messageWithPlaceholders, errors[0].params)
                      : null,
                  }}
                />
              );
            } else {
              return FieldsMapper(
                field,
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              );
            }
          }}
          onTranslateString={formOnTranslateString}
        />
      </FormContainer>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getUserDetails: user.getUserDetails,
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
    },
    dispatch,
  );

const mapStateToProps = (state: ApplicationState) => ({
  lang: state.lang.current,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDataForm);
