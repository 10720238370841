import React, { useState } from 'react';
import { __ } from '../../../services/translation';
import { durationToMinutes, formatDuration } from '../../../utils/format-duration';
import { parseDurationInput } from '../../../utils/parse-duration-input';
import RequiredIndicator from '../RequiredIndicator';
import './Input.scss';

interface Props {
  type: string;
  input: any;
  error?: any;
  meta?: any;
  touched?: any;
  placeholder?: string;
  label?: string;
  min?: number;
  max?: boolean;
  maxValue?: number;
  minValue?: number;
  step?: number;
  value?: any;
  options?: any[];
  readonly?: boolean;
  required?: boolean;
  nomargin?: boolean;
  noborder?: boolean;
  help?: string;
  onChange?: (value: string) => void;
  dark?: boolean;
  defaultValue?: any;
  darkBackground?: boolean;
  hidden?: boolean;
  selectOnlyArrow?: boolean;
}

const errorClass = (error: boolean, touched: boolean) => (error && touched ? 'is-invalid' : '');

const Input = (props: Props) => {
  const {
    input,
    type,
    meta,
    placeholder,
    label,
    min,
    max,
    minValue,
    maxValue,
    step,
    options,
    readonly,
    required,
    nomargin,
    noborder,
    help,
    hidden,
    selectOnlyArrow,
  } = props;

  const containerClass = `input-container ${max ? 'input-max' : ''} ${nomargin ? 'nomargin' : ''} ${
    noborder ? 'noborder' : ''
  } ${selectOnlyArrow ? 'select-only-arrow' : ''} ${hidden ? 'hidden' : ''} ${
    type === 'hidden' ? 'hidden' : ''
  } ${readonly ? 'readonly' : ''}`;
  const inputLabel = (
    <label htmlFor={input.name}>
      {label ? __(label) : `${'\u00a0'}`} {required && <RequiredIndicator />}{' '}
    </label>
  );

  const getInputIcon = (type: string) => {
    switch (type) {
      case 'cost':
        return '€';
      case 'time':
        return 'min';
      case 'distance':
        return 'km';
      case 'percent':
        return '%';
      default:
        return '';
    }
  };

  if (type === 'select') {
    const selectOptions = options
      ? options.map((option) => (
          <option disabled={option.disabled} key={option.value} value={option.value}>
            {option.name}
          </option>
        ))
      : null;
    return (
      <div className={containerClass}>
        <div className="input-wrapper">
          {inputLabel}
          <select
            value={input.value || '0'}
            className={`input input-${type} ${max ? 'input-max' : ''} ${
              nomargin ? 'nomargin' : ''
            } ${errorClass(meta.error, meta.touched)}`}
            id={input.name}
            {...input}
            // onChange={(e: any) => {
            //   input.onChange(e.target.value);
            //   if (onValueChange) onValueChange(e.target.value);
            // }}
            placeholder={placeholder ? __(placeholder) : ''}
          >
            {selectOptions}
          </select>
        </div>
        {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
      </div>
    );
  } else if (type === 'checkbox') {
    return (
      <div className="checkbox-outer-cont">
        <label htmlFor={input.name} className="checkbox-container">
          <input
            id={input.name}
            onChange={input.onChange}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            type={type}
            value={input.checked || input.value}
            checked={input.checked || input.value}
          />
          <p className="checkbox-label-label">{__(label)}</p>
          <span className="checkbox-checkmark" />
        </label>
        {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
      </div>
    );
  } else if (type === 'cost' || type === 'time' || type === 'distance' || type === 'percent') {
    return (
      <div className={containerClass}>
        <div className="input-wrapper">
          {inputLabel}
          <div className={`input-group input-with-icon input-${type}`}>
            <input
              className="input"
              id={input.name}
              readOnly={readonly}
              {...input}
              placeholder={placeholder ? __(placeholder) : ''}
              type="number"
              min={minValue || undefined}
              max={maxValue || undefined}
              step={step || 0.01}
            />
            <div className="input-icon">{getInputIcon(type)}</div>
          </div>
        </div>
        {meta.error && <small className="text-danger">{meta.error}</small>}
        {help && <small className="text-help">{help}</small>}
      </div>
    );
  } else if (type === 'textarea') {
    return (
      <div className={containerClass}>
        <div className="input-wrapper">
          {inputLabel}
          <textarea
            className={`input input-${type} ${errorClass(meta.error, meta.touched)}`}
            id={input.name}
            readOnly={readonly}
            {...input}
            placeholder={placeholder ? __(placeholder) : ''}
          />{' '}
        </div>
        {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
      </div>
    );
  } else if (type === 'duration') {
    let valueToDisplay = input.value;

    if (typeof input.value === 'number') {
      valueToDisplay = formatDuration(input.value, true);
    }

    return (
      <div className={containerClass}>
        <div className="input-wrapper">
          {inputLabel}
          <input
            className="input"
            id={input.name}
            readOnly={readonly}
            {...input}
            onChange={(e: any) => {
              input.onChange(e.target.value);
            }}
            onBlur={(e: any) => {
              input.onChange(durationToMinutes(parseDurationInput(e.target.value)));
            }}
            value={valueToDisplay}
            placeholder={placeholder ? __(placeholder) : ''}
            type="text"
          />
        </div>
        {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
        {help && <small className="text-help">{help}</small>}
      </div>
    );
  } else {
    return (
      <div className={containerClass}>
        <div className="input-wrapper">
          {inputLabel}
          <input
            className="input"
            id={input.name}
            readOnly={readonly}
            {...input}
            // onChange={(e: any) => {
            //   input.onChange(e.target.value);
            //   if (onValueChange) onValueChange(e.target.value);
            // }}
            placeholder={placeholder ? __(placeholder) : ''}
            type={type}
            min={minValue || undefined}
            max={maxValue || undefined}
            step={step || 1}
          />
        </div>
        {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
        {help && <small className="text-help">{help}</small>}
      </div>
    );
  }
};

export default Input;

// defaultValue={defaultValue}
