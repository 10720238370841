import { FieldType, TableSchema } from '../typings';

export const notificationsTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.date',
      field: 'date',
      mobile: true,
      sortable: true,
      oppositeSortDir: true,
      defaultSort: true,
      type: FieldType.Date,
    },
    {
      name: 'application.title',
      field: 'title',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
    },
    {
      name: 'application.internal_notification',
      field: 'internal',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Boolean,
    },
    {
      name: 'application.email_notification',
      field: 'email',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Boolean,
    },
    {
      name: 'application.push_notification',
      field: 'push',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Boolean,
    },
  ],
};
