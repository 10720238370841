import React, { useState, useEffect } from 'react';
import DateInput from '../DateInput';
import TimePickerInput from '../TimePickerInput';
import { parseDateInput } from '../../../utils/parse-date-input';
import { FieldsGroup } from '../../Layout';

interface Props {
  value: string;
  meta: any;
  labels: string[];
  ids: string[];
  onChange: (val: Date | Date[]) => void;
  required?: boolean;
  multiple?: boolean;
}

export const parsePickerDate = (date: Date | Date[], hour: any) => {
  if (!date) return null;
  if (Array.isArray(date)) {
    const parsedDates: Date[] = (date as any[]).map((d: Date) => {
      const parsedDate = new Date(d);
      parsedDate.setSeconds(0);
      parsedDate.setMilliseconds(0);
      if (hour) {
        parsedDate.setHours(hour.split(':')[0] || 0);
        parsedDate.setMinutes(hour.split(':')[1] || 0);
      }
      return parsedDate;
    });

    return parsedDates;
  } else {
    const parsedDate = new Date(date);
    parsedDate.setSeconds(0);
    parsedDate.setMilliseconds(0);
    if (hour) {
      parsedDate.setHours(hour.split(':')[0] || 0);
      parsedDate.setMinutes(hour.split(':')[1] || 0);
    }

    return parsedDate;
  }
};

const DateTimeInput = ({ value, onChange, meta, labels, ids, required, multiple }: Props) => {
  const [date, setDate] = useState<Date | Date[]>(multiple ? [new Date()] : new Date());
  const [time, setTime] = useState<string>();

  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          const dateValue = new Date(value[0]);
          const hours = dateValue.getHours();
          const minutes = dateValue.getMinutes();
          setDate(multiple ? value : value[0]);
          setTime(`${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`);
        }
      } else {
        const dateValue = new Date(value);
        const hours = dateValue.getHours();
        const minutes = dateValue.getMinutes();
        setDate(dateValue);
        setTime(`${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`);
      }
    }
  }, []);

  useEffect(() => {
    const changed = parsePickerDate(date, time);
    if (changed) onChange(changed);
  }, [date, time]);

  return (
    <FieldsGroup>
      <DateInput
        label={labels[0]}
        required={required}
        input={{
          name: ids[0],
          id: ids[0],
          value: date,
          onChange: (value: any) => {
            setDate(parseDateInput(value, multiple));
          },
        }}
        multiple={multiple}
        meta={meta}
      />
      <TimePickerInput
        label={labels[1]}
        required={required}
        input={{
          name: ids[1],
          id: ids[1],
          value: time,
          onChange: (value: any) => {
            setTime(value);
          },
        }}
        meta={meta}
      />
    </FieldsGroup>
  );
};

export default DateTimeInput;
