import { AccountantConnectorDetails } from 'app-types';
import React, { useEffect, useState } from 'react';
import Dashboard from '../../../views/DashboardCommon';
import { Page } from '../../../components/Layout';
import ApiService from '../../../services/api-service';
import useHeader from '../../../hooks/useHeader';
import InvitationsList from '../../../components/Common/InvitationsList';

const Invitations = () => {
  useHeader('application.invitations_header', '/dashboard/client/addons');
  const [managedUsers, setManagedUsers] = useState<AccountantConnectorDetails[]>();

  const getMine = async () => {
    await ApiService.callFetch(
      'GET',
      'accountant-connector/my-managed-no-accepted',
      (users: AccountantConnectorDetails[]) => {
        setManagedUsers(users);
      },
    );
  };

  useEffect(() => {
    getMine();
  }, []);

  return (
    <Page light withNavigation>
      <Dashboard>
        <InvitationsList getMine={getMine} managedUsers={managedUsers} />
      </Dashboard>
    </Page>
  );
};

export default Invitations;
