import React from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AccountantConnectorDetails, AuthLoginUserResult } from 'app-types';
import { ButtonsContainer, FormContainer } from '../../../../components/Layout';
import {
  Spinner,
  Button,
  Subheader,
  FieldsMapper,
  RequiredIndicator,
} from '../../../../components/Common';
import ApiService from '../../../../services/api-service';
import { formOnTranslateString } from '../../../../utils/trans-form';
import { user, notifications } from '../../../../actions';
import { ApplicationState } from '../../../../reducers';

interface Props {
  managedUser: AuthLoginUserResult | null;
  getUserDetails: () => void;
  successNotification: (text: string) => void;
}

const Logo = ({ getUserDetails, successNotification, managedUser }: Props) => {
  const deleteLogo = async () => {
    await ApiService.callFetch('DELETE', 'user/logo-by-accountant', () => {
      getUserDetails();
      window.location.reload();
    });
  };

  if (!managedUser) return null;

  return (
    <>
      <Subheader text="application.logo" paddingTop />
      <FormContainer>
        <ItfApiForm
          formId="upload-logo"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          useFormDataInsteadOfPlainObject
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() =>
            ApiService.loadForm(`user/form/upload-logo-by-accountant/${managedUser.id}`)
          }
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm('user/upload-logo-by-accountant', values, 'POST')
          }
          onSavedSuccessfully={() => {
            successNotification('application.your_data_has_been_updated.');
            getUserDetails();
            window.location.reload();
          }}
          loadingInfoAboveContent
          loadingInfo={<Spinner halfTransparent />}
          submitButton={
            <ButtonsContainer>
              {(managedUser as any).logo && (
                <Button type="button" danger text="application.delete" click={deleteLogo} />
              )}
              <Button type="submit" primary text="application.save" />
            </ButtonsContainer>
          }
          onTranslateString={formOnTranslateString}
          onRenderFullField={FieldsMapper}
        />
      </FormContainer>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  managedUser: state.accounting.managedUser,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { getUserDetails: user.getUserDetails, successNotification: notifications.successNotification },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
