import React from 'react';
import { useDispatch } from 'react-redux';
import { GetClientRes, SimpleKvkResponse } from 'app-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import SingleClientPreview from '../../../../modals/SingleClientPreview';
import { modal } from '../../../../actions';
import { __ } from '../../../../services/translation';
import './Suggestion.scss';

interface Props {
  item: GetClientRes | SimpleKvkResponse;
  onSelect: (data: SimpleKvkResponse | GetClientRes) => void;
  forForm?: 'invoiceForm' | 'offerForm';
}

const Suggestion = ({ item, onSelect, forForm }: Props) => {
  const dispatch = useDispatch();

  return (
    <li
      role="button"
      onClick={() => {
        onSelect(item);
      }}
      className="suggestion-wrapper"
    >
      <div>
        <span className="client-suggestion-name">
          {item.name ||
            `${(item as GetClientRes).firstName} ${(item as GetClientRes).lastName}` ||
            (item as GetClientRes).email}
        </span>
        <div className="client-suggestion-address">{item.address}</div>
      </div>
      {(item as GetClientRes).id && (
        <button
          type="button"
          onClick={() =>
            dispatch(
              modal.showModal(
                <SingleClientPreview forForm={forForm} id={(item as GetClientRes).id} />,
              ),
            )
          }
          className="suggestion-icon"
        >
          <FontAwesomeIcon icon={faInfoCircle} size="2x" />
        </button>
      )}
    </li>
  );
};

export default Suggestion;
