import ApiService from '../services/api-service';
import { notifications, modal } from '../actions';
import { history } from '../App';

export const getSingleClient = (id: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_SINGLE_CLIENT_SUCCESS',
    'GET_SINGLE_CLIENT_FAILED',
    null,
    null,
    `client/detail/${id}`,
    'LOADING_SINGLE_CLIENT',
  );
};

export const getCheatersList = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_CHEATERS_LIST_SUCCESS',
    'GET_CHEATERS_LIST_FAILED',
    null,
    null,
    'user/cheaters',
    'LOADING_CHEATERS_LIST',
  );
};

export const deleteClient = (id: string) => async (dispatch: any) => {
  await ApiService.delete(
    dispatch,
    'DELETE_CONTACT_SUCCESS',
    'DELETE_CONTACT_FAILED',
    () => {
      dispatch(modal.hideModal());
      history.push('/dashboard/clients-and-contacts');
      dispatch(notifications.successNotification('application.client_was_deleted.'));
      dispatch({ type: 'REFRESH_API_LIST' });
    },
    null,
    `client/${id}`,
    'LOADING_CONTACT_LIST',
  );
};