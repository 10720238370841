import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { Spinner, Button, FieldsMapper, RequiredIndicator, LoginParagraph } from '../../../components/Common';
import ApiService from '../../../services/api-service';
import { notifications } from '../../../actions';
import { history } from '../../../App';
import { formOnTranslateString } from '../../../utils/trans-form';
import { FormContainer, BottomContainer } from '../../../components/Layout';

interface Props {
  successNotification: (text: string) => void;
}

const ResetPassword = ({ successNotification }: Props) => (
  <FormContainer lightLabels stretch>
    <ItfApiForm
      formId="reset-password-request"
      schemaMayBeDynamic={false}
      schemaCacheEnabled
      requiredIndicator={<RequiredIndicator />}
      schemaFetcher={() => ApiService.loadForm('user/form/reset-pass-request')}
      sendFetcher={(formId: string, values: any) => ApiService.sendForm('user/reset-pass-request', values, 'POST')}
      onSavedSuccessfully={() => {
        successNotification(
          'application.if_your_email_address_was_correct_you_will_receive_link_to_change_your_password._it_will_be_valid_for_next_24_hours.',
        );
        history.push('/');
      }}
      loadingInfo={<Spinner transparent />}
      submitButton={
        <BottomContainer>
          <Button type="submit" primary className="form-submit-button" text="application.submit" noShadow />
          <LoginParagraph />
        </BottomContainer>
      }
      onTranslateString={formOnTranslateString}
      onRenderFullField={FieldsMapper}
    />
  </FormContainer>
);

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ successNotification: notifications.successNotification }, dispatch);

export default connect(null, mapDispatchToProps)(ResetPassword);
