import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import InvoiceForm from '../../OfferForm';
import { ApplicationState } from '../../../../../../reducers';

interface Props {
  invoiceData: any;
}

const EditInvoicePreview = ({ invoiceData }: Props) => {
  if (invoiceData.services.length === 0) return <Redirect to="/dashboard/invoices/list" />;
  return <InvoiceForm invoice={invoiceData} edit />;
};

const mapStateToProps = (state: ApplicationState) => ({
  invoiceData: state.invoice.receivedInvoiceData,
});

export default connect(mapStateToProps)(EditInvoicePreview);
