import React from 'react';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import ApiService from '../../../../../services/api-service';
import { Spinner, Button, RequiredIndicator } from '../../../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../../../components/Layout';
import { notifications } from '../../../../../actions';
import { history, flatpickr } from '../../../../../App';
import { formOnTranslateString } from '../../../../../utils/trans-form';
import useHeader from '../../../../../hooks/useHeader';
import CostFieldsMapper from '../../CostFieldsMapper';
import { useParams } from 'react-router';

const AddCostFromScheme = () => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  useHeader('application.add_cost', '/dashboard/costs/list');

  return (
    <>
      <FormContainer>
        <ItfApiForm
          formId="add-cost-from-scheme"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm(`cost/form/add-item-from-scheme/${id}`)}
          sendFetcher={(formId: string, values: any) => {
            const fd = new FormData();
            for (const key in values) {
              if (key === 'cost' && values.cost) {
                for (const file of values.cost) {
                  fd.append('cost', file);
                }
              } else fd.append(key, values[key]);
            }
            return ApiService.sendForm('cost/file', fd, 'POST');
          }}
          onSavedSuccessfully={() => {
            dispatch(notifications.successNotification('application.cost_added.'));
            history.push('/dashboard/costs');
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button type="submit" text="application.save" primary />
            </ButtonsContainer>
          }
          onRenderFullField={(
            field: any,
            errors: any,
            fieldHtmlId: any,
            currentValue: any,
            onChange: any,
            onFocus: any,
            onBlur: any,
          ) => (
            <CostFieldsMapper
              data={{ field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur }}
            />
          )}
          onTranslateString={formOnTranslateString}
          thirdPartyComponents={{ flatpickr }}
        />
      </FormContainer>
    </>
  );
};

export default AddCostFromScheme;
