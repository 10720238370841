import React from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { useDispatch } from 'react-redux';
import { ButtonsContainer, FormContainer } from '../../../components/Layout';
import { Button, FieldsMapper, RequiredIndicator, Spinner } from '../../../components/Common';
import { formOnTranslateString } from '../../../utils/trans-form';
import { history } from '../../../App';
import { apiList, modal, notifications } from '../../../actions';
import ApiService from '../../../services/api-service';
import { __ } from '../../../services/translation';

interface Props {
  formUrl: string;
  formSendUrl: string;
  method: string;
  edit?: boolean;
  callback?: (data: any) => void;
  buttonText?: string;
  withNotification?: boolean;
}

const MileageCarForm = ({
  formUrl,
  formSendUrl,
  method,
  edit,
  callback,
  buttonText,
  withNotification,
}: Props) => {
  const dispatch = useDispatch();

  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  return (
    <>
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="mileage-car-form"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm(formUrl)}
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm(formSendUrl, values, method)
          }
          onSavedSuccessfully={(data: any) => {
            if (callback) {
              callback(data);
              withNotification &&
                dispatch(notifications.successNotification('application.mileage_car_created'));
              dispatch(modal.hideModal());
            } else if (edit) {
              withNotification &&
                dispatch(notifications.successNotification('application.mileage_car_edited'));
              dispatch(modal.hideModal());
              dispatch(apiList.refreshApiList());
              history.push('/dashboard/mileage-cars/list');
            } else {
              withNotification &&
                dispatch(notifications.successNotification('application.mileage_car_created'));
              history.push('/dashboard/mileage-cars/list');
            }
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text={buttonText || 'application.save'}
                primary
              />
            </ButtonsContainer>
          }
          onTranslateString={(string: string) => formOnTranslateString(string)}
          onRenderFullField={(
            field: any,
            errors: any,
            fieldHtmlId: any,
            currentValue: any,
            onChange: any,
            onFocus: any,
            onBlur: any,
          ) => {
            return FieldsMapper(
              field,
              errors,
              fieldHtmlId,
              currentValue,
              onChange,
              onFocus,
              onBlur,
            );
          }}
        />
      </FormContainer>
    </>
  );
};

export default MileageCarForm;
