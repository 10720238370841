import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Subheader, UserDataForm, Tabs } from '../../../components/Common';
import useHeader from '../../../hooks/useHeader';
import InvoiceAnnotationForm from './InvoiceAnnotation';
import { SingleTab } from '../../../types/tabs';
import Logo from './Logo';
import SetInvoiceNumber from '../../DashboardCommon/SetInvoiceNumber';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';

enum ProfileTab {
  DATA,
  LOGO,
  ANNOTATION,
  INVOICE_NUMBER,
}

const ManagedClientSettings = () => {
  useHeader('application.managed_client_settings');

  const tabs: SingleTab[] = [
    {
      label: 'application.personal_data_and_notifications',
      value: ProfileTab.DATA,
      to: '/dashboard/client-data/data',
    },
    { label: 'application.logo', value: ProfileTab.LOGO, to: '/dashboard/client-data/logo' },
    {
      label: 'application.invoice_annotation',
      value: ProfileTab.ANNOTATION,
      to: '/dashboard/client-data/annotation',
    },
    {
      label: 'application.invoice_number',
      value: ProfileTab.INVOICE_NUMBER,
      to: '/dashboard/client-data/invoice-number',
    },
  ];

  return (
    <Page light>
      <Dashboard>
        <Tabs tabs={tabs} />

        <Switch>
          <Route
            exact
            path="/dashboard/client-data/data"
            component={() => (
              <>
                <Subheader paddingTop text="application.personal_data_and_notifications" />
                <UserDataForm accountantAccess />
              </>
            )}
          />
          <Route exact path="/dashboard/client-data/logo" component={Logo} />
          <Route exact path="/dashboard/client-data/annotation" component={InvoiceAnnotationForm} />
          <Route
            exact
            path="/dashboard/client-data/invoice-number"
            render={() => (
              <>
                <Subheader paddingTop text="application.set_invoice_number" />
                <SetInvoiceNumber />
              </>
            )}
          />
          <Route
            path="/dashboard/client-data"
            render={() => <Redirect to="/dashboard/client-data/data" />}
          />
        </Switch>
      </Dashboard>
    </Page>
  );
};
export default ManagedClientSettings;
