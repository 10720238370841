import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Mileage from './Mileage';
import MileageSchemes from './MileageSchemes';
import TimeSheet from './TimeSheet';
import TimeSheetSchemes from './TimeSheetSchemes';
import Costs from './Costs';
import Home from './Home';
import ContactUs from './ContactUs';
import Invoices from './Invoices';
import VOF from './VOF';
import MileageGenerator from './Generator';
import SendToAccountant from './SendToAccountant';
import Statistics from './Statistics';
import Notifications from './Notifications';
import OtherIncome from './OtherIncome';
import Addons from './Addons';
import { RouteSubscribed, RouteAccountant } from '../../components/Routes';
import ManagedClientSettings from './ManagedClientSettings';
import Accountants from './Accountants';
import ClientsAndContacts from './ClientsAndContacts';
import CostSchemes from './CostSchemes';
import Invitations from './Invitations';
import MileageCars from './MileageCars';
import CostCategories from './CostCategories';
import CostListByCategory from './CostsByCategory';
import Offers from './Offers';

const Profile = React.lazy(() => import('./Profile'));

const DashboardClient = () => (
  <Switch>
    <Route exact path="/dashboard" render={() => <Redirect to="/dashboard/home" />} />
    <RouteSubscribed path="/dashboard/home" component={Home} />
    <RouteSubscribed
      accountantNeedsUser
      path="/dashboard/clients-and-contacts"
      component={ClientsAndContacts}
    />
    <Route path="/dashboard/invitations" component={Invitations} />
    <Route path="/dashboard/profile" component={Profile} />
    <Route path="/dashboard/vof" component={VOF} />
    <RouteSubscribed accountantNeedsUser path="/dashboard/invoices" component={Invoices} />
    <RouteSubscribed accountantNeedsUser path="/dashboard/offers" component={Offers} />
    <RouteSubscribed accountantNeedsUser path="/dashboard/mileages" component={Mileage} />
    <RouteSubscribed accountantNeedsUser path="/dashboard/mileage-cars" component={MileageCars} />
    <RouteSubscribed
      accountantNeedsUser
      path="/dashboard/mileage-schemes"
      component={MileageSchemes}
    />
    <RouteSubscribed accountantNeedsUser path="/dashboard/generator" component={MileageGenerator} />
    <RouteSubscribed
      accountantNeedsUser
      path="/dashboard/category/:categoryId?"
      component={CostListByCategory}
    />
    <RouteSubscribed accountantNeedsUser path="/dashboard/costs" component={Costs} />
    <RouteSubscribed accountantNeedsUser path="/dashboard/costs-schemes" component={CostSchemes} />
    <RouteSubscribed accountantNeedsUser path="/dashboard/categories" component={CostCategories} />
    <RouteSubscribed accountantNeedsUser path="/dashboard/time-sheets" component={TimeSheet} />
    <RouteSubscribed accountantNeedsUser path="/dashboard/statistics" component={Statistics} />
    <RouteSubscribed
      accountantNeedsUser
      path="/dashboard/send-to-accountant"
      component={SendToAccountant}
    />
    <RouteSubscribed
      accountantNeedsUser
      path="/dashboard/time-sheets-schemes"
      component={TimeSheetSchemes}
    />
    <Route path="/dashboard/contact-us" component={ContactUs} />
    <RouteSubscribed path="/dashboard/notifications" component={Notifications} />
    <RouteSubscribed accountantNeedsUser path="/dashboard/other-income" component={OtherIncome} />
    <RouteSubscribed accountantNeedsUser path="/dashboard/addons" component={Addons} />
    <RouteSubscribed accountantNeedsUser path="/dashboard/accountants" component={Accountants} />
    <RouteAccountant needsClient path="/dashboard/client-data" component={ManagedClientSettings} />
  </Switch>
);

export default DashboardClient;
