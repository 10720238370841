import FileSaver from 'file-saver';
import { FilesystemDirectory, Plugins } from '@capacitor/core';
import { writeFile } from 'capacitor-blob-writer';
import { FileOpener } from '@ionic-native/file-opener';
import mime from 'mime-types';
import { isMobileBuild } from '../utils/is-mobile';
import { __ } from './translation';
import ApiService from './api-service';

const { Toast } = Plugins;

export class DownloadService {
  static async download(url: string) {
    const { data, filename } = await ApiService.sendForFile('GET', url);
    const normalizedName = filename.replace(/[&\/\\#,+()$~%'":*?<>{} ]/g, '_');
    if (isMobileBuild()) {
      const { uri } = await writeFile({
        path: `${normalizedName}`,
        directory: FilesystemDirectory.Documents,
        data,
        recursive: true,

        fallback: (err) => {
          console.log(err);
          return process.env.NODE_ENV === 'production';
        },
      });

      FileOpener.open(uri, mime.lookup(normalizedName) as string).catch((error: any) => {
        console.log('ERROR OPENING FILE', error);
      });

      await Toast.show({
        text: __('application.file_download', { link: uri }),
      });
    } else {
      FileSaver.saveAs(data, normalizedName);
    }
  }
}
