import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { GetClientKvKRes, ClientType, ClientEntity } from 'app-types';
import { ItfApiForm } from 'itf_formbuilder_react';

import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ApiService from '../../services/api-service';
import {
  Spinner,
  Button,
  AddressAutosuggestion,
  RequiredIndicator,
  FieldsMapper,
  ClientAutosuggestion,
  Input,
} from '../../components/Common';
import { notifications, modal, apiList, client } from '../../actions';
import { formOnTranslateString } from '../../utils/trans-form';
import { __ } from '../../services/translation';
import { ButtonsContainer } from '../../components/Layout';
import { AddressAutosuggestionResponse } from '../../types/autosuggestion';

interface Props {
  id: string;
  successNotification: (text: string) => void;
  refreshApiList: () => void;
  hideModal: () => void;
  getSingleClient: (id: string) => void;
}

const EditClient = ({
  id,
  successNotification,
  hideModal,
  refreshApiList,
  getSingleClient,
}: Props) => {
  const [type, setType] = useState<ClientType>(ClientType.COMPANY);
  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  const selectOption = (option: GetClientKvKRes) => {
    itfForm.api.setValues({
      name: option.name,
      kvk: option.kvk,
      city: option.city,
      country: option.country,
      address: option.address,
    });
  };

  const selectSugestion = (sugestion: AddressAutosuggestionResponse) => {
    itfForm.api.setValue('address', sugestion.first);
    if (sugestion.second) itfForm.api.setValue('city', sugestion.second);
    if (sugestion.third) itfForm.api.setValue('country', sugestion.third);

    const changes: { [key: string]: string } = { address: sugestion.first };

    if (sugestion.second) changes.city = sugestion.second;
    if (sugestion.third) changes.country = sugestion.third;

    itfForm.api.setValues(changes);
  };

  const registerFormCallbacks = () => {
    window.globalForms['edit-client'].valueChangedClb = (newValues: ClientEntity) => {
      setType(Number(newValues.type));
    };
  };
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.edit_client" />
      </ModalHeader>
      <ItfApiForm
        ref={setRef}
        formId="edit-client"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        requiredIndicator={<RequiredIndicator />}
        schemaFetcher={() => ApiService.loadForm(`client/form/update-item/${id}`)}
        sendFetcher={(formId: string, values: any) =>
          ApiService.sendForm(`client/${id}`, values, 'PATCH')
        }
        onSavedSuccessfully={() => {
          successNotification('application.client_was_edited.');
          hideModal();
          refreshApiList();
          getSingleClient(id);
        }}
        loadingInfo={<Spinner transparent />}
        submitButton={
          <ButtonsContainer>
            <Button type="submit" className="form-submit-button" text="application.save" primary />
          </ButtonsContainer>
        }
        onTranslateString={(string: string) => formOnTranslateString(string)}
        onRenderFullField={(
          field: any,
          errors: any,
          fieldHtmlId: any,
          currentValue: any,
          onChange: any,
          onFocus: any,
          onBlur: any,
        ) => {
          if (field.id === 'name') {
            return (
              <ClientAutosuggestion
                required={field.required}
                label={field.title}
                input={{
                  value: currentValue,
                  name: fieldHtmlId,
                  id: fieldHtmlId,
                  onChange,
                }}
                kvkOnly
                onOptionSelect={selectOption}
                placeholder={field.placeholder}
                meta={{
                  touched: errors.length > 0,
                  error: errors.length
                    ? __(errors[0].messageWithPlaceholders, errors[0].params)
                    : null,
                }}
              />
            );
          } else if (field.id === 'address') {
            return (
              <AddressAutosuggestion
                required={field.required}
                label={field.title}
                input={{
                  value: currentValue,
                  name: fieldHtmlId,
                  id: fieldHtmlId,
                  onChange,
                }}
                onSugestionSelect={selectSugestion}
                splitted
                placeholder={field.placeholder}
                meta={{
                  touched: errors.length > 0,
                  error: errors.length
                    ? __(errors[0].messageWithPlaceholders, errors[0].params)
                    : null,
                }}
              />
            );
          } else if (field.id === 'kvk') {
            return (
              <Input
                type={field.options.type || field.type}
                required={type === ClientType.COMPANY}
                label={field.title}
                input={{
                  name: fieldHtmlId,
                  id: fieldHtmlId,
                  placeholder: field.placeholder,
                  value: currentValue,
                  onChange: (e: any) => onChange(e.target.value),
                  onFocus,
                  onBlur,
                }}
                step={field.options.step}
                placeholder={field.placeholder}
                meta={{
                  touched: errors.length > 0,
                  error: errors.length
                    ? __(errors[0].messageWithPlaceholders, errors[0].params)
                    : null,
                }}
              />
            );
          } else if (field.id !== 'type') {
            return FieldsMapper(
              field,
              errors,
              fieldHtmlId,
              currentValue,
              onChange,
              onFocus,
              onBlur,
            );
          }
        }}
        registerAsGlobalForm
        onFormGloballyRegistered={registerFormCallbacks}
      />
    </ModalContent>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
      refreshApiList: apiList.refreshApiList,
      getSingleClient: client.getSingleClient,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(EditClient);
