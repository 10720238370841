import { Client } from '../../src/client/client.entity';
import { ClientEntity } from '../client';
import { CostEntity } from '../cost';
import { InvoiceFile } from '../invoice';
import { NewServiceForInvoice, ServiceEntity } from '../service';
import { InvoiceUser } from '../user';

export interface OfferEntity {
    id: string;
    createdAt: Date;
    client: ClientEntity;
    issuer: InvoiceUser;
    mark: string;
    totalVal: number;
    totalTaxVal: number;
    validTo: Date;
    status: OfferStatus;
    statusChangeDt: Date;
}


export interface OfferRes {
    id: string;
    createdAt: Date;
    client: ClientEntity;
    issuer: InvoiceUser;
    mark: string;
    validTo: Date;
    annotation: string;
    services: ServiceEntity[];
    files: InvoiceFile[];
    costs: CostEntity[];
    status: OfferStatus;
    statusChangeDt: Date;
}

export interface OfferListRes {
    id: string;
    createdAt: Date;
    validTo: Date;
    mark: string;
    totalVal: number;
    totalTaxVal: number;
    client: string;
    status: OfferStatus;
    statusChangeDt: Date;
}

export interface OfferReq {
    number: string;
    createdAt: Date;
    client: Client;
    issuer: InvoiceUser;
    mark: string;
    validTo: Date;
    totalVal: number;
    totalTaxVal: number;
    services: NewServiceForInvoice[];

}

export enum OfferStatus {
    NEW,
    ACCEPTED
}

