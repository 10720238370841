import React from 'react';
import {
  ApiErrorCode,
  GetClientKvKRes,
  ClientEntity,
  OfferEntity,
  InvoiceFile,
  SomeUserEntity,
} from 'app-types';
import {
  Field,
  FieldArray,
  reduxForm,
  InjectedFormProps,
  GenericFieldArray,
  formValueSelector,
  change,
} from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addWeeks } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FormContainer, ButtonsContainer } from '../../../../../components/Layout';
import {
  Input,
  Button,
  DateInput,
  ClientAutosuggestion,
  Icon,
} from '../../../../../components/Common';
import { history } from '../../../../../App';
import { offer, modal, notifications, header } from '../../../../../actions';
import ValidationService from '../../../../../services/validation-service';
import RenderServices from './RenderServices';
import { __ } from '../../../../../services/translation';
import { ApplicationState } from '../../../../../reducers';
import { scrollTop } from '../../../../../utils/scroll-top';
import { CostForInvoice } from '../../../../../types/cost-for-invoice';
import RenderCosts from './RenderCosts';
import RenderFiles from './RenderFiles';
import { InvoiceClient } from '../../../../../modals';
import { weekPlaceholder } from '../../../../../utils/week-placeholder';
import './OfferForm.scss';

interface Props extends InjectedFormProps {
  user: SomeUserEntity;
  number: string;
  errorNotification: (error: ApiErrorCode) => void;
  errorNotificationText: (error: string) => void;
  receiveOfferData: (values: any) => void;
  showModal: (content: React.ReactNode) => void;
  initialData?: any;
  edit?: boolean;
  services: any;
  costs: CostForInvoice[];
  issueDate: Date | string;
  paymentTerm: Date | string;
  serviceData: any;
  changeField: (form: string, field: string, value: any) => void;
  file?: any;
  client?: ClientEntity | null;
  offer?: OfferEntity;
  files?: InvoiceFile[];
  setOfferClient: (client: ClientEntity | GetClientKvKRes | null) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

interface State {
  displayAnnotation: boolean;
}

const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

class OfferForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displayAnnotation: false,
    };
  }

  componentDidMount() {
    const { edit, setHeader, client } = this.props;
    if (client) this.handleClientSelect(client);
    setHeader(edit ? 'application.edit_offer' : 'application.add_offer');
  }

  componentDidUpdate(prevProps: Props) {
    const { client } = this.props;

    if (client !== prevProps.client && typeof client !== 'undefined') {
      this.handleClientSelect(client);
    }
  }

  private handleClientSelect = (data: GetClientKvKRes | ClientEntity | null) => {
    const { setOfferClient, changeField } = this.props;
    setOfferClient(data);
    if (data) {
      changeField(
        'offerForm',
        'client.name',
        data.name ||
          `${(data as ClientEntity).firstName} ${(data as ClientEntity).lastName}` ||
          (data as ClientEntity).email,
      );
    } else {
      changeField('offerForm', 'client.name', null);
    }
  };

  private handleNewClientModal = (name: string) => {
    const { showModal } = this.props;
    showModal(
      <InvoiceClient
        forForm="offerForm"
        client={{ name } as ClientEntity}
        callback={this.handleClientSelect}
      />,
    );
  };

  private handleClientModal = (client: ClientEntity | GetClientKvKRes) => {
    const { showModal } = this.props;
    showModal(
      <InvoiceClient forForm="offerForm" client={client} callback={this.handleClientSelect} />,
    );
  };

  private submitOffer = (values: any) => {
    const { receiveOfferData, errorNotification } = this.props;

    console.log('SUBMITTING OFFER', values);

    if (values.services && values.services.length > 0) {
      receiveOfferData(values);
      history.push('/dashboard/offers/add/preview');
    } else {
      errorNotification(ApiErrorCode.OfferWithoutService);
    }
  };

  private checkForm = () => {
    const { invalid, errorNotificationText } = this.props;
    if (invalid) {
      errorNotificationText('error.incorrect_offer');
      scrollTop();
    }
  };

  render() {
    const { handleSubmit, services, costs, files, user, client, showModal } = this.props;
    const userMinYear = new Date(user.createdAt).getFullYear() - 1;

    return (
      <div className="offer-form">
        <FormContainer>
          <form onSubmit={handleSubmit(this.submitOffer)} name="add-offer">
            {/* {!client ? ( */}
            <div className="client-name">
              <Field
                name="client.name"
                component={ClientAutosuggestion}
                label="application.client_name"
                allowNewClient
                validate={ValidationService.required}
                onNewClientSelect={this.handleNewClientModal}
                onOptionSelect={this.handleClientModal}
                required
                disabled={!!client}
                placeholder="John Smith"
              />
              {client && (
                <div className="offer-client-buttons">
                  <button onClick={() => this.handleClientSelect(null)}>
                    <Icon icon="cross" className="client-remove-button" />
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      showModal(
                        <InvoiceClient
                          forForm="offerForm"
                          client={client}
                          callback={this.handleClientSelect}
                        />,
                      )
                    }
                    className="client-edit-button"
                  >
                    <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                  </button>
                </div>
              )}
            </div>

            <Field
              name="mark"
              placeholder={weekPlaceholder({
                withMonth: true,
                withYear: true,
              })}
              type="text"
              component={Input}
              label="application.mark"
              max={50}
              validate={ValidationService.max50}
            />
            <Field
              name="createdAt"
              type="date"
              component={DateInput}
              label="application.date"
              validate={ValidationService.required}
              minYear={userMinYear}
              required
            />
            <Field
              name="validTo"
              type="date"
              component={DateInput}
              label="application.offer_valid_to"
              validate={ValidationService.required}
              minYear={userMinYear}
              required
            />

            <FieldArrayCustom name="costs" component={RenderCosts} data={costs} />
            <FieldArrayCustom name="services" component={RenderServices} data={services} />

            <FieldArrayCustom name="files" component={RenderFiles} data={files} />
            <div className="annotation-container">
              <div className="annotation-check">
                <Button
                  secondary
                  leftIcon
                  constant
                  icon={this.state.displayAnnotation ? 'cross' : 'plus'}
                  type="button"
                  text="application.offer_custom_annotation"
                  click={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    this.setState((prevState) => ({
                      displayAnnotation: !prevState.displayAnnotation,
                    }));
                  }}
                />
              </div>
              {this.state.displayAnnotation && (
                <Field
                  name="annotation"
                  type="textarea"
                  component={Input}
                  max={650}
                  validate={ValidationService.max650}
                />
              )}
            </div>

            <ButtonsContainer>
              <Button type="submit" text="application.next" click={this.checkForm} primary />
            </ButtonsContainer>
          </form>
        </FormContainer>
      </div>
    );
  }
}

const today = new Date();
const selector = formValueSelector('offerForm');
const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const file = selector(state, 'file');
  const createdAt = selector(state, 'createdAt');

  return {
    file,
    initialValues: {
      createdAt: createdAt || today,
      validTo: addWeeks(today, 1),
      ...ownProps.initialData,
      ...(ownProps.offer ? ownProps.offer : {}),
      annotation: '',
    },
    issueDate: selector(state, 'createdAt'),
    services: selector(state, 'services') || [],
    costs: selector(state, 'costs') || [],
    files: selector(state, 'files') || [],
    user: state.user.details,
    client: state.offer.client,
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      changeField: change,
      showModal: modal.showModal,
      receiveOfferData: offer.receiveOfferData,
      setOfferClient: offer.setOfferClient,
      errorNotification: notifications.errorNotification,
      errorNotificationText: notifications.errorNotificationText,
      setHeader: header.setHeader,
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, any>({
    form: 'offerForm',
    // important to leave it like that, so the form does not get repopulated
    enableReinitialize: false,
  })(OfferForm),
);
