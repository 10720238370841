import React from 'react';
import { MileageCarForm } from '../../../../components/Common';
import useHeader from '../../../../hooks/useHeader';

const AddMileageCar = () => {
  useHeader('application.add_car', '/dashboard/mileage-cars/list');
  return (
    <>
      <MileageCarForm
        formSendUrl="mileage-car/new"
        formUrl="mileage-car/form/add-item"
        method="POST"
        withNotification
      />
    </>
  );
};

export default AddMileageCar;
