import React from 'react';
import { CostEntity } from 'app-types';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { ButtonsContainer } from '../../components/Layout';
import { Button } from '../../components/Common';

interface Props {
  selectCost: (costs: CostEntity[], fields: any[]) => void;
  newCost: () => void;
}

const SelectCostType = ({ selectCost, newCost }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.choose" />
    </ModalHeader>
    <ButtonsContainer max>
      <Button text="application.existing_cost" click={selectCost} />
      <Button secondary text="application.new_cost" click={newCost} />
    </ButtonsContainer>
  </ModalContent>
);

export default SelectCostType;
