import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrderDetails } from 'app-types';
import GeneratorForm from '../Form';
import { Spinner } from '../../../../components/Common';
import { modal, notifications, orders, header } from '../../../../actions';
import { ApplicationState } from '../../../../reducers';

interface Props {
  id: string;
  loading: boolean;
  data: OrderDetails;
  successNotification: (text: string) => void;
  hideModal: () => void;
  getSingleOrder: (id: string) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
  match: any;
}

class EditOrder extends React.Component<Props> {
  componentDidMount() {
    const { getSingleOrder, match, setHeader } = this.props;
    getSingleOrder(match.params.id);
    setHeader('mileage_generator.edit');
  }

  render() {
    const { loading, data } = this.props;
    if (loading) return <Spinner />;
    return <GeneratorForm data={data} />;
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.orders.loadingSingleOrder,
  data: state.orders.singleOrder,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
      getSingleOrder: orders.getSingleOrder,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditOrder);
