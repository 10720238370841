import { GetClientKvKRes, TimeSheetPeriod } from 'app-types';
import {
  addDays,
  addHours,
  differenceInMinutes,
  isBefore,
  setHours,
  setMinutes,
  startOfDecade,
} from 'date-fns';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Input, TimePickerInput, ListItem } from '..';
import { ButtonsContainer, FieldsGroup } from '../../Layout';
import { __ } from '../../../services/translation';
import { getTimeFromDate } from '../../../utils/parse-date';
import ClientAutosuggestion from '../ClientAutosuggestion';
import Subheader from '../Subheader';
import { formatDurationHoursMinutes } from '../../../utils/format-duration';

import './Periods.scss';

interface Props {
  updatePeriods: (contacts: TimeSheetPeriod[]) => void;
  errors?: {
    clientName?: any;
    name?: any;
    start?: any;
    end?: any;
    [key: string]: any;
    arrayIndex: number;
  };
  initialPeriods?: TimeSheetPeriod[];
}

interface PeriodErrors {
  clientName: string | null;
  name: string | null;
  start: string | null;
  end: string | null;
}

const initialPeriod: TimeSheetPeriod = {
  clientName: '',
  start: getTimeFromDate(new Date()),
  end: getTimeFromDate(addHours(new Date(), 1)),
  name: '',
};

const Periods = ({ updatePeriods, errors, initialPeriods }: Props) => {
  const [periods, setPeriods] = useState<TimeSheetPeriod[]>(initialPeriods || []);
  const [period, setPeriod] = useState<TimeSheetPeriod>(initialPeriod);
  const [periodErrors, setPeriodErrors] = useState<PeriodErrors>({
    clientName: null,
    start: null,
    end: null,
    name: null,
  });

  useEffect(() => {
    updatePeriods(periods);
  }, [periods]);

  const calculateNextPeriodEnd = (start: string) => {
    let startDate = new Date();
    const startParts = start.split(':');
    const startHours = parseInt(startParts[0]);
    const startMinutes = parseInt(startParts[1]);
    startDate = setMinutes(startDate, startMinutes);
    startDate = setHours(startDate, startHours);

    startDate = addHours(startDate, 1);

    return startDate;
  };

  const addPeriod = () => {
    let isError = false;
    const newErrors: PeriodErrors = {
      name: null,
      clientName: null,
      start: null,
      end: null,
    };
    if (!period.start) {
      newErrors.start = 'error.field_required';
      isError = true;
    }
    if (!period.end) {
      newErrors.end = 'error.field_required';
      isError = true;
    }
    if (!period.name) {
      newErrors.name = 'error.field_required';
      isError = true;
    }
    if (period.name.length > 128) {
      newErrors.name = 'error.max128';
      isError = true;
    }
    if (period.clientName.length > 128) {
      newErrors.clientName = 'error.max128';
      isError = true;
    }

    if (isError) {
      setPeriodErrors(newErrors);
    } else {
      setPeriods((items) => [
        ...items,
        {
          ...period,
        },
      ]);
      setPeriod({
        ...period,
        start: period.end,
        end: getTimeFromDate(calculateNextPeriodEnd(period.end)),
      });
      setPeriodErrors({
        name: null,
        start: null,
        end: null,
        clientName: null,
      });
    }
  };

  const removePeriod = (indexToRemove: number) => {
    setPeriods((items) => items.filter((period, index) => index !== indexToRemove));
  };

  const setValue = (field: string, value: string) => {
    setPeriod((item: TimeSheetPeriod) => ({ ...item, [field]: value }));
  };

  const selectClient = (client: GetClientKvKRes) => {
    setPeriod((item: TimeSheetPeriod) => ({ ...item, clientName: client.name }));
  };

  const calculateBreak = (start: string, end: string) => {
    let startDate = new Date();
    let finishDate = new Date();

    const parts = end.split(':');
    const hours = parseInt(parts[0]);
    const minutes = parseInt(parts[1]);

    finishDate = setMinutes(finishDate, minutes);
    finishDate = setHours(finishDate, hours);

    const startParts = start.split(':');
    const startHours = parseInt(startParts[0]);
    const startMinutes = parseInt(startParts[1]);
    startDate = setMinutes(startDate, startMinutes);
    startDate = setHours(startDate, startHours);

    if (isBefore(finishDate, startDate)) addDays(finishDate, 1);

    const difference = differenceInMinutes(finishDate, startDate);

    return formatDurationHoursMinutes(difference);
  };

  return (
    <>
      <Input
        type="text"
        required
        label="application.timesheet_work_name"
        placeholder="application.timesheet_work_name_placeholder"
        input={{
          value: period.name,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setValue('name', e.target.value);
          },
        }}
        meta={{ error: __(periodErrors.name), touched: periodErrors.name }}
      />
      <FieldsGroup>
        <TimePickerInput
          label="application.start_time"
          required
          input={{
            value: period.start,
            onChange: (value: string) => {
              setValue('start', value);
            },
          }}
          meta={{ error: __(periodErrors.start), touched: periodErrors.start }}
        />
        <TimePickerInput
          label="application.finish_time"
          required
          input={{
            value: period.end,
            onChange: (value: string) => {
              setValue('end', value);
            },
          }}
          meta={{ error: __(periodErrors.end), touched: periodErrors.end }}
        />
      </FieldsGroup>
      <ClientAutosuggestion
        label="application.client_optional"
        input={{
          name: 'clientName',
          id: 'clientName',
          value: period.clientName,
          onChange: (value: string) => setValue('clientName', value),
        }}
        kvkOnly
        onOptionSelect={selectClient}
        placeholder="application.client_autosuggestion"
        meta={{
          touched: periodErrors.clientName,
          error: __(periodErrors.clientName),
        }}
      />
      <ButtonsContainer>
        <Button text="application.add" click={addPeriod} type="button" />
      </ButtonsContainer>
      <Subheader text="application.timesheet_periods" />

      {periods.length === 0 ? (
        <Alert type="notice" text="application.timesheet_no_periods" />
      ) : (
        periods.map((item, index) => (
          <Fragment key={index}>
            <ListItem
              key={index}
              paragraphs={[
                {
                  text: item.name,
                  icon: null,
                  strong: true,
                },
                {
                  text: `${item.start} - ${item.end}`,
                  icon: null,
                },
                {
                  text: item.clientName,
                  icon: null,
                },
              ]}
              buttons={[
                {
                  text: 'application.delete',
                  type: 'danger',
                  click: () => removePeriod(index),
                },
              ]}
            />
            {periods.length > 1 && index < periods.length - 1 && (
              <div className="period-break">
                {__('application.timesheet_break')}
                <span className="calculated-break">
                  {calculateBreak(item.end, periods[index + 1].start)}
                </span>
              </div>
            )}
          </Fragment>
        ))
      )}
    </>
  );
};

export default Periods;
