import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { __ } from '../../../services/translation';
import './Alert.scss';

interface AlertProps {
  type: string;
  text: string;
  simple?: boolean;
  notification?: boolean;
  list?: boolean;
  center?: boolean;
}

const Alert = ({ type, simple, notification, text, list, center }: AlertProps) => (
  <div
    className={`alert alert-${type} ${simple ? 'alert-simple' : ''} ${notification ? 'notification' : ''} ${
      center ? 'center' : ''
    } ${list ? 'list' : ''}`}
  >
    {type === 'error' && <FontAwesomeIcon className="alert-icon" icon={faExclamationCircle} />}
    {type === 'notice' && <FontAwesomeIcon className="alert-icon" icon={faInfoCircle} />}
    {type === 'success' && <FontAwesomeIcon className="alert-icon" icon={faCheckCircle} />}
    {__(text)}
  </div>
);

export default Alert;
