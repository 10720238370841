import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { GetClientKvKRes, ClientType, ClientEntity } from 'app-types';
import { ItfApiForm } from 'itf_formbuilder_react';

import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ApiService from '../../services/api-service';
import {
  Spinner,
  Button,
  AddressAutosuggestion,
  RequiredIndicator,
  FieldsMapper,
  ClientAutosuggestion,
  Input,
} from '../../components/Common';
import { notifications, modal, apiList, client } from '../../actions';
import { formOnTranslateString } from '../../utils/trans-form';
import { __ } from '../../services/translation';
import { ButtonsContainer } from '../../components/Layout';
import { AddressAutosuggestionResponse } from '../../types/autosuggestion';

interface Props {
  forForm: 'invoiceForm' | 'offerForm';
  client: ClientEntity | GetClientKvKRes;
  callback: (client: ClientEntity) => void;
}

const InvoiceClient = ({ client, callback, forForm }: Props) => {
  const dispatch = useDispatch();
  const [type, setType] = useState<ClientType>(ClientType.COMPANY);
  const [saveRelation, setSaveRelation] = useState<boolean>(false);
  let itfForm: any;

  const setInitialData = () => {
    const { id, ident, owner, ...rest } = client as any; // form does not have id field
    if (itfForm.api) {
      const initial: any = {};
      Object.keys(rest).forEach((key) => {
        const el = (rest as any)[key];
        initial[key] = el || el === 0 || el === '0' ? el : '';
      });
      itfForm.api.setValues(initial);
    }
  };

  const setRef = (form: any) => {
    itfForm = form;
  };

  const selectOption = (option: GetClientKvKRes) => {
    itfForm.api.setValues({
      name: option.name,
      kvk: option.kvk,
      city: option.city,
      country: option.country,
      address: option.address,
    });
  };

  const selectSugestion = (sugestion: AddressAutosuggestionResponse) => {
    itfForm.api.setValue('address', sugestion.first);
    if (sugestion.second) itfForm.api.setValue('city', sugestion.second);
    if (sugestion.third) itfForm.api.setValue('country', sugestion.third);

    const changes: { [key: string]: string } = { address: sugestion.first };

    if (sugestion.second) changes.city = sugestion.second;
    if (sugestion.third) changes.country = sugestion.third;

    itfForm.api.setValues(changes);
  };

  const registerFormCallbacks = () => {
    setInitialData();
    window.globalForms['edit-invoice-client'].valueChangedClb = (newValues) => {
      setType(Number(newValues.type));
      setSaveRelation(Boolean(newValues.saveRelation));
    };
  };
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.edit_client" />
      </ModalHeader>
      <ItfApiForm
        ref={setRef}
        formId="edit-invoice-client"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        requiredIndicator={<RequiredIndicator />}
        schemaFetcher={() => ApiService.loadForm('client/form/add-to-invoice/')}
        sendFetcher={(formId: string, values: any) => {
          return (client as ClientEntity).id
            ? ApiService.sendForm(
                `client/save-from-invoice/${(client as ClientEntity).id}`,
                values,
                'PATCH',
              )
            : ApiService.sendForm('client/save-from-invoice', values, 'POST');
        }}
        onSavedSuccessfully={(newClient: ClientEntity) => {
          if (saveRelation) {
            if ((client as ClientEntity).id) {
              dispatch(notifications.successNotification('application.client_was_edited.'));
            } else dispatch(notifications.successNotification('application.client_added.'));
          }

          dispatch(modal.hideModal());
          if (typeof callback === 'function') callback(newClient);
        }}
        loadingInfo={<Spinner transparent />}
        submitButton={
          <ButtonsContainer>
            <Button type="submit" className="form-submit-button" text="application.ok" primary />
          </ButtonsContainer>
        }
        onTranslateString={(string: string) => formOnTranslateString(string)}
        onRenderFullField={(
          field: any,
          errors: any,
          fieldHtmlId: any,
          currentValue: any,
          onChange: any,
          onFocus: any,
          onBlur: any,
        ) => {
          if (field.id === 'name') {
            return (
              <ClientAutosuggestion
                forForm={forForm}
                required={field.required}
                label={field.title}
                input={{
                  value: currentValue,
                  name: fieldHtmlId,
                  id: fieldHtmlId,
                  onChange,
                }}
                kvkOnly
                onOptionSelect={selectOption}
                placeholder={field.placeholder}
                meta={{
                  touched: errors.length > 0,
                  error: errors.length
                    ? __(errors[0].messageWithPlaceholders, errors[0].params)
                    : null,
                }}
              />
            );
          } else if (field.id === 'address') {
            return (
              <AddressAutosuggestion
                required={type === ClientType.COMPANY}
                label={field.title}
                input={{
                  value: currentValue,
                  name: fieldHtmlId,
                  id: fieldHtmlId,
                  onChange,
                }}
                onSugestionSelect={selectSugestion}
                splitted
                placeholder={field.placeholder}
                meta={{
                  touched: errors.length > 0,
                  error: errors.length
                    ? __(errors[0].messageWithPlaceholders, errors[0].params)
                    : null,
                }}
              />
            );
          } else if (field.id === 'firstName') {
            return FieldsMapper(
              { ...field, required: type === ClientType.PRIVATE },
              errors,
              fieldHtmlId,
              currentValue,
              onChange,
              onFocus,
              onBlur,
            );
          } else if (field.id === 'email') {
            return FieldsMapper(
              { ...field, required: type === ClientType.COMPANY },
              errors,
              fieldHtmlId,
              currentValue,
              onChange,
              onFocus,
              onBlur,
            );
          } else if (field.id === 'city') {
            return FieldsMapper(
              { ...field, required: type === ClientType.COMPANY },
              errors,
              fieldHtmlId,
              currentValue,
              onChange,
              onFocus,
              onBlur,
            );
          } else if (field.id === 'kvk') {
            return (
              <Input
                type={field.options.type || field.type}
                required={type === ClientType.COMPANY}
                label={field.title}
                input={{
                  name: fieldHtmlId,
                  id: fieldHtmlId,
                  placeholder: field.placeholder,
                  value: currentValue,
                  onChange: (e: any) => onChange(e.target.value),
                  onFocus,
                  onBlur,
                }}
                step={field.options.step}
                placeholder={field.placeholder}
                meta={{
                  touched: errors.length > 0,
                  error: errors.length
                    ? __(errors[0].messageWithPlaceholders, errors[0].params)
                    : null,
                }}
              />
            );
          } else if (field.id !== 'type') {
            return FieldsMapper(
              field,
              errors,
              fieldHtmlId,
              currentValue,
              onChange,
              onFocus,
              onBlur,
            );
          }
        }}
        registerAsGlobalForm
        onFormGloballyRegistered={registerFormCallbacks}
      />
    </ModalContent>
  );
};

export default InvoiceClient;
