import { __ } from '../services/translation';

export const formatThousands = (n: number, isHtml = false, toFixed = 2): string => {
  if (typeof n === 'undefined') return '-';
  return Number(n)
    .toFixed(toFixed)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${isHtml ? '&nbsp;' : ' '}`)
    .replace('.', ',');
};

export const formatDistance = (n: number, isHtml = false): string =>
  `${formatThousands(n, isHtml)} ${__('application.kilometers')}`;
