import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TableSchema, TableSchemaField } from 'app-tables';
import { Spinner, Table } from '../../../components/Common';
import SearchBar from '../../../components/SearchBar';
import debounce from '../../../utils/debounce';
import { apiTable } from '../../../actions';
import { ApplicationState } from '../../../reducers';

interface Props {
  fetchApiTableData: (url: string, method?: string) => void;
  clearApiTable: () => void;
  loading: boolean;
  data: any;
  scheme: TableSchema;
  apiEndpointSubUrl: string;
  apiEndpointMethod?: string; // Defaults to GET
  fields?: any;
}

interface State {
  searchQuery: string;
  searchBy: string;
  page: number;
  sortBy: string;
  sortDirBack: boolean;
}

class ApiTable<T> extends Component<Props, State> {
  private debouncedFetch: () => void;

  constructor(props: Props) {
    super(props);
    this.debouncedFetch = debounce(this.fetch, 400);

    const { scheme } = props;

    const defaultSearchField = scheme.fields.find(
      (field) => ['string', 'number'].indexOf(typeof field.defaultSearch) > -1,
    );

    this.state = {
      searchQuery: defaultSearchField ? String(defaultSearchField.defaultSearch) : '',
      searchBy: defaultSearchField ? defaultSearchField.field : '',
      page: 1,
      sortBy: '',
      sortDirBack: false,
    };
  }

  componentDidMount() {
    const { apiEndpointSubUrl, data, scheme } = this.props;
    try {
      const storageSearchBy = localStorage.getItem(`${apiEndpointSubUrl}_searchBy`);
      const storageSearchQuery = localStorage.getItem(`${apiEndpointSubUrl}_searchQuery`);
      const storageSortBy = localStorage.getItem(`${apiEndpointSubUrl}_sortBy`);
      const storageDirBack = JSON.parse(
        localStorage.getItem(`${apiEndpointSubUrl}_sortDirBack`) || 'false',
      );

      if (storageSearchBy) {
        this.setState({ searchBy: storageSearchBy });
      }
      if (storageSearchQuery) {
        this.setState({ searchQuery: storageSearchQuery });
      }
      if (storageSortBy) {
        this.setState({ sortBy: storageSortBy });
      }
      if (storageDirBack) {
        this.setState({ sortDirBack: storageDirBack });
      }
    } catch (e) {
      console.error(e);
    }
    this.fetch();
  }

  componentDidUpdate(prevProps: Props) {
    const { data } = this.props;
    if (data.uuid !== prevProps.data.uuid) {
      this.fetch();
    }
  }

  componentWillUnmount() {
    const { clearApiTable } = this.props;
    clearApiTable();
  }

  private getCurrentSort(): {
    sortBy: string;
    sortDirBack: boolean;
  } {
    const { sortBy, sortDirBack } = this.state;
    const { scheme } = this.props;

    if (sortBy !== '') {
      return {
        sortBy,
        sortDirBack,
      };
    } else {
      const field = scheme.fields.find((f) => f.defaultSort === true) as TableSchemaField;
      return {
        sortBy: field.field,
        sortDirBack: field.oppositeSortDir !== true,
      };
    }
  }

  handlePageChange = (newPage: number) => {
    const { page } = this.state;
    if (page === newPage) return;

    this.setState(
      {
        page: newPage,
      },
      this.fetch,
    );
  };

  handleSortChange = (sortBy: string, sortDirBack: boolean) => {
    const { apiEndpointSubUrl } = this.props;
    const currSort = this.getCurrentSort();
    if (currSort.sortBy === sortBy && currSort.sortDirBack === sortDirBack) return;

    try {
      localStorage.setItem(`${apiEndpointSubUrl}_sortBy`, sortBy);
      localStorage.setItem(`${apiEndpointSubUrl}_sortDirBack`, JSON.stringify(sortDirBack));
    } catch (e) {
      console.error(e);
    }

    this.setState(
      {
        sortBy,
        sortDirBack,
      },
      this.fetch,
    );
  };

  handleSearchChange = (searchBy: string, searchQuery: string) => {
    const { apiEndpointSubUrl } = this.props;
    const { searchBy: searchByNow, searchQuery: searchQueryNow } = this.state;
    if (searchBy === searchByNow && searchQuery === searchQueryNow) return;

    const newSearchQuery = searchBy === searchByNow ? searchQuery : '';

    try {
      localStorage.setItem(`${apiEndpointSubUrl}_searchBy`, searchBy);
      localStorage.setItem(`${apiEndpointSubUrl}_searchQuery`, newSearchQuery);
    } catch (e) {
      console.error(e);
    }

    this.setState(
      {
        searchBy,
        searchQuery: newSearchQuery,
      },
      this.debouncedFetch,
    );
  };

  private buildUrl(): string {
    const { apiEndpointSubUrl } = this.props;
    const { searchBy, searchQuery, page, sortBy, sortDirBack } = this.state;
    return `${apiEndpointSubUrl}?${new URLSearchParams({
      searchBy,
      searchQuery,
      page,
      sortBy,
      sortDirBack,
    } as any)}`;
  }

  private async fetch() {
    const { fetchApiTableData, apiEndpointMethod } = this.props;
    await fetchApiTableData(this.buildUrl(), apiEndpointMethod);
  }

  render() {
    const { page, searchQuery, searchBy, sortBy, sortDirBack } = this.state;
    const { loading, data, scheme } = this.props;

    let table;

    if (loading) {
      table = <Spinner overlay transparent />;
    } else {
      table = (
        <Table
          data={data}
          scheme={scheme}
          initialPage={page}
          sort={this.getCurrentSort()}
          onPageChange={this.handlePageChange}
          onSortChange={this.handleSortChange}
        />
      );
    }

    return (
      <>
        {data && (data.countTotal > 0 || searchQuery) && (
          <SearchBar
            allFields={scheme.fields}
            search={{
              searchBy,
              searchQuery,
            }}
            sort={null}
            onSearchChange={this.handleSearchChange}
          />
        )}
        {/* // SearchBar(
          //   scheme.fields,
          //   {
          //     searchBy,
          //     searchQuery,
          //   },
          //   null,
          //   this.handleSearchChange,
          // )} */}
        <div className="api-table">{table}</div>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  data: state.apiTable,
  loading: state.apiTable.loading,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { fetchApiTableData: apiTable.fetchApiTableData, clearApiTable: apiTable.clearApiTable },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ApiTable);
