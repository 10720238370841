import { AccountantConnectorDetails, AddConnectorReq } from 'app-types';
import { ItfApiForm } from 'itf_formbuilder_react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Dashboard from '../../DashboardCommon';
import { modal, notifications } from '../../../actions';
import {
  RequiredIndicator,
  Spinner,
  Button,
  FieldsMapper,
  Subheader,
} from '../../../components/Common';
import { FormContainer, ButtonsContainer, Page } from '../../../components/Layout';
import useHeader from '../../../hooks/useHeader';
import ApiService from '../../../services/api-service';
import { formOnTranslateString } from '../../../utils/trans-form';
import List from './List';
import Confirmation from '../../../modals/Confirmation';
import { SettlementSection } from './SettlementSection';

const Accountants = () => {
  const dispatch = useDispatch();
  useHeader('application.accountants', '/', {
    title: 'application.accountants',
    text: 'annotations.accountants_description',
  });

  const [accountants, setAccountants] = useState<AccountantConnectorDetails[]>();

  const getAccountants = async () => {
    await ApiService.callFetch(
      'GET',
      'accountant-connector/my-accountants',
      (users: AccountantConnectorDetails[]) => {
        setAccountants(users);
      },
    );
  };

  const onConfirmSendInvitation = async (email: string) => {
    await ApiService.callFetch(
      'POST',
      'accountant-connector/send-invitation-to-create-account',
      () => {
        dispatch(
          notifications.successNotification('application.invitation_to_create_account_sent'),
        );
        dispatch(modal.hideModal());
      },
      null,
      { email },
    );
  };

  const sendInvitation = (email: string) => {
    dispatch(
      modal.showModal(
        <Confirmation
          confirmCallback={() => {
            onConfirmSendInvitation(email);
          }}
          text="application.confirm_invite_accountant"
          textArgs={{ email }}
        />,
      ),
    );
  };

  return (
    <Page withNavigation light>
      <Dashboard>
        <Subheader text="application.add_accountant" />
        <FormContainer>
          <ItfApiForm
            formId="copy-mileage"
            schemaMayBeDynamic={false}
            schemaCacheEnabled
            requiredIndicator={<RequiredIndicator />}
            loadingInfoAboveContent
            schemaFetcher={() => ApiService.loadForm('accountant-connector/form/add-connector')}
            sendFetcher={(formId: string, values: AddConnectorReq) =>
              ApiService.sendForm('accountant-connector/add-connector', values, 'POST')
            }
            onSavedSuccessfully={(payload: any) => {
              if (payload.accountantIsExist) {
                dispatch(notifications.successNotification('application.accountant_added'));
                getAccountants();
              } else {
                sendInvitation(payload.email);
              }
            }}
            loadingInfo={<Spinner overlay halfTransparent />}
            submitButton={
              <ButtonsContainer>
                <Button
                  type="submit"
                  className="form-submit-button"
                  text="application.submit"
                  primary
                />
              </ButtonsContainer>
            }
            onTranslateString={formOnTranslateString}
            onRenderFullField={FieldsMapper}
          />
        </FormContainer>
        <List accountants={accountants} getAccountants={getAccountants} />
        <SettlementSection />
      </Dashboard>
    </Page>
  );
};

export default Accountants;
