import React from 'react';
import { GetCompanyKvKRes, MileageCarRes, SearchedCompanyKvKRes } from 'app-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from '../../../components/Common';
import debounce from '../../../utils/debounce';
import { __ } from '../../../services/translation';
import Suggestion from './Suggestion';
import './CompanyAutosuggestion.scss';
import ApiService from '../../../services/api-service';
import RequiredIndicator from '../RequiredIndicator';
import NewCarSuggestion from './NewClientSuggestion';

interface Props {
  input: {
    onChange: (data: any) => void;
    name: string;
    id: string;
    value: string;
  };
  allowNewCar?: boolean;
  onNewCarSelect?: (val: string) => void;
  placeholder: string;
  onOptionSelect: (data: MileageCarRes) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  meta: { error: any; touched: any };
}

interface State {
  active: boolean;
  results: MileageCarRes[];
  loading: boolean;
}

class CarAutosuggestion extends React.Component<Props, State> {
  debouncedSearch: any;

  constructor(props: Props) {
    super(props);
    this.debouncedSearch = debounce(this.fetchList, 200);

    this.state = {
      active: false,
      results: [],
      loading: false,
    };
  }

  private fetchList = async (regNumber: string) => {
    const list: MileageCarRes[] = await ApiService.callFetch(
      'GET',
      `mileage-car/search-by-reg/${regNumber}`,
    );
    this.setState({
      results: list,
      loading: false,
    });
  };

  private handleChange = (val: string) => {
    const { input } = this.props;

    input.onChange(val);
    this.setState({ loading: true, active: true });
    this.debouncedSearch(val);
  };

  render() {
    const {
      input,
      label,
      onOptionSelect,
      placeholder,
      required,
      meta,
      disabled,
      allowNewCar,
      onNewCarSelect,
    } = this.props;
    const { results, loading, active } = this.state;
    const inputLabel = label && (
      <label htmlFor={input.name}>
        {__(label)} {required && <RequiredIndicator />}{' '}
      </label>
    );
    return (
      <div className="input-container">
        {inputLabel}

        <div className="input-sugestion-container">
          <div className="input-group">
            <input
              readOnly={disabled}
              autoComplete="off"
              className="company-autosuggestion-input"
              type="text"
              id="company-autosuggestion-input"
              value={input.value}
              onChange={(e: any) => this.handleChange(e.target.value)}
              onFocus={(e: any) => {
                if (!disabled) {
                  this.handleChange(e.target.value);
                  this.setState({ active: true });
                }
              }}
              onBlur={(e: any) => window.setTimeout(() => this.setState({ active: false }), 100)}
              placeholder={__(placeholder)}
            />

            {!disabled && (
              <FontAwesomeIcon icon={faSearch} className="company-autosuggestion-icon" />
            )}
          </div>

          {active && (results.length || loading || (allowNewCar && input.value)) && (
            <ul className="company-suggestions">
              {loading && <Spinner overlay />}
              {results.length > 0 && (
                <>
                  <li className="company-suggestions-separator">{__('application.sugestions')}</li>
                  {results.map((r: MileageCarRes) => (
                    <Suggestion item={r} onSelect={onOptionSelect} key={r.regNumber} />
                  ))}
                </>
              )}
              {allowNewCar && typeof onNewCarSelect === 'function' && input.value && (
                <>
                  <li className="client-suggestions-separator">{__('application.other_car')}</li>
                  <NewCarSuggestion name={input.value} onNewCarSelect={onNewCarSelect} />
                </>
              )}
            </ul>
          )}
          {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
        </div>
      </div>
    );
  }
}

export default CarAutosuggestion;
