import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ApiService from '../../services/api-service';
import { Spinner, Button, FieldsMapper, RequiredIndicator } from '../../components/Common';
import { notifications, modal } from '../../actions';
import { formOnTranslateString } from '../../utils/trans-form';
import { FormContainer, ButtonsContainer } from '../../components/Layout';

interface Props {
  id: string;
  successNotification: (text: string) => void;
  hideModal: () => void;
}

const AddAttachmentToSentInvoiceHistory = ({ id, hideModal, successNotification }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.add_attachment" />
    </ModalHeader>
    <FormContainer>
      <ItfApiForm
        formId="add-file-to-invoice"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        requiredIndicator={<RequiredIndicator />}
        schemaFetcher={() => ApiService.loadForm('sent-invoice-history/form/add-attachment')}
        sendFetcher={(formId: string, values: any) => {
          const fd = new FormData();
          for (const key in values) {
            if (key === 'cost' && values.cost) {
              for (const file of values.cost) {
                fd.append('cost', file);
              }
            } else fd.append(key, values[key]);
          }
          return ApiService.sendForm(`sent-invoice-history/attachment/${id}`, fd, 'POST');
        }}
        onSavedSuccessfully={() => {
          successNotification('application.attachement_added');
          hideModal();
        }}
        onRenderFullField={FieldsMapper}
        loadingInfo={<Spinner transparent />}
        submitButton={
          <ButtonsContainer>
            <Button type="submit" className="form-submit-button" text="application.add" success />
          </ButtonsContainer>
        }
        onTranslateString={formOnTranslateString}
      />
    </FormContainer>
  </ModalContent>
);

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { successNotification: notifications.successNotification, hideModal: modal.hideModal },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(AddAttachmentToSentInvoiceHistory);
