import React from 'react';
import { TableSchema, paymentsForUser, FieldType } from 'app-tables';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router';
import { InteractiveTableSchema } from '../../../utils/table-shcema-utils';
import { AdminPaymentButtons, ApiTable, Button } from '../../../components/Common';
import useHeader from '../../../hooks/useHeader';
import ApiService from '../../../services/api-service';
import { __ } from '../../../services/translation';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';

const UserPayments = () => {
  useHeader('application.payment_list');

  const downloadPDF = (id: string) => {
    saveAs(`${ApiService.url}payment/invoice-pdf/${id}`);
  };

  const scheme: TableSchema = new InteractiveTableSchema(paymentsForUser).addFieldAtTheEnd({
    name: 'application.actions',
    field: 'editButton',
    mobile: true,
    type: FieldType.Custom,
    customRender: (value, field, values) => <AdminPaymentButtons payment={values} />,
  });
  const { id } = useParams<{ id: string }>();

  return (
    <Page light>
      <Dashboard>
        <ApiTable apiEndpointSubUrl={`payment/list/${id}`} scheme={scheme} />
      </Dashboard>
    </Page>
  );
};

export default UserPayments;
