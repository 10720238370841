import React from 'react';
import { Logo } from '../../components/Common';
import { Container, Page } from '../../components/Layout';
import './Privacy.scss';

const Privacy = () => {
  return (
    <Page stretch center padded>
      <Logo center big dark />
      <Container>
        <div className="static-privacy">
          <h1>Privacy Statement HeyZZP</h1>
          <p>
            Dit is het privacy statement van Vennootschap onder firma HeyZZP, hierna: HeyZZP. HeyZZP
            is ingeschreven bij de Kamer van Koophandel onder nummer 76646173 en is gevestigd aan
            Rokkeveenseweg 42A (2712XZ) te Zoetermeer. In dit document wordt uitgelegd hoe HeyZZP
            omgaat met het verwerken van uw persoonsgegevens. Bij de verwerking van uw
            persoonsgegevens betracht HeyZZP de grootst mogelijke zorgvuldigheid.
          </p>
          <h2>Verkrijging persoonsgegevens:</h2>
          <p>
            Indien u gebruik maakt van de diensten van HeyZZP, verstrekt u zelf een aantal
            persoonsgegevens aan HeyZZP, of worden persoonsgegevens van u verkregen in het kader van
            de overeenkomst. Onder persoonsgegevens wordt verstaan alle gegevens die betrekking
            hebben op geïdentificeerde of identificeerbare natuurlijke persoon.
          </p>
          <h2>Categorieën persoonsgegevens</h2>
          <p>HeyZZP verwerkt de volgende categorieën van persoonsgegevens:</p>
          <ul>
            <li> Contact- en NAW-gegevens, waaronder telefoonnummer en e-mailadres;</li>
            <li>Burgerservicenummer;</li>
            <li>Bedrijfsgegevens, waaronder btw-nummer en KvK-nummer;</li>
            <li>IP-adres en locatiegegevens;</li>
            <li>Financiële gegevens;</li>
            <li>Accountgegevens, waaronder tevens uw wachtwoord en gebruikersnaam;</li>
            <li>Overige door u zelf (via de applicatie) verstrekte persoonsgegevens.</li>
          </ul>
          <h2>Doel van de verwerking</h2>
          <p>De persoonsgegevens die door HeyZZP verwerkt worden, hebben ten doel om:</p>
          <ul>
            <li>
              contact met u op te nemen om u te informeren over de door u afgenomen diensten en de
              uitvoering daarvan;
            </li>
            <li>haar diensten te kunnen uitvoeren; haar dienstverlening te verbeteren;</li>
            <li>betalingen te kunnen verrichten;</li>
            <li>wettelijke verplichtingen na te kunnen komen;</li>
            <li>
              marketing en communicatie uitingen te kunnen doen, waaronder het verbeteren van de
              website en applicatie door middel van het analyseren van bezoekersgedrag op de website
              en applicatie;
            </li>
            <li>nieuwsbrieven te kunnen versturen;</li>
            <li>
              met derden gegevens uit te wisselen ten behoeve van de uitvoering van de
              dienstverlening, voor zover dit noodzakelijk is.
            </li>
          </ul>
          <h2>Grondslag</h2>
          <p>
            verwerking Het verwerken van persoonsgegevens is slechts mogelijk op grond van de
            hiernavolgende grondslagen: (i) wettelijke verplichting, (ii) uitvoering overeenkomst,
            (iii) verkregen (uitdrukkelijke) toestemming van de betrokkenen, en (iv) gerechtvaardigd
            belang. Bij het verlenen van de diensten van HeyZZP worden persoonsgegevens verwerkt.
            HeyZZP verwerkt uitsluitend gegevens die HeyZZP noodzakelijk acht voor (het verbeteren
            van) de dienstverlening en gaat zorgvuldig om met de (persoons)gegevens die zij over u
            en uw gebruik van de diensten heeft verzameld. De grondslag voor het verwerken van deze
            gegevens is de overeenkomst die u met HeyZZP bent aangegaan. Ook kunnen er
            persoonsgegevens van u verwerkt worden door het bezoeken van de website [websiteURL]
            alsmede het gebruik van de applicatie indien u hiervoor toestemming hebt gegeven.
            Noodzaak verwerking De verwerking van uw persoonsgegevens is noodzakelijk om de
            dienstverlening uit te kunnen voeren. De diensten die HeyZZP biedt, kunnen zonder het
            verwerken van uw persoonsgegevens niet volledig uitgevoerd worden. Indien voor
            specifieke doeleinden met betrekking tot de persoonsgegevens uw expliciete toestemming
            is vereist, dient u hiervoor separaat toestemming te geven.
          </p>
          <h2>Geautomatiseerde besluitvorming</h2>
          <p>Er is geen sprake van geautomatiseerde besluitvorming.</p>
          <h2>Bewaartermijn</h2>
          <p>
            De door HeyZZP verwerkte persoonsgegevens worden bewaard conform de relevante wet- en
            regelgeving. Indien een langere bewaartermijn noodzakelijk is op grond van wet- of
            regelgeving, worden de persoonsgegevens langer bewaard conform deze eisen. Alle
            (verkregen) persoonsgegevens worden niet langer bewaard dan strikt noodzakelijk.
          </p>
          <h2>Verwerking door derden</h2>
          <p>
            HeyZZP deelt persoonsgegevens slechts met derden indien dit strikt noodzakelijk is voor
            de uitvoering van een overeenkomst en om te voldoen aan relevante wet- en regelgeving.
            Er worden geen persoonsgegevens verkocht. Op HeyZZP kan een wettelijke verplichting
            rusten om persoonsgegevens met derden te delen. Indien met derden persoonsgegevens
            worden gedeeld, worden hiervoor verwerkersovereenkomsten gesloten. De derden waarmee
            persoonsgegevens gedeeld worden zijn:
          </p>
          <ul>
            <li>
              De Payment Provider, ten behoeve van de uitvoering van een overeenkomst. De
              categorieën van persoonsgegevens die verwerkt worden zijn financiële gegevens.
            </li>
            <li>
              Debiteurenbeheerder, ten behoeve van de financiële administratie. De categorieën van
              persoonsgegevens die verwerkt worden, zijn de contact- en NAW-gegevens, financiële
              gegevens en BSN-nummers.
            </li>
            <li>
              De Boekhouder, ten behoeve van de uitvoering van een overeenkomst. De categorieën van
              persoonsgegevens die verwerkt worden zijn contact- en NAW-gegevens en financiële
              gegevens.
            </li>
            <li>
              Softwareleveranciers en -ontwikkelaars, ten behoeve van de uitvoering van de
              overeenkomst. De categorieën van persoonsgegevens die verwerkt worden zijn de contact-
              en NAW-gegevens en de accountgegevens.
            </li>
            <li>
              Hostingpartij, ten behoeve van de uitvoering van de overeenkomst. De categorieën van
              persoonsgegevens die verwerkt worden zijn contact- en NAW-gegevens, het IP-adres, het
              e-mailadres.
            </li>
          </ul>
          <h2>Beveiliging van Persoonsgegevens</h2>
          <p>
            HeyZZP neemt het beschermen van uw persoonsgegevens serieus en neemt dienaangaande,
            rekening houdend met de stand van de techniek, uitvoeringskosten, alsook met de aard,
            omvang, context en de verwerkingsdoeleinden en de qua waarschijnlijkheid en ernst
            uiteenlopende risico’s voor de rechten en vrijheden van personen, passende technische en
            organisatorische maatregelen om een op het risico afgestemd beveiligingsniveau te
            waarborgen. Verzamelen van persoonsgegevens op de achtergrond Door gebruikmaking van de
            applicatie van HeyZZP verwerkt HeyZZP uw persoonsgegevens. Deze persoonsgegevens kunnen
            ook op de achtergrond worden verwerkt. Hiermee wordt bedoelt dat HeyZZP ook
            persoonsgegevens verwerkt wanneer de applicatie niet meer in gebruik is, maar deze nog
            op de achtergrond draait. HeyZZP verzamelt dus uw gegevens zolang de applicatie in
            gebruik is. U geeft hierbij toestemming voor het verwerken van uw persoonsgegevens (meer
            specifiek: uw locatiegegevens) op de achtergrond.
          </p>
          <p>
            Ter verduidelijking toont HeyZZP hier een lijst met functies waarvoor de applicatie uw
            (locatie)gegevens op de achtergrond verzamelt.
          </p>
          <ul>
            <li>
              Kilometerregistratie doormiddel van GPS-tracking. Je zet GPS aan en zet hem uit op de
              bestemming. De applicatie zelf krijgt het startpunt, eindpunt, tijd doorgebracht op de
              weg en afstand. Let op: deze werkt alleen juist bij gebruik van de mobiele app en niet
              in de webbrowser;
            </li>
          </ul>
          <h2>Disclaimer</h2>
          <p>
            Door het gebruiken van de website en applicatie stemt u in met de disclaimer. HeyZZP
            behoudt zich het recht voor te allen tijde de inhoud van haar website en applicatie
            en/of deze disclaimer te wijzigen zonder haar klanten en/of gebruikers van de website en
            applicatie over deze wijziging te informeren. De inhoud van de website en applicatie is
            met de grootst mogelijke zorgvuldigheid samengesteld, maar kan desondanks mogelijke
            onjuistheden bevatten of onvolledig zijn. HeyZZP aanvaardt op geen enkele wijze enig
            aansprakelijkheid voor schade die is ontstaan door, of voortvloeit uit het gebruik van
            de website en applicatie. Het gebruiken van deze website en applicatie komt geheel voor
            eigen rekening en risico van een gebruiker van de websiteen applicatie. Aan de inhoud
            van de website en applicatie kunnen geen rechten ontleend worden. Alle teksten op de
            website en applicatie zijn auteursrechtelijk beschermd en eigendom van HeyZZP voor zover
            deze niet aan derden toebehoren.
          </p>
          <h2>Cookieverklaring</h2>
          <p>
            De cookies die HeyZZP gebruikt zijn functionele cookies: deze cookies zorgen ervoor dat
            de website naar behoren werkt. Deze cookies hebben geen gevolgen voor de persoonlijke
            levenssfeer zodat er geen toestemming gevraagd en verleend hoeft te worden. Met deze
            cookies wordt bijvoorbeeld uw browserinstellingen opgeslagen zodat onze website het
            beste bekeken kan worden, of dat de website bereikbaar blijft (load-balancing) maar ook
            cookies die ervoor zorgen dat er geen andere cookies mogen worden geplaatst (no-follow).
          </p>
          <p>
            Op de website van HeyZZP worden tevens cookies van Google geplaatst ten behoeve van
            Google Analytics. Dit houdt in dat HeyZZP informatie niet kan koppelen aan een
            natuurlijk persoon. HeyZZP houdt geen informatie bij van hetgeen u op internet doet.
            Google Analytics is mogelijk verplicht op grond van geldende wet- en regelgeving inzage
            te geven in deze gegevens. Indien u hierover vragen heeft kunt u contact opnemen via
            info@wisniowsky.nl.
          </p>
          <h2>Gebruik social media</h2>
          <p>
            HeyZZP maakt gebruik van cookies van derde partijen om de website en applicatie te
            optimaliseren. Sommige cookies worden geplaatst door diensten van derden die op de
            website en applicatie worden weergegeven. Met derden wordt bedoeld Google Analytics en
            social media (LinkedIn, Facebook, Twitter). Op het gebruik van cookies van andere
            bedrijven (derden) is het privacy- en cookiebeleid van het betreffende bedrijf van
            toepassing. Wanneer u op de website en applicatie de social media button aanklikt, wordt
            een social media cookie geplaatst. Hierdoor kan de social media partij uw IP-adres
            herkennen zodra u een artikel van de website en applicatie deelt. Voor de cookies van
            social media partijen en de data en/of persoonsgegevens die zij hiermee verzamelen,
            verwijst HeyZZP u naar de privacy- en cookieverklaringen van deze partijen.
          </p>
          <h2>Browserinstellingen</h2>
          <p>
            Als u niet wilt dat websites cookies plaatsen op uw apparaat waarmee u de website
            bekijkt, kunt u uw browserinstellingen aanpassen. Voordat een cookie geplaatst wordt,
            krijgt u een waarschuwing en dient u toestemming te geven voor de cookie. Doet u dat
            niet, kan dat bijvoorbeeld tot gevolg hebben dat de website minder goed werkt. U kunt de
            instellingen van uw browser aanpassen zodat uw browser alle cookies en ook de cookies
            van derde(n) weigert. Ook kunt u geplaatste cookies verwijderen. Hiervoor moet u de
            instellingen van uw browser aanpassen via voorkeuren en vervolgens kunt u de privacy
            instellingen aanpassen. Deze privacyverklaring is niet van toepassing op websites en
            applicatie van derden die door middel van links met deze website en applicatie zijn
            verbonden. HeyZZP kan niet garanderen dat deze derden op een betrouwbare of veilige
            manier met uw persoonsgegevens omgaan. HeyZZP raadt u aan de privacyverklaring van deze
            websites en applicatie te lezen alvorens van deze websites en applicatie gebruik te
            maken.
          </p>
          <h2>Recht van betrokkenen</h2>
          <p>Als betrokkene heeft u de volgende rechten:</p>
          <ul>
            <li>
              Recht op inzage; Als betrokkene heeft u het recht om uitsluitsel te krijgen over het
              al dan niet verwerken van uw persoonsgegevens en, wanneer dat het geval is, om inzage
              te verkrijgen met betrekking tot de verwerking daarvan.
            </li>
            <li>
              Recht op rectificatie; U heeft het recht om rectificatie en aanvulling van onjuiste
              gegevens te verkrijgen.
            </li>
            <li>
              Recht op gegevenswissing; Zonder onredelijke vertraging dient een betrokkene wissing
              van hem betreffende persoonsgegevens te verkrijgen. Recht op beperking van de
              verwerking; Als betrokkene heeft u het recht op beperking van de verwerking van uw
              persoonsgegevens. Neem hiervoor contact op met HeyZZP.
            </li>
            <li>
              Recht op overdraagbaarheid van gegevens; Een betrokkene heeft het recht om de hem
              betreffende persoonsgegevens die hij verstrekt heeft aan HeyZZP, in een
              gestructureerde, gangbare en machineleesbare vorm te verkrijgen om deze
              persoonsgegevens eventueel over te dragen aan een derde. Een betrokkene kan HeyZZP ook
              de opdracht geven om deze gegevens rechtstreeks aan een derde over te dragen.
            </li>
            <li>
              Recht van bezwaar; De betrokkene heeft te allen tijde het recht om vanwege met zijn
              specifieke situatie verband houdende redenen bezwaar te maken tegen de verwerking van
              hem betreffende persoonsgegevens met inbegrip van profilering op basis van die
              bepalingen. HeyZZP staakt de verwerking van de persoonsgegevens tenzij er sprake is
              van dwingende gerechtvaardigde gronden voor de verwerking die zwaarder wegen dan de
              belangen, vrijheden en rechten van betrokkene die verband houden met de uitoefening
              van, instelling of onderbouwing van een rechtsvordering.
            </li>
          </ul>
          <h2>Klacht</h2>
          <p>
            Indien u een klacht heeft over de wijze waarop HeyZZP met uw persoonsgegevens omgaat, of
            een van bovengenoemde rechten wenst uit te oefenen, kunt u contact opnemen via de
            website en applicatie of per e-mail via info@wisniowsky.nl. Om er zeker van te zijn dat
            u een recht wenst uit te oefenen verzoekt HeyZZP u om een kopie van uw identiteitsbewijs
            mee te sturen. U kunt hierbij uw pasfoto en MRZ (strook met nummers onderaan) zwart
            maken ter bescherming van uw privacy. HeyZZP zal zo spoedig mogelijk, doch uiterlijk
            binnen 4 weken op uw verzoek reageren. Tevens kunt u een klacht indienen bij de
            Autoriteit Persoonsgegevens. Dit is de bevoegde handhavingsinstantie. U kunt hiervoor
            contact opnemen via deze link:
            <a href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons">
              https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
            </a>
            .
          </p>
          <h2>Wijzigingen Privacy Statement</h2>
          <p>
            HeyZZP kan te allen tijde de privacyverklaring aanpassen. Op de website en applicatie
            staan de meest recente versie gepubliceerd. Houd daarom altijd de website en applicatie
            in de gaten voor de meest recente versie. Als het nieuwe privacy statement gevolgen
            heeft voor de wijze waarop HeyZZP reeds verzamelde gegevens met betrekking tot u
            verwerken, dan brengt HeyZZP u per e-mail daarvan op de hoogte.
          </p>
          <p>Deze versie is voor het laatst vastgesteld op 9 augustus 2021.</p>
        </div>
      </Container>
    </Page>
  );
};
export default Privacy;
