import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ClientEntity } from 'app-types';
import { invoice } from '../../../../../actions';
import { Spinner } from '../../../../../components/Common';
import InvoiceForm from '../InvoiceForm';
import ApiService from '../../../../../services/api-service';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  setInvoiceClient: (client: ClientEntity | null) => void;
}

interface State {
  loading: boolean;
}

class AddClient extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.getSingleClient(match.params.id);
  }

  private getSingleClient = async (id: string) => {
    const { setInvoiceClient } = this.props;
    await ApiService.callFetch('GET', `client/detail/${id}`, (client: ClientEntity) => {
      setInvoiceClient(client);
    });
    this.setState({
      loading: false,
    });
  };

  render() {
    const { loading } = this.state;
    return <>{loading ? <Spinner /> : <InvoiceForm />}</>;
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setInvoiceClient: invoice.setInvoiceClient,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(AddClient);
