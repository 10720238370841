import React from 'react';
import { CategoryRes } from '../../../../../../heyzzp-back/types';
import { CategoryAutosuggestion, FieldsMapper, FileInput } from '../../../../components/Common';
import { __ } from '../../../../services/translation';
import { formOnTranslateString } from '../../../../utils/trans-form';

const CostFieldsMapper = ({
  data: {
    field,
    errors,
    fieldHtmlId,
    currentValue,
    onChange,
    onFocus,
    onBlur,
    onCategorySelect,
    onNewCategorySelect,
  },
}: any) => {
  if (field.type === 'fileDragDrop') {
    return (
      <FileInput
        multiple
        accept={field.options.accept}
        hasImage={field.options.previewUrl}
        label={field.title}
        input={{
          name: fieldHtmlId,
          id: fieldHtmlId,
          value: currentValue,
          onChange,
          onFocus,
          onBlur,
        }}
        meta={{
          touched: errors.length > 0,
          error: errors.length
            ? formOnTranslateString(errors[0].messageWithPlaceholders, errors[0].params)
            : null,
        }}
      />
    );
  } else if (field.id === 'category') {
    return (
      <CategoryAutosuggestion
        required={field.required}
        label={field.title}
        input={{
          value: currentValue,
          name: fieldHtmlId,
          id: fieldHtmlId,
          onChange,
        }}
        allowNewCategory
        onNewCategorySelect={onNewCategorySelect}
        onOptionSelect={onCategorySelect}
        placeholder={field.placeholder}
        meta={{
          touched: errors.length > 0,
          error: errors.length ? __(errors[0].messageWithPlaceholders, errors[0].params) : null,
        }}
      />
    );
  } else return FieldsMapper(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur);
};

export default CostFieldsMapper;
