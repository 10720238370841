import React from 'react';
import Language from './Language';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { ButtonsContainer } from '../../components/Layout';
import CONFIG from '../../config/app';
import './ChangeLanguageModal.scss';

const ChangeLanguageModal = () => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.choose_language" />
    </ModalHeader>
    <ButtonsContainer max>
      {CONFIG.LANGUAGES.map((language) => (
        <Language name={language.name} flag={language.flag} shortcut={language.shortcut} key={language.name} />
      ))}
    </ButtonsContainer>
  </ModalContent>
);

export default ChangeLanguageModal;
