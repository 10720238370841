import { MileageEntity, MileageType, TrackingStatus } from 'app-types';
import { MileageCoordinates } from '../types/mileage';
import SessionService from '../services/session-service';

interface StartGpsTracking {
  type: 'START_GPS_TRACKING';
  payload: {
    handler: any;
    date: Date;
  };
}
interface ContinueGpsTracking {
  type: 'CONTINUE_GPS_TRACKING';
  payload: {
    handler: any;
    coordinates: MileageCoordinates[];
    trackingStart: Date;
  };
}

interface StopGpsTracking {
  type: 'STOP_GPS_TRACKING';
  payload: Date;
}
interface CancelGpsTracking {
  type: 'CANCEL_GPS_TRACKING';
}

interface ClearGpsTrackingInterval {
  type: 'CLEAR_GPS_TRACKING_INTERVAL';
}

interface GetMileageList {
  type: 'GET_MILEAGE_LIST_SUCCESS';
  payload: MileageEntity[];
}

interface LoadingMileageList {
  type: 'LOADING_MILEAGE_LIST';
  payload: boolean;
}

interface GetSingleMileage {
  type: 'GET_SINGLE_MILEAGE_SUCCESS';
  payload: MileageEntity;
}

interface LoadingSingleMileage {
  type: 'LOADING_SINGLE_MILEAGE';
  payload: boolean;
}
interface GetMileageYears {
  type: 'GET_MILEAGE_YEARS_SUCCESS';
  payload: number[];
}

interface LoadingMileageYears {
  type: 'LOADING_MILEAGE_YEARS';
  payload: boolean;
}

interface GetGoogleMapsLink {
  type: 'GET_GOOGLE_MAPS_LINK_SUCCESS';
  payload: any;
}

interface LoadingGoogleMapsLink {
  type: 'LOADING_GOOGLE_MAPS_LINK';
  payload: boolean;
}

interface SetTrackingState {
  type: 'SET_TRACKING_STATE';
  payload: string;
}

interface StoreCoordinates {
  type: 'STORE_COORDINATES';
  payload: MileageCoordinates;
}

interface ClearCoordinates {
  type: 'CLEAR_COORDINATES';
}

export interface MileageState {
  loading: boolean;
  list: MileageEntity[];
  loadingSingle: boolean;
  single: MileageEntity;
  mileageYears: number[];
  loadingMileageYears: boolean;
  googleMaps: {
    path: string;
  };
  loadingGoogleMaps: boolean;
  coordinates: MileageCoordinates[];
  geolocationStartDate: Date;
  geolocationFinishDate: Date;
  trackingStatus: TrackingStatus;
  interval: any;
}

const initialState: MileageState = {
  loading: true,
  list: [],
  loadingSingle: true,
  single: {
    date: new Date(),
    description: '',
    regNumber: '',
    carModel: '',
    startPoint: '',
    finishPoint: '',
    distance: 0,
    carType: '',
    time: 0,
    id: '',
    type: MileageType.private,
    extraPrivateDistance: 0,
    extraPrivateTime: 0,
  },
  mileageYears: [],
  loadingMileageYears: true,
  googleMaps: {
    path: '',
  },
  loadingGoogleMaps: true,
  coordinates: [],
  geolocationStartDate: new Date(),
  geolocationFinishDate: new Date(),
  trackingStatus: TrackingStatus.Idle,
  interval: null,
};

type Action =
  | StartGpsTracking
  | ContinueGpsTracking
  | StopGpsTracking
  | CancelGpsTracking
  | ClearGpsTrackingInterval
  | GetMileageList
  | LoadingMileageList
  | GetSingleMileage
  | LoadingSingleMileage
  | GetMileageYears
  | LoadingMileageYears
  | SetTrackingState
  | GetGoogleMapsLink
  | LoadingGoogleMapsLink
  | StoreCoordinates
  | ClearCoordinates;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_MILEAGE_LIST_SUCCESS': {
      return { ...state, list: action.payload };
    }
    case 'LOADING_MILEAGE_LIST': {
      return { ...state, loading: action.payload };
    }
    case 'GET_SINGLE_MILEAGE_SUCCESS': {
      return { ...state, single: action.payload };
    }
    case 'LOADING_SINGLE_MILEAGE': {
      return { ...state, loadingSingle: action.payload };
    }

    case 'SET_TRACKING_STATE': {
      return { ...state, trackingStatus: action.payload };
    }

    case 'GET_MILEAGE_YEARS_SUCCESS': {
      const mileageYears = action.payload;
      return { ...state, mileageYears };
    }
    case 'LOADING_MILEAGE_YEARS': {
      return { ...state, loadingMileageYears: action.payload };
    }
    case 'GET_GOOGLE_MAPS_LINK_SUCCESS': {
      return { ...state, googleMaps: action.payload };
    }
    case 'LOADING_GOOGLE_MAPS_LINK': {
      return { ...state, loadingGoogleMaps: action.payload };
    }
    case 'STORE_COORDINATES': {
      SessionService.addCoordinates(action.payload);
      const updated = [...state.coordinates, action.payload];
      return { ...state, coordinates: updated };
    }
    case 'CLEAR_COORDINATES': {
      SessionService.clearCoordinates();
      return { ...state, coordinates: [], geolocationStartDate: '', geolocationFinishDate: '' };
    }

    case 'START_GPS_TRACKING': {
      SessionService.clearCoordinates();
      SessionService.setTrackingStart(action.payload.date);
      if (state.interval) clearInterval(state.interval);
      return {
        ...state,
        coordinates: [],
        interval: setInterval(action.payload.handler, 10000),
        trackingStatus: TrackingStatus.Working,
        geolocationStartDate: action.payload.date,
      };
    }
    case 'CONTINUE_GPS_TRACKING': {
      if (state.interval) clearInterval(state.interval);

      return {
        ...state,
        interval: setInterval(action.payload.handler, 10000),
        geolocationStartDate: action.payload.trackingStart,
        coordinates: action.payload.coordinates,
        trackingStatus: TrackingStatus.Working,
      };
    }
    case 'CANCEL_GPS_TRACKING': {
      SessionService.clearCoordinates();
      SessionService.clearTrackingStart();
      if (state.interval) clearInterval(state.interval);
      return {
        ...state,
        interval: undefined,
        trackingStatus: TrackingStatus.Idle,
      };
    }
    case 'STOP_GPS_TRACKING': {
      SessionService.clearTrackingStart();
      clearInterval(state.interval);
      return {
        ...state,
        interval: undefined,
        trackingStatus: TrackingStatus.Idle,
        geolocationFinishDate: action.payload,
      };
    }
    default:
      return state;
  }
};
