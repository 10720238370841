import React from 'react';
import { useDispatch } from 'react-redux';
import { MileageRoute } from '../../../modals';
import { modal } from '../../../actions';
import './RouteInput.scss';
import { __ } from '../../../services/translation';
import Button from '../Button';
import { ButtonsContainer } from '../../../components/Layout';

interface Data {
  startPoint: string;
  finishPoint: string;
  distance: number;
  time: number;
}

interface Props extends Data {
  onChange: (vals: Data) => void;
  returnRoute?: boolean;
  customCloseModal?: boolean;
  allowOwnAddress?: boolean;
}

const RouteInput = ({
  startPoint,
  finishPoint,
  distance,
  time,
  onChange,
  customCloseModal,
  allowOwnAddress,
  returnRoute,
}: Props) => {
  const dispatch = useDispatch();

  const handleInputClick = () => {
    dispatch(
      modal.showModal(
        <MileageRoute
          onChange={onChange}
          startPoint={startPoint}
          finishPoint={finishPoint}
          distance={distance}
          time={time}
          customCloseModal={customCloseModal}
          allowOwnAddress={allowOwnAddress}
          returnRoute={returnRoute}
        />,
      ),
    );
  };

  return (
    <div className="route-input">
      <div className="route-input__location">
        <img src="/assets/icons/pin_start.svg" />
        <span>{startPoint ? startPoint : '-'}</span>
      </div>
      <div className="route-input__static-text">{__('To')}</div>
      <div className="route-input__location">
        <img src="/assets/icons/pin_stop.svg" />
        <span>{finishPoint ? finishPoint : '-'}</span>
      </div>
      <ButtonsContainer>
        <Button
          small
          click={(e: any) => {
            e.preventDefault();
            handleInputClick();
          }}
        >
          {__('application.edit')}
        </Button>
      </ButtonsContainer>
    </div>
  );
};

export default RouteInput;
