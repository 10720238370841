import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { MileageEntity } from 'app-types';
import { mileage as mileageActions, modal, header } from '../../../../actions';
import { ButtonsContainer } from '../../../../components/Layout';
import { Spinner, Button, InfoParagraph } from '../../../../components/Common';
import { EditMileage, Confirmation } from '../../../../modals';
import { ApplicationState } from '../../../../reducers';
import GoogleMapsButton from '../../../../components/Common/GoogleMapsButton';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  getSingleMileage: (mileageId: string) => void;
  loading: boolean;
  mileage: MileageEntity;
  showModal: (content: React.ReactNode) => void;
  deleteSingleMileage: (id: string) => void;
  hideModal: () => void;
  googleMaps: { path: string };
  loadingGoogleMaps: boolean;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class SingleMileage extends Component<Props> {
  componentDidMount() {
    const { getSingleMileage, match, setHeader } = this.props;
    getSingleMileage(match.params.id);
    setHeader('application.mileage');
  }

  private deleteMileage = () => {
    const { showModal, hideModal, deleteSingleMileage, mileage } = this.props;
    showModal(
      <Confirmation
        text="application.are_you_sure_you_want_to_delete_this_mileage?"
        confirmCallback={() => deleteSingleMileage(mileage.id)}
        cancelCallback={() => hideModal()}
      />,
    );
  };

  private editMileage = () => {
    const { showModal, mileage } = this.props;
    showModal(<EditMileage mileageId={mileage.id} />);
  };

  render() {
    const { loading, loadingGoogleMaps, mileage } = this.props;

    return (
      <>
        {(loading || loadingGoogleMaps) && <Spinner overlay />}
        <InfoParagraph name="application.start_point" text={mileage.startPoint} />
        <InfoParagraph name="application.end_point" text={mileage.finishPoint} />
        <InfoParagraph name="application.distance" distance={mileage.distance} />
        {!!mileage.time && (
          <InfoParagraph name="application.duration_time" duration={mileage.time} />
        )}
        <InfoParagraph name="application.date" date={mileage.date} />
        <InfoParagraph name="application.description" text={mileage.description} />
        <InfoParagraph name="application.registration_number" text={mileage.regNumber} />
        <InfoParagraph name="application.car_model" text={mileage.carModel} />
        <InfoParagraph name="application.car_type" text={mileage.carType} />
        <InfoParagraph name="application.company_route?" boolean={!!mileage.type} />
        <InfoParagraph name="mileage.with_private_ride" boolean={!!mileage.extraPrivateDistance} />
        {!!mileage.extraPrivateDistance && (
          <>
            <InfoParagraph
              name="mileage.extra_private_distance"
              distance={mileage.extraPrivateDistance}
            />
            <InfoParagraph name="mileage.extra_private_time" duration={mileage.extraPrivateTime} />
          </>
        )}
        <ButtonsContainer marginTop>
          <GoogleMapsButton
            startPoint={mileage.startPoint}
            finishPoint={mileage.finishPoint}
            previewRoute
          />
          <Button
            text="application.edit"
            secondary
            noShadow
            click={this.editMileage}
            rightMargin
            bottomMargin
          />
          <Button
            text="application.delete"
            danger
            noShadow
            click={this.deleteMileage}
            bottomMargin
          />
        </ButtonsContainer>
      </>
    );
  }
}
const mapStateToProps = (state: ApplicationState) => ({
  loading: state.mileage.loadingSingle,
  mileage: state.mileage.single,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getSingleMileage: mileageActions.getSingleMileage,
      showModal: modal.showModal,
      hideModal: modal.hideModal,
      deleteSingleMileage: mileageActions.deleteSingleMileage,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SingleMileage);
