import React from 'react';
import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';
import App from './App';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
console.log(pdfjs.version);
const isMobile = !!window.matchMedia('(max-width: 768px)').matches;

if (isMobile) {
  const target = document.querySelector('body');
  const headTarget = document.querySelector('head');

  const config = { subtree: true, childList: true };

  let observer: MutationObserver;
  let observerHead: MutationObserver;

  const cb = (m: any) => {
    m.forEach((change: any) => {
      if (change.target.id === 'trengo-web-widget') {
        const trengoFrame: any = document.querySelector('.trengo-vue-iframe');
        const innerDoc = trengoFrame.contentDocument || trengoFrame.contentWindow.document;
        const elmnt = innerDoc.querySelector('.launcher-container');
        const wrapper: any = document.querySelector('.TrengoWidgetLauncher__iframe');
        const elmntBody = innerDoc.querySelector('body');
        if (elmnt && wrapper) {
          elmnt.style.transformOrigin = '0 0';
          elmnt.style.transform = 'scale(0.7)';
          elmntBody.style.overflow = 'hidden';
          wrapper.style.width = '42px';
          wrapper.style.height = '42px';
        }
        observer.disconnect();
      }
      if (change.addedNodes[0] && change.addedNodes[0].id === 'trengo-viewport-meta') {
        change.addedNodes[0].remove();
        const vw: any = document.getElementsByName('viewport')[0];
        vw.content = 'width=device-width, initial-scale=1, user-scalable=no, viewport-fit=cover';
      }
    });
  };

  observer = new MutationObserver(cb);
  observer.observe(target!, config);
  observerHead = new MutationObserver(cb);
  observerHead.observe(headTarget!, config);
}

ReactDOM.render(
  <App />,

  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
