import React, { useEffect, useState } from 'react';
import { Alert, Spinner } from '../../../../components/Common';
import { CategoryRes } from '../../../../../../heyzzp-back/types';
import ApiService from '../../../../services/api-service';
import CategoryTile from './CategoryTile';

import './CategoryTiles.scss';
import { __ } from '../../../../services/translation';

const Categories = () => {
  const [categories, setCategories] = useState<CategoryRes[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getCategories = async () => {
    await ApiService.callFetch('GET', 'category', (data: CategoryRes[]) => {
      setCategories(data);
    });
    setLoading(false);
  };

  useEffect(() => {
    getCategories();
  }, []);

  if (loading) return <Spinner />;

  if (!categories || categories.length === 0) {
    return <Alert type="notice" text="application.no_data" />;
  }

  return (
    <div className="category-tiles">
      <CategoryTile
        category={{
          id: '',
          title: __('application.no_category'),
          description: '',
        }}
      />
      {categories.map((cat) => (
        <CategoryTile category={cat} />
      ))}
    </div>
  );
};

export default Categories;
