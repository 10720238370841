import { OfferRes, ClientType, ClientEntity } from 'app-types';

interface GetNextOfferNumber {
    type: 'GET_NEXT_OFFER_NUMBER_SUCCESS';
    payload: number;
}

interface LoadingNextOfferNumber {
    type: 'LOADING_NEXT_OFFER_NUMBER';
    payload: boolean;
}

interface GetSingleOffer {
    type: 'GET_SINGLE_OFFER_SUCCESS';
    payload: number;
}

interface LoadingOfferSend {
    type: 'LOADING_OFFER_SEND';
    payload: boolean;
}

interface LoadingSingleOffer {
    type: 'LOADING_SINGLE_OFFER';
    payload: boolean;
}

interface ReceiveFormData {
    type: 'RECEIVE_FORM_DATA';
    payload: any;
}

interface ReceiveOfferData {
    type: 'RECEIVE_OFFER_DATA';
    payload: OfferRes;
}

interface LoadingCreditnotaData {
    type: 'LOADING_CREDITNOTA_DATA';
    payload: boolean;
}

interface GetCreditnotaData {
    type: 'GET_CREDITNOTA_DATA_SUCCESS';
    payload: OfferRes;
}

interface ReceiveClientData {
    type: 'RECEIVE_CLIENT_DATA_FOR_OFFER';
    payload: {};
}

interface SetOfferClient {
    type: 'SET_OFFER_CLIENT';
    payload: ClientEntity | null;
}

export interface OfferState {
    singleOffer: OfferRes | null;
    receivedOfferData: any;
    loadingSingleOffer: boolean;
    loadingOfferSend: boolean;
    client: ClientEntity | null;
}

const initialState: OfferState = {
    singleOffer: null,
    receivedOfferData: {
        client: {},
        services: [],
    },
    loadingSingleOffer: true,
    loadingOfferSend: false,
    client: null,
};

type Action =
    | GetNextOfferNumber
    | LoadingNextOfferNumber
    | GetSingleOffer
    | LoadingSingleOffer
    | ReceiveFormData
    | ReceiveOfferData
    | LoadingOfferSend
    | ReceiveClientData
    | SetOfferClient;

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case 'GET_SINGLE_OFFER_SUCCESS': {
            return { ...state, singleOffer: action.payload };
        }
        case 'LOADING_SINGLE_OFFER': {
            return { ...state, loadingSingleOffer: action.payload };
        }
        case 'LOADING_OFFER_SEND': {
            return { ...state, loadingOfferSend: action.payload };
        }
        case 'RECEIVE_OFFER_DATA': {
            return { ...state, receivedOfferData: action.payload };
        }

        case 'SET_OFFER_CLIENT': {
            return { ...state, client: action.payload };
        }

        case 'RECEIVE_CLIENT_DATA_FOR_OFFER': {
            return {
                ...state,
                clientForOffer: action.payload,
            };
        }
        default:
            return state;
    }
};
