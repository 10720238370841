import ApiService from './services/api-service';

const convertedVapidKey = urlBase64ToUint8Array(
  'BNi26vfxIvuHGILYoXhBRcwatUFiXej7yjaZH38ThnnrVVbJIOo4TboQvqThMSaDfJFTm4RV3UbFpgG59MaHGXo',
);

function urlBase64ToUint8Array(base64String: string | undefined) {
  const padding = '='.repeat((4 - ((base64String ? base64String.length : 0) % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function sendSubscription(subscription: PushSubscription) {
  return fetch(`${ApiService.url}user/set-notification`, {
    method: 'POST',
    body: JSON.stringify(subscription),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  }).catch((err) => console.log(err));
}

export function subscribeUser() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        if (!registration.pushManager) {
          console.log('Push manager unavailable.');
          return;
        }

        registration.pushManager
          .getSubscription()
          .then((existedSubscription: PushSubscription | null) => {
            if (existedSubscription === null) {
              console.log('No subscription detected, make a request.');
              registration.pushManager
                .subscribe({
                  applicationServerKey: convertedVapidKey,
                  userVisibleOnly: true,
                })
                .then((newSubscription: PushSubscription) => {
                  console.log('New subscription added.');
                  sendSubscription(newSubscription);
                })
                .catch((e: any) => {
                  if (Notification.permission !== 'granted') {
                    console.log('Permission was not granted.');
                  } else {
                    console.error('An error ocurred during the subscription process.', e);
                  }
                });
            } else {
              console.log('Existed subscription detected.');
              sendSubscription(existedSubscription);
            }
          });
      })
      .catch((e) => {
        console.error('An error ocurred during Service Worker registration.', e);
      });
  }
}
