import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ItfApiForm, PublicItfFormBuilderComponentApi } from 'itf_formbuilder_react';
import { MileageSchemaEntity } from 'app-types';
import { FormContainer, ButtonsContainer } from '../../../../components/Layout';
import {
  Button,
  Spinner,
  RequiredIndicator,
  MileageFieldsMapper,
} from '../../../../components/Common';
import ApiService from '../../../../services/api-service';
import { notifications, header } from '../../../../actions';
import { flatpickr, history, store } from '../../../../App';
import { formOnTranslateString } from '../../../../utils/trans-form';
import ReactSelect from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';

export interface GlobalFormsData {
  [formId: string]: {
    api: PublicItfFormBuilderComponentApi;
    values: {
      [id: string]: any;
    };
    valueChangedClb?: (newValues: any) => any;
  };
}

declare global {
  interface Window {
    globalForms: GlobalFormsData;
  }
}

interface State {
  route: { startPoint: string; finishPoint: string; distance: number; time: number };
  returnRoute: { startPoint: string; finishPoint: string; distance: number; time: number };
  itfForm: any;
}

interface Props {
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class AddMileageScheme extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      itfForm: null,
      route: { startPoint: '', finishPoint: '', distance: 0, time: 0 },
      returnRoute: { startPoint: '', finishPoint: '', distance: 0, time: 0 },
    };
  }

  registerFormCallbacks = () => {
    window.globalForms['add-mileage-scheme'].valueChangedClb = (newValues: MileageSchemaEntity) => {
      this.setState({
        route: {
          startPoint: newValues.startPoint,
          finishPoint: newValues.finishPoint,
          distance: newValues.distance,
          time: newValues.time,
        },
        returnRoute: {
          startPoint: newValues.startPointReturn,
          finishPoint: newValues.finishPointReturn,
          distance: newValues.distanceReturn,
          time: newValues.timeReturn,
        },
      });
    };
  };

  componendDidMount() {
    const { setHeader } = this.props;
    setHeader('application.add_mileage_scheme', '/dashboard/mileage-schemes/list', {
      title: 'application.add_mileage_scheme',
      text: 'annotations.mileage_scheme',
    });
  }

  setRef = (form: any) => {
    this.setState({
      itfForm: form,
    });
  };

  render() {
    const { itfForm, route, returnRoute } = this.state;

    return (
      <>
        <FormContainer>
          <ItfApiForm
            ref={this.setRef}
            formId="add-mileage-scheme"
            schemaMayBeDynamic
            schemaCacheEnabled={false}
            requiredIndicator={<RequiredIndicator />}
            schemaFetcher={() => ApiService.loadForm('mileage-schema/form/add-item')}
            sendFetcher={(formId: string, values: any) =>
              ApiService.sendForm('mileage-schema/new', values, 'POST')
            }
            onSavedSuccessfully={() => {
              store.dispatch(notifications.successNotification('application.scheme_added'));
              history.push('/dashboard/mileage-schemes/list');
            }}
            loadingInfo={<Spinner transparent />}
            submitButton={
              <ButtonsContainer marginTop>
                <Button type="submit" text="application.add" primary />
              </ButtonsContainer>
            }
            thirdPartyComponents={{ ReactSelect, ReactCreatableSelect, flatpickr }}
            onTranslateString={formOnTranslateString}
            registerAsGlobalForm
            enableLiveValuesOfGlobalForm
            onFormGloballyRegistered={this.registerFormCallbacks}
            onRenderFullField={(
              field: any,
              errors: any,
              fieldHtmlId: any,
              currentValue: any,
              onChange: any,
              onFocus: any,
              onBlur: any,
            ) => (
              <MileageFieldsMapper
                data={{
                  itfForm,
                  field,
                  errors,
                  fieldHtmlId,
                  currentValue,
                  onChange,
                  onFocus,
                  onBlur,
                  allowOwnAddress: true,
                  noDurationHelp: true,
                  route,
                  returnRoute,
                }}
              />
            )}
          />
        </FormContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ setHeader: header.setHeader }, dispatch);

export default connect(null, mapDispatchToProps)(AddMileageScheme);
