import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MileageEntity } from 'app-types';
import { ListItem } from '../../../../../components/Common';
import CopyMileage from '../../../../../modals/CopyMileage';
import Confirmation from '../../../../../modals/Confirmation';
import { modal, mileage } from '../../../../../actions';
import { formatDate } from '../../../../../utils/format-date';
import { formatDistance } from '../../../../../utils/format-distance';
import { formatDuration } from '../../../../../utils/format-duration';
import { __ } from '../../../../../services/translation';
import { EditMileage } from '../../../../../modals';

interface Props {
  showModal: (content: React.ReactNode) => void;
  item: MileageEntity;
  deleteSingleMileage: (id: string) => void;
}

const MileageItem = ({ item, showModal, deleteSingleMileage }: Props) => {
  const getExtraInfo = () => {
    if (item.extraPrivateDistance) {
      return [
        {
          icon: faInfoCircle,
          text: `${__('mileage.with_private_ride')}:`,
          strong: true,
          success: true,
        },
        {
          icon: 'mileages',
          text: formatDistance(item.extraPrivateDistance),
        },
        {
          hide: !item.time,
          icon: 'time_sheets',
          text:
            typeof item.time !== 'undefined'
              ? formatDuration(item.extraPrivateTime)
              : item.extraPrivateTime,
        },
      ];
    }
    return [];
  };
  return (
    <>
      <ListItem
        header={formatDate(item.date)}
        // subheader={<MileageSubheader item={item} />}
        buttons={[
          { text: 'application.details', to: `/dashboard/mileages/${item.id}` },
          {
            text: 'application.edit',
            type: 'secondary',
            click: () => showModal(<EditMileage mileageId={item.id} />),
          },
          {
            text: 'application.copy',
            type: 'secondary',
            click: () => showModal(<CopyMileage id={item.id} />),
          },
          {
            text: 'application.delete',
            type: 'danger',
            click: () =>
              showModal(
                <Confirmation
                  text="application.delete?"
                  confirmCallback={() => deleteSingleMileage(item.id)}
                />,
              ),
          },
        ]}
        paragraphs={[
          {
            icon: 'pin_start',
            text: item.startPoint,
          },
          {
            icon: 'pin_stop',
            text: item.finishPoint,
          },
          {
            icon: 'mileages',
            text: formatDistance(item.distance),
          },
          {
            hide: !item.time,
            icon: 'time_sheets',
            text: typeof item.time !== 'undefined' ? formatDuration(item.time) : item.time,
          },
          ...getExtraInfo(),
        ]}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { showModal: modal.showModal, deleteSingleMileage: mileage.deleteSingleMileage },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(MileageItem);
