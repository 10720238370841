import React, { useEffect, useState } from 'react';
import { TableSchema, invoiceForUser } from 'app-tables';
import { ApiList, Button } from '../../../../components/Common';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import InvoiceListItem from './Item';
import useHeader from '../../../../hooks/useHeader';
import { BottomContainer } from '../../../../components/Layout';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiList, invoice } from '../../../../actions';
import { ApplicationState } from '../../../../reducers';
import ApiService from '../../../../services/api-service';
import { __ } from '../../../../services/translation';

const InvoiceList = () => {
  useHeader('application.invoices');
  const dispatch = useDispatch();
  const [notPaidInvoicesCount, setNotPaidInvoicesCount] = useState(0);
  const scheme: TableSchema = new InteractiveTableSchema(invoiceForUser);

  const getNotPaidInvoicesCount = async () => {
    const notPaidInvoices: number = await ApiService.callFetch('GET', 'invoice/get-not-paid-count');
    setNotPaidInvoicesCount(notPaidInvoices);
  };

  const handleClickMarkAllInvoicesAsPaid = async () => {
    await dispatch(invoice.markAllInvoicesAsPaid());
    await dispatch(apiList.fetchApiListData('invoice/list'));
    dispatch(apiList.refreshApiList());
    setNotPaidInvoicesCount(0);
  };

  const updateNotPaidInvoiceCount = (action: 'INCREASE' | 'DECREASE') => {
    setNotPaidInvoicesCount(
      action === 'INCREASE' ? notPaidInvoicesCount + 1 : notPaidInvoicesCount - 1,
    );
  };

  useEffect(() => {
    getNotPaidInvoicesCount();
  }, []);

  return (
    <>
      <ApiList
        apiEndpointSubUrl="invoice/list"
        scheme={scheme}
        component={InvoiceListItem}
        additionalComponentProps={{
          updateNotPaidInvoiceCount,
        }}
        customButtons={[
          {
            translateKey: 'application.mark_all_as_paid',
            onClick: handleClickMarkAllInvoicesAsPaid,
            isHidden: !notPaidInvoicesCount,
          },
        ]}
      />
    </>
  );
};

export default InvoiceList;
