import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '..';
import { FullPaymentRes, PaymentStatus } from '../../../../../heyzzp-back/types';
import { apiTable, modal, notifications } from '../../../actions';
import { Confirmation } from '../../../modals';
import ApiService from '../../../services/api-service';
import { DownloadService } from '../../../services/download-service';

interface Props {
  payment: FullPaymentRes;
}

const AdminPaymentButtons = ({ payment }: Props) => {
  const dispatch = useDispatch();

  const downloadPDF = async (id: string) => {
    await DownloadService.download(`payment/invoice-pdf/${id}`);
  };

  const downloadCreditnotePDF = async (id: string) => {
    await DownloadService.download(`payment/generate-creditnote/${id}`);
  };
  const confirmForceApprove = (id: string) => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.force_payment_description"
          confirmCallback={() => forcePayment(id)}
        />,
      ),
    );
  };

  const forcePayment = (id: string) => {
    ApiService.callFetch('PATCH', `payment/force-payment-approve/${id}`, () => {
      dispatch(notifications.successNotification('application.forced_payment_successfully'));
      dispatch(modal.hideModal());
      dispatch(apiTable.refreshApiTable());
    });
  };

  return (
    <>
      <Button text="application.download_pdf" click={() => downloadPDF(payment.id)} />
      {(payment.status !== PaymentStatus.PAID || !payment.isPaid) &&
        !payment.isManuallyApproved && (
          <Button
            secondary
            text="application.force_payment_approve"
            click={() => confirmForceApprove(payment.id)}
          />
        )}
      <Button text="application.creditnote" click={() => downloadCreditnotePDF(payment.id)} />,
    </>
  );
};

export { AdminPaymentButtons };
