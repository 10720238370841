import { FieldType, TableSchema } from "../typings";

export const offers: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "application.creation_date",
            field: "createdAt",
            mobile: true,
            searchable: false,
            sortable: true,
            defaultSort: true,
            type: FieldType.Date
        },
        {
            name: "application.valid_to",
            field: "validTo",
            mobile: true,
            searchable: true,
            sortable: true,
            defaultSort: true,
            type: FieldType.Date
        },

    ]
};
