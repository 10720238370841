import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { __ } from '../../../services/translation';

import './NavItems.scss';
import { NavigationItem } from '../../../types/navigation';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';

interface Props {
  items: NavigationItem[];
}

const NavItems = ({ items }: Props) => {
  const user = useSelector((state: ApplicationState) => state.user.details);
  const params: any = useParams();

  const getNavItemUrl = (item: NavigationItem): string => {
    const splitUrl = item.link.split('/');
    let newUrl = '';
    splitUrl.forEach((urlPart, index) => {
      const slash = index === 0 ? '' : '/';
      if (urlPart.startsWith('%{') && urlPart.endsWith('}')) {
        newUrl += `${slash}${params[urlPart.slice(0, -1).substring(2)]}`;
        return;
      }
      newUrl += `${slash}${urlPart}`;
    });
    return newUrl;
  };

  if (!user) return null;
  const getIcon = (item: NavigationItem) => {
    if (typeof item.icon === 'string') {
      return (
        <img
          className="dashboard-navigation-icon"
          src={`/assets/icons/${item.icon}.svg`}
          alt={__(item.title)}
        />
      );
    }
    return item.icon;
  };
  return (
    <div className="dashboard-navigation-container">
      {items
        .filter((item) => !item.inactive)
        .filter((item) => !item.restrict || (item.restrict && item.restrict.includes(user.role)))
        .map((item: NavigationItem) => (
          <NavLink
            exact={item.exact}
            to={getNavItemUrl(item)}
            key={getNavItemUrl(item)}
            className={`dashboard-navigation-item ${item.highlight ? 'hightlight' : ''}`}
            activeClassName="active"
          >
            {getIcon(item)}
            <div className="item-title">{__(item.title)}</div>
            {item.indicator && <span className="indicator" />}
          </NavLink>
        ))}
    </div>
  );
};

export default NavItems;
