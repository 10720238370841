import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';
import ReactSelect from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';
import flatpickr from 'flatpickr';
import { WorkBoxProvider } from 'react-workbox';
import i18next from 'i18next';
import reducers from './reducers';
import './index.scss';

import {
  Modal,
  Menu,
  LocaleWrapper,
  OnlineContent,
  OfflineContent,
  AppOffline,
  UpdateInfoBar,
  Spinner,
} from './components/Common';
import { appStatus } from './actions';
import { NotificationContainer } from './components/Notifications';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/airbnb.css';

import { AppWrapper, TopHeader } from './components/Layout';
import { scrollTop } from './utils/scroll-top';
import ErrorBoundary from './components/Common/ErrorBoundary';
import RouteInactive from './components/Routes/RouteInactive';
import { RegisterAccountant } from './views';
import {
  RouteAdmin,
  RouteClientAccountant,
  RouteUnauthorized,
  RouteUnsubscribed,
} from './components/Routes';
import Privacy from './views/Privacy';

const Registration = React.lazy(() => import('./views/Registration'));
const Login = React.lazy(() => import('./views/Login'));
const PageNotFound = React.lazy(() => import('./views/PageNotFound'));
const Welcome = React.lazy(() => import('./views/Welcome'));
const DashboardClient = React.lazy(() => import('./views/DashboardClient'));
const ResetPassword = React.lazy(() => import('./views/ResetPassword'));
const SendActivationLink = React.lazy(() => import('./views/SendActivationLink'));
const DashboardAdmin = React.lazy(() => import('./views/DashboardAdmin'));
const PaymentSuccess = React.lazy(() => import('./views/PaymentSuccess'));
const PaymentFailure = React.lazy(() => import('./views/PaymentFailure'));
const PaymentStatus = React.lazy(() => import('./views/PaymentStatus'));
const NoSubscription = React.lazy(() => import('./views/Payments/NoSubscription'));
const PaymentPlans = React.lazy(() => import('./views/Payments/PaymentPlans'));
const BuySubscription = React.lazy(() => import('./views/Payments/BuySubscription'));
const DashboardInactive = React.lazy(() => import('./views/DashboardInactive'));
const Blocked = React.lazy(() => import('./views/Blocked'));
const AcceptOffer = React.lazy(() => import('./views/AcceptOffer'));

flatpickr.defaultConfig.time_24hr = true;
flatpickr.defaultConfig.disableMobile = true;
flatpickr.defaultConfig.allowInput = true;

export { flatpickr };
export { ReactSelect, ReactCreatableSelect };

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
export const store = createStoreWithMiddleware(reducers);

i18next.init({
  interpolation: { escapeValue: false },
});

export const history = createBrowserHistory({ basename: '/' });

history.listen((l) => scrollTop());

const AppContent = () => (
  <Provider store={store}>
    <Router history={history}>
      <ErrorBoundary>
        <OnlineContent>
          <NotificationContainer />
          <LocaleWrapper>
            <UpdateInfoBar />
            <AppWrapper>
              <TopHeader />
              <Menu />
              <Suspense fallback={<Spinner overlay dark />}>
                <Switch>
                  <RouteUnauthorized exact path="/" component={Welcome} />
                  <RouteUnauthorized
                    exact
                    path="/activate/:userId/:updateToken"
                    component={Welcome}
                  />
                  <RouteInactive path="/inactive" component={DashboardInactive} />
                  <RouteUnauthorized exact path="/login" component={Login} />
                  <RouteClientAccountant path="/dashboard" component={DashboardClient} />
                  <RouteAdmin path="/admin" component={DashboardAdmin} />
                  <RouteUnauthorized exact path="/registration" component={Registration} />
                  <RouteUnauthorized
                    exact
                    path="/register-accountant"
                    component={RegisterAccountant}
                  />
                  <Route path="/restore-pass" component={ResetPassword} />
                  <Route path="/send-activation-link" component={SendActivationLink} />
                  <Route path="/payment-success" component={PaymentSuccess} />
                  <Route path="/payment-failure" component={PaymentFailure} />
                  <Route path="/payment-status/:paymentId" component={PaymentStatus} />
                  <RouteUnsubscribed path="/no-subscription" component={NoSubscription} />
                  <Route path="/payment-plans" component={PaymentPlans} />
                  <Route path="/blocked" component={Blocked} />
                  <Route path="/buy-subscription" component={BuySubscription} />
                  <Route path="/privacy" component={Privacy} />
                  <Route path="/accept-offer/:offerId/:accessToken" component={AcceptOffer} />
                  <Route component={PageNotFound} />
                </Switch>
              </Suspense>
            </AppWrapper>
            <Modal />
          </LocaleWrapper>
        </OnlineContent>
      </ErrorBoundary>
      <OfflineContent>
        <AppOffline />
      </OfflineContent>
    </Router>
  </Provider>
);

const App = () => {
  if (process.env.REACT_APP_IS_MOBILE) return <AppContent />;
  return (
    <WorkBoxProvider>
      <AppContent />
    </WorkBoxProvider>
  );
};

const listenToWindowEvent = (name: string, mapEventToAction: any) => (dispatch: any) => {
  const handleEvent = (e: any) => {
    dispatch(mapEventToAction(e));
  };
  window.addEventListener(name, handleEvent);
};

store.dispatch(listenToWindowEvent('offline', appStatus.setStatus));

store.dispatch(listenToWindowEvent('online', appStatus.setStatus));

store.dispatch(appStatus.setStatus({ type: window.navigator.onLine ? 'online' : 'offline' }));

export default App;
