import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { invoice } from '../../../../actions';
import { Spinner } from '../../../../components/Common';
import InvoiceForm from '../AddInvoice/InvoiceForm';
import ApiService from '../../../../services/api-service';
import { InvoiceFromOffer } from '../../../../../../heyzzp-back/types';

const FromOffer = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const { offerId } = useParams<{ offerId: string }>();

  useEffect(() => {
    getOfferData();
  }, []);

  useEffect(() => {
    if (!data) return;
    dispatch(invoice.setInvoiceClient(data.client));
  }, [data]);

  const getOfferData = async () => {
    await ApiService.callFetch('GET', `offer/invoice/${offerId}`, (data: InvoiceFromOffer) => {
      setData(data);
      setLoading(false);
    });
  };

  if (loading) return <Spinner />;

  return <InvoiceForm edit invoice={data} />;
};

export { FromOffer };
