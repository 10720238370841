import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  reduxForm,
  Field,
  InjectedFormProps,
  change,
  formValueSelector,
  FieldArray,
} from 'redux-form';
import { OrderReq, Frequency, OrderDetails, OrderEvent } from 'app-types';
import { RouteComponentProps } from 'react-router';

import {
  addMonths,
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  addDays,
  addWeeks,
  differenceInCalendarMonths,
  addBusinessDays,
} from 'date-fns';

import { differenceInBusinessDays } from 'date-fns/esm';
import { ApplicationState } from '../../../../reducers';
import { orders, modal } from '../../../../actions';
import { Input, Button, DateInput } from '../../../../components/Common';
import ValidationService from '../../../../services/validation-service';
import { ButtonsContainer, FormContainer, FieldsGroup } from '../../../../components/Layout';

import { __ } from '../../../../services/translation';
import { parseInputNumber } from '../../../../utils/parse-input-number';
import RenderEvents from './RenderEvents';

import './AddForm.scss';

interface PassedProps {
  data?: OrderDetails;
}

interface Props extends RouteComponentProps, PassedProps {
  count: number;
  endAt: Date;
  nextAt: Date;
  frequency: Frequency;
  events: OrderEvent[];
  addOrder: (values: OrderReq) => void;
  editOrder: (values: OrderReq, id: string) => void;
  showModal: (content: React.ReactNode) => void;
  changeField: (form: string, fieldId: string, value: any) => void;
}

class AddOrder extends React.Component<InjectedFormProps<any, any> & Props> {
  componentDidMount() {
    const { data } = this.props;

    if (data) {
      this.updateEndDate(data.nextAt, data.count, data.frequency);
    }
  }

  private updateEndDate = (nextAt: any, count: number, frequency: Frequency) => {
    const { changeField } = this.props;

    if (frequency === Frequency.daily) {
      changeField('standingOrderForm', 'endAt', addDays(new Date(nextAt), count));
    }
    if (frequency === Frequency.workdays) {
      changeField('standingOrderForm', 'endAt', addBusinessDays(new Date(nextAt), count));
    }
    if (frequency === Frequency.weekly) {
      changeField('standingOrderForm', 'endAt', addWeeks(new Date(nextAt), count));
    }
    if (frequency === Frequency.monthly) {
      changeField('standingOrderForm', 'endAt', addMonths(new Date(nextAt), count));
    }
  };

  private updateStartDate = (endAt: any, nextAt: any, frequency: Frequency) => {
    const { changeField } = this.props;
    if (frequency === Frequency.daily) {
      changeField(
        'standingOrderForm',
        'count',
        differenceInCalendarDays(new Date(endAt), new Date(nextAt)),
      );
    }
    if (frequency === Frequency.workdays) {
      changeField(
        'standingOrderForm',
        'count',
        differenceInBusinessDays(new Date(endAt), new Date(nextAt)),
      );
    }
    if (frequency === Frequency.weekly) {
      changeField(
        'standingOrderForm',
        'count',
        differenceInCalendarWeeks(new Date(endAt), new Date(nextAt)),
      );
    }
    if (frequency === Frequency.monthly) {
      changeField(
        'standingOrderForm',
        'count',
        differenceInCalendarMonths(new Date(endAt), new Date(nextAt)),
      );
    }
  };

  // any because DateInput handles onChange differently
  private changedNextAt = (nextAt: any) => {
    const { count, frequency } = this.props;
    this.updateEndDate(nextAt, count, frequency);
  };

  // any because DateInput handles onChange differently
  private changedEndAt = (endAt: any) => {
    const { nextAt, frequency } = this.props;
    this.updateStartDate(endAt, nextAt, frequency);
  };

  private changedFrequency = (change: ChangeEvent<any>) => {
    const frequency = change.target.value as Frequency;
    const { nextAt, count } = this.props;
    this.updateEndDate(nextAt, count, frequency as Frequency);
  };

  private changedCount = (change: ChangeEvent<any>) => {
    const count = parseInt(change.target.value);

    const { nextAt, frequency } = this.props;

    this.updateEndDate(nextAt, count, frequency);
  };

  submitForm = (values: any) => {
    const { data, editOrder, addOrder } = this.props;
    const { endAt, ...orderData } = values;
    if (data) editOrder(orderData, data.id);
    else addOrder(orderData);
  };

  render() {
    const { handleSubmit, data, events, frequency } = this.props;
    return (
      <FormContainer>
        <form onSubmit={handleSubmit(this.submitForm)}>
          <Field
            label="application.name"
            placeholder="application.generator_name_placeholder"
            type="text"
            name="name"
            component={Input}
            validate={ValidationService.required}
            required
          />
          <Field
            label="application.next_at"
            name="nextAt"
            component={DateInput}
            validate={ValidationService.required}
            required
            noWeekends={frequency === Frequency.workdays}
            minToday
            onChange={this.changedNextAt}
          />
          <Field
            label="application.end_date"
            name="endAt"
            component={DateInput}
            validate={ValidationService.required}
            required
            onChange={this.changedEndAt}
            minToday
          />
          <FieldsGroup>
            <Field
              name="frequency"
              type="select"
              component={Input}
              label="application.frequency"
              options={[
                { value: Frequency.daily, name: __('application.frequency_daily') },
                { value: Frequency.workdays, name: __('application.frequency_workdays') },
                { value: Frequency.weekly, name: __('application.frequency_weekly') },
                { value: Frequency.monthly, name: __('application.frequency_monthly') },
              ]}
              validate={ValidationService.required}
              onChange={this.changedFrequency}
              required
            />
            <Field
              type="number"
              step="1"
              label="application.count"
              placeholder="application.count_placeholder"
              name="count"
              validate={ValidationService.required}
              required
              component={Input}
              onChange={this.changedCount}
              parse={parseInputNumber}
            />
          </FieldsGroup>
          <FieldArray name="events" data={events} component={RenderEvents} />
          <ButtonsContainer marginTop marginLittle>
            <Button
              type="submit"
              primary
              text={data ? 'application.save' : 'application.add'}
              disabled={events.length === 0}
              noShadow
            />
          </ButtonsContainer>
        </form>
      </FormContainer>
    );
  }
}
const selector = formValueSelector('standingOrderForm');
const mapStateToProps = (state: ApplicationState, ownProps: PassedProps) => {
  let initialData = {};
  if (ownProps.data) {
    const { id, ...data } = ownProps.data;
    initialData = {
      ...data,
      nextAt: data.nextAt || addDays(new Date(), 1),
      endAt: addDays(new Date(data.nextAt), 1) || addDays(new Date(), 1),
    };
  } else {
    initialData = {
      nextAt: addDays(new Date(), 1),
      endAt: addDays(new Date(), 2),
      frequency: Frequency.daily,
      count: 1,
    };
  }

  return {
    initialValues: initialData,
    count: selector(state, 'count'),
    endAt: selector(state, 'endAt'),
    nextAt: selector(state, 'nextAt'),
    frequency: selector(state, 'frequency'),
    events: selector(state, 'events') || [],
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      addOrder: orders.addOrder,
      showModal: modal.showModal,
      editOrder: orders.editOrder,
      changeField: change,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  // @TODO: check why it does not work with enableReinitialize
  reduxForm<any, any>({
    form: 'standingOrderForm',
    enableReinitialize: false,
    destroyOnUnmount: true,
  })(AddOrder),
);
