import React from 'react';
import { Content } from '../../../../components/Layout';
import useHeader from '../../../../hooks/useHeader';
import { __ } from '../../../../services/translation';
import { Paragraph } from '../../../../components/Common';
import ClientReminderList from './List';

const Admonitions = () => {
  useHeader('application.admonitions');
  return (
    // <Content center>
    //   <Paragraph translation={__('application.admonitions_description')} />
    // </Content>
    <ClientReminderList />
  );
};

export default Admonitions;
