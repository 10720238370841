import React from 'react';
import Swiper from 'react-id-swiper';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Page, Container } from '../../../components/Layout';
import Basic from './Basic';
import Unlimited from './Unlimited';
import './BuySubscription.scss';
import { Alert, Button } from '../../../components/Common';
import { ClientUserEntity, SomeUserEntity } from 'app-types';
import { ApplicationState } from '../../../reducers';
import { isMobileBuild } from '../../../utils/is-mobile';
import { history } from '../../../App';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  item: any;
  user: SomeUserEntity;
}

interface State {
  preventSwiping: boolean;
}

class BuySubscription extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      preventSwiping: false,
    };
  }

  componentDidMount() {
    if (isMobileBuild()) history.push('/');
  }

  render() {
    const { preventSwiping } = this.state;
    const { user } = this.props;
    return (
      <>
        <Page light>
          <Container paddingTop>
            {user &&
              (user as ClientUserEntity).isActiveSub &&
              (user as ClientUserEntity).subscription &&
              !(user as ClientUserEntity).trial && (
                <Alert type="success" text="application.you_have_subscription" />
              )}
            {user &&
              (user as ClientUserEntity).isActiveSub &&
              !(user as ClientUserEntity).subscription &&
              !(user as ClientUserEntity).trial && (
                <Alert type="notice" text="application.your_account_is_still_valid" />
              )}
            <Swiper
              threshold={20}
              containerClass="subscription-swiper"
              slidesPerView="auto"
              centeredSlides
              spaceBetween={30}
              shouldSwiperUpdate
              noSwiping={preventSwiping}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
            >
              <div className="subscription-option">
                <Unlimited />
              </div>
              <div className="subscription-option">
                <Basic />
              </div>
            </Swiper>
          </Container>
        </Page>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
});

export default connect(mapStateToProps)(BuySubscription);
