import React from 'react';
import { __ } from '../../../services/translation';
import { Page } from '../../../components/Layout';
import Dashboard from '../../../views/DashboardCommon';
import { ApiTable, Button } from '../../../components/Common';
import { InteractiveTableSchema } from '../../../utils/table-shcema-utils';
import { accountantsListForAdmin, FieldType } from 'app-tables';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../actions/modal';
import { UserDetails } from '../../../modals';
import { AccountantListForAdmin } from 'app-types';

const AccountantsListForAdmin = () => {
  const dispatch = useDispatch();
  const scheme = new InteractiveTableSchema(accountantsListForAdmin).addFieldAtTheEnd({
    name: 'application.actions',
    field: 'actions',
    mobile: true,
    type: FieldType.Custom,
    customRender: (value, field, values: AccountantListForAdmin) => (
      <>
        <Button small secondary text="application.details" click={() => goToDetails(values.id)} />
      </>
    ),
  });

  const goToDetails = (id: string) => {
    dispatch(showModal(<UserDetails id={id} accountant />));
  };

  return (
    <Page light>
      <Dashboard>
        <ApiTable apiEndpointSubUrl="user/accountant/all" scheme={scheme} />
      </Dashboard>
    </Page>
  );
};

export default AccountantsListForAdmin;
