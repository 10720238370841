import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TableSchema, TableSchemaField } from 'app-tables';
import Spinner from '../Spinner';
import SearchBar from '../../SearchBar';
import debounce from '../../../utils/debounce';
import { apiList } from '../../../actions';
import List from '../List';
import { __ } from '../../../services/translation';
import { Alert, Button } from '..';
import { ApplicationState } from '../../../reducers';
import Tabs from '../Tabs';
import { CustomSearchTab, CustomSearchType, CustomButton } from '../../../types/api-list';
import { ButtonsContainer } from '../../Layout';

interface Props {
  fetchApiListData: (url: string, method?: string) => void;
  clearApiList: () => void;
  loading: boolean;
  data: any;
  component: any;
  scheme: TableSchema;
  apiEndpointSubUrl: string;
  apiEndpointMethod?: string; // Defaults to GET
  narrow?: boolean;
  padded?: boolean;
  id?: string;
  filterBy?: any[];
  customSearchTabs?: CustomSearchTab[];
  customButtons?: CustomButton[];
  inModal?: boolean;
  noSearch?: boolean;
  additionalComponentProps?: any;
}

interface State {
  customSearchBy: string;
  customSearchQuery: string | number | null;
  addressSuffix: string | number | null;
  searchQuery: string;
  searchBy: string;
  page: number;
  sortBy: string;
  sortDirBack: boolean;
}

class ApiList<T> extends Component<Props, State> {
  private debouncedFetch: () => void;

  constructor(props: Props) {
    super(props);
    this.debouncedFetch = debounce(this.fetch, 400);
    const { scheme, customSearchTabs } = props;

    const defaultSearchField = scheme.fields.find(
      (field) => ['string', 'number'].indexOf(typeof field.defaultSearch) > -1,
    );

    this.state = {
      customSearchBy: customSearchTabs ? customSearchTabs[0].field : '',
      customSearchQuery: customSearchTabs ? customSearchTabs[0].default : '',
      searchQuery: defaultSearchField ? String(defaultSearchField.defaultSearch) : '',
      searchBy: defaultSearchField ? defaultSearchField.field : '',
      addressSuffix: null,
      page: 1,
      sortBy: '',
      sortDirBack: false,
    };
  }

  componentDidMount() {
    const { sortBy, sortDirBack } = this.state;
    const { apiEndpointSubUrl } = this.props;
    const currSort = this.getCurrentSort();

    let storageSearchBy;
    let storageSearchQuery;
    let storageSortBy;
    let storageSortDirBack;

    try {
      storageSearchBy = localStorage.getItem(`${apiEndpointSubUrl}_searchBy`);
      storageSearchQuery = localStorage.getItem(`${apiEndpointSubUrl}_searchQuery`);
      storageSortBy = localStorage.getItem(`${apiEndpointSubUrl}_sortBy`);
      storageSortDirBack = JSON.parse(
        localStorage.getItem(`${apiEndpointSubUrl}_sortDirBack`) || 'false',
      );
    } catch (e) {
      console.error(e);
    }

    const newSortBy =
      typeof storageSortBy !== 'undefined' && storageSortBy !== null
        ? storageSortBy
        : currSort.sortBy;
    const newSortDirBack =
      typeof storageSortDirBack !== 'undefined' && storageSortDirBack !== null
        ? storageSortDirBack
        : currSort.sortDirBack;

    if (newSortBy === sortBy && newSortDirBack === sortDirBack) return;

    this.setState(
      {
        searchBy: storageSearchBy || '',
        searchQuery: storageSearchQuery || '',
        sortBy: newSortBy,
        sortDirBack: newSortDirBack,
      },
      () => {
        this.fetch();
      },
    );
  }

  componentDidUpdate(prevProps: Props) {
    const { data } = this.props;
    if (data.uuid !== prevProps.data.uuid) {
      this.fetch();
    }
  }

  componentWillUnmount() {
    const { clearApiList } = this.props;
    clearApiList();
  }

  handleSortChange = (sortBy: string, sortDirBack: boolean) => {
    const { apiEndpointSubUrl } = this.props;
    const currSort = this.getCurrentSort();
    if (currSort.sortBy === sortBy && currSort.sortDirBack === sortDirBack) return;

    try {
      localStorage.setItem(`${apiEndpointSubUrl}_sortBy`, sortBy);
      localStorage.setItem(`${apiEndpointSubUrl}_sortDirBack`, JSON.stringify(sortDirBack));
    } catch (e) {
      console.error(e);
    }

    this.setState(
      {
        sortBy,
        sortDirBack,
      },
      this.fetch,
    );
  };

  private getCurrentSort(): {
    sortBy: string;
    sortDirBack: boolean;
  } {
    const { sortBy, sortDirBack } = this.state;
    const { scheme } = this.props;

    if (sortBy !== '') {
      return {
        sortBy,
        sortDirBack,
      };
    } else {
      const field = scheme.fields.find((f) => f.defaultSort === true) as TableSchemaField;
      if (!field) {
        return {
          sortBy: '',
          sortDirBack: false,
        };
      }
      return {
        sortBy: field.field,
        sortDirBack: field.oppositeSortDir !== true,
      };
    }
  }

  handlePageChange = (newPage: number) => {
    const { page } = this.state;
    if (page === newPage) return;
    this.setState(
      {
        page: newPage,
      },
      this.fetch,
    );
  };

  handleSearchChange = (searchBy: string, searchQuery: string) => {
    const { apiEndpointSubUrl } = this.props;
    const { searchBy: searchByNow, searchQuery: searchQueryNow } = this.state;
    if (searchBy === searchByNow && searchQuery === searchQueryNow) return;

    const newSearchQuery = searchBy === searchByNow ? searchQuery : '';

    try {
      localStorage.setItem(`${apiEndpointSubUrl}_searchBy`, searchBy);
      localStorage.setItem(`${apiEndpointSubUrl}_searchQuery`, newSearchQuery);
    } catch (e) {
      console.error(e);
    }

    this.setState(
      {
        searchBy,
        searchQuery: newSearchQuery,
      },
      this.debouncedFetch,
    );
  };

  handleCustomSearchChange = (
    field: string,
    value: string | number | null,
    type: CustomSearchType,
  ) => {
    if (type === CustomSearchType.MODIFY_URL_ADDRESS) {
      this.setState(
        {
          page: 1,
          addressSuffix: value,
        },
        this.fetch,
      );
    } else {
      this.setState(
        {
          page: 1,
          customSearchBy: field,
          customSearchQuery: value,
        },
        this.fetch,
      );
    }
  };

  private async fetch() {
    const { fetchApiListData, apiEndpointMethod } = this.props;
    await fetchApiListData(this.buildUrl(), apiEndpointMethod);
  }

  private buildUrl(): string {
    const { apiEndpointSubUrl } = this.props;
    const {
      searchBy,
      searchQuery,
      page,
      sortBy,
      sortDirBack,
      customSearchBy,
      customSearchQuery,
      addressSuffix,
    } = this.state;

    const searchParams = [];
    if (searchBy && searchQuery !== null && typeof searchQuery !== 'undefined') {
      searchParams.push(searchBy);
    }
    if (customSearchBy && customSearchQuery !== null && typeof customSearchQuery !== 'undefined') {
      searchParams.push(customSearchBy);
    }

    const searchQueryParams = [];
    if (searchBy && searchQuery !== null && typeof searchQuery !== 'undefined') {
      searchQueryParams.push(searchQuery);
    }
    if (customSearchBy && customSearchQuery !== null && typeof customSearchQuery !== 'undefined') {
      searchQueryParams.push(customSearchQuery);
    }

    return `${apiEndpointSubUrl}${
      addressSuffix !== null ? `/${addressSuffix}` : ''
    }?${new URLSearchParams({
      searchBy: searchParams.join(','),
      searchQuery: searchQueryParams.join(','),
      page,
      sortBy,
      sortDirBack,
    } as any)}`;
  }

  render() {
    const {
      page,
      searchQuery,
      searchBy,
      sortBy,
      sortDirBack,
      customSearchQuery,
      addressSuffix,
    } = this.state;
    const {
      loading,
      scheme,
      narrow,
      filterBy,
      data,
      component,
      id,
      padded,
      customSearchTabs,
      inModal,
      noSearch,
      additionalComponentProps,
      customButtons,
    } = this.props;
    let list;

    if (loading) {
      list = <Spinner overlay transparent />;
    } else if (data.countTotal === 0) {
      list = (
        <>
          <Alert type="error" simple text="application.nothing_to_show." />
        </>
      );
    } else if (filterBy && !loading) {
      const ids = filterBy.map((item: any) => item.id);
      const filteredData = () => {
        const filteredItems = data.items.filter((item: any) => !ids.includes(item.id));
        return {
          countTotal: filteredItems.length,
          items: filteredItems,
          loading,
        };
      };
      list = (
        <List
          data={filteredData()}
          scheme={scheme}
          initialPage={page}
          onPageChange={this.handlePageChange}
          component={component}
          additionalComponentProps={additionalComponentProps}
        />
      );
    } else {
      list = (
        <>
          <List
            data={data}
            scheme={scheme}
            initialPage={page}
            onPageChange={this.handlePageChange}
            component={component}
            additionalComponentProps={additionalComponentProps}
          />
        </>
      );
    }

    return (
      <>
        {data && !noSearch && (
          <SearchBar
            allFields={scheme.fields}
            search={{
              searchBy,
              searchQuery,
            }}
            sort={{
              sortBy,
              sortDirBack,
            }}
            onSearchChange={this.handleSearchChange}
            onSortChange={this.handleSortChange}
            inModal={inModal}
            padded={padded}
          />
        )}
        {customButtons && (
          <ButtonsContainer>
            {customButtons.map((customButton: CustomButton) => (
              <>
                {!customButton.isHidden && (
                  <Button text={__(customButton.translateKey)} click={customButton.onClick} />
                )}
              </>
            ))}
          </ButtonsContainer>
        )}
        {customSearchTabs &&
          customSearchTabs.map((customSearch: CustomSearchTab) => (
            // <ButtonsContainer>
            <Tabs
              activeTab={customSearchQuery || addressSuffix}
              tabs={customSearch.options}
              start
              chooseTab={(value: string | number | any) =>
                this.handleCustomSearchChange(customSearch.field, value, customSearch.type)
              }
            />
            // </ButtonsContainer>
          ))}
        <div className="api-list">{list}</div>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  data: state.apiList,
  loading: state.apiList.loading,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { fetchApiListData: apiList.fetchApiListData, clearApiList: apiList.clearApiList },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ApiList);
