import ApiService from '../services/api-service';
import { modal, notifications, apiList } from '../actions';
import { history } from '../App';

export const getMileageSchemeList = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_MILEAGE_SCHEME_LIST_SUCCESS',
    'GET_MILEAGE_SCHEME_LIST_FAILED',
    null,
    null,
    'mileage-schema/list',
    'LOADING_MILEAGE_SCHEME_LIST',
  );
};

export const getSingleMileageScheme = (mileageId: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_SINGLE_MILEAGE_SCHEME_SUCCESS',
    'GET_SINGLE_MILEAGE_SCHEME_FAILED',
    null,
    null,
    `mileage-schema/detail/${mileageId}`,
    'LOADING_SINGLE_MILEAGE_SCHEME',
  );
};

export const deleteSingleMileageScheme = (mileageId: string) => async (dispatch: any) => {
  await ApiService.delete(
    dispatch,
    'DELETE_SINGLE_MILEAGE_SCHEME_SUCCESS',
    'DELETE_SINGLE_MILEAGE_SCHEME_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(apiList.refreshApiList());
      dispatch(notifications.successNotification('application.mileage_deleted'));
      history.push('/dashboard/mileage-schemes');
    },
    null,
    `mileage-schema/${mileageId}`,
    'LOADING_SINGLE_MILEAGE_SCHEME',
  );
};
