import Registration from './Registration';
import Login from './Login';
import ResetPassword from './ResetPassword';
import PageNotFound from './PageNotFound';
import Welcome from './Welcome';
import DashboardClient from './DashboardClient';
import PaymentSuccess from './PaymentSuccess';
import PaymentFailure from './PaymentFailure';
import NoSubscription from './Payments/NoSubscription';
import PaymentPlans from './Payments/PaymentPlans';
import BuySubscription from './Payments/BuySubscription';
import DashboardAdmin from './DashboardAdmin';
import KvkData from './Registration/KvkData';
import EnterKvk from './Registration/EnterKvk';
import RegisterAccountant from './RegisterAccountant';
import DashboardInactive from './DashboardInactive';
import Error from './Error';
import Blocked from './Blocked';

import PaymentStatus from './PaymentStatus';
import AcceptOffer from './AcceptOffer';

export {
  Registration,
  Login,
  ResetPassword,
  PageNotFound,
  Welcome,
  DashboardClient,
  DashboardAdmin,
  PaymentSuccess,
  PaymentFailure,
  NoSubscription,
  PaymentPlans,
  BuySubscription,
  KvkData,
  EnterKvk,
  DashboardInactive,
  Error,
  Blocked,
  PaymentStatus,
  RegisterAccountant,
  AcceptOffer
};
