import { SomeUserEntity, UserRole } from 'app-types';
import ApiService from '../services/api-service';
import { modal, notifications, apiList, apiTable } from '.';
import { history } from '../App';
import { refreshApiTable } from './api-table';
import { getCheatersList } from './client';
import { getPendingInvitationsCount } from './pendingInvitations';

export const getUserDetails = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'FETCH_USER_DETAILS_SUCCESS',
    'FETCH_USER_DETAILS_FAILED',
    (res: SomeUserEntity) => {
      if (res.role === UserRole.Accountant) {
        dispatch(getPendingInvitationsCount());
      }
    },
    null,
    'user/details',
    'LOADING_FETCH_USER_DETAILS',
  );
};

export const validateKvk = (kvk: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'VALIDATE_KVK_SUCCESS',
    'VALIDATE_KVK_FAILED',
    null,
    null,
    `user/prepare-add-user-form-for-kvk/${kvk}`,
    'LOADING_VALIDATE_KVK',
  );
};

export const getUserForAdmin = (id: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'FETCH_USER_DETAILS_FOR_ADMIN_SUCCESS',
    'FETCH_USER_DETAILS_FOR_ADMIN_FAILED',
    null,
    null,
    `user/one/${id}`,
    'LOADING_USER_FOR_ADMIN',
  );
};

export const deleteUser = (id: string) => async (dispatch: any) => {
  await ApiService.delete(
    dispatch,
    'DELETE_USER_FOR_ADMIN_SUCCESS',
    'DELETE_USER_FOR_ADMIN_FAILURE',
    () => {
      dispatch(notifications.successNotification('application.user_blocked.'));
      dispatch(modal.hideModal());
      window.location.reload();
    },
    null,
    `user/${id}`,
    'DELETE_USER_FOR_ADMIN',
  );
};

export const unblockUser = (id: string) => async (dispatch: any) => {
  await ApiService.patch(
    dispatch,
    'UNLBLOCK_USER_FOR_ADMIN_SUCCESS',
    'UNBLOCK_USER_FOR_ADMIN_FAILURE',
    () => {
      dispatch(notifications.successNotification('application.successfully_unblocked_user'));
      dispatch(modal.hideModal());
      dispatch(apiTable.refreshApiTable());
    },
    null,
    `user/unblock/${id}`,
    'LOADING_USER_FOR_ADMIN',
  );
};

export const markAsCheater = (id: string, isCheater: boolean) => async (dispatch: any) => {
  await ApiService.patch(
    dispatch,
    'MARK_CHEATER_USER_FOR_ADMIN_SUCCESS',
    'MARK_CHEATER_USER_FOR_ADMIN_FAILURE',
    () => {
      if (isCheater)
        dispatch(notifications.successNotification('application.successfully_marked_as_cheater'));
      else {
        dispatch(
          notifications.successNotification('application.successfully_marked_as_not_cheater'),
        );
        dispatch(getCheatersList());
      }
      dispatch(modal.hideModal());
      dispatch(apiTable.refreshApiTable());
    },
    null,
    `user/mark-as-cheater/${id}`,
    'LOADING_USER_FOR_ADMIN',
    { isCheater },
  );
};

export const deleteFromCheaterList = (userId: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'DELETE_USER_FROM_CHEATER_LIST_SUCCESS',
    'DELETE_USER_FROM_CHEATER_LIST_FAILED',
    () => {
      dispatch(notifications.successNotification('application.deleted.'));
      dispatch(modal.hideModal());
    },
    null,
    `user/no-cheater/${userId}`,
  );
};

export const getUserPayments = (userId: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_USER_PAYMENTS_SUCCESS',
    'GET_USER_PAYMENTS_FAILED',
    null,
    null,
    `payment/list/${userId}`,
    'LOADING_USER_PAYMENTS',
  );
};

export const extendSubscription = (userId: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'EXTEND_SUBSCRIPTION_SUCCESS',
    'EXTEND_SUBSCRIPTION_FAILED',
    () => {
      dispatch(notifications.successNotification('application.subscription_extended'));
      dispatch(modal.hideModal());
      dispatch(refreshApiTable());
    },
    null,
    `payment/give-free-for-month/${userId}`,
    'LOADING_EXTEND_SUBSCRIPTION',
  );
};

export const receiveKvkFormData = (form: any) => ({
  type: 'RECEIVE_KVK_FORM_DATA',
  payload: form,
});

export const confirmDataChange = (id: string) => async (dispatch: any) => {
  await ApiService.patch(
    dispatch,
    'CONFIRM_USER_DATA_CHANGE_SUCCESS',
    'CONFRIM_USER_DATA_CHANGE_FAILED',
    () => {
      dispatch(notifications.successNotification('application.user_data_was_edited'));
      dispatch(modal.hideModal());
      dispatch(apiList.refreshApiList());
      dispatch(apiTable.refreshApiTable());
    },
    null,
    `user/confirm-change-data/${id}`,
    'LOADING_USER_CONFIRMATION',
    {},
  );
};

export const receiveKvk = (values: any) => {
  history.push(`/inactive/data/${values.kvk}`);
  return {
    type: 'RECEIVE_KVK_NUMBER',
    payload: values.kvk,
  };
};
