import React, { useState } from 'react';

import TimePickerNew from '../../../modals/TimePickerNew';
import { __ } from '../../../services/translation';
import RequiredIndicator from '../RequiredIndicator';
import './TimePickerInput.scss';
import { parseTimeInput } from '../../../utils/parse-time-input';

interface Props {
  input: any;
  meta: any;
  label: any;
  required?: boolean;
}

const TimePickerInput = (props: Props) => {
  const { label, input, meta, required } = props;

  const [opened, setOpened] = useState<boolean>(false);

  const inputLabel = label && (
    <label htmlFor={input.name}>
      {__(label)} {required && <RequiredIndicator />}
    </label>
  );
  return (
    <>
      <div className="time-picker input-container">
        <div className="input-wrapper">
          {inputLabel}
          <input
            required={required}
            type="text"
            {...input}
            onChange={(e: any) => input.onChange(e.target.value)}
            placeholder="00:00"
            value={input.value}
            onFocus={() => setOpened(true)}
            onBlur={(e: any) => {
              input.onChange(parseTimeInput(e.target.value));
              setOpened(false);
            }}
          />
        </div>
        {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
        {opened && <TimePickerNew change={input.onChange} value={input.value} />}
      </div>
    </>
  );
};

export default TimePickerInput;
