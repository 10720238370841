import React, { useState } from 'react';
import { GetClientKvKRes, ClientEntity, ClientType } from 'app-types';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { AddressAutosuggestionResponse } from '../../../types/autosuggestion';
import { FormContainer, ButtonsContainer } from '../../Layout';
import {
  ClientAutosuggestion,
  Button,
  AddressAutosuggestion,
  RequiredIndicator,
  Spinner,
  FieldsMapper,
} from '../../Common';

import ApiService from '../../../services/api-service';
import { formOnTranslateString } from '../../../utils/trans-form';
import { __ } from '../../../services/translation';

import { notifications, modal } from '../../../actions';
import { history } from '../../../App';

interface Props {
  isModal?: boolean;
  callback?: (data: ClientEntity) => void;
}

const ClientForm = ({ isModal, callback }: Props) => {
  const [type, setType] = useState<ClientType>(ClientType.COMPANY);
  const dispatch = useDispatch();
  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  const selectOption = (option: GetClientKvKRes) => {
    itfForm.api.setValues({
      name: option.name,
      kvk: option.kvk,
      city: option.city,
      country: option.country,
      address: option.address,
    });
  };

  const selectSugestion = (sugestion: AddressAutosuggestionResponse) => {
    itfForm.api.setValue('address', sugestion.first);
    if (sugestion.second) itfForm.api.setValue('city', sugestion.second);
    if (sugestion.third) itfForm.api.setValue('country', sugestion.third);
    const changes: { [key: string]: string } = { address: sugestion.first };

    if (sugestion.second) changes.city = sugestion.second;
    if (sugestion.third) changes.country = sugestion.third;

    itfForm.api.setValues(changes);
  };

  const registerFormCallbacks = () => {
    window.globalForms['client-form'].valueChangedClb = (newValues: ClientEntity) => {
      setType(Number(newValues.type));
    };
  };
  return (
    <>
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="client-form"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm('client/form/add-item/')}
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm('client/new', values, 'POST')
          }
          onSavedSuccessfully={(data: ClientEntity) => {
            if (isModal) {
              dispatch(modal.hideModal());
              if (callback) callback(data);
            } else {
              history.push('/dashboard/clients-and-contacts/clients/list');
            }
            dispatch(notifications.successNotification('application.client_added.'));
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text="application.save"
                primary
              />
            </ButtonsContainer>
          }
          onTranslateString={(string: string) => formOnTranslateString(string)}
          onRenderFullField={(
            field: any,
            errors: any,
            fieldHtmlId: any,
            currentValue: any,
            onChange: any,
            onFocus: any,
            onBlur: any,
          ) => {
            if (field.id === 'name') {
              return (
                <ClientAutosuggestion
                  required={field.required}
                  label={field.title}
                  input={{
                    value: currentValue,
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    onChange,
                  }}
                  kvkOnly
                  onOptionSelect={selectOption}
                  placeholder={field.placeholder}
                  meta={{
                    touched: errors.length > 0,
                    error: errors.length
                      ? __(errors[0].messageWithPlaceholders, errors[0].params)
                      : null,
                  }}
                />
              );
            } else if (field.id === 'address') {
              return (
                <AddressAutosuggestion
                  required={type === ClientType.COMPANY}
                  label={field.title}
                  input={{
                    value: currentValue,
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    onChange,
                  }}
                  onSugestionSelect={selectSugestion}
                  splitted
                  placeholder={field.placeholder}
                  meta={{
                    touched: errors.length > 0,
                    error: errors.length
                      ? __(errors[0].messageWithPlaceholders, errors[0].params)
                      : null,
                  }}
                />
              );
            } else if (field.id === 'firstName') {
              return FieldsMapper(
                { ...field, required: type === ClientType.PRIVATE },
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              );
            } else if (field.id === 'email') {
              return FieldsMapper(
                { ...field, required: type === ClientType.COMPANY },
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              );
            } else if (field.id === 'city') {
              return FieldsMapper(
                { ...field, required: type === ClientType.COMPANY },
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              );
            } else if (field.id === 'kvk') {
              return FieldsMapper(
                field,
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              );
            } else {
              return FieldsMapper(
                field,
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              );
            }
          }}
          registerAsGlobalForm
          onFormGloballyRegistered={registerFormCallbacks}
        />
      </FormContainer>
    </>
  );
};

export default ClientForm;
