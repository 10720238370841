import { VofStatus } from "./vof-entity";

export enum UserVofStatus {
  PARENT,
  CHILD,
  NONE
}

export interface UserVofStatusResponse {
  status: UserVofStatus;
  size?: number;
  parent?: { email: string; userId: string; vofId: string; status: VofStatus };
  children?: {
    email: string;
    userId: string;
    vofId: string;
    status: VofStatus;
  }[];
}
