import React, { useEffect } from 'react';
import { TableSchema } from 'app-tables';
import { InteractiveTableSchema } from '../../../../../utils/table-shcema-utils';
import { ApiTable } from '../../../../../components/Common';
import { useParams } from 'react-router';
import { sentInvoiceHistory as sentInvoiceHistoryTable } from 'app-tables';
import { modal } from '../../../../../actions';
import { useDispatch } from 'react-redux';
import { DownloadService } from '../../../../../services/download-service';
import { SentInvoiceHistoryRes } from 'app-types';
import { AddAttachmentToSentInvoiceHistory } from '../../../../../modals';

const SentInvoicesHistory = () => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();

  const scheme: TableSchema = new InteractiveTableSchema(sentInvoiceHistoryTable).addFieldAtTheEnd({
    name: 'application.actions',
    field: 'editButton',
    mobile: true,
    buttons: [
      {
        name: 'application.add_attachment',
        type: 'secondary',
        click: (item: SentInvoiceHistoryRes) => {
          dispatch(modal.showModal(<AddAttachmentToSentInvoiceHistory id={item.id} />));
        },
      },

      {
        name: 'application.download',
        type: 'first',
        click: async (item: SentInvoiceHistoryRes) => {
          await DownloadService.download(`sent-invoice-history/${item.id}`);
        },
      },
    ],
  });

  return (
    <>
      <ApiTable apiEndpointSubUrl={`sent-invoice-history/list/${id}`} scheme={scheme} />
    </>
  );
};

export default SentInvoicesHistory;
