import React from 'react';
import { __ } from '../../../../../services/translation';
import { ListItem } from '../../../../../components/Common';
import { formatDateTime } from '../../../../../utils/formatDateTime';

interface Props {
  item: any;
}

const LogListItem = ({ item }: Props) => {
  return (
    <div>
      <ListItem
        header={`${__('application.request_about_settlement_has_been_sent', {
          date: formatDateTime(item.sentDate),
        })}`}
        buttons={[]}
        noShadow
        singleRow
      />
    </div>
  );
};

export default LogListItem;
