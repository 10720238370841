import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import AddCost from './Add/AddCost';
import CostList from './List';
import SingleCost from './SingleCost';
import AddCostFromScheme from './Add/AddFromScheme';

const costTabs = [
  {
    title: 'application.cost_list',
    link: '/dashboard/costs/list',
    icon: 'costs',
  },
  {
    title: 'application.add_cost',
    link: '/dashboard/costs/add',
    icon: 'plus',
  },
  {
    title: 'application.cost_schemes',
    link: '/dashboard/costs-schemes/list',
    icon: 'list',
  },
  {
    title: 'application.categories',
    link: '/dashboard/categories/list',
    icon: 'list',
    indicator: true,
  },
];

const Costs = () => (
  <>
    <Page light withNavigation>
      <Dashboard navigation={costTabs}>
        <Switch>
          <Route
            exact
            path="/dashboard/costs"
            render={() => <Redirect to="/dashboard/costs/list" />}
          />
          <Route exact path="/dashboard/costs/add/usescheme/:id" component={AddCostFromScheme} />
          <Route exact path="/dashboard/costs/add" component={AddCost} />
          <Route exact path="/dashboard/costs/list" component={CostList} />
          <Route exact path="/dashboard/costs/:id" component={SingleCost} />
        </Switch>
      </Dashboard>
    </Page>
  </>
);

export default Costs;
