import React from 'react';
import { TableSchema, FieldType, paymentsForAdmin } from 'app-tables';
import { FullPaymentRes } from 'app-types';
import { InteractiveTableSchema } from '../../../utils/table-shcema-utils';
import { AdminPaymentButtons, ApiTable, Button } from '../../../components/Common';
import useHeader from '../../../hooks/useHeader';
import ApiService from '../../../services/api-service';
import { __ } from '../../../services/translation';
import { ButtonsContainer, Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';

const AllUserPayments = () => {
  useHeader('application.payment_list');

  const scheme: TableSchema = new InteractiveTableSchema(paymentsForAdmin).addFieldAtTheEnd({
    name: 'application.actions',
    field: 'editButton',
    mobile: true,
    type: FieldType.Custom,
    customRender: (value, field, values: FullPaymentRes) => (
      <AdminPaymentButtons payment={values} />
    ),
  });

  const getSubscriptioners = () => {
    ApiService.callFetch('GET', 'payment/get-subscriptioners');
  };

  const testRecurring = () => {
    ApiService.callFetch('GET', 'payment/test-subscriptions');
  };

  return (
    <Page light>
      <Dashboard>
        <ButtonsContainer>
          <Button primary text="TEST (zwraca listę subbskrypcji)" click={getSubscriptioners} />
          <Button secondary text="TEST (GENERUJE SUBSKRYPCJE!)" click={testRecurring} />
        </ButtonsContainer>
        <ApiTable apiEndpointSubUrl="payment/list/all" scheme={scheme} />
      </Dashboard>
    </Page>
  );
};

export default AllUserPayments;
