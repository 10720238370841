import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import NotificationList from './List';
import NewNotification from './New';

const notificationTabs = [
  {
    title: 'application.notification_list',
    link: '/admin/notifications/list',
    icon: 'list',
  },
  {
    title: 'application.new_notification',
    link: '/admin/notifications/add',
    icon: 'plus',
  },
];

const Notifications = () => (
  <Page light withNavigation>
    <Dashboard navigation={notificationTabs}>
      <Switch>
        <Route exact path="/admin/notifications" render={() => <Redirect to="/admin/notifications/list" />} />
        <Route exact path="/admin/notifications/list" component={NotificationList} />
        <Route exact path="/admin/notifications/add" component={NewNotification} />
      </Switch>
    </Dashboard>
  </Page>
);
export default Notifications;
