import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { statistics } from '../../../../../actions';
import { __ } from '../../../../../services/translation';
import { Spinner, InfoParagraph, Subheader } from '../../../../../components/Common';
import { ApplicationState } from '../../../../../reducers';
import { StatisticPeriod, StatisticsSummaryItem } from '../../../../../types/statistics';
import { formatDistance } from '../../../../../utils/format-distance';
import { formatPrice } from '../../../../../utils/format-price';
import Chart from '../Chart';
import Summary from '../Summary';

interface Props {
  getMileageForCharts: () => void;
  getWeeklyMileage: (year?: number) => void;
  getFullDistance: (year?: number) => void;
  getMileageFinancialResult: () => void;
  mileageCharts: any;
  weeklyMileage: number;
  fullDistance: any;
  mileageFinancialResult: any;
  loadingMileageFinancialResult: any;
  loadingMileageCharts: boolean;
  loadingWeeklyMileage: boolean;
  loadingFullDistance: boolean;
  loadingMileage: boolean;
  period: StatisticPeriod;
}

class MileageStats extends Component<Props> {
  componentDidMount() {
    const { getMileageFinancialResult, getWeeklyMileage, getMileageForCharts, getFullDistance } = this.props;
    const year = this.getCurrentYear();
    getMileageForCharts();
    getWeeklyMileage(year);
    getFullDistance(year);
    getMileageFinancialResult();
  }

  componentDidUpdate(prevProps: Props) {
    const { getWeeklyMileage, getFullDistance } = this.props;
    const { period } = this.props;
    if (period !== prevProps.period) {
      if (period === StatisticPeriod.THIS_YEAR) {
        const year = this.getCurrentYear();
        getWeeklyMileage(year);
        getFullDistance(year);
      } else {
        getWeeklyMileage();
        getFullDistance();
      }
    }
  }

  private getCurrentYear = () => new Date().getFullYear();

  render() {
    const { period, loadingMileage, weeklyMileage, fullDistance, mileageCharts, mileageFinancialResult } = this.props;

    const summary: StatisticsSummaryItem[] = [
      {
        label: 'application.average/week',
        amount: formatDistance(weeklyMileage),
      },
      {
        label: period === StatisticPeriod.THIS_YEAR ? __('application.this_year') : __('application.all_time'),
        amount: formatDistance(fullDistance),
      },
    ];

    if (loadingMileage) return <Spinner />;

    return (
      <>
        <Subheader text="application.mileage_statistics" />

        <Summary data={summary} />

        <Subheader level={2} text="application.distance_from_last_6_weeks" />

        <Chart data={mileageCharts} />

        <InfoParagraph name="application.week_of_the_year" text="km" />
        {mileageCharts
          ? mileageCharts.map((week: any) => <InfoParagraph name={week.week} text={formatDistance(week.value)} />)
          : null}

        <Subheader level={2} text={__('application.mileage')} paddingTop />

        {mileageFinancialResult
          ? mileageFinancialResult.map((result: any) => (
            <InfoParagraph name={result.year} text={formatPrice(result.value)} />
          ))
          : null}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  mileageCharts: state.statistics.mileageCharts,
  loadingMileageCharts: state.statistics.loadingMileageCharts,
  weeklyMileage: state.statistics.weeklyMileage,
  loadingWeeklyMileage: state.statistics.loadingWeeklyMileage,
  fullDistance: state.statistics.fullDistance,
  loadingFullDistance: state.statistics.loadingFullDistance,
  loadingMileageFinancialResult: state.statistics.loadingMileageFinancialResult,
  mileageFinancialResult: state.statistics.mileageFinancialResult,
  loadingMileage: state.statistics.loadingMileage,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getMileageForCharts: statistics.getMileageForCharts,
      getWeeklyMileage: statistics.getWeeklyMileage,
      getFullDistance: statistics.getFullDistance,
      getMileageFinancialResult: statistics.getMileageFinancialResult,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MileageStats);
