import { FieldType, TableSchema } from "../typings";

export const contactsForUser: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: "application.first_name",
      field: "firstName",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.last_name",
      field: "lastName",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.email",
      field: "email",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Email
    },
    {
      name: "application.phone",
      field: "phone",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Tel
    }
  ]
};
