import React from 'react';
import { __ } from '../../../../services/translation';
import './Suggestion.scss';
import { GetCompanyKvKRes, SimpleKvkResponse } from 'app-types';

interface Props {
  item: SimpleKvkResponse;
  onSelect: (data: SimpleKvkResponse) => void;
}

const Suggestion = ({ item, onSelect }: Props) => {
  return (
    <li
      role="button"
      onClick={() => {
        onSelect(item);
      }}
      className="company-suggestion-wrapper"
    >
      <div>
        <span className="company-suggestion-name">{item.name}</span>
        <div className="company-suggestion-address">{item.address}</div>
      </div>
    </li>
  );
};

export default Suggestion;
