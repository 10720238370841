import React from 'react';
import { connect } from 'react-redux';
import { SomeUserEntity } from 'app-types';
import { ApplicationState } from '../../../../../reducers';
import './CompanyData.scss';

interface Props {
  user: any;
}

const CompanyData = ({ user }: Props) => (
  <div className="company-data">
    <p>{user.companyName ? user.companyName : null}</p>
    <p>
      <p className="kvk-label">KVK:</p>
      {user.kvk}
    </p>
  </div>
);

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
});

export default connect(mapStateToProps)(CompanyData);
