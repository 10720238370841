import React from 'react';
import './Logo.scss';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

interface Props {
  animate?: boolean;
  interval?: boolean;
  big?: boolean;
  dark?: boolean;
  center?: boolean;
}

class Logo extends React.Component<Props> {
  render() {
    const { big, animate, dark, center } = this.props;
    return (
      <Link to="/">
        <svg version="1.1" viewBox="0 0 300 94" className={clsx('animated-logo', { big, dark, center })}>
          <g transform="matrix(1.3333 0 0 -1.3333 0 793.7)">
            <g transform="translate(-205.9 310.05)" clipPath="url(#a)">
              <g transform="matrix(.53852 0 0 .53852 220.22 267.16)">
                <path
                  className="hand-background"
                  d="m0 0c12.075 27.933 44.507 40.787 72.44 28.713 27.933-12.075 40.788-44.508 28.712-72.441-12.074-27.932-44.507-40.788-72.44-28.712-27.932 12.075-40.787 44.507-28.712 72.44"
                />
              </g>
              <g transform="matrix(.53852 0 0 .53852 247 264.02)">
                <path
                  d="m0 0c-0.9 3.5-4.5 5.7-8 4.8s-5.7-4.5-4.8-8c9.4-37.8 8.5-63.1-2.7-71.2-12.8-9.3-38.6 3-47.5 8.5-3.1 1.9-7.1 1-9.1-2.1-1.9-3.1-1-7.1 2.1-9.1 1.2-0.7 22.4-13.8 42.5-13.8 7 0 13.8 1.6 19.7 5.8 16.6 12 19.1 39.9 7.8 85.1"
                  fill="#151515"
                />
              </g>
              <g transform="matrix(.53852 0 0 .53852 215.22 258.69)">
                <path
                  d="m0 0 28.5-13.6c0.9-0.4 1.9-0.599 2.8-0.599 2.5 0 4.8 1.399 5.9 3.699 1.6 3.301 0.2 7.2-3.1 8.801l-28.4 13.599c-3.3 1.6-7.2 0.201-8.8-3.099-1.6-3.301-0.2-7.2 3.1-8.801"
                  fill="#151515"
                />
              </g>
              <g transform="matrix(.53852 0 0 .53852 207.9 249.64)">
                <path
                  d="m0 0 32.8-15.6c0.9-0.4 1.9-0.6 2.8-0.6 2.5 0 4.8 1.4 5.9 3.7 1.6 3.3 0.2 7.2-3.1 8.8l-32.7 15.6c-3.3 1.6-7.2 0.201-8.8-3.1-1.5-3.3-0.1-7.199 3.1-8.8"
                  fill="#151515"
                />
              </g>
              <g transform="matrix(.53852 0 0 .53852 220.23 231.22)">
                <path
                  d="m0 0c0.9-0.399 1.9-0.6 2.8-0.6 2.5 0 4.8 1.401 5.9 3.701 1.6 3.299 0.2 7.2-3.1 8.799l-22 10.5c-3.3 1.6-7.2 0.201-8.8-3.099-1.4-3.301 0-7.301 3.3-8.801z"
                  fill="#151515"
                />
              </g>
              <g transform="matrix(.53852 0 0 .53852 283.22 224.4)">
                <path
                  d="m0 0v26.493c0 6.038-3.151 7.923-8.107 7.923-4.417 0-7.931-2.517-9.912-5.136v-29.28h-11.445v60.102h11.445v-22.259c2.791 3.338 8.199 6.757 15.319 6.757 9.549 0 14.145-5.224 14.145-13.698v-30.902z"
                  fill="#231f20"
                />
              </g>
              <g transform="matrix(.53852 0 0 .53852 306.14 243.52)">
                <path
                  d="m0 0c-7.12 0-10.183-5.319-10.722-9.641h21.445c-0.272 4.146-3.155 9.641-10.723 9.641m-22.527-13.697c0 12.611 9.281 22.795 22.527 22.795 12.975 0 21.805-9.729 21.805-23.969v-2.517h-32.347c0.718-5.503 5.135-10.094 12.523-10.094 3.694 0 8.83 1.621 11.625 4.328l5.044-7.396c-4.325-3.962-11.174-6.03-17.931-6.03-13.246 0-23.246 8.914-23.246 22.883"
                  fill="#231f20"
                />
              </g>
              <g transform="matrix(.53852 0 0 .53852 322.46 220.76)">
                <path
                  d="m0 0c0.99-0.455 2.52-0.719 3.603-0.719 2.974 0 4.955 0.807 6.038 3.155l1.621 3.69-17.66 44.152h12.252l11.354-30.278 11.354 30.278h12.343l-20.454-50.637c-3.243-8.204-9.01-10.368-16.489-10.543-1.351 0-4.233 0.271-5.675 0.631z"
                  fill="#231f20"
                />
              </g>
              <g transform="matrix(.53852 0 0 .53852 346.25 224.4)">
                <path
                  d="m0 0v10.359l28.382 38.474h-28.382v11.269h45.139v-10.271l-28.382-38.569h29.013v-11.262z"
                  className="secondary-letter"
                />
              </g>
              <g transform="matrix(.53852 0 0 .53852 374.99 224.4)">
                <path
                  d="m0 0v10.359l28.382 38.474h-28.382v11.269h45.139v-10.271l-28.382-38.569h29.014v-11.262z"
                  className="secondary-letter"
                />
              </g>
              <g transform="matrix(.53852 0 0 .53852 423.87 246.33)">
                <path
                  className="secondary-letter"
                  d="m0 0c0 5.048-3.785 8.107-8.829 8.107h-13.607v-16.213h13.607c5.044 0 8.829 3.154 8.829 8.106m-35.23-40.726v60.102h28.114c13.063 0 20.183-8.833 20.183-19.376 0-10.447-7.209-19.368-20.183-19.368h-15.32v-21.358z"
                />
              </g>
            </g>
          </g>
        </svg>
      </Link>
    );
  }
}

export default Logo;
