import React from 'react';
import { Link } from 'react-router-dom';
import { CategoryRes } from 'app-types';
import './CategoryTile.scss';
import clsx from 'clsx';

interface Props {
  category: CategoryRes;
}

const CategoryTile = ({ category }: Props) => {
  return (
    <div className="category-tile-container">
      <Link to={`/dashboard/category/${category.id}`} className="category-tile">
        <div className="category-content">
          <header
            className={clsx('category-header-container', {
              'with-description': category.description,
            })}
          >
            <h2 className="category-header">{category.title}</h2>
          </header>

          {category.description && (
            <div className="category-description">
              <p>{category.description}</p>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default CategoryTile;
