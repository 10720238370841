import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CostEntity, TaxRate } from 'app-types';
import { arrayPush, formValueSelector } from 'redux-form';
import uuid from 'uuid';
import { modal } from '../../../../../actions';
import { Button } from '../../../../../components/Common';
import AddCostToInvoice from '../../../../../modals/AddCostToInvoice';
import { __ } from '../../../../../services/translation';
import { SelectCostType } from '../../../../../modals';
import { ApplicationState } from '../../../../../reducers';

interface Props {
  costs: CostEntity[];
  showModal: (content: React.ReactNode) => void;
  hideModal: () => void;
  addToCosts: (form: string, field: string, value: any) => void;
  inline?: boolean;
  callback?: (cost: CostEntity) => void;
}

class AddCostButton extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  private openCostModal = () => {
    const { showModal, costs, addToCosts, callback } = this.props;
    showModal(
      <AddCostToInvoice
        callback={(cost: CostEntity) => {
          addToCosts('invoiceForm', 'costs', cost);
          if (callback) callback(cost);
        }}
        invoiceCosts={costs}
      />,
    );
  };

  private selectCostType = () => {
    const { showModal, hideModal, addToCosts } = this.props;
    showModal(
      <SelectCostType
        selectCost={() => this.openCostModal()}
        newCost={() => {
          addToCosts('invoiceForm', 'costs', {
            date: new Date(),
            taxRate: TaxRate['21%'],
            uuid: uuid.v4(),
          });
          hideModal();
        }}
      />,
    );
  };

  render() {
    const { inline } = this.props;
    return (
      <Button
        type="button"
        small={inline}
        constant={!inline}
        secondary={!inline}
        text="application.invoice_add_cost"
        leftIcon
        icon="plus"
        click={!inline ? this.selectCostType : this.openCostModal}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      showModal: modal.showModal,
      hideModal: modal.hideModal,
      addToCosts: arrayPush,
    },
    dispatch,
  );

const mapStateToProps = (state: ApplicationState) => {
  const costs = formValueSelector('invoicForm')(state, 'costs');
  return {
    costs,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCostButton);
