import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { menu } from '../../../../../actions';
import { __ } from '../../../../../services/translation';
import { MenuItemType } from '../../../../../types/menu';
import './MenuItem.scss';

interface Props {
  toggleMenu: () => void;
  item: MenuItemType;
}

const MenuItem = ({ item, toggleMenu }: Props) => (
  <NavLink
    to={item.to}
    className={`sliding-menu-link ${item.secondary ? 'secondary' : ''}`}
    onClick={toggleMenu}
    activeClassName="active"
  >
    <div className="menu-icon-wrapper">
      {typeof item.icon === 'string' ? (
        <img
          src={`/assets/icons/${item.icon}.svg`}
          alt={__(item.name)}
          className="sliding-menu-link-icon"
        />
      ) : (
        item.icon
      )}
      {item.indicator && <span className="indicator" />}
    </div>
    {item.translate ? __(item.name) : item.name}
  </NavLink>
);

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ toggleMenu: menu.toggleMenu }, dispatch);

export default connect(null, mapDispatchToProps)(MenuItem);
