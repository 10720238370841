import React from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ApiService from '../../../services/api-service';
import { Page, FormContainer, Container, ButtonsContainer } from '../../../components/Layout';
import {
  Spinner,
  Button,
  Alert,
  FieldsMapper,
  RequiredIndicator,
  Subheader,
} from '../../../components/Common';
import { formOnTranslateString } from '../../../utils/trans-form';
import { history } from '../../../App';
import { user as userActions, header } from '../../../actions';
import { ApplicationState } from '../../../reducers';
import { checkIfMissingData } from '../../../utils/user';

interface Props extends RouteComponentProps {
  match: any;
  validateKvk: (kvk: string) => void;
  receiveKvkFormData: (values: any) => void;
  isKvkValid: boolean;
  loadingKvkValidation: boolean;
  setHeader: (title: string) => void;
  getUserDetails: () => void;
  user: any;
  userLoading: any;
}

interface State {
  number: string;
  prefix: string;
}

interface NumberForm {
  number: string;
  prefix: string;
  multipart: boolean;
}

class KvkData extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      number: '001',
      prefix: new Date().getFullYear().toString(),
    };
  }

  componentDidMount() {
    const { user, setHeader } = this.props;
    if ((!user || user.isActived) && !checkIfMissingData(user)) {
      history.push('/');
    }
    const { validateKvk, match } = this.props;
    validateKvk(match.params.id);
    setHeader('application.kvk_data');
  }

  componentDidUpdate(prevProps: Props) {
    const { user } = this.props;
    if (user.isActived !== prevProps.user.isActived) {
      history.push('/');
    }
  }

  registerFormCallbacks = () => {
    window.globalForms['receive-kvk-form'].valueChangedClb = (newValues: NumberForm) => {
      this.setState({
        prefix: newValues.multipart ? newValues.prefix : '',
        number: newValues.number,
      });
    };
  };

  private generateNumbers = () => {
    const { prefix, number } = this.state;
    const { length } = number;

    const numbers = [0, 1, 2].map((num) => {
      const parsed = parseInt(number);
      const nextNumberPadded = (Number.isNaN(parsed) ? '-' : parsed + num)
        .toString()
        .padStart(length, '0');
      return `${prefix}${nextNumberPadded}`;
    });
    return numbers;
  };

  private getContent = () => {
    const {
      match,
      loadingKvkValidation,
      receiveKvkFormData,
      isKvkValid,
      getUserDetails,
      userLoading,
    } = this.props;
    const { number, prefix } = this.state;
    if (loadingKvkValidation || userLoading) {
      return <Spinner />;
    }
    if (isKvkValid === false) {
      return (
        <>
          <Alert type="error" text="application.invalid_kvk" />
          <ButtonsContainer>
            <Button to="/inactive/enter-kvk" text="application.try_again" />
          </ButtonsContainer>
        </>
      );
    }
    return (
      <FormContainer>
        <ItfApiForm
          formId="receive-kvk-form"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() =>
            ApiService.loadForm(`user/prepare-add-user-form-for-kvk/${match.params.id}`)
          }
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm('user/complete-data', values, 'PATCH')
          }
          onSavedSuccessfully={(values: any) => {
            receiveKvkFormData(values);
            getUserDetails();
          }}
          loadingInfo={<Spinner overlay halfTransparent />}
          submitButton={
            <>
              <Subheader level={2} text="application.next_invoice_numbers" paddingTop />
              <p>{this.generateNumbers().join(', ')}</p>
              <ButtonsContainer marginTop>
                <Button
                  type="button"
                  to="/inactive/enter-kvk"
                  text="application.try_again"
                  secondary
                />
                <Button type="submit" text="application.confirm" primary />
              </ButtonsContainer>
            </>
          }
          onTranslateString={formOnTranslateString}
          onRenderFullField={FieldsMapper}
          registerAsGlobalForm
          enableLiveValuesOfGlobalForm
          onFormGloballyRegistered={this.registerFormCallbacks}
        />
      </FormContainer>
    );
  };

  render() {
    return (
      <>
        <Page light>
          <Container paddingTop>{this.getContent()}</Container>
        </Page>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  isKvkValid: state.user.validKvk,
  loadingKvkValidation: state.user.loadingKvkValidation,
  user: state.user.details,
  userLoading: state.user.loading,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      validateKvk: userActions.validateKvk,
      receiveKvkFormData: userActions.receiveKvkFormData,
      getUserDetails: userActions.getUserDetails,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(KvkData);
