import { FieldType, TableSchema } from "../typings";

export const fullClientsForUser: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: "application.name",
      field: "name",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.address",
      field: "address",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.city",
      field: "city",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.btw",
      field: "btw",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.kvk",
      field: "kvk",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    }
  ]
};
