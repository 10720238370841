import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import Picker from 'emoji-picker-react';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import ApiService from '../../../../services/api-service';
import { notifications } from '../../../../actions';
import { Spinner, Button, FieldsMapper, RequiredIndicator } from '../../../../components/Common';
import { FormContainer, ButtonsContainer } from '../../../../components/Layout';
import { history } from '../../../../App';
import { formOnTranslateString } from '../../../../utils/trans-form';
import useHeader from '../../../../hooks/useHeader';
import { __ } from '../../../../services/translation';
import './New.scss';

const NewNotification = () => {
  const dispatch = useDispatch();
  const [isPicker, setPicker] = useState<boolean>(false);

  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  useHeader('application.new_notification');

  const selectEmoji = (e: any, data: any) => {
    const currentValue = itfForm.api.getValue('text');
    itfForm.api.setValue('text', `${currentValue}${data.emoji}`);
  };

  return (
    <>
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="add-contact-id"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          useFormDataInsteadOfPlainObject
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm('notification/form/new')}
          sendFetcher={(formId: string, values: any) => ApiService.sendForm('notification/new', values, 'POST')}
          onSavedSuccessfully={() => {
            dispatch(notifications.successNotification('application.notification_created'));
            dispatch({ type: 'REFRESH_API_TABLE' });
            history.push('/dashboard/notifications/list');
          }}
          onRenderFullField={(
            field: any,
            errors: any,
            fieldHtmlId: any,
            currentValue: any,
            onChange: any,
            onFocus: any,
            onBlur: any,
          ) => {
            if (field.id === 'text') {
              return (
                <>
                  {FieldsMapper(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur)}
                  <div className="editor-toolbar">
                    <ButtonsContainer>
                      <Button
                        type="button"
                        leftIcon
                        faIcon={faSmile}
                        click={() => setPicker(!isPicker)}
                        text="emoticons.title"
                      />
                    </ButtonsContainer>

                    <div className={clsx('picker-container', { visible: isPicker })}>
                      <Picker
                        disableAutoFocus
                        disableSearchBar
                        onEmojiClick={selectEmoji}
                        groupNames={{
                          smileys_people: __('emoticons.smileys_people'),
                          animals_nature: __('emoticons.animals_nature'),
                          food_drink: __('emoticons.food_drink'),
                          travel_places: __('emoticons.travel_places'),
                          activities: __('emoticons.activities'),
                          objects: __('emoticons.objects'),
                          symbols: __('emoticons.symbols'),
                          flags: __('emoticons.flags'),
                          recently_used: __('emoticons.recently_used'),
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            }
            return FieldsMapper(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur);
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button type="submit" text="application.add" noShadow primary />
            </ButtonsContainer>
          }
          onTranslateString={formOnTranslateString}
        />
      </FormContainer>
    </>
  );
};

export default NewNotification;
