import React from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { ClientReminderRes, ClientType } from 'app-types';
import { Button, Icon, ListItem } from '../../../../../../components/Common';
import { InvoiceItem } from '../../InvoiceItem';
import './Item.scss';
import { ButtonsContainer } from '../../../../../../components/Layout';

interface Props {
  name: string;
  item: ClientReminderRes;
}

const ClientItem = ({ item }: Props) => {
  return (
    <ListItem
      className="client-reminder-container"
      header={
        item.name ||
        ((item.firstName || item.lastName) && `${item.firstName} ${item.lastName}`) ||
        item.email
      }
      headerRight={
        item.type === ClientType.COMPANY ? (
          <Icon icon="company_client" />
        ) : (
          <Icon icon="private_client" />
        )
      }
      data={
        <div className="client-reminder-data">
          <div className="client-address">
            {item.address}, {item.city}
          </div>

          <div className="client-invoices">
            {item.invoices.map((invoice) => (
              <InvoiceItem item={invoice} />
            ))}
          </div>
          <ButtonsContainer marginTop>
            <Button text="application.send_reminder" />
            <Button text="application.history" secondary />
          </ButtonsContainer>
        </div>
      }
      icon={faUser}
      buttons={[]}
    />
  );
};

export default ClientItem;
