import { FieldType, TableSchema } from "../typings";

export const ordersForUserTable: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: "application.name",
      field: "name",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.frequency",
      field: "frequency",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.count",
      field: "count",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.next_at",
      field: "nextAt",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    }
  ]
};
