import React from 'react';
import { ClientUserEntity } from 'app-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Page, Content, Container } from '../../components/Layout';
import { Logo, WelcomeHeader } from '../../components/Common';
import { ApplicationState } from '../../reducers';
import './Blocked.scss';
import { __ } from '../../services/translation';

const Blocked = () => {
  const user = useSelector((state: ApplicationState) => state.user.details);
  if (!user) return <Redirect to="/" />;
  return (
    <>
      <Page center className="blocked-page">
        <Container paddingTop>
          <Logo big center />
          <Content center>
            <WelcomeHeader text="application.blocked_account" />
          </Content>
          <h2>{__('application.block_reason')}</h2>
          <p>{(user as ClientUserEntity).blockReason}</p>
          {/* For flexbox to work properly */}
          <span />
        </Container>
      </Page>
    </>
  );
};

export default Blocked;
