import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrderEventType, OrderDetails, OrderEvent } from 'app-types';
import { RouteComponentProps } from 'react-router';
import { orders, modal, header } from '../../../../actions';
import { InfoParagraph, Spinner, Button, Subheader } from '../../../../components/Common';
import { ButtonsContainer } from '../../../../components/Layout';
import { Confirmation, EditOrder } from '../../../../modals';
import TimeSheetItem from '../Items/TimeSheetItem';
import MileageItem from '../Items/MileageItem';
import { ApplicationState } from '../../../../reducers';
import './SingleOrder.scss';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  order: OrderDetails;
  loading: boolean;
  getSingleOrder: (id: string) => void;
  deleteSingleOrder: (id: string) => void;
  hideModal: () => void;
  showModal: (content: React.ReactNode) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
  match: any;
}
class SingleOrder extends Component<Props> {
  componentDidMount() {
    const { getSingleOrder, match, setHeader } = this.props;
    getSingleOrder(match.params.id);
    setHeader('mileage_generator.single_title', '/dashboard/generator');
  }

  private editOrder = () => {
    const { showModal, match } = this.props;
    showModal(<EditOrder id={match.params.id} />);
  };

  private confirmOrderDeletion = () => {
    const { showModal, match, deleteSingleOrder, hideModal } = this.props;
    showModal(
      <Confirmation
        text="mileage_generator.delete_confirmation"
        confirmCallback={() => deleteSingleOrder(match.params.id)}
        cancelCallback={() => hideModal()}
      />,
    );
  };

  private events = () => {
    const { order } = this.props;
    return order.events ? (
      <div className="events-container">
        <Subheader highlight text="application.events" level={1} />
        {order.events.map((item: OrderEvent, index: number) =>
          item.type === OrderEventType.timeSheet ? (
            <TimeSheetItem id={item.templateId} key={item.templateId} />
          ) : (
            <MileageItem id={item.templateId} key={item.templateId} />
          ),
        )}
      </div>
    ) : null;
  };

  render() {
    const { loading, order, match } = this.props;
    return (
      <>
        {loading && <Spinner overlay />}
        <InfoParagraph name="application.name" text={order.name} />
        <InfoParagraph name="application.start" date={order.nextAt} />
        <InfoParagraph name="application.count" text={order.count} />
        <InfoParagraph
          name="application.frequency"
          translate={`application.frequency_${order.frequency}`}
        />
        {this.events()}
        <ButtonsContainer marginTop>
          <Button
            text="application.edit"
            secondary
            rightMargin
            noShadow
            to={`/dashboard/generator/edit/${match.params.id}`}
            bottomMargin
          />
          <Button
            text="application.delete"
            danger
            noShadow
            click={this.confirmOrderDeletion}
            bottomMargin
          />
        </ButtonsContainer>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  order: state.orders.singleOrder,
  loading: state.orders.loadingSingleOrder,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getSingleOrder: orders.getSingleOrder,
      deleteSingleOrder: orders.deleteSingleOrder,
      showModal: modal.showModal,
      hideModal: modal.hideModal,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SingleOrder);
