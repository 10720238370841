import React from 'react';
import ReactNoSleep from 'react-no-sleep';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApiErrorCode, TrackingStatus } from 'app-types';
import { Plugins } from '@capacitor/core';
import { BackgroundGeolocationPlugin } from '@capacitor-community/background-geolocation';

import { Content } from '../../../../../components/Layout';
import { TrackingButton, Spinner, NarrowParagraph, Alert } from '../../../../../components/Common';
import { mileage, notifications, header, modal } from '../../../../../actions';
import { __ } from '../../../../../services/translation';
import './UseGeolocation.scss';
import { ApplicationState } from '../../../../../reducers';
import { isMobile, isMobileBuild } from '../../../../../utils/is-mobile';
import GPSMobileInfo from '../../../../../modals/GPSMobileInfo';

interface Props {
  trackingStatus: TrackingStatus;
  startTracking: (handler: any, startDate: Date) => void;
  sendTrackingPoint: (latitude: number, longitude: number) => void;
  stopTracking: (finishDate: Date) => void;
  setTrackingState: (status: TrackingStatus) => void;
  cancelTracking: () => void;
  errorNotification: (error: ApiErrorCode) => void;
  successNotification: (text: string) => void;
  storeCoordinates: (lat: number, long: number) => void;
  setGpsTrackingInterval: (handler: any) => void;
  clearGpsTrackingInterval: () => void;
  showModal: (content: React.ReactNode, isClosable: boolean) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

const BackgroundGeolocation = Plugins.BackgroundGeolocation as BackgroundGeolocationPlugin;
const { Modals } = Plugins;

interface State {
  gpsWatcher: string;
}

class UseGeolocation extends React.Component<Props, State> {
  private counter: any;

  state = {
    gpsWatcher: '',
  };

  componentDidMount() {
    const { setHeader } = this.props;
    setHeader('application.mileage_tracking', '', {
      title: 'application.mileage_tracking',
      text: 'annotations.mileage_gps',
    });
  }

  private getLocation = () =>
    new Promise((resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            lat: position.coords.latitude,
            long: position.coords.longitude,
          };
          resolve(location);
        },
        (err) => reject(err),
      );
    });

  private startTracking = () => {
    const {
      errorNotification,
      startTracking,
      setTrackingState,
      storeCoordinates,
      showModal,
    } = this.props;

    if (isMobileBuild()) {
      showModal(<GPSMobileInfo callback={this.startAppTracking} />, false);
    } else {
      this.startWebTracking();
    }
  };

  private startWebTracking = () => {
    const { errorNotification, startTracking, setTrackingState, storeCoordinates } = this.props;
    setTrackingState(TrackingStatus.Loading);
    this.getLocation()
      .then((location: any) => {
        setTrackingState(TrackingStatus.Working);
        startTracking(this.trackLocal, new Date());
        storeCoordinates(location.lat, location.long);
      })
      .catch(() => {
        errorNotification(ApiErrorCode.CannotReadGpsCoords);
        setTrackingState(TrackingStatus.Idle);
      });
  };

  private startAppTracking = () => {
    const { errorNotification, startTracking, setTrackingState, storeCoordinates } = this.props;
    setTrackingState(TrackingStatus.Loading);
    const watcherId = BackgroundGeolocation.addWatcher(
      {
        backgroundMessage: __('application.stop_to_prevent_battery_drain'),
        backgroundTitle: __('application.tracking_you'),
        requestPermissions: true,
        stale: false,
        distanceFilter: 0,
      },
      (location, error) => {
        if (error) {
          setTrackingState(TrackingStatus.Idle);
          if (error.code === 'NOT_AUTHORIZED') {
            Modals.confirm({
              title: __('application.location_required'),
              message: __('application.location_required_description'),
            }).then(({ value }) => {
              if (value) {
                BackgroundGeolocation.openSettings();
              }
            });
          }
          return console.error(error);
        }
        setTrackingState(TrackingStatus.Working);
        if (location) storeCoordinates(location.latitude, location.longitude);
      },
    );

    this.setState({
      gpsWatcher: watcherId,
    });
  };

  private finishTracking = () => {
    const { errorNotification, startTracking, setTrackingState, storeCoordinates } = this.props;
    setTrackingState(TrackingStatus.Loading);
    if (isMobileBuild()) {
      this.finishAppTracking();
    } else {
      this.finishWebTracking();
    }
  };

  private finishWebTracking = () => {
    const { errorNotification, stopTracking, setTrackingState, storeCoordinates } = this.props;
    setTrackingState(TrackingStatus.Loading);
    this.stopSendingCoords();
    this.getLocation()
      .then((location: any) => {
        storeCoordinates(location.lat, location.long);
        stopTracking(new Date());
      })
      .catch(() => errorNotification(ApiErrorCode.CannotReadGpsCoords));
  };

  private finishAppTracking = () => {
    const { errorNotification, stopTracking, setTrackingState, storeCoordinates } = this.props;
    const { gpsWatcher } = this.state;
    if (gpsWatcher !== null) {
      BackgroundGeolocation.removeWatcher({ id: gpsWatcher }).then(() => {
        stopTracking(new Date());
      });
      this.setState({
        gpsWatcher: '',
      });
    }
  };

  private trackLocal = () => {
    const { errorNotification, storeCoordinates } = this.props;
    this.getLocation()
      .then((location: any) => {
        storeCoordinates(location.lat, location.long);
      })
      .catch(() => errorNotification(ApiErrorCode.CannotReadGpsCoords));
  };

  private stopSendingCoords = () => {
    window.clearInterval(this.counter);
  };

  private resetTracking = () => {
    if (isMobileBuild()) {
      this.cancelAppTracking();
    } else {
      this.cancelWebTracking();
    }
  };

  private cancelWebTracking = () => {
    const { successNotification, cancelTracking } = this.props;
    cancelTracking();
    successNotification('application.tracking_canceled');
  };

  private cancelAppTracking = () => {
    const { successNotification, cancelTracking } = this.props;
    const { gpsWatcher } = this.state;
    if (gpsWatcher !== null) {
      BackgroundGeolocation.removeWatcher({ id: gpsWatcher }).then(() => {
        cancelTracking();
        successNotification('application.tracking_canceled');
      });
      this.setState({
        gpsWatcher: '',
      });
    }
  };

  private getStartStopButton = (status: TrackingStatus) => {
    switch (status) {
      case TrackingStatus.Idle:
        return (
          <TrackingButton
            text="application.start"
            onClick={() => {
              this.startTracking();
            }}
            start
          />
        );
      case TrackingStatus.Loading:
        return (
          <TrackingButton
            text="application.start"
            onClick={() => {
              this.startTracking();
            }}
            start
          />
        );

      case TrackingStatus.Working:
        return (
          <TrackingButton
            text="application.stop"
            onClick={() => {
              this.finishTracking();
            }}
            stop
          />
        );
      default:
        break;
    }
  };

  private getResetButton = (status: TrackingStatus) => {
    switch (status) {
      case TrackingStatus.Idle:
        return <TrackingButton text="application.reset" inactive small />;
      case TrackingStatus.Loading:
        return <TrackingButton text="application.reset" inactive small />;
      case TrackingStatus.Working:
        return (
          <TrackingButton text="application.reset" onClick={this.resetTracking} active small />
        );
      default:
        break;
    }
  };

  render() {
    const { trackingStatus } = this.props;
    return (
      <>
        <Content center>
          <div className="gps-info">
            {isMobileBuild() ? (
              <NarrowParagraph text="application.gps_background" />
            ) : (
              <NarrowParagraph text="application.gps_keep_open" />
            )}
          </div>
          {trackingStatus === TrackingStatus.Loading && <Spinner overlay halfTransparent />}
          <p className="geolocation-status-header">{__('application.status')}</p>
          <p
            className={`geolocation-status ${
              trackingStatus === TrackingStatus.Working ? 'application.working' : ''
            }`}
          >
            {__(`application.${trackingStatus}`).toUpperCase()}
          </p>
          <ReactNoSleep>
            {({ isOn, enable, disable }) => (
              <>
                <button onClick={isOn ? disable : disable}>
                  {this.getResetButton(trackingStatus)}
                </button>
                <button onClick={isOn ? disable : enable}>
                  {isOn ? '' : ''}
                  {this.getStartStopButton(trackingStatus)}
                </button>
              </>
            )}
          </ReactNoSleep>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  trackingStatus: state.mileage.trackingStatus,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      startTracking: mileage.startTracking,
      stopTracking: mileage.stopTracking,
      cancelTracking: mileage.cancelTracking,
      errorNotification: notifications.errorNotification,
      successNotification: notifications.successNotification,
      setTrackingState: mileage.setTrackingState,
      storeCoordinates: mileage.storeCoordinates,
      showModal: modal.showModal,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UseGeolocation);
