import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { ClientEntity, CostEntity, GetClientKvKRes, TaxRate } from 'app-types';
import { isEmptyObject } from '../../../../../utils/is-empty-object';
import { invoice } from '../../../../../actions';
import { ButtonsContainer } from '../../../../../components/Layout';
import { InfoParagraph, Button, Subheader, Spinner } from '../../../../../components/Common';
import { ApplicationState } from '../../../../../reducers';
import useHeader from '../../../../../hooks/useHeader';
import { history } from '../../../../../App';
import InvoicePreview from './InvoicePreview';
import './Preview.scss';

interface Props {
  invoiceData: any;
  nextNumber: string;
  saveInvoice: (values: any, id?: string, redirectToSend?: boolean) => any;
  loadingInvoiceSend: boolean;
  client: ClientEntity | GetClientKvKRes | null;
}

const Preview = ({ invoiceData, saveInvoice, loadingInvoiceSend, client }: Props) => {
  useHeader('application.invoice');
  // we do not send the invoice number
  const { street, city, id, paymentDays, owner, ...invoiceDataToParse } = invoiceData;

  useEffect(() => {
    if (invoiceData.services.length === 0) history.push('/dashboard/invoices/list');
  }, []);

  if (!client) return null;
  const invoiceDataToSend = {
    // annotation: invoiceData.annotation || '',
    ...invoiceDataToParse,
    client,
    costIds: invoiceDataToParse.costs
      ? invoiceDataToParse.costs
          .filter((cost: CostEntity) => cost.id)
          .map((cost: CostEntity) => cost.id)
      : [],
    costs: invoiceDataToParse.costs
      ? invoiceDataToParse.costs
          .filter((cost: CostEntity) => !cost.id)
          .map((cost: CostEntity) => ({ ...cost, date: invoiceData.createdAt }))
      : [],
  };

  if (isEmptyObject(invoiceData.services) || !invoiceData.services) {
    return <Redirect to="/" />;
  }

  const { mark, createdAt, paymentTerm, files, services, costs, number, annotation } = invoiceData;

  return (
    <>
      <div className="invoice-preview">
        {loadingInvoiceSend && <Spinner halfTransparent overlay />}
        <InfoParagraph text={number} name="application.number" />
        <InfoParagraph text={mark} name="application.mark" />
        <InfoParagraph date={createdAt} name="application.date" />
        <InfoParagraph date={paymentTerm} name="application.payment_term" />

        <InvoicePreview data={invoiceDataToSend} />

        {files && files.length > 0 && files[0].name && (
          <>
            <Subheader level={1} text="application.files" highlight />
            {files.map((file: any) => (
              <div key={file.name} className="invoice-file-preview">
                <InfoParagraph text={file.name} name="application.name" />
                <InfoParagraph date={file.date} name="application.date" />
                {file.file && <InfoParagraph text={file.file.name} name="application.file_name" />}
              </div>
            ))}
          </>
        )}
        {costs && costs.length > 0 && (
          <>
            <Subheader level={1} text="application.costs" highlight />
            {costs.map((c: CostEntity) => (
              <div key={c.name} className="invoice-file-preview">
                <InfoParagraph text={c.name} name="application.name" />
                <InfoParagraph name="application.value" price={c.value} />
                <InfoParagraph
                  name="application.cost_tax_rate"
                  text={Object.values(TaxRate)[c.taxRate]}
                />
                <InfoParagraph name="application.date" date={c.date} />
              </div>
            ))}
          </>
        )}

        <ButtonsContainer marginTop>
          <Button
            text="application.edit"
            to="/dashboard/invoices/add/preview/edit"
            bottomMargin
            secondary
            disabled={loadingInvoiceSend}
          />
          <Button
            text="application.save_and_send"
            disabled={loadingInvoiceSend}
            click={() => {
              saveInvoice(invoiceDataToSend, id, true);
            }}
            bottomMargin
          />

          <Button
            text="application.save"
            disabled={loadingInvoiceSend}
            primary
            click={() => {
              saveInvoice(invoiceDataToSend, id);
            }}
            bottomMargin
          />
        </ButtonsContainer>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  invoiceData: state.invoice.receivedInvoiceData,
  client: state.invoice.client,
  loadingInvoiceSend: state.invoice.loadingInvoiceSend,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      saveInvoice: invoice.saveInvoice,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
