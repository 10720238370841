import React from 'react';

interface Props {
  icon: string;
  className?: string;
}

const Icon = ({ icon, className }: Props) => (
  <img className={className} src={`/assets/icons/${icon}.svg`} />
);

export default Icon;
