import { apiList, modal, notifications } from '.';
import ApiService from '../services/api-service';
import { history } from '../App';

export const getCostSchemeList = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_COST_SCHEME_LIST_SUCCESS',
    'GET_COST_SCHEME_LIST_FAILED',
    null,
    null,
    'cost/template/list',
    'LOADING_COST_SCHEME_LIST',
  );
};

export const getSingleCostScheme = (costId: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_SINGLE_COST_SCHEME_SUCCESS',
    'GET_SINGLE_COST_SCHEME_FAILED',
    null,
    null,
    `cost/template/detail/${costId}`,
    'LOADING_SINGLE_COST_SCHEME',
  );
};

export const deleteSingleCostScheme = (costId: string, redirect?: string) => async (
  dispatch: any,
) => {
  await ApiService.delete(
    dispatch,
    'DELETE_SINGLE_COST_SCHEME_SUCCESS',
    'DELETE_SINGLE_COST_SCHEME_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(apiList.refreshApiList());
      dispatch(notifications.successNotification('application.cost_deleted'));
      if (redirect) {
        history.push(redirect);
      }
    },
    null,
    `cost/template/${costId}`,
    'LOADING_SINGLE_COST_SCHEME',
  );
};
