import { GetFullInvoiceResponse, ClientType, ClientEntity } from 'app-types';

interface GetNextInvoiceNumber {
  type: 'GET_NEXT_INVOICE_NUMBER_SUCCESS';
  payload: number;
}

interface LoadingNextInvoiceNumber {
  type: 'LOADING_NEXT_INVOICE_NUMBER';
  payload: boolean;
}

interface GetSingleInvoice {
  type: 'GET_SINGLE_INVOICE_SUCCESS';
  payload: number;
}

interface LoadingInvoiceSend {
  type: 'LOADING_INVOICE_SEND';
  payload: boolean;
}

interface LoadingSingleInvoice {
  type: 'LOADING_SINGLE_INVOICE';
  payload: boolean;
}

interface ReceiveInvoiceData {
  type: 'RECEIVE_INVOICE_DATA';
  payload: GetFullInvoiceResponse;
}

interface LoadingCreditnotaData {
  type: 'LOADING_CREDITNOTA_DATA';
  payload: boolean;
}

interface GetCreditnotaData {
  type: 'GET_CREDITNOTA_DATA_SUCCESS';
  payload: GetFullInvoiceResponse;
}

interface ReceiveClientData {
  type: 'RECEIVE_CLIENT_DATA_FOR_INVOICE';
  payload: {};
}

interface SetInvoiceClient {
  type: 'SET_INVOICE_CLIENT';
  payload: ClientEntity | null;
}

export interface InvoiceState {
  loadingNextNumber: boolean;
  nextNumber: string;
  singleInvoice: GetFullInvoiceResponse;
  receivedInvoiceData: any;
  loadingSingleInvoice: boolean;
  loadingInvoiceSend: boolean;
  loadingCreditnota: boolean;
  creditnotaData: GetFullInvoiceResponse | null;
  client: ClientEntity | null;
}

const initialState: InvoiceState = {
  loadingNextNumber: true,
  nextNumber: '',
  singleInvoice: {
    annotation: '',
    id: '',
    number: '',
    mark: '',
    paymentTerm: new Date(),
    files: [],
    costs: [],
    client: {
      id: '',
      type: ClientType.COMPANY,
      address: '',
      country: '',
      city: '',
      kvk: '',
      btw: '',
      bic: '',
      iban: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      ident: 0,
      phone: '',
      note: '',
    },
    services: [],
    createdAt: new Date(),
  },
  receivedInvoiceData: {
    client: {},
    services: [],
  },
  loadingSingleInvoice: true,
  loadingInvoiceSend: false,
  loadingCreditnota: true,
  creditnotaData: null,
  client: null,
};

type Action =
  | GetNextInvoiceNumber
  | LoadingNextInvoiceNumber
  | GetSingleInvoice
  | LoadingSingleInvoice
  | ReceiveInvoiceData
  | LoadingInvoiceSend
  | LoadingCreditnotaData
  | GetCreditnotaData
  | ReceiveClientData
  | SetInvoiceClient;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_NEXT_INVOICE_NUMBER_SUCCESS': {
      return { ...state, nextNumber: action.payload };
    }
    case 'LOADING_NEXT_INVOICE_NUMBER': {
      return { ...state, loadingNextNumber: action.payload };
    }
    case 'GET_SINGLE_INVOICE_SUCCESS': {
      return { ...state, singleInvoice: action.payload };
    }
    case 'LOADING_SINGLE_INVOICE': {
      return { ...state, loadingSingleInvoice: action.payload };
    }
    case 'LOADING_INVOICE_SEND': {
      return { ...state, loadingInvoiceSend: action.payload };
    }
    case 'RECEIVE_INVOICE_DATA': {
      return { ...state, receivedInvoiceData: action.payload };
    }
    case 'GET_CREDITNOTA_DATA_SUCCESS': {
      return { ...state, creditnotaData: action.payload };
    }
    case 'LOADING_CREDITNOTA_DATA': {
      return { ...state, loadingCreditnota: action.payload };
    }
    case 'SET_INVOICE_CLIENT': {
      return { ...state, client: action.payload };
    }

    case 'RECEIVE_CLIENT_DATA_FOR_INVOICE': {
      return {
        ...state,
        clientForInvoice: action.payload,
      };
    }
    default:
      return state;
  }
};
