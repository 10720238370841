import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserRole, UserEntity } from 'app-types';
import { rememberRedirect } from '../../../utils/remember-redirect';
import { ApplicationState } from '../../../reducers';

interface Props {
  user: UserEntity;
  exact?: boolean;
  path?: string;
  component: any;
}

const RouteAdmin = ({ user, exact, path, component }: Props) => {
  if (!user || user.role !== UserRole.Admin) {
    rememberRedirect(UserRole.Admin);
    return <Route render={() => <Redirect to="/" />} />;
  }
  return <Route exact={exact} path={path} component={component} />;
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
});

export default connect(mapStateToProps)(RouteAdmin);
