import { FieldType, TableSchema } from '../typings';

export const reminderClients: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: 'application.name',
            field: 'id',
            mobile: true,
            searchable: true,
            sortable: true,
            defaultSort: true,
            type: FieldType.Text,
            alias: 'c'
        },
        {
            name: 'application.first_name',
            field: 'firstName',
            mobile: true,
            searchable: true,
            sortable: true,
            defaultSort: true,
            type: FieldType.Text,
            alias: 'c'
        },
        {
            name: 'application.last_name',
            field: 'lastName',
            mobile: true,
            searchable: true,
            sortable: true,
            defaultSort: false,
            type: FieldType.Text,
            alias: 'c'
        },
        {
            name: 'application.email',
            field: 'email',
            mobile: true,
            searchable: true,
            sortable: true,
            defaultSort: false,
            type: FieldType.Email,
            alias: 'c'
        },
        {
            name: 'application.address',
            field: 'address',
            mobile: true,
            searchable: true,
            sortable: true,
            defaultSort: true,
            type: FieldType.Text,
            alias: 'c'
        },
        {
            name: 'application.city',
            field: 'city',
            mobile: true,
            searchable: true,
            sortable: true,
            defaultSort: true,
            type: FieldType.Text,
            alias: 'c'
        },
        {
            name: 'application.kvk',
            field: 'kvk',
            mobile: true,
            searchable: true,
            sortable: true,
            defaultSort: true,
            type: FieldType.Text,
            alias: 'c'
        },
        {
            name: 'application.btw',
            field: 'btw',
            mobile: true,
            searchable: true,
            sortable: true,
            defaultSort: true,
            type: FieldType.Text,
            alias: 'c'
        },
    ],
};
