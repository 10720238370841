import React from 'react';
import useHeader from '../../../../hooks/useHeader';
import { ButtonRound } from '../../../../components/Common';
import './Add.scss';

const AddTimeSheet = () => {
  useHeader('application.add_time_sheet', '/dashboard/time-sheets/list');
  return (
    <div className="add-timesheet-wrapper">
      <ButtonRound to="/dashboard/time-sheets/add/start-stop" label="application.use_timer" icon="timer" />
      <ButtonRound to="/dashboard/time-sheets/add/form" label="application.use_form" icon="form" />
      <ButtonRound to="/dashboard/time-sheets-schemes/list" label="application.use_scheme" icon="route" />
    </div>
  );
};

export default AddTimeSheet;
