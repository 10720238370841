import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Plugins } from '@capacitor/core';
import { Spinner } from '../../../components/Common';
import { i18n } from '../../../services/translation';
import { ApplicationState } from '../../../reducers';
import ApiService from '../../../services/api-service';

const { SplashScreen } = Plugins;

interface Props {
  children: React.ReactNode;
}

const LocaleWrapper = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const { current } = useSelector((state: ApplicationState) => state.lang);
  const user = useSelector((state: ApplicationState) => state.user.details);

  const changeServerLanguage = async (lang: string) => {
    await ApiService.callFetch('GET', `user/change-language/${lang}`);
  };

  const checkI18nLoaded = () => {
    if (i18n.loaded) {
      setLoading(false);
      SplashScreen.hide();
      changeServerLanguage(current);
    } else {
      setTimeout(checkI18nLoaded, 200);
    }
  };

  useEffect(() => {
    if (current && current !== i18n.currentLang) {
      setLoading(true);
      i18n.loaded = false;
      i18n.load(current);
    }
    setTimeout(checkI18nLoaded, 200);
  }, [current, user]);

  if (loading) return <Spinner overlay />;

  return <>{children}</>;
};

export default LocaleWrapper;
