import React from 'react';
import { TableSchema, reminderClients } from 'app-tables';
import { InteractiveTableSchema } from '../../../../../utils/table-shcema-utils';
import { ApiList } from '../../../../../components/Common';

import ClientItem from './Item';
import useHeader from '../../../../../hooks/useHeader';

interface Props {
  toInvoice?: boolean;
}

const ClientReminderList = ({ toInvoice }: Props) => {
  const scheme: TableSchema = new InteractiveTableSchema(reminderClients);

  const backButton = toInvoice ? '/dashboard/invoices/list' : '/';
  useHeader('application.client_list', backButton, {
    title: 'application.client_list',
    text: 'annotations.clients',
  });
  return (
    <>
      <ApiList
        noSearch
        apiEndpointSubUrl="client/reminder-list"
        scheme={scheme}
        component={ClientItem}
      />
    </>
  );
};

export default ClientReminderList;
