import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { ClientEntity, CostEntity, GetClientKvKRes, TaxRate } from 'app-types';
import { isEmptyObject } from '../../../../../utils/is-empty-object';
import { offer } from '../../../../../actions';
import { ButtonsContainer } from '../../../../../components/Layout';
import { InfoParagraph, Button, Subheader, Spinner } from '../../../../../components/Common';
import { ApplicationState } from '../../../../../reducers';
import useHeader from '../../../../../hooks/useHeader';
import { history } from '../../../../../App';
import OfferPreview from './OfferPreview';
import './Preview.scss';

interface Props {
  offerData: any;
  nextNumber: string;
  saveOffer: (values: any, id?: string, redirectToSend?: boolean) => any;
  loadingOfferSend: boolean;
  client: ClientEntity | GetClientKvKRes | null;
}

const Preview = ({ offerData, saveOffer, loadingOfferSend, client }: Props) => {
  useHeader('application.offer');
  // we do not send the offer number
  const { street, city, id, paymentDays, owner, ...offerDataToParse } = offerData;

  useEffect(() => {
    if (offerData.services.length === 0) history.push('/dashboard/offers/list');
  }, []);

  if (!client) return null;

  const offerDataToSend = {
    // annotation: offerData.annotation || '',
    ...offerDataToParse,
    client,
    costIds: offerDataToParse.costs
      ? offerDataToParse.costs
          .filter((cost: CostEntity) => cost.id)
          .map((cost: CostEntity) => cost.id)
      : [],
    costs: offerDataToParse.costs
      ? offerDataToParse.costs
          .filter((cost: CostEntity) => !cost.id)
          .map((cost: CostEntity) => ({ ...cost, date: offerData.createdAt }))
      : [],
  };

  if (isEmptyObject(offerData.services) || !offerData.services) {
    return <Redirect to="/" />;
  }

  const { mark, createdAt, paymentTerm, files, services, costs, number, annotation } = offerData;

  return (
    <>
      <div className="offer-preview">
        {loadingOfferSend && <Spinner halfTransparent overlay />}
        <InfoParagraph text={number} name="application.number" />
        <InfoParagraph text={mark} name="application.mark" />
        <InfoParagraph date={createdAt} name="application.date" />
        <InfoParagraph date={paymentTerm} name="application.payment_term" />

        <OfferPreview data={offerDataToSend} />

        {files && files.length > 0 && files[0].name && (
          <>
            <Subheader level={1} text="application.files" highlight />
            {files.map((file: any) => (
              <div key={file.name} className="offer-file-preview">
                <InfoParagraph text={file.name} name="application.name" />
                <InfoParagraph date={file.date} name="application.date" />
                {file.file && <InfoParagraph text={file.file.name} name="application.file_name" />}
              </div>
            ))}
          </>
        )}
        {costs && costs.length > 0 && (
          <>
            <Subheader level={1} text="application.costs" highlight />
            {costs.map((c: CostEntity) => (
              <div key={c.name} className="offer-file-preview">
                <InfoParagraph text={c.name} name="application.name" />
                <InfoParagraph name="application.value" price={c.value} />
                <InfoParagraph
                  name="application.cost_tax_rate"
                  text={Object.values(TaxRate)[c.taxRate]}
                />
                <InfoParagraph name="application.date" date={c.date} />
              </div>
            ))}
          </>
        )}

        <ButtonsContainer marginTop>
          <Button
            text="application.edit"
            to="/dashboard/offers/add/preview/edit"
            bottomMargin
            secondary
            disabled={loadingOfferSend}
          />
          <Button
            text="application.save_and_send"
            disabled={loadingOfferSend}
            click={() => {
              saveOffer(offerDataToSend, id, true);
            }}
            bottomMargin
          />

          <Button
            text="application.save"
            disabled={loadingOfferSend}
            primary
            click={() => {
              saveOffer(offerDataToSend, id);
            }}
            bottomMargin
          />
        </ButtonsContainer>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  offerData: state.offer.receivedOfferData,
  client: state.offer.client,
  loadingOfferSend: state.offer.loadingOfferSend,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      saveOffer: offer.saveOffer,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
