import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrderEventType, OrderEvent, TimeSheetTemplateDetails } from 'app-types';
import { modal } from '../../../../actions';
import { ListItem } from '../../../../components/Common';

interface Props {
  item: TimeSheetTemplateDetails;
  callback: (event: OrderEvent) => void;
  hideModal: () => void;
}

const Item = ({ item, callback, hideModal }: Props) => (
  <>
    <ListItem
      singleRow
      header={item.periods.map((period) => period.name).join(', ')}
      icon="time_sheets"
      buttons={[
        {
          text: 'application.use',
          type: 'success',
          click: () => {
            callback({ templateId: item.id, type: OrderEventType.timeSheet });
            hideModal();
          },
        },
      ]}
    />
  </>
);

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ hideModal: modal.hideModal }, dispatch);

export default connect(null, mapDispatchToProps)(Item);
