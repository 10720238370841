import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Page } from '../../../components/Layout';
import AddFromForm from './Add/AddForm';
import AddFromScheme from './Add/AddScheme';
import TimeTracker from './Add/AddTimer/Timer';
import TimeTrackerResults from './Add/AddTimer/Results';
import TimeSheetList from './List';
import AddTimeSheet from './Add';
import SingleTimeSheet from './SingleTimeSheet';
import Dashboard from '../../DashboardCommon';
import YearSummary from './YearSummary';
import SendTimeSheet from './Send';
import { RouteClient, RouteClientAccountant } from '../../../components/Routes';

const timeSheetTabs = [
  {
    title: 'application.time_sheet_list',
    link: '/dashboard/time-sheets/list',
    icon: 'time_sheets',
  },
  {
    title: 'application.add_time_sheet',
    link: '/dashboard/time-sheets/add',
    icon: 'plus',
  },
  {
    title: 'application.time_sheet_schemes',
    link: '/dashboard/time-sheets-schemes',
    icon: 'list',
  },
  {
    title: 'application.annual_summary',
    link: '/dashboard/time-sheets/summary',
    icon: 'summary',
  },
  {
    title: 'application.generator',
    link: '/dashboard/generator/list',
    icon: 'generator',
  },
];

const TimeSheet = () => (
  <>
    <Page light withNavigation>
      <Dashboard navigation={timeSheetTabs}>
        <Switch>
          <Route
            exact
            path="/dashboard/time-sheets"
            render={() => <Redirect to="/dashboard/time-sheets/add" />}
          />
          <Route exact path="/dashboard/time-sheets/add" component={AddTimeSheet} />
          <Route exact path="/dashboard/time-sheets/add/form" component={AddFromForm} />
          <RouteClientAccountant
            needsFullData
            exact
            path="/dashboard/time-sheets/summary"
            component={YearSummary}
          />
          <Route exact path="/dashboard/time-sheets/add/use-scheme/:id" component={AddFromScheme} />
          <Route exact path="/dashboard/time-sheets/add/start-stop" component={TimeTracker} />
          <Route exact path="/dashboard/time-sheets/list" component={TimeSheetList} />
          <Route
            exact
            path="/dashboard/time-sheets/add/time/:start/:finish"
            component={TimeTrackerResults}
          />
          <Route exact path="/dashboard/time-sheets/send/:year" component={SendTimeSheet} />
          <Route exact path="/dashboard/time-sheets/:id" component={SingleTimeSheet} />
        </Switch>
      </Dashboard>
    </Page>
  </>
);

export default TimeSheet;
