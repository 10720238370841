import React from 'react';
import { CategoryRes } from 'app-types';
import { useDispatch } from 'react-redux';
import { ListItem } from '../../../../../components/Common';
import { __ } from '../../../../../services/translation';
import { modal } from '../../../../../actions';
import { Confirmation } from '../../../../../modals';
import EditCategory from '../../../../../modals/EditCategory';
import ApiService from '../../../../../services/api-service';
import { successNotification } from '../../../../../actions/notifications';

interface Props {
  item: CategoryRes;
  callback: (data?: CategoryRes) => void;
}

const CategoryItem = ({ item, callback }: Props) => {
  const dispatch = useDispatch();

  const deleteCategory = async () => {
    await ApiService.callFetch('DELETE', `category/${item.id}`, () => {
      dispatch(successNotification('application.category_deleted'));
      callback();
    });
  };

  const confirmDeleteCategory = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.confirm_category_delete"
          confirmCallback={() => {
            deleteCategory();
          }}
          cancelCallback={() => dispatch(modal.hideModal())}
        />,
      ),
    );
  };

  return (
    <ListItem
      header={item.title}
      paragraphs={[
        {
          icon: 'costs',
          text: item.description,
        },
      ]}
      buttons={[
        {
          text: 'application.edit',
          type: 'secondary',
          click: () => dispatch(modal.showModal(<EditCategory id={item.id} callback={callback} />)),
        },
        { type: 'danger', text: 'application.delete', click: confirmDeleteCategory },
      ]}
    />
  );
};

export default CategoryItem;
