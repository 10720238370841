import React, { Component } from 'react';
import { InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';
import { ClientEntity, GetFullInvoiceResponse } from 'app-types';
import { invoice } from '../../../../actions';
import { Spinner } from '../../../../components/Common';
import { ApplicationState } from '../../../../reducers';
import InvoiceForm from '../AddInvoice/InvoiceForm';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  getCreditnotaData: (id: string) => void;
  setInvoiceClient: (client: ClientEntity) => void;
  creditnotaData: GetFullInvoiceResponse | null;
  loadingCreditnota: boolean;
}

class Creditnota extends Component<InjectedFormProps<{}, Props> & Props> {
  componentDidMount() {
    const { getCreditnotaData, match } = this.props;
    getCreditnotaData(match.params.id);
  }

  componentDidUpdate(prevProps: Props) {
    const { creditnotaData, setInvoiceClient } = this.props;
    if (creditnotaData) {
      setInvoiceClient(creditnotaData.client);
    }
  }

  render() {
    const { loadingCreditnota, creditnotaData } = this.props;
    return <>{loadingCreditnota ? <Spinner /> : <InvoiceForm edit invoice={creditnotaData} />}</>;
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  creditnotaData: state.invoice.creditnotaData,
  loadingCreditnota: state.invoice.loadingCreditnota,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { getCreditnotaData: invoice.getCreditnotaData, setInvoiceClient: invoice.setInvoiceClient },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Creditnota);
