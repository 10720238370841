import { FieldType, TableSchema } from "../typings";

export const changeDataTable: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: "application.website",
      field: "urlAddress",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.phone",
      field: "phone",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.bank_account_number",
      field: "accountNumber",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.kvk",
      field: "kvk",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.company_name",
      field: "companyName",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.city",
      field: "addressCity",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.postcode",
      field: "addressPostCode",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.street",
      field: "addressStreet",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.nip",
      field: "nip",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.owner",
      field: "ownerId",
      mobile: false,
      searchable: false,
      sortable: false,
      defaultSort: false,
      type: FieldType.Text
    }
  ]
};
