import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import userReducer, { UserState } from './user-reducer';
import notificationsReducer, { NotificationsState } from './notifications-reducer';
import menuReducer, { MenuState } from './menu-reducer';
import contactsReducer, { ContactsState } from './contacts-reducer';
import apiTableReducer, { ApiTableState } from './api-table-reducer';
import modalReducer, { ModalState } from './modal-reducer';
import mileageReducer, { MileageState } from './mileage-reducer';
import mileageSchemesReducer, { MileageSchemesState } from './mileage-schemes-reducer';
import apiListReducer, { ApiListState } from './api-list-reducer';
import invoiceReducer, { InvoiceState } from './invoice-reducer';
import clientReducer, { ClientState } from './client-reducer';
import timeSheetReducer, { TimeSheetState } from './time-sheet-reducer';
import ordersReducer, { OrderState } from './orders-reducer';
import langReducer, { LangState } from './lang-reducer';
import statisticsReducer, { StatisticsState } from './statistics-reducer';
import timeTrackingReducer, { TimeTrackingState } from './time-tracking-reducer';
import timeSheetSchemeReducer, { TimeSheetTemplateState } from './time-sheet-scheme-reducer';
import timeReducer, { TimeState } from './time-reducer';
import costReducer, { CostState } from './cost-reducer';
import appStatusReducer, { AppStatusState } from './app-status-reducer';
import userForAdminReducer, { UserForAdminState } from './user-for-admin-reducer';
import headerReducer, { HeaderState } from './header-reducer';
import userInfoReducer, { UserInfoState } from './user-info-reducer';
import otherIncomeReducer, { OtherIncomeState } from './other-income-reducer';
import accountaingReducer, { AccountingState } from './accountaing-reducer';
import costSchemesReducer, { CostSchemesState } from './cost-schemes-reducer';
import pendingInvitationsReducer, { PendingInvitationsState } from './pending-invitations-reducer';
import offerReducer, { OfferState } from './offer-reducer';

export interface ApplicationState {
  form: any;
  user: UserState;
  notifications: NotificationsState;
  menu: MenuState;
  contacts: ContactsState;
  apiTable: ApiTableState;
  apiList: ApiListState;
  modal: ModalState;
  mileage: MileageState;
  mileageSchemes: MileageSchemesState;
  invoice: InvoiceState;
  offer: OfferState;
  client: ClientState;
  timeSheet: TimeSheetState;
  orders: OrderState;
  lang: LangState;
  statistics: StatisticsState;
  timeTracking: TimeTrackingState;
  timeSheetScheme: TimeSheetTemplateState;
  time: TimeState;
  cost: CostState;
  costScheme: CostSchemesState;
  appStatus: AppStatusState;
  userForAdmin: UserForAdminState;
  header: HeaderState;
  userInfo: UserInfoState;
  otherIncome: OtherIncomeState;
  accounting: AccountingState;
  pendingInvitations: PendingInvitationsState;
}

const appReducer = combineReducers({
  form: reduxFormReducer,
  user: userReducer,
  notifications: notificationsReducer,
  menu: menuReducer,
  contacts: contactsReducer,
  apiTable: apiTableReducer,
  apiList: apiListReducer,
  modal: modalReducer,
  mileage: mileageReducer,
  mileageSchemes: mileageSchemesReducer,
  invoice: invoiceReducer,
  offer: offerReducer,
  client: clientReducer,
  timeSheet: timeSheetReducer,
  orders: ordersReducer,
  lang: langReducer,
  statistics: statisticsReducer,
  timeTracking: timeTrackingReducer,
  timeSheetScheme: timeSheetSchemeReducer,
  time: timeReducer,
  cost: costReducer,
  costScheme: costSchemesReducer,
  appStatus: appStatusReducer,
  userForAdmin: userForAdminReducer,
  header: headerReducer,
  userInfo: userInfoReducer,
  otherIncome: otherIncomeReducer,
  accounting: accountaingReducer,
  pendingInvitations: pendingInvitationsReducer,
});

const rootReducer = (state: any, action: any) => appReducer(state, action);
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
