import { AccountantConnectorDetails, AuthLoginUserResult } from 'app-types';

export interface AccountingState {
  managedUser: AuthLoginUserResult | null;
}

const initialState: AccountingState = {
  managedUser: null,
};

interface SetManagedUser {
  type: 'SELECT_MANAGED_USER';
  payload: AuthLoginUserResult;
}

type Action = SetManagedUser;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SELECT_MANAGED_USER':
      return {
        ...state,
        managedUser: action.payload,
      };

    default:
      return state;
  }
};
