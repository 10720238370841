import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ServiceSchemaEntity, ServiceEntity, NewServiceForInvoice } from 'app-types';
import { change, formValueSelector, arrayPush } from 'redux-form';
import { isBefore } from 'date-fns';
import { modal } from '../../../../../actions';
import { Button } from '../../../../../components/Common';
import { __ } from '../../../../../services/translation';
import ServicesForInvoice from '../../../../../modals/ServicesForInvoice';
import { AddService, SelectServiceType } from '../../../../../modals';
import { ApplicationState } from '../../../../../reducers';
import { formatDate } from '../../../../../utils/format-date';

interface Props {
  index: number;
  showModal: (content: React.ReactNode) => void;
  changeField: (formId: string, fieldId: string, value: any) => void;
  arrayFieldPush: (formId: string, fieldId: string, value: any) => void;
  dates?: Date[];
}

class AddServicButton extends React.Component<Props> {
  private setService = (item: ServiceSchemaEntity) => {
    const { changeField, index, dates, arrayFieldPush } = this.props;
    // When using service template the ID should not be used - because it will mess with services
    if (!dates) {
      const service: NewServiceForInvoice = {
        name: item.name,
        description: item.description,
        price: item.price,
        taxRate: Number(item.taxRate),
        net: !!Number(item.net),
        discount: item.discount,
        discountType: Number(item.discountType),
        type: item.type,
        quantity: 60,
      };

      changeField('invoiceForm', `services[${index}]`, service);
    } else {
      dates
        .sort((a, b) => (isBefore(b, a) ? 1 : -1))
        .forEach((date, i) => {
          const service: NewServiceForInvoice = {
            name: item.name,
            description: `${item.description} ${formatDate(date)}`,
            price: item.price,
            taxRate: Number(item.taxRate),
            net: !!Number(item.net),
            discount: item.discount,
            discountType: Number(item.discountType),
            type: item.type,
            quantity: 60,
          };
          if (i === 0) {
            changeField('invoiceForm', `services[${index}]`, service);
          } else arrayFieldPush('invoiceForm', 'services', service);
        });

      if (dates[0].getMonth() !== dates[dates.length - 1].getMonth()) {
        changeField(
          'invoiceForm',
          'mark',
          `${__('application.week')} ${formatDate(dates[0], false, 'I, LLLL Y')} / ${formatDate(
            dates[dates.length - 1],
            false,
            'LLLL Y',
          )}`,
        );
      } else {
        changeField(
          'invoiceForm',
          'mark',
          `${__('application.week')} ${formatDate(dates[0], false, 'I, LLLL Y')}`,
        );
      }
    }
  };

  private openServiceModal = (fields: any[]) => {
    const { showModal } = this.props;
    showModal(
      <ServicesForInvoice callback={(item: ServiceSchemaEntity) => this.setService(item)} />,
    );
  };

  private addNewService = () => {
    const { showModal } = this.props;
    showModal(<AddService callback={(service: ServiceEntity) => this.setService(service)} />);
  };

  private selectServiceType = () => {
    const { showModal } = this.props;
    showModal(
      <SelectServiceType selectService={this.openServiceModal} newService={this.addNewService} />,
    );
  };

  render() {
    return (
      <Button
        leftIcon
        small
        icon="plus"
        type="button"
        text="application.add_invoice_line"
        click={this.selectServiceType}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const services: ServiceEntity[] = formValueSelector('invoiceForm')(state, 'services');
  return {
    services,
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      showModal: modal.showModal,
      changeField: change,
      arrayFieldPush: arrayPush,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddServicButton);
