import { AccountType } from '../payment';

export enum Language {
  pl = 'pl',
  en = 'en',
  nl = 'nl',
}

export interface ClientUserRegisterRequest {
  email: string;
  pwd: string;
  parentId: string;
}

export interface AccountantUserRegisterRequest {
  email: string;
  pwd: string;
  companyName: string;
  kvk: string;
}

export interface UpdatePassRequest {
  oldPwd: string;
  newPwd: string;
}

export interface ResetPassLinkRequest {
  email: string;
}

export interface ResetPassRequest {
  newPwd: string;
  userId: string;
  updateToken: string;
}

export interface ClientUserUpdateRequest {
  email: string;
  isEnabled?: boolean;
  companyName?: string;
  addressCity?: string;
  addressPostCode?: string;
  addressStreet?: string;
  nip?: string;
  currentAccoutnType: AccountType;
  language: Language;
  urlAddress: string;
  phone: string;
  accountNumber: string;
  kvk: string;
}

export interface CheaterUser {
  id: string;
  companyName: string;
  email: string;
  isCheater: boolean;
  isBlocked: boolean;
  changeCount: number;
}

export interface UserListForAdmin {
  id: string;
  email: string;
  subscribedTo: Date;
  nip: string;
  companyName: string;
  addressStreet: string;
  addressPostCode: string;
  addressCity: string;
  isEnabled: boolean;
  isBlocked: boolean;
  isCheater: boolean;
  currentAccountType: string;
  paymentType: string;
  language: string;
  createdAt: Date;
  allowEmails: boolean;
  allowPushes: boolean;
}

export interface AccountantListForAdmin {
  id: string;
  email: string;
  createdAt: Date;
  servedUsersCount: number;
}
export interface UserBlockRequest {
  blockReason: string;
}

export interface UserNotificationRequest {
  title: string;
  text: string;
}

export interface UserCheaterRequest {
  isCheater: boolean;
}

export interface UpdateInvoiceAnnotationRequest {
  annotation: string;
}

export interface UpdateInvoiceMailRequest {
  invoiceMailTitle: string;
  invoiceMailBody: string;
}
