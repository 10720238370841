import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import AddOtherIncome from './AddOtherIncome';
import OtherIncomeList from './List';
import SingleOtherIncome from './SingleOtherIncome';

const incomeTabs = [
  {
    title: 'application.other_income_list',
    link: '/dashboard/other-income/list',
    icon: 'other-income',
  },
  {
    title: 'application.add_other_income',
    link: '/dashboard/other-income/add',
    icon: 'plus',
  },
];

const OtherIncome = () => (
  <>
    <Page light withNavigation>
      <Dashboard navigation={incomeTabs}>
        <Switch>
          <Route exact path="/dashboard/other-income" render={() => <Redirect to="/dashboard/other-income/list" />} />
          <Route exact path="/dashboard/other-income/add" component={AddOtherIncome} />
          <Route exact path="/dashboard/other-income/list" component={OtherIncomeList} />
          <Route exact path="/dashboard/other-income/:id" component={SingleOtherIncome} />
        </Switch>
      </Dashboard>
    </Page>
  </>
);

export default OtherIncome;
