import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ItfApiForm, PublicItfFormBuilderComponentApi } from 'itf_formbuilder_react';
import ReactSelect from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';
import { MileageToCreate } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ApiService from '../../services/api-service';
import { Spinner, Button, RequiredIndicator, MileageFieldsMapper } from '../../components/Common';
import { history, flatpickr } from '../../App';
import { modal, notifications, apiList } from '../../actions';
import { __ } from '../../services/translation';
import { formOnTranslateString } from '../../utils/trans-form';
import { FormContainer, ButtonsContainer } from '../../components/Layout';
import { ExtendedOnFormGloballyRegisteredProps } from '../../types/extendedOnFormGloballyRegisteredProps';

interface Props {
  mileageId: string;
  successNotification: (text: string) => void;
  hideModal: () => void;
  refreshApiList: () => void;
  formVals?: any;
}

const EditMileage = ({
  mileageId,
  successNotification,
  hideModal,
  refreshApiList,
  formVals,
}: Props) => {
  const [route, setRoute] = useState({
    startPoint: '',
    finishPoint: '',
    distance: 0,
    time: 0,
  });
  const [formState, setFormState] = useState<any>({});
  let itfForm: any;
  const dispatch = useDispatch();

  const setRef = (form: any) => {
    itfForm = form;
  };

  const updateRouteState = (vals: any) => {
    const { startPoint, finishPoint, distance, time } = vals;
    setRoute({ startPoint, finishPoint, distance, time });
  };

  const registerFormCallbacks = (api: PublicItfFormBuilderComponentApi) => {
    updateRouteState(window.globalForms['edit-mileage'].values);
    setFormState(window.globalForms['edit-mileage'].values);

    if (formVals) {
      (api as ExtendedOnFormGloballyRegisteredProps).setValues({ ...formVals });
    }

    window.globalForms['edit-mileage'].valueChangedClb = (newValues: MileageToCreate) => {
      setFormState(window.globalForms['edit-mileage'].values);
      updateRouteState(newValues);
    };
  };

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.edit_mileage" />
      </ModalHeader>
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="edit-mileage"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm(`mileage/form/update-item/${mileageId}`)}
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm(`mileage/${mileageId}`, values, 'PATCH')
          }
          onSavedSuccessfully={() => {
            successNotification('application.mileage_was_edited.');
            hideModal();
            refreshApiList();
            history.push('/dashboard/mileages/list');
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text="application.save"
                primary
              />
            </ButtonsContainer>
          }
          thirdPartyComponents={{ ReactSelect, ReactCreatableSelect, flatpickr }}
          onTranslateString={formOnTranslateString}
          registerAsGlobalForm
          onFormGloballyRegistered={registerFormCallbacks}
          onRenderFullField={(
            field: any,
            errors: any,
            fieldHtmlId: any,
            currentValue: any,
            onChange: any,
            onFocus: any,
            onBlur: any,
          ) => (
            <MileageFieldsMapper
              data={{
                itfForm,
                field,
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
                route,
                formState,
                allowOwnAddress: true,
                noDurationHelp: true,
                closedRelatedModalCallback: (vals: any) => {
                  dispatch(
                    modal.showModal(
                      <EditMileage
                        mileageId={mileageId}
                        successNotification={successNotification}
                        hideModal={hideModal}
                        refreshApiList={refreshApiList}
                        formVals={vals}
                      />,
                    ),
                  );
                },
              }}
            />
          )}
        />
      </FormContainer>
    </ModalContent>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      refreshApiList: apiList.refreshApiList,
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(EditMileage);
