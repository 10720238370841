import React from 'react';
import './Content.scss';

interface Props {
  children: any;
  center?: boolean;
  forBottomButton?: boolean;
  className?: string;
  spread?: boolean;
}

const Content = ({ children, center, forBottomButton, className, spread }: Props) => (
  <div
    className={`content ${center ? 'center' : ''} ${forBottomButton ? 'for-bottom-button' : ''} ${className || ''}  ${
      spread ? 'spread' : ''
    } `}
  >
    {children}
  </div>
);

export default Content;
