import { NewCertificateReq, PaymentUrl } from 'app-types';
import { ItfApiForm } from 'itf_formbuilder_react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { notifications } from '../../../../actions';
import { Button, FieldsMapper, RequiredIndicator, Spinner } from '../../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../../components/Layout';
import useHeader from '../../../../hooks/useHeader';
import ApiService from '../../../../services/api-service';
import { formOnTranslateString } from '../../../../utils/trans-form';

const CertificateNonArrears = () => {
  const dispatch = useDispatch();
  useHeader('addons.certificate_non_arrears', '/dashboard/client/addons');
  return (
    <FormContainer>
      <ItfApiForm
        formId="client-form"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        requiredIndicator={<RequiredIndicator />}
        schemaFetcher={() => ApiService.loadForm('addons/form/new-certificate/')}
        sendFetcher={(formId: string, values: NewCertificateReq) =>
          ApiService.sendForm('addons/prepare-certificate', values, 'POST')
        }
        onSavedSuccessfully={(data: string) => {
          window.location.replace(data);
        }}
        loadingInfo={<Spinner transparent />}
        submitButton={
          <ButtonsContainer>
            <Button type="submit" className="form-submit-button" text="application.send" primary />
          </ButtonsContainer>
        }
        onTranslateString={(string: string) => formOnTranslateString(string)}
        onRenderFullField={FieldsMapper}
        registerAsGlobalForm
      />
    </FormContainer>
  );
};

export default CertificateNonArrears;
