import React, { Component } from 'react';
import { InjectedFormProps } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ClientEntity, OfferRes } from 'app-types';
import { offer } from '../../../../actions';
import { Spinner } from '../../../../components/Common';
import { ApplicationState } from '../../../../reducers';
import OfferForm from '../AddOffer/OfferForm';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  getSingleOffer: (id: string) => void;
  setOfferClient: (client: ClientEntity) => void;
  loadingSingleOffer: boolean;
  singleOffer: OfferRes | null;
}

class EditOffer extends Component<InjectedFormProps<{}, Props> & Props> {
  componentDidMount() {
    const { getSingleOffer, match } = this.props;
    getSingleOffer(match.params.id);
  }

  componentDidUpdate(prevProps: Props) {
    const { singleOffer, setOfferClient } = this.props;
    if (singleOffer) {
      setOfferClient(singleOffer.client);
    }
  }

  render() {
    const { loadingSingleOffer, singleOffer } = this.props;

    return <>{loadingSingleOffer ? <Spinner /> : <OfferForm offer={singleOffer} edit />}</>;
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  singleOffer: state.offer.singleOffer,
  loadingSingleOffer: state.offer.loadingSingleOffer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getSingleOffer: offer.getSingleOffer,
      setOfferClient: offer.setOfferClient,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(EditOffer);
