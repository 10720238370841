import React from 'react';
import { CreateTimeSheetWithPeriods, CreateUpdateTimeSheetReq, TimeSheetEntity } from 'app-types';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { ButtonsContainer, FormContainer } from '../../Layout';
import { Button, RequiredIndicator, Spinner, FieldsMapper } from '..';
import { notifications } from '../../../actions';
import ApiService from '../../../services/api-service';
import { formOnTranslateString } from '../../../utils/trans-form';
import { history, flatpickr } from '../../../App';
import Periods from '../Periods';

interface Props {
  formUrl: string;
  withPeriods?: boolean;
}

// eslint-disable-next-line react/prefer-stateless-function
const TimeSheetForm = ({ formUrl, withPeriods }: Props) => {
  const dispatch = useDispatch();
  return (
    <>
      <FormContainer>
        <ItfApiForm
          formId="time-sheet-form"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm(formUrl)}
          sendFetcher={(
            formId: string,
            values: CreateTimeSheetWithPeriods | CreateUpdateTimeSheetReq,
          ) => {
            if (withPeriods) {
              return ApiService.sendForm('time-sheet/new-periods', values, 'POST');
            } else {
              return ApiService.sendForm('time-sheet/new', values, 'POST');
            }
          }}
          onSavedSuccessfully={(data: TimeSheetEntity) => {
            history.push('/dashboard/time-sheets/list');
            dispatch(notifications.successNotification('application.time_sheet_was_created'));
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text="application.save"
                primary
              />
            </ButtonsContainer>
          }
          onTranslateString={(string: string) => formOnTranslateString(string)}
          onRenderFullField={(
            field: any,
            errors: any,
            fieldHtmlId: any,
            currentValue: any,
            onChange: any,
            onFocus: any,
            onBlur: any,
          ) => {
            if (field.id === 'periods') {
              return (
                <Periods
                  updatePeriods={onChange}
                  errors={errors ? errors[0] : {}}
                  initialPeriods={currentValue || []}
                />
              );
            } else {
              return FieldsMapper(
                field,
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              );
            }
          }}
          thirdPartyComponents={{ flatpickr }}
        />
      </FormContainer>
    </>
  );
};

export default TimeSheetForm;
