import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { MileageEntity, InvoiceFile, CostRes, GetFullInvoiceResponse } from 'app-types';
import ApiService from '../../../../services/api-service';
import { invoice as invoiceActions, modal, header } from '../../../../actions';
import { ButtonsContainer } from '../../../../components/Layout';
import {
  Spinner,
  Button,
  InfoParagraph,
  Subheader,
  TaxRateTable,
  SingleCostPreview,
  OptionalImage,
  SingleFilePreview,
} from '../../../../components/Common';
import { Confirmation } from '../../../../modals';
import { ApplicationState } from '../../../../reducers';
import './SingleInvoice.scss';
import { DownloadService } from '../../../../services/download-service';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  getSingleInvoice: (mileageId: string) => void;
  loading: boolean;
  mileage: MileageEntity;
  showModal: (content: React.ReactNode) => void;
  deleteSingleInvoice: (id: string) => void;
  hideModal: () => void;
  invoice: GetFullInvoiceResponse;
  deleteFileFromInvoice: (fileId: string, invoiceId: string) => void;
  setHeader: (header: string) => void;
}

class SingleInvoice extends Component<Props> {
  componentDidMount() {
    const { getSingleInvoice, match, setHeader } = this.props;
    getSingleInvoice(match.params.id);
    setHeader('application.invoice');
  }

  private deleteInvoice = () => {
    const { showModal, deleteSingleInvoice, hideModal, match } = this.props;
    showModal(
      <Confirmation
        text="application.are_you_sure_you_want_to_delete_this_invoice?"
        confirmCallback={() => deleteSingleInvoice(match.params.id)}
        cancelCallback={() => hideModal()}
      />,
    );
  };

  render() {
    const { loading, match, invoice } = this.props;

    const img = (img: InvoiceFile) => (
      <div className="single-invoice-file">
        <InfoParagraph text={img.name} name="application.name" />
        <InfoParagraph date={img.date} name="application.date" />
        <OptionalImage
          url={`invoice-file/one/${img.id}`}
          buttonLabel="application.see_file"
          alt="File"
        />
      </div>
    );

    const downloadPDF = async () => {
      await DownloadService.download(`invoice/pdf/${match.params.id}`);
    };

    return (
      <div className="single-invoice-container">
        {loading ? (
          <Spinner overlay />
        ) : (
          <div className="single-invoice">
            <InfoParagraph name="application.number" text={invoice.number} />
            <InfoParagraph name="application.mark" text={invoice.mark} />
            <InfoParagraph name="application.date" date={invoice.createdAt} />
            <InfoParagraph name="application.payment_term" date={invoice.paymentTerm} />
            {invoice.annotation && (
              <InfoParagraph
                breakLine
                text={invoice.annotation}
                name="application.invoice_annotation"
              />
            )}
            <Subheader highlight level={1} text="application.client" paddingTop />
            <InfoParagraph name="application.name" text={invoice.client.name} />
            <InfoParagraph name="application.address_street" text={invoice.client.address} />
            <InfoParagraph name="application.city" text={invoice.client.city} />
            <InfoParagraph name="application.tin" text={invoice.client.btw} />

            {invoice.files.length > 0 && (
              <>
                <Subheader highlight level={1} text="application.files" paddingTop />
                {invoice.files.map((file: InvoiceFile) => (
                  <SingleFilePreview file={file} key={file.id} />
                ))}
              </>
            )}
            {invoice.costs.length > 0 && (
              <>
                <Subheader highlight level={1} text="application.costs" paddingTop />
                {invoice.costs.map((cost: CostRes) => (
                  <SingleCostPreview cost={cost} key={cost.id} displayLinkToCost />
                ))}
              </>
            )}
            <Subheader highlight level={1} text="application.services" paddingTop />
            <TaxRateTable data={invoice.services} showSum showNames details />
            <ButtonsContainer marginTop>
              <Button
                text="application.edit"
                primary
                noShadow
                to={`/dashboard/invoices/edit/${match.params.id}`}
                rightMargin
                bottomMargin
              />
              <Button
                text="application.delete"
                danger
                noShadow
                click={this.deleteInvoice}
                rightMargin
                bottomMargin
              />
              <Button
                text="application.download_pdf"
                click={downloadPDF}
                noShadow
                rightMargin
                secondary
                bottomMargin
              />
              <Button
                text="application.send_to_client"
                to={`/dashboard/invoices/send/${match.params.id}`}
                noShadow
                secondary
                bottomMargin
              />
            </ButtonsContainer>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getSingleInvoice: invoiceActions.getSingleInvoice,
      showModal: modal.showModal,
      hideModal: modal.hideModal,
      deleteSingleInvoice: invoiceActions.deleteSingleInvoice,
      deleteFileFromInvoice: invoiceActions.deleteFileFromInvoice,
      setHeader: header.setHeader,
    },
    dispatch,
  );

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.invoice.loadingSingleInvoice,
  invoice: state.invoice.singleInvoice,
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleInvoice);
