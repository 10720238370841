import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserRole } from 'app-types';
import { ApplicationState } from '../../../../reducers';
import Alert from '../../Alert';
import './ManagedUser.scss';
import Button from '../../Button';
import { ButtonsContainer } from '../../../Layout';
import { modal } from '../../../../actions';
import { SelectManagedUser } from '../../../../modals';

const ManagedUser = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.user.details);
  const { managedUser } = useSelector((state: ApplicationState) => state.accounting);
  if (user.role !== UserRole.Accountant) return null;

  const selectUser = () => {
    dispatch(modal.showModal(<SelectManagedUser />));
  };

  return (
    <div className="managed-user-container">
      <div className="managed-user-info">
        {!managedUser && <Alert simple type="error" text="application.select_managed_user_menu" />}
        {managedUser && <strong>{managedUser.companyName || managedUser.email}</strong>}
        {managedUser && (
          <div>
            <span>{managedUser.email}</span>
          </div>
        )}
      </div>
      <ButtonsContainer max>
        <Button
          small
          text={managedUser ? 'application.change_managed_user' : 'application.select_managed_user'}
          click={selectUser}
        />
        {managedUser && (
          <Button secondary small text="application.settings" to="/dashboard/client-data" />
        )}
      </ButtonsContainer>
    </div>
  );
};

export default ManagedUser;
