import ApiService from '../services/api-service';
import { notifications, modal, apiList } from '../actions';
import { parsePickerDate } from '../utils/parse-date';
import {
  receiveStartTime,
  receiveFinishTime,
  receiveStartDate,
  receiveFinishDate,
  clearTime,
} from './time';
import { history } from '../App';
import { formatDate } from '../utils/format-date';
import { formatTime } from '../utils/format-time';

export const getSingleTimeSheet = (mileageId: string, edit?: boolean) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_SINGLE_TIME_SHEET_SUCCESS',
    'GET_SINGLE_TIME_SHEET_FAILED',
    (values: any) => {
      if (edit) {
        const startDate = formatDate(values.start);
        const startTime = formatTime(values.start);
        const finishDate = formatDate(values.finish);
        const finishTime = formatTime(values.finish);
        dispatch(receiveStartTime(startTime.slice(0, 2), startTime.slice(3, 5)));
        dispatch(receiveFinishTime(finishTime.slice(0, 2), finishTime.slice(3, 5)));
        dispatch(receiveStartDate(startDate));
        dispatch(receiveFinishDate(finishDate));
      }
    },
    null,
    `time-sheet/detail/${mileageId}`,
    'LOADING_SINGLE_TIME_SHEET',
  );
};

export const deleteTimeSheet = (mileageId: string) => async (dispatch: any) => {
  await ApiService.delete(
    dispatch,
    'DELETE_SINGLE_TIME_SHEET_SUCCESS',
    'DELETE_SINGLE_TIME_SHEET_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(apiList.refreshApiList());
      dispatch(notifications.successNotification('application.time_sheet_deleted'));
      history.push('/dashboard/time-sheets/list');
    },
    null,
    `time-sheet/${mileageId}`,
    'LOADING_TIME_SHEET',
  );
};

export const addTimeSheet = (timeSheet: any) => async (dispatch: any) => {
  await ApiService.post(
    dispatch,
    'ADD_TIME_SHEET_SUCCESS',
    'ADD_TIME_SHEET_FAILED',
    (data: any) => {
      dispatch(notifications.successNotification('application.time_sheet_added'));
      dispatch(clearTime());
      history.push('/dashboard/time-sheets/list');
    },
    null,
    'time-sheet/new',
    'ADD_TIME_SHEET_LOADING',
    {
      name: timeSheet.name,
      start: parsePickerDate(timeSheet.startDate, timeSheet.startTime),
      finish: parsePickerDate(timeSheet.finishDate, timeSheet.finishTime),
    },
  );
};

export const updateTimeSheet = (timeSheet: any, id: string) => async (dispatch: any) => {
  await ApiService.patch(
    dispatch,
    'UPDATE_TIME_SHEET_SUCCESS',
    'UPDATE_TIME_SHEET_FAILED',
    () => {
      dispatch(notifications.successNotification('application.your_data_has_been_updated.'));
      dispatch(modal.hideModal());
      dispatch(apiList.refreshApiList());
    },
    null,
    `time-sheet/${id}`,
    'UPDATE_TIME_SHEET_LOADING',
    {
      name: timeSheet.name,
      start: parsePickerDate(timeSheet.startDate, timeSheet.startTime),
      finish: parsePickerDate(timeSheet.finishDate, timeSheet.finishTime),
    },
  );
};

export const getTimeSheetYears = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_TIME_SHEET_YEARS_SUCCESS',
    'GET_TIME_SHEET_YEARS_FAILED',
    null,
    null,
    'time-sheet/years',
    'LOADING_TIME_SHEET_YEARS',
  );
};
