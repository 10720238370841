import React from 'react';
import { faList, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from '../../DashboardCommon';
import { Page } from '../../../components/Layout';
import AddOrder from './Add';
import OrderList from './List';
import AddOrderList from '../MileageSchemes/MileageSchemeList';
import SingleOrder from './SingleOrder';
import Edit from './Edit';

const clientTabs = [
  {
    title: 'mileage_generator.list',
    link: '/dashboard/generator/list',
    icon: 'list',
  },
  {
    title: 'mileage_generator.add',
    link: '/dashboard/generator/add',
    icon: 'plus',
  },
];

const MileageGenerator = () => (
  <Page light withNavigation>
    <Dashboard navigation={clientTabs}>
      <Switch>
        <Route exact path="/dashboard/generator" render={() => <Redirect to="/dashboard/generator/list" />} />
        <Route exact path="/dashboard/generator/list/" component={OrderList} />
        <Route exact path="/dashboard/generator/add/" component={AddOrder} />
        <Route exact path="/dashboard/generator/add/list" render={() => <AddOrderList toOrder />} />
        <Route exact path="/dashboard/generator/edit/:id" component={Edit} />
        <Route exact path="/dashboard/generator/:id" component={SingleOrder} />
      </Switch>
    </Dashboard>
  </Page>
);

export default MileageGenerator;
