import React from 'react';
import { useDispatch } from 'react-redux';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { ClientEntity, ClientType } from 'app-types';
import { Icon, ListItem } from '../../../../../components/Common';
import { modal, client } from '../../../../../actions';
import EditClient from '../../../../../modals/EditClient';
import { Confirmation } from '../../../../../modals';
import './Item.scss';

interface Props {
  name: string;
  item: ClientEntity;
}

const ClientItem = ({ name, item }: Props) => {
  const dispatch = useDispatch();
  const editClient = () => {
    dispatch(modal.showModal(<EditClient id={item.id} />));
  };

  const deleteClient = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.are_you_sure_you_want_to_delete_this_client?"
          confirmCallback={() => dispatch(client.deleteClient(item.id))}
          cancelCallback={() => dispatch(modal.hideModal())}
        />,
      ),
    );
  };

  return (
    <ListItem
      header={
        item.name ||
        ((item.firstName || item.lastName) && `${item.firstName} ${item.lastName}`) ||
        item.email
      }
      headerRight={
        item.type === ClientType.COMPANY ? (
          <Icon icon="company_client" />
        ) : (
          <Icon icon="private_client" />
        )
      }
      data={
        <>
          <div className="client-address">
            {item.address}, {item.city}
          </div>
        </>
      }
      icon={faUser}
      buttons={[
        { text: 'application.details', to: `/dashboard/clients-and-contacts/clients/${item.id}` },

        {
          type: 'secondary',
          text: 'application.edit',
          click: editClient,
        },
        {
          type: 'success',
          text: 'application.issue_invoice',
          to: `/dashboard/invoices/add/client/${item.id}`,
        },
        { type: 'danger', text: 'application.delete', click: deleteClient },
      ]}
    />
  );
};

export default ClientItem;
