import { format } from 'date-fns';
import { formatDate } from './format-date';
import { formatTime } from './format-time';

export const parseDate = (date: any) => {
  const dateString = formatDate(date);
  const timeString = formatTime(date);

  return `${dateString} ${timeString}`;
};

export const parsePickerDate = (date: string | null, hour: any) => {
  const parsedDate = date ? new Date(date) : new Date();
  return new Date(
    parsedDate.getFullYear(),
    parsedDate.getMonth(),
    parsedDate.getDate(),
    hour.split(':')[0],
    hour.split(':')[1],
  );
};

export const getTime = (date: string) => {
  const parsedDate = new Date(date);
  const hours =
    parsedDate.getHours().toString().length === 1
      ? `0${parsedDate.getHours()}`
      : parsedDate.getHours();
  const minutes =
    parsedDate.getMinutes().toString().length === 1
      ? `0${parsedDate.getMinutes()}`
      : parsedDate.getMinutes();
  return `${hours}:${minutes}`;
};

export const getTimeFromDate = (date: Date): string => format(date, 'HH:mm');
