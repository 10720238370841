import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, InjectedFormProps, reduxForm, formValueSelector } from 'redux-form';
import { SomeUserEntity, ClientUserEntity } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { Spinner, Button, Input } from '../../components/Common';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import { ApplicationState } from '../../reducers';
import { modal, mileage } from '../../actions';
import { __ } from '../../services/translation';
import './MileageDescription.scss';

interface Props {
  hideModal: () => void;
  isChecked: boolean;
  triggerMileageModal: () => void;
  user: ClientUserEntity;
}

class MileageDescription extends React.Component<InjectedFormProps<any, any> & Props> {
  render() {
    const { hideModal, isChecked, handleSubmit, triggerMileageModal, user } = this.props;
    return (
      <ModalContent>
        <ModalHeader>
          <ModalTitle title="mileage_disclaimer.header" />
        </ModalHeader>
        <p>{__('mileage_disclaimer.paragraph_1')}</p>
        <p>{__('mileage_disclaimer.paragraph_2')}</p>
        <ul className="mileage-disclaimer-list">
          <li>{__('mileage_disclaimer.list_1')}</li>
          <li>{__('mileage_disclaimer.list_2')}</li>
          <li>{__('mileage_disclaimer.list_3')}</li>
          <li>{__('mileage_disclaimer.list_4')}</li>
          <li>{__('mileage_disclaimer.list_5')}</li>
        </ul>
        <p>{__('mileage_disclaimer.paragraph_3')}</p>
        <p>{__('mileage_disclaimer.paragraph_4')}</p>
        <ul className="mileage-disclaimer-list">
          <li>{__('mileage_disclaimer.list_1')}</li>
          <li>{__('mileage_disclaimer.list_2')}</li>
          <li>{__('mileage_disclaimer.list_3')}</li>
          <li>{__('mileage_disclaimer.list_4')}</li>
          <li>{__('mileage_disclaimer.list_5')}</li>
          <li>{__('mileage_disclaimer.list_6')}</li>
          <li>{__('mileage_disclaimer.list_7')}</li>
        </ul>
        <p>{__('mileage_disclaimer.paragraph_5')}</p>

        <form className="mileage-description-submit-section">
          <Field
            name="isChecked"
            type="checkbox"
            component={Input}
            label={
              user.acceptedMileageInfo
                ? __('mileage_disclaimer.always_show')
                : __('mileage_disclaimer.dont_show_again')
            }
          />
          <ButtonsContainer marginTop marginLittle>
            <Button
              type="button"
              text="application.save_and_close"
              noShadow
              click={() => {
                if (isChecked) {
                  triggerMileageModal();
                }
                hideModal();
              }}
            />
          </ButtonsContainer>
        </form>
      </ModalContent>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      hideModal: modal.hideModal,
      triggerMileageModal: mileage.triggerMileageModal,
    },
    dispatch,
  );

const selector = formValueSelector('mileageDescription');

const mapStateToProps = (state: ApplicationState) => {
  const isChecked = selector(state, 'isChecked');
  return {
    isChecked,
    user: state.user.details,
    initialValues: {
      isChecked: false,
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, any>({
    form: 'mileageDescription',
    enableReinitialize: true,
  })(MileageDescription),
);
