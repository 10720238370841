import React, { Component } from 'react';
import { UserRole, ClientUserEntity, SomeUserEntity, AccountantUserEntity } from 'app-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Spinner } from '../../../components/Common';
import { ApplicationState } from '../../../reducers';
import EditUserData from '../../../modals/EditUserData';
import { modal } from '../../../actions';
import { checkIfMissingData } from '../../../utils/user';

interface Props {
  exact?: boolean;
  path?: string;
  component: any;
  isActiveSub?: any;
  accountantNeedsUser?: boolean;
  needsFullData?: boolean;
}

const RouteClientAccountant = ({
  exact,
  path,
  component,
  accountantNeedsUser,
  needsFullData,
}: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.user.details);
  const loading = useSelector((state: ApplicationState) => state.user.loading);
  const managedUser = useSelector((state: ApplicationState) => state.accounting.managedUser);
  let content;

  if (
    !user ||
    ![UserRole.Client, UserRole.Accountant].includes(user.role) ||
    (accountantNeedsUser && user.role === UserRole.Accountant && !managedUser)
  ) {
    content = <Route render={() => <Redirect to="/" />} />;
  } else if (needsFullData && !managedUser && checkIfMissingData(user as ClientUserEntity)) {
    dispatch(modal.showModal(<EditUserData />));
    content = <Route render={() => <Redirect to="/" />} />;
  } else if ((user as ClientUserEntity | AccountantUserEntity).isBlocked === true) {
    content = <Route render={() => <Redirect to="/blocked" />} />;
  } else {
    content = <Route exact={exact} path={path} component={component} />;
  }
  return <>{loading ? <Spinner overlay /> : content}</>;
};

export default RouteClientAccountant;
