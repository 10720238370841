import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';

import { faFileInvoice, faEdit, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiTable, invoice } from '../../../../../actions';
import { Button, ListParagraph, ListItem } from '../../../../../components/Common';
import { __ } from '../../../../../services/translation';
import { formatDate } from '../../../../../utils/format-date';
import { formatPrice } from '../../../../../utils/format-price';
import './InvoiceListItem.scss';
import { DownloadService } from '../../../../../services/download-service';
import { ApplicationState } from 'src/reducers';

interface Props {
  item?: any;
  id?: string;
  date: string;
  triggerPaymentMark: (id: string) => void;
  updateNotPaidInvoiceCount: (action: 'INCREASE' | 'DECREASE') => void;
}

interface State {
  paid: any;
}

const InvoiceListItem = ({ item, triggerPaymentMark, updateNotPaidInvoiceCount }: Props) => {
  const [paid, setPaid] = useState(item.isPaid);

  const creditnotaButton = () =>
    !item.isCreditNote ? (
      <Button
        small
        to={`/dashboard/invoices/creditnota/${item.id}`}
        text={__('application.creditnote')}
        noShadow
      />
    ) : null;

  const isExpired = () => {
    const addDays = (date: string | Date, days: number) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };
    const payday = addDays(item.createdAt, item.paymentTerm);
    const today = new Date();
    return today > payday && !item.isPaid;
  };

  const handlePaidTrigger = () => {
    triggerPaymentMark(item.id);
    setPaid(!paid);
    updateNotPaidInvoiceCount(!paid ? 'DECREASE' : 'INCREASE');
  };

  const paragraphs = [
    { icon: 'time_sheets', text: formatDate(item.createdAt) },
    { icon: 'pencil', text: item.mark },
    { icon: 'costs', text: formatPrice(item.totalVal + item.totalTaxVal) },
    {
      icon: faHourglassEnd,
      text: `${__('application.payment_term_exceeded')}`,
      warning: true,
      hide: !isExpired(),
    },
    { icon: 'invoices', text: `${__('application.creditnote')}`, hide: !item.isCreditNote },
  ];

  const downloadPDF = async () => {
    await DownloadService.download(`invoice/pdf/${item.id}`);
  };

  return (
    <div className="invoice-list-item-uberwrapper">
      <ListItem
        header={item.number}
        subheader={item.client}
        icon={faFileInvoice}
        paragraphs={paragraphs}
        buttons={[
          {
            text: 'application.details',
            to: `/dashboard/invoices/${item.id}`,
          },
          {
            text: 'application.download_pdf',
            click: downloadPDF,
            type: 'secondary',
          },
          {
            text: 'application.edit',
            to: `/dashboard/invoices/edit/${item.id}`,
            type: 'secondary',
          },
          {
            text: 'application.send_to_client',
            to: `/dashboard/invoices/send/${item.id}`,
            type: 'success',
          },
        ]}
        noShadow
      />
      <div className="invoice-bottom-container">
        <div className="invoice-is-paid-container">
          <span className="invoice-is-paid-label"> {__('application.is_paid?')}</span>
          <Switch
            onChange={() => handlePaidTrigger()}
            checked={paid}
            offColor="#cccccc"
            onColor="#faba1a"
            uncheckedIcon={false}
            checkedIcon={false}
            height={25}
            className="invoice-paid-switch"
          />
        </div>
        {creditnotaButton()}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ triggerPaymentMark: invoice.triggerPaymentMark }, dispatch);

export default connect(null, mapDispatchToProps)(InvoiceListItem);
