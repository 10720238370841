import React, { useEffect } from 'react';
import { AccountPrice } from 'app-types';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Page, ButtonsContainer, Container } from '../../../components/Layout';
import { Button } from '../../../components/Common';
import { __ } from '../../../services/translation';
import useHeader from '../../../hooks/useHeader';
import { formatPrice } from '../../../utils/format-price';
import { isMobileBuild } from '../../../utils/is-mobile';
import { history } from '../../../App';
import './PaymentPlans.scss';

const PaymentPlans = () => {
  useEffect(() => {
    if (isMobileBuild()) history.push('/');
  }, []);
  useHeader('application.payment_plans');
  return (
    <>
      <Page stretch light>
        <Container paddingTop>
          <table className="payment-plans-table">
            <thead>
              <tr>
                <th className="no-border" />
                <th className="header-border">{__('application.standard')}</th>
                <th>{__('application.unlimited')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{__('application.contact_list')}</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
              </tr>
              <tr>
                <td>{__('application.kvk_customer_search')}</td>
                <td> 6 {__('application.per_hour')} </td>
                <td> 6 {__('application.per_hour')} </td>
              </tr>
              <tr>
                <td>{__('application.sending_invoices_with_e-mail')}</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
              </tr>
              <tr>
                <td>{__('application.pdf_invoice_download')}</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
              </tr>
              <tr>
                <td>{__('application.basic_mileage_creation')}</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
              </tr>
              <tr>
                <td>{__('application.mileage_creation_using_gps')}</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
              </tr>
              <tr>
                <td>{__('application.invoices_with_time_sheets')}</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
              </tr>
              <tr>
                <td>{__('application.annual_mileage_summary')}</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
              </tr>
              <tr>
                <td>{__('application.time_sheets')}</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
              </tr>
              <tr>
                <td>{__('application.standing_orders')}</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{__('application.unlimited_cost_upload')}</strong>
                </td>
                <td>
                  <p>
                    <FontAwesomeIcon icon={faTimes} className="cross" />
                  </p>
                  <p>5/{__('application.month')}</p>
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="tick" />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{__('application.monthly_subscription_-_regular')}</strong>
                </td>
                <td>
                  <p>{formatPrice(AccountPrice.BasicMonthly)}</p>
                </td>
                <td>
                  <p>{formatPrice(AccountPrice.ProfessionalMonthly)}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{__('application.annual_subscription_-_regular')}</strong>
                </td>
                <td>
                  <p>{formatPrice(AccountPrice.BasicYearly)}</p>
                </td>
                <td>
                  <p>{formatPrice(AccountPrice.ProfessionalYearly)}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <ButtonsContainer marginTop>
            <Button text="application.get_started" to="/buy-subscription" rightMargin />
          </ButtonsContainer>
        </Container>
      </Page>
    </>
  );
};

export default PaymentPlans;
