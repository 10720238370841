import { Validator } from 'redux-form';
import { addYears, isBefore } from 'date-fns';
import { __ } from './translation';
import { formatDate } from '../utils/format-date';
import { store } from '../App';
import { UserEntity } from '../../../heyzzp-back/types';

interface FunctionsObj {
  [name: string]: Validator;
}

export default class ValidationService {
  static readyFunctions: FunctionsObj = {};

  static isValidEmail(email: string) {
    return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(
      email,
    );
  }

  static isNipValid(nip: string) {
    if (!nip) return false;
    const nipWithoutDashes = nip.replace(/-/g, '');
    const reg = /^[0-9]{10}$/;
    if (reg.test(nipWithoutDashes) === false) {
      return false;
    }
    const digits = `${nipWithoutDashes}`.split('');
    const checksum =
      (6 * parseInt(digits[0]) +
        5 * parseInt(digits[1]) +
        7 * parseInt(digits[2]) +
        2 * parseInt(digits[3]) +
        3 * parseInt(digits[4]) +
        4 * parseInt(digits[5]) +
        5 * parseInt(digits[6]) +
        6 * parseInt(digits[7]) +
        7 * parseInt(digits[8])) %
      11;

    return parseInt(digits[9]) === checksum;
  }

  static requiredWithAllowedFalse(value: string | number | boolean) {
    return value || value === '0' || value === 0 || value === false
      ? undefined
      : __('error.field_required');
  }

  static required(value: string | number) {
    return value || value === '0' || value === 0 ? undefined : __('error.field_required');
  }

  static email(email: string) {
    return ValidationService.isValidEmail(email) ? undefined : __('error.invalid_email');
  }

  static password(password: string) {
    return password.length >= 6 ? undefined : __('error.password_too_short');
  }

  static zipcode(zipcode: string) {
    return /^[0-9]{2}\-[0-9]{3}$/.test(zipcode) ? undefined : __('error.invalid_postal_code');
  }

  static nip(nip: string) {
    return ValidationService.isNipValid(nip) ? undefined : __('error.invalid_nip');
  }

  static phone(phone: string) {
    return /^(?:\(?\+?48)?(?:[-\.\(\)\s]*(\d)){9}\)?$/.test(phone)
      ? undefined
      : __('error.invalid_phone_format');
  }

  static max32(text: string) {
    return text && text.length > 32 ? __('error.max32') : undefined;
  }

  static max50(text: string) {
    return text && text.length > 50 ? __('error.max50') : undefined;
  }

  static max55(text: string) {
    return text && text.length > 55 ? __('error.max55') : undefined;
  }

  static max80(text: string) {
    return text && text.length > 80 ? __('error.max80') : undefined;
  }

  static max500(text: string) {
    return text && text.length > 500 ? __('error.max500') : undefined;
  }

  static max250(text: string) {
    return text && text.length > 250 ? __('error.max250') : undefined;
  }

  static max650(text: string) {
    return text && text.length > 650 ? __('error.max650') : undefined;
  }

  static max25(value: number) {
    return value > 25 ? __('error.max25') : undefined;
  }

  static max100(value: number) {
    return value > 100 ? __('error.max100') : undefined;
  }

  static min0(value: number) {
    return value <= 0 ? __('error.should_be_greater_than_0') : undefined;
  }

  static mustAcceptRules(value: boolean) {
    return !value ? __('error.must_accept_rules') : undefined;
  }

  static passwordLength(pwd: string) {
    return pwd.length < 8 ? __('error.password_should_be_at_least_8_characters_long') : undefined;
  }

  static match(matchName: string) {
    if (!ValidationService.readyFunctions[`match_${matchName}`]) {
      ValidationService.readyFunctions[`match_${matchName}`] = function (
        value: string,
        allValues: string[],
      ) {
        return value !== allValues[matchName as any] ? __('error.passwords_dont_match') : undefined;
      };
    }
    return ValidationService.readyFunctions[`match_${matchName}`];
  }

  static costOrFile(matchName: string, index: number) {
    const funcName = `costOrFile_${matchName}_${index}`;
    if (!ValidationService.readyFunctions[funcName]) {
      ValidationService.readyFunctions[funcName] = function (value: string, allValues: any) {
        if (allValues.costs[index]) {
          const secondValue = allValues.costs[index][matchName];
          return (!value || value === '0') && (!secondValue || secondValue === '0')
            ? __('error.required_cost_or_file')
            : undefined;
        }
      };
      return undefined;
    }

    return ValidationService.readyFunctions[funcName];
  }

  static minDateValidator(date: string) {
    const { user } = store.getState();

    const minDate = new Date(`01-01-${addYears(new Date(), -(user as UserEntity).yearsPeriod)}`);

    if (isBefore(new Date(date), minDate)) {
      return __('error.date_unavailable', { minDate: formatDate(minDate) });
    }
  }
}
