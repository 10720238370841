import { FieldType, TableSchema } from "../typings";

export const serviceSchemaTable: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: "application.name",
      field: "name",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "application.price",
      field: "price",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Price
    },
    {
      name: "application.tax_rate",
      field: "taxRate",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    }
  ]
};
