import React from 'react';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { RouteComponentProps } from 'react-router-dom';
import { Spinner, Button, FieldsMapper, RequiredIndicator, LoginParagraph } from '../../../components/Common';
import ApiService from '../../../services/api-service';
import { notifications } from '../../../actions';
import { history } from '../../../App';
import { formOnTranslateString } from '../../../utils/trans-form';
import { FormContainer, ButtonsContainer, BottomContainer } from '../../../components/Layout';

interface RouteProps {
  userId: string;
  updateToken: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  userId: string;
  updateToken: string;
}

const NewPassword = ({ match }: Props) => {
  const dispatch = useDispatch();
  return (
    <FormContainer>
      <ItfApiForm
        formId="reset-password"
        schemaMayBeDynamic={false}
        schemaCacheEnabled
        requiredIndicator={<RequiredIndicator />}
        schemaFetcher={() => ApiService.loadForm('user/form/set-pass')}
        sendFetcher={(formId: string, values: any) =>
          ApiService.sendForm(
            'user/reset-pass',
            {
              ...values,
              userId: match.params.userId,
              updateToken: match.params.updateToken,
            },
            'PATCH',
          )
        }
        onSavedSuccessfully={() => {
          dispatch(notifications.successNotification('application.new_password_set'));
          history.push('/login');
        }}
        loadingInfo={<Spinner transparent />}
        submitButton={
          <BottomContainer>
            <Button type="submit" className="form-submit-button" text="application.submit" noShadow primary />
            <LoginParagraph />
          </BottomContainer>
        }
        onTranslateString={formOnTranslateString}
        onRenderFullField={FieldsMapper}
      />
    </FormContainer>
  );
};

export default NewPassword;
