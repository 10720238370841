import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { __ } from '../../../services/translation';
import { formatDate } from '../../../utils/format-date';
import { formatTime } from '../../../utils/format-time';
import { formatDistance } from '../../../utils/format-distance';
import { formatPrice } from '../../../utils/format-price';
import { formatDuration } from '../../../utils/format-duration';
import './InfoParagraph.scss';

interface Props {
  name: string | number;
  translate?: string;
  text?: string | number | null;
  boolean?: boolean;
  date?: Date | string;
  time?: Date | string;
  distance?: number;
  url?: string;
  email?: string;
  phone?: string;
  duration?: number;
  durationNoUnit?: number;
  price?: number;
  optional?: boolean;
  narrow?: boolean;
  breakLine?: boolean;
}

const InfoParagraph = ({
  name,
  text,
  boolean,
  date,
  time,
  distance,
  price,
  url,
  phone,
  email,
  translate,
  duration,
  durationNoUnit,
  optional,
  narrow,
  breakLine,
}: Props) => (
  <div className={`info-paragraph ${narrow ? 'narrow' : ''} ${breakLine ? 'break-line' : ''}`}>
    <strong className="info-paragraph-label">
      {__(name)} {optional ? `- ${__('application.optional')}` : null}{' '}
    </strong>
    <div className="info-paragraph-content">
      {typeof translate !== 'undefined' ? __(translate) || '-' : null}
      {typeof text !== 'undefined' ? text || '-' : null}
      {typeof url !== 'undefined'
        ? (
            <a href={url} target="_blank">
              {url}
            </a>
          ) || '-'
        : null}
      {typeof phone !== 'undefined'
        ? (
            <>
              <a href={`tel:${phone}`}>{phone}</a>
              <FontAwesomeIcon icon={faPhone} className="phone-icon" />
            </>
          ) || '-'
        : null}
      {typeof email !== 'undefined' ? <a href={`mailto:${email}`}>{email}</a> || '-' : null}
      {typeof boolean !== 'undefined'
        ? boolean
          ? __('application.yes')
          : __('application.no')
        : null}
      {typeof date !== 'undefined' && formatDate(date)}
      {typeof time !== 'undefined' && formatTime(time)}
      {typeof distance !== 'undefined' && formatDistance(distance)}
      {typeof price !== 'undefined' && formatPrice(price)}
      {typeof duration !== 'undefined' && formatDuration(duration)}
      {typeof durationNoUnit !== 'undefined' && formatDuration(durationNoUnit, true)}
    </div>
  </div>
);

export default InfoParagraph;
