import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { menu, auth } from '../../../../actions';

import { __ } from '../../../../services/translation';

import { ApplicationState } from '../../../../reducers';

import './MenuFooter.scss';

interface Props {
  toggleMenu: () => void;
  logout: () => void;
}

const MenuFooter = ({ toggleMenu, logout }: Props) => (
  <div className="menu-bottom-container">
    <a
      className="logout-link"
      type="button"
      tabIndex={0}
      onClick={() => {
        toggleMenu();
        logout();
      }}
    >
      <div className="logout-icon-wrapper">
        <img src="/assets/icons/logout.svg" />
      </div>
      {__('application.logout')}
    </a>
  </div>
);

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
  toggled: state.menu.toggled,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ toggleMenu: menu.toggleMenu, logout: auth.logout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuFooter);
