import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ApiService from '../../services/api-service';
import { Spinner, Button, FieldsMapper, RequiredIndicator } from '../../components/Common';

import { notifications, modal, apiList } from '../../actions';
import { formOnTranslateString } from '../../utils/trans-form';

import { FormContainer, ButtonsContainer } from '../../components/Layout';
import { flatpickr } from '../../App';
import { __ } from '../../services/translation';

interface Props {
  id: string;
  successNotification: (text: string) => void;
  refreshApiList: () => void;
  hideModal: () => void;
}

const CopyTimeSheet = ({ id, successNotification, hideModal, refreshApiList }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.copy_time_sheet" />
    </ModalHeader>
    <p>{__('timesheet.copy_description')}</p>
    <FormContainer>
      <ItfApiForm
        formId="copy-timesheet"
        schemaMayBeDynamic={false}
        schemaCacheEnabled
        requiredIndicator={<RequiredIndicator />}
        schemaFetcher={() => ApiService.loadForm('mileage/form/duplicate-for-dates/')}
        sendFetcher={(formId: string, values: any) => {
          ApiService.sendForm(`time-sheet/duplicate-for-dates/${id}`, values, 'PATCH');
        }}
        onSavedSuccessfully={() => {
          successNotification('application.copied.');
          hideModal();
          refreshApiList();
        }}
        loadingInfo={<Spinner transparent />}
        submitButton={
          <ButtonsContainer>
            <Button type="submit" className="form-submit-button" text="copy.button" primary />
          </ButtonsContainer>
        }
        onTranslateString={formOnTranslateString}
        thirdPartyComponents={{ flatpickr }}
        onRenderFullField={FieldsMapper}
      />
    </FormContainer>
  </ModalContent>
);

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
      refreshApiList: apiList.refreshApiList,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(CopyTimeSheet);
