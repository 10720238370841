import React from 'react';
import { connect } from 'react-redux';
import { Capacitor, Plugins } from '@capacitor/core';
import { bindActionCreators } from 'redux';
import { AppSettingsEntity, ClientUserEntity, SomeUserEntity, UserRole } from 'app-types';
import { modal, user as userActions, info, auth } from '../../../actions';
import { ApplicationState } from '../../../reducers';
import './AppWrapper.scss';
import EditUserData from '../../../modals/EditUserData';
import { checkIfMissingData } from '../../../utils/user';
import { i18n } from '../../../services/translation';
import ApiService from '../../../services/api-service';
import { AppOutdated } from '../../../modals';
import { Spinner } from '../../Common';

const { Device } = Plugins;

interface Props {
  children: React.ReactNode;
  user: SomeUserEntity;
  initializeMobile: () => void;
  getUserDetails: () => void;
  loading: boolean;
  hideTrialIndicator?: boolean;
  getUserInfo: () => void;
  showModal: (content: React.ReactNode, isClosable: boolean, isFullScreen?: boolean) => void;
}

interface State {
  interval: any;
  loadingMobileInfo: boolean;
}

class AppWrapper extends React.Component<Props, State> {
  state = {
    interval: undefined,
    loadingMobileInfo: false,
  };

  componentDidMount() {
    const { getUserDetails, getUserInfo } = this.props;
    getUserDetails();
    getUserInfo();

    if (Capacitor.isNative) {
      this.initMobile();
    } else {
      getUserDetails();
      getUserInfo();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { user, showModal, getUserInfo } = this.props;
    const { interval } = this.state;
    console.log('UUUUUUSER', user);
    const client = user as ClientUserEntity;
    if (client && client.isActived && client.role === UserRole.Client) {
      if (checkIfMissingData(client)) {
        showModal(<EditUserData />, true);
      }

      if (!interval) {
        const interval = setInterval(() => {
          getUserInfo();
        }, 10000);
        this.setState({
          interval,
        });
      }
    } else if (interval) {
      clearInterval(interval);
      this.setState({
        interval: undefined,
      });
    }
  }

  initMobile() {
    const { initializeMobile } = this.props;
    initializeMobile();
    this.fetchAppSettings();
  }

  async fetchAppSettings() {
    const { showModal } = this.props;
    this.setState({
      loadingMobileInfo: true,
    });
    await ApiService.callFetch('GET', 'settings', async (res: AppSettingsEntity) => {
      const device = await Device.getInfo();
      if (
        (device.operatingSystem === 'android' && device.appVersion !== res.androidVersion) ||
        (device.operatingSystem === 'ios' && device.appVersion !== res.iosVersion)
      ) {
        showModal(<AppOutdated settings={res} system={device.operatingSystem} />, false, true);
      }
    });
    this.setState({
      loadingMobileInfo: false,
    });
  }

  render() {
    const { children, user } = this.props;
    const { loadingMobileInfo } = this.state;
    if (loadingMobileInfo) return <Spinner />;
    if (!i18n.loaded) return null;
    return <div className="app-wrapper">{children}</div>;
  }
}
const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
  loading: state.user.loading,
  hideTrialIndicator: state.menu.hideTrialIndicator,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      initializeMobile: auth.initializeMobile,
      getUserDetails: userActions.getUserDetails,
      showModal: modal.showModal,
      getUserInfo: info.getUserInfo,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
