import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { MileageSchemaEntity } from 'app-types';
import { mileageSchemes, modal, header } from '../../../../actions';
import { ButtonsContainer } from '../../../../components/Layout';
import { Spinner, Button, InfoParagraph } from '../../../../components/Common';
import { EditMileageScheme, Confirmation } from '../../../../modals';
import { ApplicationState } from '../../../../reducers';
import ReturnData from './ReturnData';
import GoogleMapsButton from '../../../../components/Common/GoogleMapsButton';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  getSingleMileageScheme: (mileageId: string) => void;
  loading: boolean;
  scheme: MileageSchemaEntity;
  showModal: (content: React.ReactNode) => void;
  deleteSingleMileageScheme: (id: string) => void;
  hideModal: () => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class SingleMileageScheme extends Component<Props> {
  componentDidMount() {
    const { match, getSingleMileageScheme, setHeader } = this.props;
    getSingleMileageScheme(match.params.id);
    setHeader('application.mileage_scheme');
  }

  private deleteMileageScheme = () => {
    const { showModal, hideModal, scheme, deleteSingleMileageScheme } = this.props;
    showModal(
      <Confirmation
        text="application.are_you_sure_you_want_to_delete_this_mileage_scheme?"
        confirmCallback={() => deleteSingleMileageScheme(scheme.id)}
        cancelCallback={() => hideModal()}
      />,
    );
  };

  private editMileageScheme = () => {
    const { showModal, scheme } = this.props;
    showModal(<EditMileageScheme mileageId={scheme.id} />);
  };

  render() {
    const { loading, scheme } = this.props;
    return (
      <>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <InfoParagraph name="mileage.route_name" text={scheme.name} />
            <InfoParagraph name="application.start_point" text={scheme.startPoint} />
            <InfoParagraph name="application.finish_point" text={scheme.finishPoint} />
            <InfoParagraph name="mileage.distance_km" distance={scheme.distance} />
            <InfoParagraph name="application.time" duration={scheme.time} />
            {scheme.startPointReturn && (
              <>
                <InfoParagraph
                  name="application.start_point_return"
                  text={scheme.startPointReturn}
                />
                <InfoParagraph
                  name="application.finish_point_return"
                  text={scheme.finishPointReturn}
                />
                <InfoParagraph
                  name="application.distance_km_return"
                  distance={scheme.distanceReturn}
                />
                <InfoParagraph name="application.time_return" duration={scheme.timeReturn} />
              </>
            )}
            {scheme.regNumber && (
              <>
                <InfoParagraph name="application.registration_number" text={scheme.regNumber} />
                <InfoParagraph name="application.car_model" text={scheme.carModel} />
                <InfoParagraph name="application.car_type" text={scheme.carType} />
              </>
            )}
            <ButtonsContainer marginTop>
              <GoogleMapsButton
                startPoint={scheme.startPoint}
                finishPoint={scheme.finishPoint}
                startPointReturn={scheme.startPointReturn}
                finishPointReturn={scheme.finishPointReturn}
                both={!!scheme.startPointReturn}
                previewRoute
              />
              <Button
                click={this.deleteMileageScheme}
                text="application.delete"
                danger
                noShadow
                rightMargin
                bottomMargin
              />
              <Button
                click={this.editMileageScheme}
                secondary
                text="application.edit"
                noShadow
                bottomMargin
              />
            </ButtonsContainer>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  scheme: state.mileageSchemes.single,
  loading: state.mileageSchemes.loadingSingle,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getSingleMileageScheme: mileageSchemes.getSingleMileageScheme,
      showModal: modal.showModal,
      hideModal: modal.hideModal,
      deleteSingleMileageScheme: mileageSchemes.deleteSingleMileageScheme,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SingleMileageScheme));
