export const parseDurationInput = (val: any) => {
  if (typeof val === 'undefined' || !val) return val;

  const isNegative = val.includes('-');

  const value = val.toString().replace('-', '');

  console.log('PARSING', val, isNegative, value);

  const parts = value.split(':');
  const clear = parts.join('');
  if (!/^\d+$/.test(clear)) return '00:00';
  let time = clear;
  const { length } = time;

  if (length === 3) time = `0${time}`;
  if (length === 2) time = `${time}00`;
  if (length === 1) time = `0${time}00`;

  let minutes = parseInt(time.slice(time.length - 2));
  let hours = parseInt(time.slice(0, time.length - 2));
  if (minutes > 59) {
    minutes -= 60;
    hours += 1;
  }

  const output = [hours < 10 ? `0${hours}` : hours, minutes < 10 ? `0${minutes}` : minutes].join(':');

  console.log('PARSING', isNegative ? `-${output}` : output);
  return isNegative ? `-${output}` : output;
};
