import React from 'react';
import clsx from 'clsx';
import { __ } from '../../../services/translation';

import './WelcomeHeader.scss';

interface Props {
  text: string;
  className?: string;
}

const WelcomeHeader = ({ text, className }: Props) => <h1 className={clsx('welcome-header', className)}>{__(text)}</h1>;

export default WelcomeHeader;
