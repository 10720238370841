import { FieldType, TableSchema } from "../typings";

export const invoiceSchemaForUser: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: "application.name",
      field: "name",
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    }
  ]
};
