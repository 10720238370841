import React, { useState } from 'react';
import { TableSchema, costsForUser } from 'app-tables';
import { ApiList, Tabs } from '../../../../components/Common';
import CostItem from '../Item';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import useHeader from '../../../../hooks/useHeader';
import CategoryTiles from '../CategoryTiles';
import { SingleTab } from '../../../../types/tabs';
import { ButtonsContainer } from '../../../../components/Layout';

enum CostLayout {
  LIST,
  TILES,
}

const tabs: SingleTab[] = [
  { label: 'application.list', value: CostLayout.LIST },
  { label: 'application.categories', value: CostLayout.TILES },
];

const CostList = () => {
  useHeader('application.cost_list', '/');
  const scheme: TableSchema = new InteractiveTableSchema(costsForUser);

  const [layout, setLayout] = useState<CostLayout>(CostLayout.TILES);

  return (
    <>
      <ButtonsContainer>
        <Tabs auto activeTab={layout} chooseTab={setLayout} tabs={tabs} />
      </ButtonsContainer>
      {layout === CostLayout.LIST && (
        <ApiList apiEndpointSubUrl="cost/list" scheme={scheme} component={CostItem} padded />
      )}
      {layout === CostLayout.TILES && <CategoryTiles />}
    </>
  );
};

export default CostList;
