import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { modal } from '../../actions';
import { Button, Subheader } from '../../components/Common';
import { ButtonsContainer } from '../../components/Layout';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import './ChooseQuarterModal.scss';

interface Props {
  setDates: (startAt: Date, endAt: Date) => void;
}

const ChooseQuarterModal = ({ setDates }: Props) => {
  const dispatch = useDispatch();
  const [year, setYear] = useState(new Date().getFullYear());
  const [quarter, setQuarter] = useState(0);
  const currentYear = new Date().getFullYear();
  const lastYear = new Date().getFullYear() - 1;
  const quarters = [
    { name: 'I', start: new Date(year, 0, 1), finish: new Date(year, 2, 31) },
    {
      name: 'II',
      start: new Date(year, 3, 1),
      finish: new Date(year, 5, 30),
    },
    { name: 'III', start: new Date(year, 6, 1), finish: new Date(year, 8, 30) },
    { name: 'IV', start: new Date(year, 9, 1), finish: new Date(year, 11, 31) },
  ];
  const getButtonHighlight = (index: number) => {
    if (index === quarter) {
      return false;
    }
    return true;
  };

  const getYearHighlight = (val: number) => {
    if (val === year) {
      return false;
    }
    return true;
  };
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.choose_quarter" />
      </ModalHeader>
      <Subheader level={2} text="application.quarter" paddingTop />
      <div className="quarters-container">
        {quarters.map((item: any, index: number) => (
          <Button
            text={item.name}
            click={() => setQuarter(index)}
            className="button-quarter"
            auto
            secondary={getButtonHighlight(index)}
            small
          />
        ))}
      </div>
      <Subheader level={2} text="application.year" paddingTop />
      <div style={{ display: 'flex' }}>
        <Button
          text={lastYear}
          click={() => setYear(lastYear)}
          secondary={getYearHighlight(lastYear)}
          small
          className="choose-quarter-modal-year-button"
        />
        <Button
          text={currentYear}
          secondary={getYearHighlight(currentYear)}
          click={() => setYear(currentYear)}
          small
          className="choose-quarter-modal-year-button"
        />
      </div>
      <ButtonsContainer marginTop>
        <Button
          text="application.choose"
          click={() => {
            setDates(quarters[quarter].start, quarters[quarter].finish);
            dispatch(modal.hideModal());
          }}
          className="choose-quarter-modal-choose-button"
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default ChooseQuarterModal;
