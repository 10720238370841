import React from 'react';
import { MileageCarRes } from 'app-types';
import { useDispatch } from 'react-redux';
import { ListItem } from '../../../../../components/Common';
import { __ } from '../../../../../services/translation';
import { modal, mileageCar } from '../../../../../actions';
import { Confirmation } from '../../../../../modals';
import { EditMileageCar } from '../../../../../modals';

interface Props {
  item: MileageCarRes;
}

const CostSchemeItem = ({ item }: Props) => {
  const dispatch = useDispatch();

  const deleteScheme = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.are_you_sure_you_want_to_delete_this_car"
          confirmCallback={() =>
            dispatch(mileageCar.deleteMileageCar(item.id, '/dashboard/mileage-cars'))
          }
          cancelCallback={() => dispatch(modal.hideModal())}
        />,
      ),
    );
  };

  return (
    <ListItem
      header={item.carModel}
      paragraphs={[
        {
          icon: 'car_number',
          text: item.regNumber,
        },
        {
          icon: 'car_type',
          text: item.carType,
        },
      ]}
      buttons={[
        {
          text: 'application.edit',
          type: 'secondary',
          click: () => dispatch(modal.showModal(<EditMileageCar id={item.id} />)),
        },
        { type: 'danger', text: 'application.delete', click: deleteScheme },
      ]}
    />
  );
};

export default CostSchemeItem;
