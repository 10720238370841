import {
  SomeUserEntity,
  UserDataEntity,
  AuthLoginAdminResult,
  AuthLoginUserResult,
  AuthLoginAccountantResult,
} from 'app-types';
import Cookies from 'universal-cookie';
import { getDefaultLanguage } from '../utils/language';
import { MileageCoordinates } from '../types/mileage';
import { i18n } from './translation';
import 'capacitor-secure-storage-plugin';
import { Capacitor, Plugins } from '@capacitor/core';
const { SecureStoragePlugin } = Plugins;


export default class SessionService {
  static setUser(
    user:
      | SomeUserEntity
      | UserDataEntity
      | AuthLoginAdminResult
      | AuthLoginUserResult
      | AuthLoginAccountantResult,
  ) {
    return localStorage.setItem('user', JSON.stringify(user));
  }

  static clearSessionStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  static clearUserSession() {
    localStorage.removeItem('user');
  }

  static isUserSet() {
    return JSON.parse(localStorage.getItem('user') || 'false');
  }

  static getUser() {
    return JSON.parse(localStorage.getItem('user') || 'null');
  }

  static setCurrentLanguage(lang: string) {
    const cookies = new Cookies();
    cookies.set('lang', lang);
  }

  static getCurrentLanguage() {
    let language = '';
    try {
      const cookies = new Cookies();
      language = cookies.get('lang') || getDefaultLanguage();
    } catch {
      language = getDefaultLanguage();
    }
    i18n.changeLang(language);
    return language;
  }

  static getCurrentCoordinates = () => {
    try {
      return JSON.parse(localStorage.getItem('coordinates') || '[]');
    } catch {
      return [];
    }
  };

  static addCoordinates = (coords: MileageCoordinates) => {
    try {
      const current = SessionService.getCurrentCoordinates();
      current.push(coords);
      localStorage.setItem('coordinates', JSON.stringify(current));
    } catch (err) {
      console.log(err);
    }
  };

  static setTrackingStart = (date: Date) => {
    try {
      localStorage.setItem('trackingStart', JSON.stringify(date));
    } catch (err) {
      localStorage.setItem('trackingStart', JSON.stringify(new Date()));
    }
  };

  static clearTrackingStart = () => {
    localStorage.removeItem('trackingStart');
  };

  static getTrackingStart = (): Date | null => {
    try {
      const date = JSON.parse(localStorage.getItem('trackingStart') || 'null');
      return date ? new Date(date) : null;
    } catch (err) {
      return null;
    }
  };

  static clearCoordinates = () => {
    localStorage.removeItem('trackingStart');
    localStorage.removeItem('coordinates');
  };

  static setToken = async (token: string) => {
    if (!Capacitor.isNative) return;
    await SecureStoragePlugin.set({ key: 'heyzzp_token', value: token });
  }


  static getToken = async () => {
    if (!Capacitor.isNative) return;
    return SecureStoragePlugin.get({ key: 'heyzzp_token' });
  }
}
