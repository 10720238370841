import React from 'react';
import { CategoryRes } from 'app-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { TableResponse } from 'app-tables';
import { Spinner } from '../../../components/Common';
import debounce from '../../../utils/debounce';
import { __ } from '../../../services/translation';
import Suggestion from './Suggestion';
import './CategoryAutosuggestion.scss';

import ApiService from '../../../services/api-service';
import RequiredIndicator from '../RequiredIndicator';
import NewCategorySuggestion from './NewCategorySuggestion';

interface Props {
  allowNewCategory?: boolean;
  onNewCategorySelect?: (name: string) => void;
  input: {
    onChange: (data: any) => void;
    name: string;
    id: string;
    value: string;
  };
  placeholder: string;
  onOptionSelect: (data: CategoryRes | null) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  meta: { error: any; touched: any };
}

interface State {
  active: boolean;
  resultsCategories: CategoryRes[];
  loading: boolean;
  selectedOption: CategoryRes | null;
  value: string;
}

class CategoryAutosuggestion extends React.Component<Props, State> {
  debouncedSearch: any;

  constructor(props: Props) {
    super(props);
    this.debouncedSearch = debounce(this.fetchList, 200);

    this.state = {
      active: false,
      selectedOption: null,
      resultsCategories: [],
      loading: false,
      value: '',
    };
  }

  componentDidMount() {
    const { input } = this.props;
    if (input.value) {
      this.setState({
        value: input.value,
        selectedOption: {
          title: input.value,
          id: '', // mock
          description: '', // mock
        },
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { input } = this.props;
    const { active, selectedOption, value } = this.state;

    if (prevProps.input.value !== input.value && input.value) {
      this.setState({
        value: input.value,
        selectedOption: {
          id: '',
          title: input.value,
          description: '',
        },
      });
    }

    if (
      (!prevState.active && active && !selectedOption) ||
      (value && !active && !selectedOption && value !== input.value)
    ) {
      this.handleChange('');
    }
  }

  private fetchList = async (query: string) => {
    const result: { resultsCategories: CategoryRes[] } = {
      resultsCategories: [],
    };

    const listCategories: TableResponse<CategoryRes> = await ApiService.callFetch(
      'GET',
      `category/list/?alternative=true&searchBy=title&searchQuery=${query}`,
    );
    result.resultsCategories = listCategories.items;

    this.setState({
      ...result,
      loading: false,
    });
  };

  private handleChange = (val: string) => {
    const { onOptionSelect, input } = this.props;
    onOptionSelect(null);
    this.setState({
      selectedOption: null,
    });
    this.setState({ loading: true, value: val });
    this.debouncedSearch(val);
  };

  private handleOwnCategorySelect = (option: CategoryRes) => {
    const { onOptionSelect } = this.props;
    this.setState({
      selectedOption: option,
      value: option.title,
    });
    onOptionSelect(option);
  };

  render() {
    const {
      input,
      label,
      placeholder,
      required,
      meta,
      allowNewCategory,
      onNewCategorySelect,
      disabled,
    } = this.props;
    const { resultsCategories, loading, active, value } = this.state;
    const inputLabel = label && (
      <label htmlFor={input.name}>
        {__(label)} {required && <RequiredIndicator />}{' '}
      </label>
    );
    return (
      <div className="input-container">
        {inputLabel}

        <div className="input-sugestion-container">
          <div className="input-group">
            <input
              readOnly={disabled}
              autoComplete="off"
              className="category-autosuggestion-input"
              type="text"
              id="category-autosuggestion-input"
              value={value}
              onChange={(e: any) => {
                this.handleChange(e.target.value);
              }}
              onFocus={(e: any) => {
                if (!disabled) {
                  this.setState({ active: true });
                }
              }}
              onBlur={(e: any) => {
                window.setTimeout(() => {
                  this.setState({ active: false });
                }, 100);
              }}
              placeholder={__(placeholder)}
            />

            {!disabled && (
              <FontAwesomeIcon icon={faSearch} className="category-autosuggestion-icon" />
            )}
          </div>

          {active && (resultsCategories.length || loading || (allowNewCategory && value)) && (
            <ul className="category-suggestions">
              {loading && <Spinner overlay />}
              {resultsCategories.length > 0 && (
                <>
                  <li className="category-suggestions-separator">
                    {__('application.your_categories')}
                  </li>
                  {resultsCategories.map((r: CategoryRes) => (
                    <Suggestion
                      item={r}
                      onSelect={(category: CategoryRes) => {
                        this.handleOwnCategorySelect(category);
                      }}
                      key={r.title}
                    />
                  ))}
                </>
              )}

              {allowNewCategory && typeof onNewCategorySelect === 'function' && value && (
                <>
                  <li className="category-suggestions-separator">
                    {__('application.add_category')}
                  </li>
                  <NewCategorySuggestion name={value} onNewCategorySelect={onNewCategorySelect} />
                </>
              )}
            </ul>
          )}
          {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
        </div>
      </div>
    );
  }
}

export default CategoryAutosuggestion;
