export enum NotificationType {
  SYSTEM,
  ADMINISTRATION,
}

export interface NotificationBase {
  id: string;
  date: Date;
  title: string;
  text: string;
  buttonText?: string;
  buttonHref?: string;
  image?: string;
}

export interface NotificationEntity extends NotificationBase {
  internal: boolean;
  email: boolean;
  push: boolean;
}

export interface UserNotificationEntity {
  id: string;
  date: Date;
  title?: string;
  text?: string;
  notification?: NotificationEntity;
  read: boolean;
  type: NotificationType;
}

export interface UserNotificationTableRes {
  id: string;
  title: string;
  read: boolean;
  date: Date;
}

export interface UserNotificationDetails {
  id: string;
  date: Date;
  title?: string;
  text?: string;
  notification?: NotificationBase;
  read: boolean;
}

export interface NotificationTableRes {
  id: string;
  date: Date;
  title: string;
  internal: boolean;
  email: boolean;
  push: boolean;
}

export interface NotificationReqFormData {
  title: string;
  text: string;
  internal: string;
  email: string;
  push: string;
  buttonText?: string;
  buttonHref?: string;
  image?: string;
}

export interface CreateNotificationReq {
  title: string;
  text: string;
  internal: boolean;
  email: boolean;
  push: boolean;
  buttonText?: string;
  buttonHref?: string;
  image?: string;
}
