import { DiscountType, ServiceEntity, TaxRate } from './service-entity';

export enum ServiceType {
  UNIT,
  TIME,
}

export interface serviceSchemaRequest {
  name: string;
  price: number;
  taxRate: number;
  discount: number;
  discountType: DiscountType;
  type: ServiceType;
}

export interface FormService extends ServiceEntity {
  net: boolean;
}

export interface ServiceSchemaList {
  id: string;
  name: string;
  price: number;
  taxRate: TaxRate;
  net: boolean;
  discount: number;
  discountType: DiscountType;
  type: ServiceType;
  description: string;
}

export interface ServiceSchemaDetails {
  name: string;
  price: number;
  taxRate: TaxRate;
  discount: number;
  discountType: DiscountType;
  type: ServiceType;
  id: string;
}
