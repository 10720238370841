import { CostRes, TaxRate } from 'app-types';

interface LoadingSingleCost {
  type: 'LOADING_SINGLE_COST';
  payload: boolean;
}
interface GetSingleCostDetails {
  type: 'GET_SINGLE_COST_DETAILS_SUCCESS';
  payload: CostRes;
}

export interface CostState {
  loading: boolean;
  details: CostRes;
  image: string;
}

const initialState: CostState = {
  loading: true,
  details: {
    id: '',
    date: new Date(),
    fileName: null,
    name: '',
    value: 0,
    invoiceId: null,
    taxRate: TaxRate['21%'],
    paymentType: 0,
  },
  image: '',
};

type Action = LoadingSingleCost | GetSingleCostDetails;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'LOADING_SINGLE_COST': {
      return { ...state, loading: action.payload };
    }
    case 'GET_SINGLE_COST_DETAILS_SUCCESS': {
      return { ...state, details: action.payload };
    }

    default:
      return state;
  }
};
